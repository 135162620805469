import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import { handleDomainOperation } from './DomainOperation';
import DomainPageBody from './DomainPageBody';
import DomainPageHeader from './DomainPageHeader';
import DomainProvider, { useDomain } from './DomainProvider';

function PageCont() {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const { setDomainData, reloadKey } = useDomain();
    const [searchParams] = useSearchParams();
    const domain = searchParams.get('domain') || '';

    useEffect(() => {
        PageProperties('Domain');
    }, []);

    // -- Initial load data
    useEffect(() => {
        handleDomainOperation(authInfo, domain)
            .then(({ data }) => {
                setDomainData(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, domain, setDomainData, setMessage, reloadKey]);

    return (
        <div className="page-cont">
            <div className="domain-page">
                <DomainPageHeader sParams={{ domain }} />
                <DomainPageBody />
            </div>
        </div>
    );
}

const DomainPage = () => (
    <DomainProvider>
        <PageCont />
    </DomainProvider>
);
export default DomainPage;
