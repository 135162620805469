/* eslint-disable jsx-a11y/label-has-associated-control */

function CheckBox({ checked, blue }) {
    return (
        <div className={`input-checkbox ${blue ? 'blue-variant' : ''}`}>
            <input type="radio" checked={checked} readOnly />
            <label htmlFor="checkbox" />
        </div>
    );
}

export default CheckBox;
