import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apiPageMenu } from '../../../../data';
import PageProperties from '../../../utils/PageProperties';
import FinApiPageBody from './FinApiPageBody';
import FinApiPageHeader from './FinApiPageHeader';

function FinApiPage() {
    const { contId } = useParams();
    const menuData = apiPageMenu;
    useEffect(() => {
        PageProperties('API');
    }, []);

    return (
        <div className="page-cont">
            <div className="api-page">
                <FinApiPageHeader />
                <FinApiPageBody menu={menuData} contId={Number(contId)} />
            </div>
        </div>
    );
}

export default FinApiPage;
