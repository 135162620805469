import Show, {
    GetDate,
    calculateDiscountPercentage,
    showCurrency,
    showValueWithUnit
} from '../../../../../../utils/Show';
import { DeleteBtn, EditBtn } from '../../_models/ActionButtons';
import PriceDiscountInfo from '../../_models/PriceDiscountInfo';
import showPrice from '../../_models/ShowPrice';

function FinInvoiceDomain({ item, sl, isInvoiceActive, onClickEdit, onClickDelete }) {
    const {
        title,
        price,
        priceOffer,
        quantity,
        unitName,
        subscriptionStart,
        subscriptionEnd,
        properties,
        vat
    } = item || {};

    const priceDiscount = calculateDiscountPercentage(price, priceOffer);

    return (
        <tr className={!isInvoiceActive ? 'clickable' : ''} onClick={null}>
            <td>{sl}</td>
            <td>
                <h4>{title}</h4>
                <p>
                    <Show show={properties.domain}>
                        Domain: <b>{properties.domain}</b>
                        <br />
                    </Show>

                    <Show show={properties?.note}>
                        <b>{properties?.note}</b>
                    </Show>
                </p>
                <span>
                    Duration: {GetDate(subscriptionStart) || 0} to {GetDate(subscriptionEnd) || 0}
                </span>
            </td>
            <td>
                {showPrice(price, quantity)}
                {/* <span className="price-amount">{showCurrency(price * 12)}</span> <br />
                /Year */}
            </td>
            <td>
                {showValueWithUnit(quantity, unitName)}
                {/* {quantity || 0} {unitName} */}
            </td>
            <td>
                <span className="price-amount">{showCurrency(price * quantity)}</span>

                <PriceDiscountInfo priceDiscount={priceDiscount} vat={vat} />

                <div className="info-action-button flex-col position-middle position-space-between">
                    <EditBtn onClickEdit={onClickEdit} />
                    <DeleteBtn onClickDelete={onClickDelete} />
                </div>
            </td>
        </tr>
    );
}

export default FinInvoiceDomain;
