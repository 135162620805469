/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { ButtonCancel, ButtonCreate } from '../../../../features/user_updater/UserUpdaterButtons';
import AnimateInputField, { AnimateTextareaField } from '../../../../utils/AnimateInputField';
import { handelCreateUser } from '../UserListOperation';

function UserCreateModal({ setOpen }) {
    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        username: '',
        phone: '',
        organization: '',
        notes: ''
    });

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    const handleCreateInvoice = () => {
        setMessage('Creating Invoice...');
        handelCreateUser(authInfo, formData)
            .then(({ error, message }) => {
                if (error === 0) {
                    navigate(mkRelativeUrl(`/users/list/`));
                    setOpen(false);
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="user-updater-container flex-col align-stretch">
            <div className="user-custom-data flex-col gap-10 w-100p">
                <div className="currency-rate  w-100p flex-col gap-20 align-space-between position-middle">
                    <AnimateInputField
                        formData={formData}
                        handelChange={handelChange}
                        name="name"
                        title="Name"
                    />

                    <AnimateInputField
                        formData={formData}
                        handelChange={handelChange}
                        name="email"
                        title="Email"
                    />
                    <AnimateInputField
                        formData={formData}
                        handelChange={handelChange}
                        name="username"
                        title="Username"
                    />
                    <AnimateInputField
                        formData={formData}
                        handelChange={handelChange}
                        name="phone"
                        title="Phone"
                    />
                    <AnimateInputField
                        formData={formData}
                        handelChange={handelChange}
                        name="organization"
                        title="Organization"
                    />
                    <AnimateTextareaField
                        formData={formData}
                        handelChange={handelChange}
                        name="notes"
                        title={"Note's"}
                    />
                </div>
            </div>
            <div className="flex-row flex-row align-right mt-10">
                <ButtonCancel evOnClick={() => setOpen(false)} />
                <ButtonCreate evOnClick={handleCreateInvoice} />
            </div>
        </div>
    );
}

export default UserCreateModal;
