import { Link } from 'react-router-dom';

function SummaryHeader({ title, currency }) {
    return (
        <div className="summary_header flex-row position-middle align-space-between">
            <h2 className="summary_title">{title}</h2>
            {currency ? <h4 className="summary_currency">({currency})</h4> : null}
        </div>
    );
}

function SummaryItem({ label, value }) {
    return (
        <div className="flex-row align-space-between">
            <span>{label}:</span>
            <span className="amount-font">{value}</span>
        </div>
    );
}

function UserDashboardBody() {
    return (
        <div className="summary-overview-cont">
            <div className="flex-row flex-wrap align-stretch align-space-between">
                <Link to="/">
                    <SummaryHeader title="Total Invoice" />
                    <div className="info">
                        <SummaryItem label="Amount" value={0 || '-------------'} />
                    </div>
                </Link>

                <Link to="/">
                    <SummaryHeader title="Total Billed" />
                    <div className="info">
                        <SummaryItem label="Amount" value={0 || '-------------'} />
                    </div>
                </Link>

                <Link to="/">
                    <SummaryHeader title="Total Paid" />
                    <div className="info">
                        <SummaryItem label="Amount" value={0 || '-------------'} />
                    </div>
                </Link>

                <Link to="/">
                    <SummaryHeader title="Total Due" />
                    <div className="info">
                        <SummaryItem label="Amount" value={0 || '-------------'} />
                    </div>
                </Link>
            </div>

            <div className="flex-row flex-wrap align-stretch align-space-between">
                <Link to="/">
                    <SummaryHeader title="Support Tickets" />
                    <div className="info">
                        <SummaryItem label="Amount" value={0 || '-------------'} />
                    </div>
                </Link>

                <Link to="/">
                    <SummaryHeader title="Reward Points" />
                    <div className="info">
                        <SummaryItem label="Amount" value={0 || '-------------'} />
                    </div>
                </Link>

                <Link to="/" className="v-hide" />
                <Link to="/" className="v-hide" />
            </div>
        </div>
    );
}

export default UserDashboardBody;
