import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import InstOption from '../../../utils/InstOption';
import { mkRelativeUrl } from '../../menus/AdvMenuProvider';
import { UserInfoV2 } from '../../user-info/UserInfo';
import CustomerFundTransferModal from '../modal/CustomerFundTransferModal';
import UserBalanceInfo from './components/UserBalanceInfo';

function CustomerFundTransferSection({ show = true, user, reload }) {
    const [mode, setMode] = useState('');
    const [open, setOpen] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handelBalanceType = (type) => {
        setMode(type);
        setOpen(true);
    };

    if (!show) {
        return null;
    }
    return (
        <>
            <div className="fund-transfer-view-cont">
                <div className="fund-transfer-view_header flex-row align-space-between">
                    <h2>Customer Balance</h2>

                    {pathname.includes('sell-invoice') ? (
                        <div className="fund-transfer_option-cont">
                            <div tabIndex={-1} role="button" onClick={() => setShowMenu(true)}>
                                <img src={icons?.optionFill} alt="dot" />
                            </div>

                            <InstOption showMenu={showMenu} setShowMenu={setShowMenu}>
                                <button
                                    onClick={() =>
                                        navigate(
                                            mkRelativeUrl(
                                                `/finance/accounts/${user.facId}/statement/`
                                            )
                                        )
                                    }
                                    type="button"
                                >
                                    Statement
                                </button>
                            </InstOption>
                        </div>
                    ) : null}
                </div>

                <div className="">
                    <UserInfoV2 img={user?.photoUrl} name={user?.name} email={user?.email} />

                    <UserBalanceInfo
                        evBalanceType={handelBalanceType}
                        currency={user?.currency}
                        balance={user?.balance || 0}
                    />
                </div>
            </div>

            <CustomerFundTransferModal
                show={open}
                mode={mode}
                setOpen={setOpen}
                customerId={user?.id}
                currency={user?.currency}
                reload={reload}
            />
        </>
    );
}

export default CustomerFundTransferSection;
