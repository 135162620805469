import { MD5 } from 'crypto-js';
import { useRef } from 'react';
import { ButtonGray, ButtonRed } from '../../../configs/Buttons';
import { templateIcons } from './TemplateIcon';

function Buttons({ confirm, setConfirm, buttons }) {
    if (confirm.buttons) {
        return buttons.map((button, index) => (
            <div className="template-clickAction" key={MD5(index)}>
                {button}
            </div>
        ));
    }
    return (
        <div className="flex-row template-clickAction">
            <ButtonGray evOnClick={() => setConfirm(null)} title={confirm.textCancel || 'Cancel'} />

            <ButtonRed
                evOnClick={() => {
                    if (typeof confirm.clickAction === 'function') {
                        confirm.clickAction(confirm, setConfirm);
                    }
                }}
                title={confirm.textAction || 'Delete'}
            />
        </div>
    );
}

function ConfirmTemplate({ setConfirm, confirm }) {
    // setConfirm
    const ref = useRef();
    const { icon, text, buttons, showButton = true } = confirm || {};

    if (!confirm) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <div className="modal-box-body text-center">
                    <div className="modal template-confirm" ref={ref}>
                        <div className="confirm-content flex-col align-center position-space-around">
                            <img src={icon || templateIcons.tConfirm} alt="" />

                            <div>{text || '--'}</div>

                            {showButton && (
                                <div className="flex-row align-center position-middle">
                                    <Buttons
                                        setConfirm={setConfirm}
                                        confirm={confirm}
                                        buttons={buttons}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmTemplate;
