import { useEffect, useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import CustomerFundTransferSection from '../../../../features/customer_fund_transfer/view/CustomerFundTransferSection';
import { useInvoiceUpdate } from '../FinInvoiceUpdateProvider';
import FinInvoiceButtons from '../invoice_buttons/FinInvoiceButtons';
import FinInvoiceInfoActivity from './FinInvoiceInfoActivity';
import FinInvoiceInfoCurrencyChanger from './FinInvoiceInfoCurrencyChanger';
import FinInvoiceInfoEditor from './FinInvoiceInfoEditor';

function ShowContentButton({ setShow }) {
    return (
        <div
            onClick={(ev) => {
                ev.preventDefault();
                setShow((s) => !s);
            }}
            role="button"
            tabIndex={-1}
            className="show-content-button flex-row align-center position-middle"
        >
            <img src={icons.defaultIcon} alt="" />
        </div>
    );
}

function FinInvoiceRightCol() {
    const [show, setShow] = useState(false);
    const ref = useRef();

    const { invoiceData, openModal, handelChangeBasic, handelChangeCurrency, handelReload } =
        useInvoiceUpdate();
    const { amountPaid, status } = invoiceData?.info || {};

    // Outside click handler
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the clicked element is the "show-content-arrow" element
            const isArrowElement = event.target.classList.contains('show-content-arrow');

            // If it's the "show-content-arrow" element, do nothing and return early
            if (isArrowElement) {
                return;
            }

            // If the clicked element is outside the component, close the content
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show]);

    return (
        <>
            <ShowContentButton setShow={setShow} />
            <div
                ref={ref}
                className={`invoice-right-col-cont flex-col position-center ${show ? 'show' : ''}`}
            >
                <FinInvoiceButtons invoiceData={invoiceData} openModal={openModal} />

                <CustomerFundTransferSection user={invoiceData?.user} reload={handelReload} />

                <FinInvoiceInfoEditor
                    show={status !== 'active'}
                    key={`edit-info-${invoiceData.hash}`}
                    invoiceData={invoiceData}
                    handelChangeBasic={handelChangeBasic}
                />

                <FinInvoiceInfoCurrencyChanger
                    key={`edit-currency-${invoiceData.hash}`}
                    show={amountPaid === 0 && status !== 'active'}
                    currency={invoiceData.info.currency}
                    customRate={invoiceData.info.ratio}
                    handelChangeCurrency={handelChangeCurrency}
                />

                <FinInvoiceInfoActivity invoiceData={invoiceData} />
            </div>
        </>
    );
}

export default FinInvoiceRightCol;
