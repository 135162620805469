import { useRef } from 'react';
import { icons } from '../../../../../configs/Icons';
import BikTable from '../../../../features/bik-table/BikTable';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import { GetDate, showCurrency } from '../../../../utils/Show';

function FinAccountsTableRow({ item, setStatementId, handleEditModal }) {
    const ref = useRef(null);

    const handleBgActive = () => {
        setStatementId(item?.facId);
        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };
    return (
        <tr className={item?.demo ? 'placeholder-mode' : ''} ref={ref} onClick={handleBgActive}>
            <td className="text-left">
                <span>#{item?.facId}</span>
            </td>
            <td className="text-left">
                <UserInfoV3 name={item?.bankInfo.bankName} img={item?.bankInfo.photoUrl} />
            </td>
            <td className="text-left">
                <span className="text-dot">{item?.bankInfo.branchName || '-----'}</span>
            </td>
            <td className="text-center">
                <span>{showCurrency(item?.facCredit)}</span>
            </td>
            <td className="text-center">
                <span>{showCurrency(item?.facDebit)}</span>
            </td>
            <td className="text-center">
                <span>{showCurrency(item?.facBalance)}</span>
            </td>
            <td className="text-center">
                <span>{item?.facCurrency}</span>
            </td>
            <td style={{ padding: '0px', textAlign: 'center' }}>
                <span>{GetDate(item?.facTime) || '-----'}</span>
            </td>
            <td className="text-center text-capitalize">
                <span className={`status ${item?.status}`}>{item?.status}</span>
            </td>
            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button
                            type="button"
                            className=""
                            onClick={(e) => handleEditModal(e, item)}
                        >
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>

                    {/* <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        <button type="button" onClick={() => handleStatement(item?.facId)}>
                            Statement
                        </button>
                    </InstOption> */}
                </div>
            </td>
        </tr>
    );
}

function FinBankAccountsTable({ facList, setStatementId, handleEditModal }) {
    return (
        <BikTable
            countDataAr={facList}
            dataTh={[
                { title: 'FAC ID', width: '10', align: 'left' },
                { title: 'Details', width: '18', align: 'left' },
                { title: 'Branch', width: '8' },
                { title: 'Total Credit', width: '9' },
                { title: 'Total Debit', width: '9' },
                { title: 'Balance', width: '9' },
                { title: 'Currency', width: '9' },
                { title: 'Last Trans...', width: '10' },
                { title: 'Status', width: '10' },
                { title: 'Action', width: '8', align: 'right' }
            ]}
        >
            {facList?.map((item) => (
                <FinAccountsTableRow
                    item={item}
                    key={item?.facId}
                    setStatementId={setStatementId}
                    handleEditModal={handleEditModal}
                />
            ))}
        </BikTable>
    );
}

export default FinBankAccountsTable;
