import { showCurrency } from '../../../../../utils/Show';

function showPrice(price, duration) {
    if (parseFloat(duration) < 12) {
        return (
            <span>
                <span className="price-amount">{showCurrency(price)}</span> <br />
                /Month
            </span>
        );
    }
    return (
        <span>
            <span className="price-amount">{showCurrency(price * 12)}</span> <br />
            /Year
        </span>
    );
}

export default showPrice;
