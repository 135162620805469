import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ButtonGreenLine,
    ButtonOrangeLine,
    ButtonPurpleLine,
    ButtonRed,
    ButtonRedLine,
    ButtonSkyLine
} from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import InvoiceProductEditOptions from '../../../../features/invoice_product_editor/InvoiceProductEditOptions';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { handelMakeActive } from '../FinInvoiceOperation';
import { useInvoiceUpdate } from '../FinInvoiceUpdateProvider';
import FinInvoiceDelete from '../modals/FinInvoiceDelete';

function Container({ show, children, cName }) {
    if (!show) {
        return null;
    }
    return <div className={cName}>{children}</div>;
}

function FinInvoiceButtons({ invoiceData, openModal }) {
    const { status, amountPayable, amountPaid, id, vatPayable } = invoiceData?.info || {};

    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();

    const { setMessage, setConfirm } = useTemplate();
    const { authInfo } = useAuth();
    const { handelReload } = useInvoiceUpdate();

    const isVatPayable = vatPayable > 0;
    const isStatusActive = status === 'active';
    const isStatusPending = status === 'pending';
    const isPayableZero = amountPayable === 0;
    const isPaidZero = amountPaid === 0;

    // Invoice Product Add
    const handelAddProduct = () => {
        setShowMenu(!showMenu);
    };

    // Invoice Payment Add
    const handelAddPayment = () => {
        // setOpenModal({ mode: true, type: 'add-payment' });
        openModal('inv-add-payment');
    };

    // Invoice Vat Pay
    const handelVatPay = () => {
        // setOpenModal({ mode: true, type: 'vat-pay' });
        openModal('inv-vat-pay');
    };

    // Invoice Balance Refund
    const handelRefund = () => {
        // setOpenModal({ mode: true, type: 'refund' });
        openModal('inv-payment-refund');
    };

    // Invoice Active
    const handelSetActive = () => {
        setConfirm({
            text: 'Do you want to active this invoice?',
            textAction: 'Active',
            clickAction: () => {
                setMessage('Activating Invoice...');
                handelMakeActive(authInfo, id)
                    .then(({ message }) => {
                        setMessage(message);
                        setConfirm(null);
                        handelReload();
                    })
                    .catch((err) => {
                        setMessage(err.toString());
                    });
            }
        });
    };

    // Invoice Reopen
    const handelReOpen = () => {
        // setOpenModal({ mode: true, type: 'reopen' });
        openModal('inv-reopen');
    };

    // Invoice Delete
    const handelDelete = () => {
        const step1 = {
            buttons: [<ButtonRed evOnClick={() => setConfirm(null)} title="Ok" />],
            text: 'Delete products to remove this invoice!'
        };

        const step2 = {
            text: (
                <FinInvoiceDelete
                    navigate={navigate}
                    invoiceId={id}
                    authInfo={authInfo}
                    setMessage={setMessage}
                    setConfirm={setConfirm}
                />
            ),
            showButton: false
        };

        if (invoiceData?.items?.length) {
            setConfirm(step1);
        } else {
            setConfirm(step2);
        }
    };

    // Send Push Notification
    const handelSendPushNotification = () => {
        openModal('inv-send-push');
    };

    // Send Email
    const handelSendEmail = () => {
        openModal('inv-send-email');
    };

    // Send SMS
    const handelSendSms = () => {
        openModal('inv-send-sms');
    };

    return (
        <div className="invoice-info-cont buttons">
            <div className="info-section_header">
                <h2 className="info-section_title">Useful Button</h2>
            </div>

            <div className="invoice-info_buttons-cont flex-row flex-wrap">
                <Container cName="p-relative" show={!isStatusActive}>
                    <span>
                        <ButtonSkyLine evOnClick={handelAddProduct} title="Add Product" />
                    </span>

                    <InvoiceProductEditOptions
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                        id={id}
                    />
                </Container>

                <Container show={!isStatusActive || !isPayableZero}>
                    <span>
                        <ButtonOrangeLine evOnClick={handelAddPayment} title="Add Payment" />
                    </span>
                </Container>

                <Container show={isVatPayable}>
                    <span>
                        <ButtonPurpleLine evOnClick={handelVatPay} title="Vat Pay" />
                    </span>
                </Container>

                <Container show={!isStatusActive}>
                    <span>
                        <ButtonOrangeLine evOnClick={handelRefund} title="Refund" />
                    </span>
                </Container>

                <Container show={!isStatusActive}>
                    <span>
                        <ButtonPurpleLine evOnClick={handelSetActive} title="Set as Active" />
                    </span>
                </Container>

                <Container show={isStatusActive}>
                    <span>
                        <ButtonGreenLine evOnClick={handelReOpen} title="Reopen" />
                    </span>
                </Container>

                <Container show={isPaidZero && isStatusPending}>
                    <span>
                        <ButtonRedLine evOnClick={handelDelete} title="Delete" />
                    </span>
                </Container>

                <Container show>
                    <span>
                        <ButtonGreenLine
                            evOnClick={handelSendPushNotification}
                            title="Send Push Notification"
                        />
                    </span>
                </Container>

                <Container show>
                    <span>
                        <ButtonOrangeLine evOnClick={handelSendEmail} title="Send Email" />
                    </span>
                </Container>

                <Container show>
                    <span>
                        <ButtonPurpleLine evOnClick={handelSendSms} title="Send Sms" />
                    </span>
                </Container>
            </div>
        </div>
    );
}

export default FinInvoiceButtons;
