function UserUpdaterSelectedUser({ data }) {
    // if (!show) {
    //     return null;
    // }
    return (
        <div
            className={`user-row selected flex-row align-space-between position-middle ${
                !data ? 'p-mode' : ''
            }`}
        >
            <div>
                <div className="user-updater_avatar ">
                    <span>
                        <img src={data?.photoUrl} alt="avatar" referrerPolicy="no-referrer" />
                    </span>
                </div>
            </div>
            <div>
                <div className="flex-row position-middle align-space-between">
                    <h2 className="user-updater_title">
                        <span>{data?.name || '-------------------'}</span>
                    </h2>
                    <p className="user-updater_tag new">New</p>
                </div>

                <p className="user-updater_sub-title">
                    <span>{data?.email || '------------------------'}</span>
                </p>
            </div>
        </div>
    );
}

export default UserUpdaterSelectedUser;
