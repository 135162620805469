/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InvoiceProductEditModal from '../../../features/invoice_product_editor/InvoiceProductEditModal';
import InvoiceProductEditorProvider, {
    useProductEditor
} from '../../../features/invoice_product_editor/InvoiceProductEditorProvider';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import NotFound from '../../../features/not-found/NotFound';
import PageProperties from '../../../utils/PageProperties';
import FinInvoiceUpdateProvider, { useInvoiceUpdate } from './FinInvoiceUpdateProvider';
import FinInvoiceModalController from './_models/FinInvoiceModalController';
import FinInvoiceRightCol from './components/FinInvoiceRightCol';
import FinInvoicePageHeader from './invoice_header/FinInvoicePageHeader';
import FinInvoiceView from './invoice_view/FinInvoiceView';
import getInvoiceTitle from './invoice_view/_models/GetInvoiceTitle';

function FinInvoiceBody({ invoiceData, openModal, printVersion, handelRemoveProduct }) {
    const { handelOpenModal } = useProductEditor();
    const navigate = useNavigate();

    if (!invoiceData.loading && invoiceData.error !== 0) {
        return (
            <NotFound
                title="Invoice Not Found"
                btnTitle="Back to List"
                evButtonClick={() => navigate(mkRelativeUrl(`/finance/sell-invoice/`))}
            />
        );
    }

    return (
        <div
            className={`invoice-page-container flex-row align-space-between ${
                invoiceData.loading ? 'placeholder-mode' : ''
            }`}
        >
            <div>
                <FinInvoiceView
                    openModal={openModal}
                    invoiceData={invoiceData}
                    printVersion={printVersion}
                    openProductModal={handelOpenModal}
                    handelRemoveProduct={handelRemoveProduct}
                />
            </div>
            <div>
                <FinInvoiceRightCol />
            </div>
        </div>
    );
}

function PageComp({ invoiceId }) {
    const { invoiceData, openModal, printVersion, handelRemoveProduct } = useInvoiceUpdate();

    useEffect(() => {
        PageProperties(getInvoiceTitle('Invoice', invoiceData?.info?.id, invoiceData?.info?.title));
    }, [invoiceData.info.id, invoiceData.info.title, invoiceData.title]);

    return (
        <div className="page-cont invoice-page-cont">
            <FinInvoicePageHeader invoiceId={invoiceId} />

            <FinInvoiceBody
                invoiceData={invoiceData}
                openModal={openModal}
                printVersion={printVersion}
                handelRemoveProduct={handelRemoveProduct}
            />
        </div>
    );
}

function FinInvoiceUpdatePage({ id }) {
    const { invoiceId } = useParams();

    return (
        <FinInvoiceUpdateProvider id={invoiceId || id}>
            <InvoiceProductEditorProvider>
                <PageComp invoiceId={invoiceId || id} />

                <FinInvoiceModalController />
                <InvoiceProductEditModal />
            </InvoiceProductEditorProvider>
        </FinInvoiceUpdateProvider>
    );
}

export default FinInvoiceUpdatePage;
