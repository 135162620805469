import { useNavigate } from 'react-router-dom';
import notFound from '../../../assets/images/not-found.png';
import { ButtonPurple } from '../../../configs/Buttons';

function NotFound({ title, btnTitle, evButtonClick, path }) {
    const navigate = useNavigate();

    const handelButtonClick = () => {
        if (evButtonClick) {
            evButtonClick();
        } else {
            navigate(path);
        }
    };

    return (
        <div className="not-found flex-col align-center">
            <img src={notFound} alt="" />
            <h2>{title || 'Not Found'}</h2>

            {btnTitle && <ButtonPurple evOnClick={handelButtonClick} title={btnTitle || 'Back'} />}
        </div>
    );
}

export default NotFound;
