import React from 'react';
import TransactionSummary from '../../../features/amount_summary/TransactionSummary';
import FinTransTable from './components/FinTransTable';

function NoCurrency() {
    return <div className="feature-no-currency">Select any currency to view summary</div>;
}

function FinTransBody({ transData, transSummary, currency, queryObj }) {
    return (
        <div className="fin-trans-page__body">
            <FinTransTable transData={transData} queryObj={queryObj} />
            <TransactionSummary summaryData={transSummary} currency={currency}>
                {!queryObj.currency && <NoCurrency />}
            </TransactionSummary>
        </div>
    );
}

export default FinTransBody;
