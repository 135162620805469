import dayjs from 'dayjs';

export const duration = (months) => {
    if (months < 12) {
        return `${months} Month`;
    }

    return `${months / 12} Year`;
};

export function GetDate(timeStamp) {
    if (!timeStamp) {
        return null;
    }
    const dateFormat = dayjs(timeStamp * 1000).format(`DD ${`MMMM`.slice(0, 3)} YYYY`);
    return dateFormat.toString() !== 'Invalid Date' ? dateFormat : '--';
}

export function GetTime(timeStamp) {
    if (!timeStamp) {
        return null;
    }
    const dateFormat = dayjs(timeStamp * 1000).format('h:mm:ss A');
    return dateFormat.toString() || null;
}

export const getDayDiff = (timestamp) => {
    const currentDate = dayjs();
    const expireDate = dayjs(timestamp * 1000);

    const days = expireDate?.diff(currentDate, 'd');

    if (!timestamp || days < 0) {
        return null;
    }

    return days;
};

// date duration (start to end)
export const getDateDuration = (start, end) => {
    const dateValue = typeof start === 'number' ? start * 1000 : start;

    const startDate = dayjs(dateValue).format('DD MMMM YYYY');

    const endDate = dayjs(dateValue, 'DD MMMM YYYY').add(end, 'month').format('DD MMMM YYYY');

    return `${startDate} to ${endDate}`;
};

// calculate discount percentige
export const calculateDiscountPercentage = (price, offerPrice) => {
    const total = parseFloat(price);
    const offer = parseFloat(offerPrice);

    if (total === offer) {
        return 0; // Return null if both prices are the same
    }

    const discountPercentage = ((total - offer) / total) * 100;

    // if (discountPercentage <= 0) {
    //     return NaN;
    // }

    return discountPercentage || 0;
};

// file size display
export const fileSizeDisplay = (bytes, text) => {
    const megabytes = bytes / (1024 * 1024);
    const gigabytes = megabytes / 1024;

    let usageText = `${megabytes.toFixed(2)} MB`;

    if (gigabytes >= 1) {
        usageText = `${gigabytes.toFixed(2)} GB`;
    }

    return <span>{`${usageText} ${text || 'Used'}` || '--'}</span>;
};

export function UpperCase(item) {
    const itemString = String(item);

    if (itemString.toLowerCase() === 'usd') {
        return 'USD';
    }

    return itemString?.charAt(0).toUpperCase() + itemString?.slice(1);
}

export function showCurrency(number) {
    if (Number.isNaN(number)) {
        return 0;
    }
    return number?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function showValueWithUnit(value, unit) {
    const map = {
        month: { s: 'Month', p: 'Months' },
        year: { s: 'Year', p: 'Years' },
        pcs: { s: 'Pcs', p: 'Pcs' },
        set: { s: 'Set', p: 'Sets' },
        emails: { s: 'Emails', p: 'Emails' },
        sms: { s: 'SMS', p: 'SMS' },
        package: { s: 'Package', p: 'Packages' },
        usd: { s: 'USD', p: 'USD' },
        noUnit: { s: '', p: '' }
    };

    if (value && map[unit]) {
        if (value > 1) {
            if (value >= 12 && unit === 'month') {
                const yearValue = (value / 12) % 1 === 0 ? value / 12 : (value / 12)?.toFixed(2);
                return (
                    <span>
                        <b>{yearValue || 0}</b> &nbsp;
                        {yearValue > 1 ? map.year.p : map.year.s}
                    </span>
                );
            }
            return (
                <span>
                    <b>{value || 0}</b> &nbsp;
                    {map[unit].p}
                </span>
            );
        }
        return (
            <span>
                <b>{value || 0}</b> &nbsp;
                {map[unit].s}
            </span>
        );
    }
    return (
        <span>
            <b>{value || 0}</b> &nbsp;
            {unit}
        </span>
    );
}

export default function Show({ show = true, children }) {
    if (!show) {
        return null;
    }
    return children;
}
