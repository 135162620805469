import dayjs from 'dayjs';
import 'flatpickr/dist/flatpickr.css';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import { DateRange } from '../../../lib/LibDatePicker';

function ButtonReload({ handelReload }) {
    return (
        <div className="content-reload">
            <div className="content-refresh-btn">
                <button type="button" className="p-relative" onClick={handelReload}>
                    <img src={icons.reloadL} alt="" />
                    <img src={icons.reloadF} alt="" />
                </button>
            </div>
        </div>
    );
}

function FinUserStatementHeader({ statementId, startDate, endDate, handelReload }) {
    const navigate = useNavigate();
    const ref = useRef();

    const [showMenu, setShowMenu] = useState(false);

    const [searchVal, setSearchVal] = useState({
        dateRange: '',
        accountId: statementId,
        accountType: ''
    });

    useEffect(() => {
        ref.current.onchange = (e) => {
            e.preventDefault();
            const { value } = e.target;

            if (value.length === 8 && /^\d*$/.test(value)) {
                navigate(
                    mkRelativeUrl(`/finance/accounts/${value}/statement/`, {
                        'date-start': startDate,
                        'date-end': endDate
                    })
                );
                handelReload();
            }
        };

        ref.current.value = searchVal.accountId;
    }, [searchVal.accountId, startDate, endDate, navigate, handelReload]);

    const handleDateChange = (e) => {
        if (e.length === 2) {
            const url = mkRelativeUrl(`/finance/accounts/${searchVal.accountId}/statement`, {
                'date-start': dayjs(e[0]).format('YYYY-MM-DD'),
                'date-end': dayjs(e[1]).format('YYYY-MM-DD')
            });

            navigate(url);
        }
    };
    const handlePrintStatement = () => {
        window.print();
    };
    return (
        <div className="fin-user-statement__header flex-row position-middle align-space-between gap-50">
            <div className="text-30 font-500">Statement</div>
            <div className="flex-row position-middle gap-20 fill">
                <div className="user-custom-data gap-5">
                    <input
                        className="custom-input"
                        type="text"
                        maxLength={8}
                        onChange={(e) => setSearchVal({ ...searchVal, accountId: e.target.value })}
                        value={searchVal.accountId}
                        placeholder="Account ID"
                        ref={ref}
                    />
                </div>

                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    handelOnChange={handleDateChange}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                    navigatePath={`/finance/accounts/${searchVal.accountId}/statement`}
                />

                {/* <div
                    className="user-custom-data gap-5"
                    onChange={(e) => setSearchVal({ ...searchVal, accountType: e.target.value })}
                >
                    <select  className='custom-select' >
                        <option value="">Account Type</option>
                        <option value="debit">Debit</option>
                        <option value="credit">Credit</option>
                    </select>
                </div> */}
            </div>
            <div className="primary-button flex-row">
                <div>
                    <ButtonReload handelReload={handelReload} />
                </div>

                <button
                    className="flex-row position-middle align-center"
                    type="button"
                    onClick={handlePrintStatement}
                >
                    <img src={icons.printer} alt="" />
                    <span>Print Statement</span>
                </button>
            </div>
        </div>
    );
}

export default FinUserStatementHeader;
