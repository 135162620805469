import AxiosAPI from '../../../utils/AxiosAPI';

export function handelLoadFac(authInfo, accountType, currency, transaction, pageNo, cpp) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/fac/list/`, {
                'account-type': accountType,
                currency,
                transaction,
                'page-no': pageNo,
                cpp
            })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleSaveBankAccOperation(authInfo, formData) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/bank-account/create/`)
            .post(formData)
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleUpdateBankAccOperation(authInfo, formData) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/bank-account/update/`)
            .post(formData)
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}
export default null;
