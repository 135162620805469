import React from 'react';
import { ProductInputField, ProductTextAreaField } from '../components/ProductEditorInputs';

function DomainInputs() {
    return (
        <div className="flex-row flex-wrap align-space-between inputs-cont">
            <ProductInputField title="Domain" name="domain" />

            <ProductTextAreaField title="Notes" name="note" />
        </div>
    );
}

export default DomainInputs;
