import { useEffect, useRef, useState } from 'react';
import { icons } from '../../../../configs/Icons';
import InvoiceProductEditOptions from '../InvoiceProductEditOptions';
import CustomProductInputs from '../property-inputs/CustomProductInputs';
import DedicatedServerInputs from '../property-inputs/DedicatedServerInputs';
import DomainInputs from '../property-inputs/DomainInputs';
import EmailInputs from '../property-inputs/EmailInputs';
import HostingInputs from '../property-inputs/HostingInputs';
import PremiumContractInputs from '../property-inputs/PremiumContractInputs';
import PushInputs from '../property-inputs/PushInputs';
import SmsInputs from '../property-inputs/SmsInputs';
import VpsInputs from '../property-inputs/VpsInputs';

export const PropertyChanger = ({ modalProperty }) => {
    const [showMenu, setShowMenu] = useState(false);
    const ref = useRef();

    if (ref.current) {
        ref.current.parentElement.style.opacity = 1;
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                ref.current.parentElement.style.opacity = '0.7';
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="property-changer flex-col position-middle" ref={ref}>
            <div
                className="flex-row align-space-between position-middle"
                role="button"
                tabIndex={-1}
                onClick={() => setShowMenu(true)}
            >
                <span>{modalProperty.property}</span>
                <img src={icons.downArrow} alt="down" />
            </div>
            <InvoiceProductEditOptions showMenu={showMenu} setShowMenu={setShowMenu} />
        </div>
    );
};

export const getProperty = (property) => {
    if (property === 'domain') return <DomainInputs />;

    if (property === 'hosting') return <HostingInputs />;

    if (property === 'server_dedicated') return <DedicatedServerInputs />;

    if (property === 'server_vps') return <VpsInputs />;

    if (property === 'premium_contract') return <PremiumContractInputs />;

    if (property === 'push') return <PushInputs />;

    if (property === 'sms') return <SmsInputs />;

    if (property === 'email') return <EmailInputs />;

    if (property === 'custom_product') return <CustomProductInputs />;

    return null;
};

export default null;
