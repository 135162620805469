import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { contractPackageList } from '../../../../data';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import { handlePackageOpe } from './PackageOperation';
import PackagePageBody from './PackagePageBody';
import PackagePageHeader from './PackagePageHeader';

function PackagePage() {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const [searchParams] = useSearchParams();
    const [pkgDataList, setPkgDataList] = useState(contractPackageList);
    const [reloadKey, setReloadKey] = useState(0);

    const startDate = searchParams.get('date-start') || '';
    const endDate = searchParams.get('date-end') || '';
    const q = searchParams.get('q') || '';

    const handleReload = () => {
        setPkgDataList(contractPackageList);
        setReloadKey((cuData) => cuData + 1);
    };

    useEffect(() => {
        handlePackageOpe(authInfo, startDate, endDate, q)
            .then(({ data }) => {
                setPkgDataList(data);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    }, [authInfo, endDate, q, setMessage, startDate, reloadKey]);

    const handleStarred = (e, id) => {
        e.stopPropagation();
        const newData = pkgDataList.map((item) => {
            if (item?.id === id) {
                return {
                    ...item,
                    favorite: !item?.favorite
                };
            }
            return item;
        });
        setPkgDataList(newData);
    };
    useEffect(() => {
        PageProperties('Virtual Private Server');
    }, []);

    return (
        <div className="page-cont">
            <div className="package-page">
                <PackagePageHeader handleReload={handleReload} />
                <PackagePageBody pkgDataList={pkgDataList} handleStarred={handleStarred} />
            </div>
        </div>
    );
}

export default PackagePage;
