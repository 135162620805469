import { useDocs } from '../DocsProvider';

function Modules() {
    const { modulesData } = useDocs();

    return (
        <div className="modules-list flex-col gap-10">
            <div className="modules-title text-22 font-500">Modules:</div>
            <div className="modules-items flex-col gap-5 ">
                {modulesData.map((items, key) => (
                    <div
                        key={items.sl}
                        className="flex-row align-left text-indent-25 text-16 font-400"
                    >
                        {key + 1}. {items.title}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Modules;
