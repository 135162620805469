import { Link } from 'react-router-dom';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import { showCurrency } from '../../../utils/Show';

function SummaryHeader({ title, currency }) {
    return (
        <div className="summary_header flex-row position-middle align-space-between">
            <h2 className="summary_title">{title}</h2>
            <h4 className="summary_currency">({currency})</h4>
        </div>
    );
}

function SummaryItem({ label, value }) {
    return (
        <div className="flex-row align-space-between">
            <span>{label}:</span>
            <span className="amount-font">{showCurrency(value) || '-------------'}</span>
        </div>
    );
}

function FinancialDashboardBody({ dashboardData, startDate, endDate }) {
    // transactions
    const tranactionUrl = (currency) =>
        mkRelativeUrl('/finance/transactions', {
            'date-start': startDate,
            'date-end': endDate,
            currency
        });

    const {
        userDeposit: bdtDeposit,
        userPayment: bdtPayment,
        userRefund: bdtRefund,
        userWithdraw: bdtWithdraw
    } = dashboardData?.transaction?.bdt || {};

    const {
        userDeposit: usdDeposit,
        userPayment: usdPayment,
        userRefund: usdRefund,
        userWithdraw: usdWithdraw
    } = dashboardData?.transaction?.usd || {};

    // invoice
    const invoiceUrl = (currency) =>
        mkRelativeUrl('/finance/sell-invoice', {
            'due-from': startDate,
            'due-to': endDate,
            currency
        });

    const {
        discount: bdtDiscount,
        paid: bdtPaid,
        payable: bdtPayable,
        productPrice: bdtProductPrice,
        vat: bdtVat
    } = dashboardData?.invoice?.bdt || {};

    const {
        discount: usdDiscount,
        paid: usdPaid,
        payable: usdPayable,
        productPrice: usdProductPrice,
        vat: usdVat
    } = dashboardData?.invoice?.usd || {};

    return (
        <div className={`fin-db-body ${!dashboardData ? 'placeholder-mode' : ''}`}>
            {/* <FinChart /> */}
            <div className="summary-overview-cont">
                <div className="flex-row flex-wrap align-stretch align-space-between">
                    {/* Deposit Info - BDT */}
                    <Link to={tranactionUrl('BDT')}>
                        <SummaryHeader title="Deposits" currency="BDT" />
                        <div className="info">
                            <SummaryItem label="Deposit" value={bdtDeposit} />
                            <SummaryItem label="Withdraw" value={bdtWithdraw} />
                            <SummaryItem label="Net Deposit" value={bdtDeposit - bdtWithdraw} />
                        </div>
                    </Link>

                    {/* Sales Info - BDT */}
                    <Link to={tranactionUrl('BDT')}>
                        <SummaryHeader title="Sales" currency="BDT" />
                        <div className="info">
                            <SummaryItem label="Sale" value={bdtPayment} />
                            <SummaryItem label="Refund" value={bdtRefund} />
                            <SummaryItem label="Net Sale" value={bdtPayment - bdtRefund} />
                        </div>
                    </Link>

                    {/* Deposit Info - USD */}
                    <Link to={tranactionUrl('USD')}>
                        <SummaryHeader title="Deposits" currency="USD" />
                        <div className="info">
                            <SummaryItem label="Deposit" value={usdDeposit} />
                            <SummaryItem label="Withdraw" value={usdWithdraw} />
                            <SummaryItem label="Net Deposit" value={usdDeposit - usdWithdraw} />
                        </div>
                    </Link>

                    {/* Sales Info - USD */}
                    <Link to={tranactionUrl('USD')}>
                        <SummaryHeader title="Sales" currency="USD" />
                        <div className="info">
                            <SummaryItem label="Sale" value={usdPayment} />
                            <SummaryItem label="Refund" value={usdRefund} />
                            <SummaryItem label="Net Sale" value={usdPayment - usdRefund} />
                        </div>
                    </Link>
                </div>

                <div className="flex-row flex-wrap align-stretch align-space-between">
                    {/* Collections - BDT */}
                    <Link to={invoiceUrl('BDT')}>
                        <SummaryHeader title="Collections" currency="BDT" />
                        <div className="info">
                            <SummaryItem label="Product Price" value={bdtProductPrice} />
                            <SummaryItem label="Discount" value={bdtDiscount} />
                            <SummaryItem label="VAT" value={bdtVat} />
                            <SummaryItem label="Paid" value={bdtPaid} />
                            <SummaryItem label="Payable" value={bdtPayable} />
                        </div>
                    </Link>

                    {/* Collections - USD */}
                    <Link to={invoiceUrl('USD')}>
                        <SummaryHeader title="Collections" currency="USD" />
                        <div className="info">
                            <SummaryItem label="Product Price" value={usdProductPrice} />
                            <SummaryItem label="Discount" value={usdDiscount} />
                            <SummaryItem label="VAT (Where Applicable)" value={usdVat} />
                            <SummaryItem label="Paid" value={usdPaid} />
                            <SummaryItem label="Payable" value={usdPayable} />
                        </div>
                    </Link>

                    <Link to="/" className="v-hide" />
                    <Link to="/" className="v-hide" />
                </div>
            </div>
        </div>
    );
}

export default FinancialDashboardBody;
