import React from 'react';

function DomainTechStatus() {
    return (
        <div className="tech-info__status flex-row align-left">
            <div className="tech-info__status-left">
                <div className="status-left__current">
                    <span>Current Status:</span>
                    <span>&bull;Active</span>
                </div>
                <div className="status-left__privacy">
                    <span>Privacy Protection: </span>
                    <span>&bull;Disable</span>
                </div>
                <div className="status-left__theft">
                    <span>Theft Protection: </span>
                    <span>&bull;Disable</span>
                </div>
                <div className="status-left__server flex-row">
                    <span>Name Server:</span>
                    <span>
                        <div>NS1.bikiran.com</div>
                        <div>NS2.bikiran.com</div>
                    </span>
                </div>
            </div>
            <div className="tech-info__status-right">
                <div className="status-left__expired flex-row position-top">
                    <span>Expired on:</span>
                    <span>
                        <div>30 July 2020</div>
                        <div className="remain-day">194 Days Remain</div>
                    </span>
                </div>
                <div className="status-left__registered">
                    <span>Registered on:</span>
                    <span>30 July 2019</span>
                </div>
                <div className="status-left__renew">
                    <span>Renew Price:</span>
                    <span>USD 12.99</span>
                </div>
            </div>
        </div>
    );
}

export default DomainTechStatus;
