import React from 'react';
import PackageTable from './components/PackageTable';

function PackagePageBody({ pkgDataList, handleStarred }) {
    return (
        <div className="package-page__body">
            <PackageTable pkgDataList={pkgDataList} handleStarred={handleStarred} />
        </div>
    );
}

export default PackagePageBody;
