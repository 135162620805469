// Menu Category Icon
import accountFillS from '../assets/images/icon-services/icon-account-fill.svg';
import accountLineS from '../assets/images/icon-services/icon-account-line.svg';
import bmFillS from '../assets/images/icon-services/icon-business-fill.svg';
import bmLineS from '../assets/images/icon-services/icon-business-line.svg';
import contractFillS from '../assets/images/icon-services/icon-contract-fill.svg';
import contractLineS from '../assets/images/icon-services/icon-contract-line.svg';
import emailFillS from '../assets/images/icon-services/icon-email-fill.svg';
import emailLineS from '../assets/images/icon-services/icon-email-line.svg';
import financeFillS from '../assets/images/icon-services/icon-finance-fill.svg';
import financeLineS from '../assets/images/icon-services/icon-finance-line.svg';
import hrmFillS from '../assets/images/icon-services/icon-hrm-fill.svg';
import hrmLineS from '../assets/images/icon-services/icon-hrm-line.svg';
import pushFillS from '../assets/images/icon-services/icon-push-fill.svg';
import pushLineS from '../assets/images/icon-services/icon-push-line.svg';
import menu from '../assets/images/icons/icon-menu.svg';

//
import access from '../assets/images/icons/icon-access.svg';
import api from '../assets/images/icons/icon-api.svg';
import billing from '../assets/images/icons/icon-billing.svg';
import campaign from '../assets/images/icons/icon-campaign.svg';
import config from '../assets/images/icons/icon-config.svg';
import dashboard from '../assets/images/icons/icon-dashboard.svg';
import report from '../assets/images/icons/icon-report.svg';

// Api page icons
import campaignBg from '../assets/images/icons/icon-campaign-bg.svg';
import defaultIcon from '../assets/images/icons/icon-default-fill.svg';
import defaultIconBg from '../assets/images/icons/icon-default-white-bg.svg';
import defaultIconWhite from '../assets/images/icons/icon-default-white-fill.svg';

import leftArrow from '../assets/images/icons/arrow-left.svg';
import avatar from '../assets/images/icons/icon-avatar.jpg';
import bell from '../assets/images/icons/icon-bell.svg';
import bikLogo from '../assets/images/icons/icon-bik-logo.svg';
import bikLogoV2 from '../assets/images/icons/icon-bik-logoV2.svg';
import contactIcon from '../assets/images/icons/icon-contact.svg';
import contract from '../assets/images/icons/icon-contract.svg';
import corner from '../assets/images/icons/icon-corner.svg';
import crossGray from '../assets/images/icons/icon-cross-gray.svg';
import crossRed from '../assets/images/icons/icon-cross-red.svg';
import domainIcon from '../assets/images/icons/icon-domain.svg';
import downArrow from '../assets/images/icons/icon-down-arrow.svg';
import editFill from '../assets/images/icons/icon-edit-fill.svg';
import editGray from '../assets/images/icons/icon-edit-gray.svg';
import emailIcon from '../assets/images/icons/icon-email.svg';
import fb from '../assets/images/icons/icon-fb.svg';
import filter from '../assets/images/icons/icon-filter.svg';
import hostingIcon from '../assets/images/icons/icon-hosting.svg';
import infoV2 from '../assets/images/icons/icon-info-v2.svg';
import infoV3 from '../assets/images/icons/icon-info-v3.svg';
import info from '../assets/images/icons/icon-info.svg';
import logout from '../assets/images/icons/icon-logout.svg';
import option from '../assets/images/icons/icon-options.svg';
import product from '../assets/images/icons/icon-product.svg';
import pushNotification from '../assets/images/icons/icon-push-notification.svg';
import rightArrow from '../assets/images/icons/icon-right-arrow.svg';
import search from '../assets/images/icons/icon-search.svg';
import serverIcon from '../assets/images/icons/icon-server.svg';
import services from '../assets/images/icons/icon-service.svg';
import smsIcon from '../assets/images/icons/icon-sms.svg';
import tickIcon from '../assets/images/icons/icon-tick.svg';
import vpsIcon from '../assets/images/icons/icon-vps.svg';
import dot from '../assets/images/icons/icon_dot.svg';
import invoiceFooter from '../assets/images/invoice-footer.svg';
import invoiceHeader from '../assets/images/invoice-header.svg';

import bahannoNews from '../assets/images/icons/icon-bahanno.ico';
import callingBell from '../assets/images/icons/icon-callingbell.svg';

import iconAddFaxFill from '../assets/images/icons/icon-add-fax-fill.svg';
import iconAddFax from '../assets/images/icons/icon-add-fax.svg';
import iconAddTelFill from '../assets/images/icons/icon-add-telephone-fill.svg';
import iconAddTel from '../assets/images/icons/icon-add-telephone.svg';
import iconMinusFill from '../assets/images/icons/icon-minus-fill.svg';
import iconMinus from '../assets/images/icons/icon-minus.svg';

// Action icons
import deleteBg from '../assets/images/icons/icon-delete-bg.svg';
import editBg from '../assets/images/icons/icon-edit-bg.svg';
import printer from '../assets/images/icons/icon-printer-bulk.svg';
import reloadF from '../assets/images/icons/icon-reload-fill.svg';
import reloadFill from '../assets/images/icons/icon-reload-fillV2.svg';
import reloadL from '../assets/images/icons/icon-reload-line.svg';
import reloadLine from '../assets/images/icons/icon-reload-lineV2.svg';
import dotVh from '../assets/images/icons/icon-three-dot-vh.svg';

// Campaign List Page
import duplicateFill from '../assets/images/icons/icon-duplicate-bg.svg';
import optionFill from '../assets/images/icons/icon-option-bg.svg';
import pauseFill from '../assets/images/icons/icon-pause-bg.svg';
import playFill from '../assets/images/icons/icon-play-bg.svg';

// Advance Menu
import attendanceFill from '../assets/images/icon-menu/icon-attendance-fill.svg';
import attendanceLine from '../assets/images/icon-menu/icon-attendance-line.svg';
import campaignFill from '../assets/images/icon-menu/icon-campaign-fill.svg';
import campaignLine from '../assets/images/icon-menu/icon-campaign-line.svg';
import codeFill from '../assets/images/icon-menu/icon-code-fill.svg';
import codeLine from '../assets/images/icon-menu/icon-code-line.svg';
import configFill from '../assets/images/icon-menu/icon-config-fill.svg';
import configLine from '../assets/images/icon-menu/icon-config-line.svg';
import contractFill from '../assets/images/icon-menu/icon-contract-fill.svg';
import contractLine from '../assets/images/icon-menu/icon-contract-line.svg';
import conversationFill from '../assets/images/icon-menu/icon-conversation-fill.svg';
import conversationLine from '../assets/images/icon-menu/icon-conversation-line.svg';
import dashboardFill from '../assets/images/icon-menu/icon-dashboard-fill.svg';
import dashboardLine from '../assets/images/icon-menu/icon-dashboard-line.svg';
import deviceFill from '../assets/images/icon-menu/icon-device-fill.svg';
import deviceLine from '../assets/images/icon-menu/icon-device-line.svg';
import domainFill from '../assets/images/icon-menu/icon-domain-fill.svg';
import domainLine from '../assets/images/icon-menu/icon-domain-line.svg';
import financeFill from '../assets/images/icon-menu/icon-finance-fill.svg';
import financeLine from '../assets/images/icon-menu/icon-finance-line.svg';
import gatewayTransactionsFill from '../assets/images/icon-menu/icon-gateway-transactions-fill.svg';
import gatewayTransactionsLine from '../assets/images/icon-menu/icon-gateway-transactions-line.svg';
import hostingFill from '../assets/images/icon-menu/icon-hosting-fill.svg';
import hostingLine from '../assets/images/icon-menu/icon-hosting-line.svg';
import invoiceFill from '../assets/images/icon-menu/icon-invoice-fill.svg';
import invoiceLine from '../assets/images/icon-menu/icon-invoice-line.svg';
import libraryFill from '../assets/images/icon-menu/icon-library-fill.svg';
import libraryLine from '../assets/images/icon-menu/icon-library-line.svg';
import manageFill from '../assets/images/icon-menu/icon-manage-fill.svg';
import manageLine from '../assets/images/icon-menu/icon-manage-line.svg';
import orderFill from '../assets/images/icon-menu/icon-order-fill.svg';
import orderLine from '../assets/images/icon-menu/icon-order-line.svg';
import premiumFill from '../assets/images/icon-menu/icon-premium-fill.svg';
import premiumLine from '../assets/images/icon-menu/icon-premium-line.svg';
import productFill from '../assets/images/icon-menu/icon-product-fill.svg';
import productLine from '../assets/images/icon-menu/icon-product-line.svg';
import reportsFill from '../assets/images/icon-menu/icon-reports-fill.svg';
import reportsLine from '../assets/images/icon-menu/icon-reports-line.svg';
import salaryFill from '../assets/images/icon-menu/icon-salary-fill.svg';
import salaryLine from '../assets/images/icon-menu/icon-salary-line.svg';
import scheduleFill from '../assets/images/icon-menu/icon-schedule-fill.svg';
import scheduleLine from '../assets/images/icon-menu/icon-schedule-line.svg';
import serverFill from '../assets/images/icon-menu/icon-server-fill.svg';
import serverLine from '../assets/images/icon-menu/icon-server-line.svg';
import teamFill from '../assets/images/icon-menu/icon-team-fill.svg';
import teamLine from '../assets/images/icon-menu/icon-team-line.svg';
import transactionsFill from '../assets/images/icon-menu/icon-transactions-fill.svg';
import transactionsLine from '../assets/images/icon-menu/icon-transactions-line.svg';
import userFill from '../assets/images/icon-menu/icon-user-fill.svg';
import userLine from '../assets/images/icon-menu/icon-user-line.svg';
import userReportFill from '../assets/images/icon-menu/icon-user-reports-fill.svg';
import userReportLine from '../assets/images/icon-menu/icon-user-reports-line.svg';
import vpsFill from '../assets/images/icon-menu/icon-vps-fill.svg';
import vpsLine from '../assets/images/icon-menu/icon-vps-line.svg';
import crossWhite from '../assets/images/icons/icon-cross-white.svg';
import homeWhite from '../assets/images/icons/icon-home-white.svg';
import plusWhite from '../assets/images/icons/icon-plus-white.svg';

// conversation
import address from '../assets/images/icons/icon-address.svg';
import attachment from '../assets/images/icons/icon-attachment.svg';
import file from '../assets/images/icons/icon-file.svg';
import location from '../assets/images/icons/icon-location.svg';
import send from '../assets/images/icons/icon-send.svg';
import smile from '../assets/images/icons/icon-smile.svg';
import threeDot from '../assets/images/icons/icon-three-dot.svg';

// Start Icon
import startFill from '../assets/images/icons/icon-start-fill.svg';
import startLine from '../assets/images/icons/icon-start-line.svg';

const loading = 'https://files.bikiran.com/loading.svg';
const loadingCircle = 'https://files.bikiran.com/loading-circle.svg';

export const icons = {
    // -- Menu services
    menu,
    tickIcon,
    product,

    dashboard,
    access,
    api,
    billing,
    campaign,
    config,
    report,
    crossRed,
    crossGray,
    editFill,
    editGray,
    search,

    // bikiran logo
    bikLogo,
    bikLogoV2,

    // invoice
    invoiceHeader,
    invoiceFooter,

    // Header Icon
    avatar,
    services,
    bell,
    dot,
    corner,
    option,
    filter,
    pushNotification,

    // arrow
    downArrow,
    rightArrow,
    leftArrow,
    logout,

    // project icon
    callingBell,
    bahannoNews,

    // address updater
    iconAddTel,
    iconAddTelFill,
    iconAddFax,
    iconAddFaxFill,
    iconMinus,
    iconMinusFill,

    // Campaign List Page
    duplicateFill,
    optionFill,
    pauseFill,
    playFill,

    // product option
    domainIcon,
    hostingIcon,
    serverIcon,
    smsIcon,
    emailIcon,
    vpsIcon,
    contactIcon,
    contract,

    // Action
    dotVh,
    editBg,
    deleteBg,
    reloadLine,
    reloadFill,
    reloadL,
    reloadF,
    printer,

    // info
    info,
    infoV2,
    infoV3,

    fb,

    // api page
    defaultIconBg,
    campaignBg,
    // default icon
    defaultIcon,
    defaultIconWhite,

    // customer overview
    homeWhite,
    crossWhite,
    plusWhite,

    // Start icon
    startLine,
    startFill,

    // conversation
    location,
    address,
    attachment,
    smile,
    send,
    file,
    threeDot
};

export const images = {
    loading,
    loadingCircle
};
export const menuIcon = {
    // Manage main menu
    contractFillS,
    contractLineS,
    emailFillS,
    emailLineS,
    pushFillS,
    pushLineS,
    accountFillS,
    accountLineS,
    hrmFillS,
    hrmLineS,
    financeFillS,
    financeLineS,
    bmFillS,
    bmLineS,
    // Manage sub menu
    dashboardLine,
    dashboardFill,
    domainFill,
    domainLine,
    hostingFill,
    hostingLine,
    productFill,
    productLine,
    serverFill,
    serverLine,
    invoiceLine,
    invoiceFill,
    transactionsFill,
    transactionsLine,
    contractFill,
    contractLine,
    reportsFill,
    reportsLine,
    codeFill,
    codeLine,
    userFill,
    userLine,
    campaignFill,
    campaignLine,
    financeFill,
    financeLine,
    manageFill,
    manageLine,
    configFill,
    configLine,
    libraryFill,
    libraryLine,
    attendanceFill,
    attendanceLine,
    deviceFill,
    deviceLine,
    salaryFill,
    salaryLine,
    scheduleFill,
    scheduleLine,
    userReportFill,
    userReportLine,
    gatewayTransactionsFill,
    gatewayTransactionsLine,
    premiumFill,
    premiumLine,
    vpsFill,
    vpsLine,
    teamFill,
    teamLine,
    orderFill,
    orderLine,
    conversationFill,
    conversationLine
};

export default null;
