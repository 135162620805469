import { createContext, useContext, useMemo, useState } from 'react';
import { contractDomainList } from '../../../../data';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import { handleDomainStarredOpa } from './DomainOperation';

const DomainContext = createContext();

export const useDomain = () => useContext(DomainContext);

const DomainProvider = ({ children }) => {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    // Domain Address Update Modal
    const [openAddressModal, setOpenAddressModal] = useState(false);
    // Assign user who can control this domain
    const [domainController, setDomainController] = useState(false);
    // Domain List
    const [domainData, setDomainData] = useState(contractDomainList);
    // Reload Key
    const [reloadKey, setReloadKey] = useState(0);

    const valueCtx = useMemo(() => {
        const handleReload = () => {
            setDomainData(contractDomainList);
            setReloadKey((cData) => cData + 1);
        };
        // -- favorite yes or no
        const handleStarred = (ev, id) => {
            ev.stopPropagation();
            handleDomainStarredOpa(authInfo, id)
                .then(({ message, data }) => {
                    setDomainData((cData) => {
                        const nDate = [...cData];
                        return nDate.map((d) => ({ ...d, isStar: d.id === id ? data : d.isStar }));
                    });
                    setMessage(message);
                })
                .catch((err) => {
                    setMessage(err.toString(0));
                });
        };
        return {
            domainData,
            setDomainData,
            reloadKey,
            setReloadKey,
            domainController,
            setDomainController,
            openAddressModal,
            setOpenAddressModal,
            handleReload,
            handleStarred
        };
    }, [authInfo, domainController, domainData, openAddressModal, reloadKey, setMessage]);

    return <DomainContext.Provider value={valueCtx}>{children}</DomainContext.Provider>;
};

export default DomainProvider;
