import AxiosAPI from '../../../utils/AxiosAPI';

export const handleVPSOperation = (authInfo, dateStart, dateEnd, q) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contract/server/vps/list/`, {
                dateStart,
                dateEnd,
                q
            })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

export default null;
