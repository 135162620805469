/* eslint-disable no-eval */

export const evaluate = (val) => {
    let output = val;

    const isValOperatorOrNumber = /^[\d.*/+=-]*$/.test(val);

    if (output.substring(output.length - 1, output.length) === '=') {
        if (isValOperatorOrNumber) {
            try {
                output = eval(output.substring(0, output.length - 1));
            } catch (error) {
                output = 0;
            }
        } else {
            output = 0;
        }
    }
    return output;
};

export default null;
