export const finGwtTransList = [
    {
        id: 10000001,
        timeSuccess: 1675937161,
        gateway: '-------',
        amount: '-------',
        currency: '-------',
        invoice: 1000000000,
        status: '-------',
        user: {
            id: '-------',
            name: '-------------------',
            photoUrl: '',
            email: '----------------------'
        },
        demo: true
    },
    {
        id: 10000002,
        timeSuccess: 1675937161,
        gateway: '-------',
        amount: '-------',
        currency: '-------',
        invoice: 1000000000,
        status: '-------',
        user: {
            id: '-------',
            name: '-------------------',
            photoUrl: '',
            email: '----------------------'
        },
        demo: true
    },
    {
        id: 10000003,
        timeSuccess: 1675937161,
        gateway: '-------',
        amount: '-------',
        currency: '-------',
        invoice: 1000000000,
        status: '-------',
        user: {
            id: '-------',
            name: '-------------------',
            photoUrl: '',
            email: '----------------------'
        },
        demo: true
    },
    {
        id: 10000004,
        timeSuccess: 1675937161,
        gateway: '-------',
        amount: '-------',
        currency: '-------',
        invoice: 1000000000,
        status: '-------',
        user: {
            id: '-------',
            name: '-------------------',
            photoUrl: null,
            email: '----------------------'
        },
        demo: true
    },
    {
        id: 10000005,
        timeSuccess: 1675937161,
        gateway: '-------',
        amount: '-------',
        currency: '-------',
        invoice: 1000000000,
        status: '-------',
        user: {
            id: '-------',
            name: '-----------------',
            photoUrl: null,
            email: '----------------------'
        },
        demo: true
    }
];

export default null;
