import { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';

function FinContractPage() {
    useEffect(() => {
        PageProperties('Contracts');
    }, []);

    return <div>Contracts</div>;
}

export default FinContractPage;
