import AddressUpdater from '../../../../features/address_updater/AddressUpdater';

// model
function FinInvoiceEditAddress({ setOpen, address, handelChangeAddress }) {
    return (
        <AddressUpdater
            handelChangeAddress={handelChangeAddress}
            address={address}
            setOpen={setOpen}
        />
    );
}

export default FinInvoiceEditAddress;
