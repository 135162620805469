import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import { handleVPSOperation } from './VPSOperation';
import VPSPageBody from './VPSPageBody';
import VPSPageHeader from './VPSPageHeader';

function VPSPage() {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const [searchParams] = useSearchParams();
    const [vpsData, setVpsData] = useState(null);
    const [reloadKey, setReloadKey] = useState(0);

    const startDate = searchParams.get('date-start') || '';
    const endDate = searchParams.get('date-end') || '';
    const q = searchParams.get('q') || '';

    const handleReload = () => {
        setVpsData(null);
        setMessage('Loading...');
        setReloadKey(Math.random());
    };

    useEffect(() => {
        handleVPSOperation(authInfo, startDate, endDate, q)
            .then(({ data, message, error }) => {
                if (error === 0) {
                    setVpsData(data);
                } else {
                    setMessage(message);
                }
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    }, [authInfo, endDate, q, setMessage, startDate, reloadKey]);

    useEffect(() => {
        PageProperties('Virtual Private Server');
    }, []);

    return (
        <div className="page-cont">
            <div className="vps-page">
                <VPSPageHeader handleReload={handleReload} />
                <VPSPageBody vpsData={vpsData} />
            </div>
        </div>
    );
}

export default VPSPage;
