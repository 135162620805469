import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthProvider';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import { handelInvoiceSummary, handleInvoiceList } from './FinInvoiceOperation';

const FinInvoiceContext = createContext();

export const useFinInvoice = () => useContext(FinInvoiceContext);
const initInvoiceData = [
    {
        id: 1000001,
        title: '----------------------',
        amountPaid: '------',
        amountBilled: '------',
        currency: '-------',
        status: '--------',
        timeDue: 1686653790,
        timeIssue: 1686653790,
        userInfo: {
            name: '--------------',
            email: '--------------------',
            photoUrl: ''
        },
        demo: true
    },
    {
        id: 1000002,
        title: '----------------------',
        amountPaid: '------',
        amountBilled: '------',
        currency: '-------',
        status: '--------',
        timeDue: 1686653790,
        timeIssue: 1686653790,
        userInfo: {
            name: '--------------',
            email: '--------------------',
            photoUrl: ''
        },
        demo: true
    },
    {
        id: 1000003,
        title: '----------------------',
        amountPaid: '------',
        amountBilled: '------',
        currency: '-------',
        status: '--------',
        timeDue: 1686653790,
        timeIssue: 1686653790,
        userInfo: {
            name: '--------------',
            email: '--------------------',
            photoUrl: ''
        },
        demo: true
    },
    {
        id: 1000004,
        title: '----------------------',
        amountPaid: '------',
        amountBilled: '------',
        currency: '-------',
        status: '--------',
        timeDue: 1686653790,
        timeIssue: 1686653790,
        userInfo: {
            name: '--------------',
            email: '--------------------',
            photoUrl: ''
        },
        demo: true
    },
    {
        id: 1000005,
        title: '----------------------',
        amountPaid: '------',
        amountBilled: '------',
        currency: '-------',
        status: '--------',
        timeDue: 1686653790,
        timeIssue: 1686653790,
        userInfo: {
            name: '--------------',
            email: '--------------------',
            photoUrl: ''
        },
        demo: true
    }
];

const initSummary = {
    productPrice: 0,
    discount: 0,
    vat: 0,
    paid: 0,
    payable: 0
};

function FinInvoiceProvider({ children }) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();
    const [reloadKey, setReloadKey] = useState(0);
    const [invoices, setInvoices] = useState(initInvoiceData);
    const [selectInvoice, setSelectInvoice] = useState([]);
    const [invoiceSummary, setInvoiceSummary] = useState(null);
    const [filterModal, setFilterModal] = useState(false);
    const [searchQueryParams, setSearchQueryParams] = useState({});

    // set search query params
    useEffect(() => {
        const searchQuery = {};
        if (searchParams.get('invoice')) {
            searchQuery.invoice = searchParams.get('invoice');
        }
        if (searchParams.get('user')) {
            searchQuery.user = searchParams.get('user');
        }
        if (searchParams.get('due-from')) {
            searchQuery.dueFrom = searchParams.get('due-from');
        }
        if (searchParams.get('due-to')) {
            searchQuery.dueTo = searchParams.get('due-to');
        }
        if (searchParams.get('currency')) {
            searchQuery.currency = searchParams.get('currency');
        }
        if (searchParams.get('status')) {
            searchQuery.status = searchParams.get('status');
        }
        if (searchParams.get('query')) {
            searchQuery.query = searchParams.get('query');
        }
        setSearchQueryParams(searchQuery);
    }, [searchParams]);
    // get invoice list
    useEffect(() => {
        setInvoices(initInvoiceData);
        handleInvoiceList(authInfo, searchQueryParams)
            .then(({ data }) => {
                setInvoices(data);
            })
            .catch((err) => {
                setInvoices(initInvoiceData);
                setMessage(err.toString());
            });
        setSelectInvoice([]);
    }, [authInfo, setMessage, reloadKey, searchQueryParams]);

    // get selected invoice summary
    useEffect(() => {
        if (selectInvoice.length) {
            handelInvoiceSummary(authInfo, selectInvoice)
                .then(({ data }) => {
                    setInvoiceSummary(data);
                })
                .catch((err) => {
                    setInvoiceSummary(initSummary);
                    setMessage(err.toString());
                });
        } else {
            setInvoiceSummary(initSummary);
        }
    }, [authInfo, selectInvoice, setMessage]);

    useEffect(() => {
        PageProperties('Invoice List');
    }, []);

    const value = useMemo(() => {
        // handle filter search query
        const handleSearchBtn = (data) => {
            if (
                !data?.invoice &&
                !data?.user &&
                !data?.dueFrom &&
                !data?.dueTo &&
                !data?.currency &&
                !data?.status
            ) {
                return;
            }

            const url = mkRelativeUrl('/finance/sell-invoice/', {
                ...(data?.invoice && { invoice: data?.invoice }),
                ...(data?.user && { user: data?.user }),
                ...(data?.dueFrom && { 'due-from': data?.dueFrom }),
                ...(data?.dueTo && { 'due-to': data?.dueTo }),
                ...(data?.currency && { currency: data?.currency }),
                ...(data?.status && { status: data?.status }),
                ...(data?.query && { query: data?.query })
            });
            navigate(url);
            setFilterModal(false);
        };

        const handleReload = () => {
            setInvoices(initInvoiceData);
            setReloadKey((prev) => prev + 1);
        };

        return {
            invoices,
            setInvoices,
            filterModal,
            setFilterModal,
            handleReload,
            selectInvoice,
            setSelectInvoice,
            invoiceSummary,
            handleSearchBtn,
            searchQueryParams,
            setSearchQueryParams
        };
    }, [filterModal, invoiceSummary, invoices, navigate, searchQueryParams, selectInvoice]);

    return <FinInvoiceContext.Provider value={value}>{children}</FinInvoiceContext.Provider>;
}
export default FinInvoiceProvider;
