import React, { useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import BikTable from '../../../../features/bik-table/BikTable';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import InstOption from '../../../../utils/InstOption';
import { GetDate, showCurrency } from '../../../../utils/Show';

function TableRow({ item, handleStarred }) {
    const ref = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const handleShowMoreMenu = () => setShowMenu((cuData) => !cuData);

    const handleBgActive = () => {
        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };
    const handleStatement = (id) => {
        console.log(id);
    };
    return (
        <tr className={!item?.demo ? 'placeholder-mode' : ''} ref={ref} onClick={handleBgActive}>
            <td className="text-left p-5">
                <div className="flex-row position-middle align-space-between">
                    <span>
                        <button
                            type="button"
                            className="started-btn"
                            onClick={(e) => handleStarred(e, item?.id)}
                        >
                            <img
                                src={item?.favorite ? icons.startFill : icons.startLine}
                                alt=""
                                className="d-block"
                            />
                        </button>
                    </span>
                    <span>#{item?.id}</span>
                </div>
            </td>
            <td className="text-left">
                <UserInfoV3
                    name={item?.userInfo.name}
                    email={item?.userInfo.email}
                    img={item?.userInfo.photoUrl}
                />
            </td>
            <td>
                <div className="text-left">
                    <span className="text-dot">{item?.vendor}</span>
                </div>
            </td>
            <td>
                <span className="text-dot">{item?.domain}</span>
            </td>
            <td className="text-center">
                <span>{showCurrency(item?.price)}</span>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{GetDate(item?.executed)}</span>
                </div>
            </td>
            <td className="text-center">
                <span>{GetDate(item?.renew)}</span>
            </td>
            <td className="text-center">
                <span>{GetDate(item?.expired)}</span>
            </td>
            <td className="text-center text-capitalize">
                <span className={`status ${item?.status}`}>{item?.status}</span>
            </td>

            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button type="button" className="" onClick={handleShowMoreMenu}>
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        <button type="button" onClick={() => handleStatement(item.id)}>
                            Manage
                        </button>
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}
function PackageTable({ pkgDataList, handleStarred }) {
    return (
        <BikTable
            countDataAr={pkgDataList}
            dataTh={[
                { title: 'SL', width: '9', align: 'left' },
                { title: 'User', width: '22', align: 'left' },
                { title: 'Title', width: '10' },
                { title: 'Server', width: '9' },
                { title: 'Date Created', width: '9' },
                { title: 'Expired', width: '9' },
                { title: 'Domain', width: '8' },
                { title: 'Price', width: '9' },
                { title: 'Status', width: '8' },
                { title: 'Action', width: '8', align: 'right' }
            ]}
        >
            {pkgDataList.map((item) => (
                <TableRow key={item.id} item={item} handleStarred={handleStarred} />
            ))}
        </BikTable>
    );
}

export default PackageTable;
