import dayjs from 'dayjs';
import 'flatpickr/dist/flatpickr.css';
import React, { useEffect, useRef, useState } from 'react';
import { icons } from '../../../../configs/Icons';
import { useInit } from '../../../features/init/InitProvider';
import { DateRange } from '../../../lib/LibDatePicker';
import LibModal from '../../../lib/LibModal';
import { camelCaseToHyphen } from '../../../utils/TextConvert';
import FinInvoiceCreate from './FinInvoiceCreate';
import { useFinInvoice } from './FinInvoiceProvider';

function FinInvoiceFilter({ sParams, handleSearchBtn, currencyData, myRef, fPickrRef }) {
    const { invoice, user, dueFrom, dueTo, currency, status } = sParams;
    const [showMenu, setShowMenu] = useState(false);

    const [filterData, setFilterData] = useState({
        invoice: invoice || '',
        user: user || '',
        dueFrom: dueFrom || '',
        dueTo: dueTo || '',
        currency: currency || '',
        status: status || '',
        query: ''
    });
    const handleOnChange = (e) => {
        if (e.length === 2) {
            setFilterData({
                ...filterData,
                dueFrom: dayjs(e[0]).format('YYYY-MM-DD') || '',
                dueTo: dayjs(e[1]).format('YYYY-MM-DD') || ''
            });
        }
    };

    return (
        <div className="fin-invoice-filter p-absolute" ref={myRef}>
            <div className="fin-invoice-filter-box flex-col position-space-between align-left w-100p gap-10">
                <div className="inv-filter__id">
                    <span>Invoice</span>
                    <input
                        type="text"
                        placeholder="Ex: 10000000 or Domain"
                        onChange={(e) => setFilterData({ ...filterData, invoice: e.target.value })}
                        value={filterData?.invoice}
                    />
                </div>
                <div className="inv-filter__user">
                    <span>User</span>
                    <input
                        type="text"
                        placeholder="Ex: Name, Organization or E-mail"
                        onChange={(e) => setFilterData({ ...filterData, user: e.target.value })}
                        value={filterData?.user}
                    />
                </div>
                <div className="inv-filter__dueDate ">
                    <span>Due Date</span>
                    <DateRange
                        startDate={
                            filterData.dueFrom || dayjs().subtract(1, 'month').format('YYYY-MM-DD')
                        }
                        endDate={filterData.dueTo || dayjs().format('YYYY-MM-DD')}
                        handelOnChange={handleOnChange}
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                        fRef={fPickrRef}
                    />
                </div>
                <div className="inv-filter__currency">
                    <span>Currency</span>
                    <select
                        value={filterData?.currency}
                        onChange={(e) => setFilterData({ ...filterData, currency: e.target.value })}
                    >
                        <option value="">Any</option>
                        {currencyData?.map((item) => (
                            <option key={item?.currency} value={item?.currency}>
                                {item.currency}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="inv-filter__status">
                    <span>Status</span>
                    <select
                        value={filterData?.status}
                        onChange={(e) => setFilterData({ ...filterData, status: e.target.value })}
                    >
                        <option value="">Any</option>
                        <option value="pending">Pending</option>
                        <option value="active">Active</option>
                    </select>
                </div>
            </div>
            <div className="search-button flex-row align-right mt-20 w-100p">
                <button type="button" onClick={() => handleSearchBtn(filterData)}>
                    Search
                </button>
            </div>
        </div>
    );
}
function FinInvoiceListPageHeader() {
    const {
        handleSearchBtn,
        handleReload,
        filterModal,
        setFilterModal,
        searchQueryParams,
        setSearchQueryParams
    } = useFinInvoice();

    const myRef = useRef(null);
    const fPickrRef = useRef(null);
    const { initInfo } = useInit();
    const [open, setOpen] = useState(false);

    const currencyData = initInfo?.initData?.currencies;

    const handleCreateInvoice = () => {
        setOpen((cuData) => !cuData);
    };
    const handleFilterModal = () => {
        setFilterModal((cuData) => !cuData);
    };

    // Outside click handler
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (fPickrRef?.current && !fPickrRef?.current?.node?.classList.contains('active')) {
                if (myRef?.current && !myRef?.current?.contains(e.target)) {
                    setFilterModal(false);
                }
            }
        };
        document.addEventListener('pointerdown', handleClickOutside);
        return () => {
            document.removeEventListener('pointerdown', handleClickOutside);
        };
    }, [setFilterModal]);

    const handleFilterInput = (e) => {
        const { value } = e.target;
        const [key, val] = value.split(':');
        setSearchQueryParams((cuData) => ({ ...cuData, [camelCaseToHyphen(key)]: val }));
    };

    return (
        <div className="campaign-list-page page-header-cont flex-row w-100p align-space-between position-middle">
            <div className="flex-row gap-50 position-middle align-left fill">
                <div className="invoice-header__title font-500 text-30">Invoice List</div>
                <div className="invoice-search flex-row align-space-between position-middle p-relative">
                    <div
                        className={`invoice-search_box flex-row align-space-between fill ${
                            filterModal ? 'filter' : ''
                        }
                        `}
                    >
                        <input
                            onFocus={handleFilterModal}
                            onChange={handleFilterInput}
                            type="text"
                            className="fill"
                            placeholder="Search Anything....."
                            autoComplete="off"
                            defaultValue={Object.keys(searchQueryParams).reduce((acc, key) => {
                                if (searchQueryParams[key] !== '') {
                                    return `${acc} ${camelCaseToHyphen(key)}:${
                                        searchQueryParams[key]
                                    }`;
                                }
                                return acc;
                            }, '')}
                        />
                        <button
                            type="button"
                            onClick={handleFilterModal}
                            className={`flex-row position-middle align-center ${
                                filterModal ? 'active' : ''
                            }`}
                        >
                            <img src={icons.filter} alt="Search" />
                        </button>
                    </div>
                    {filterModal && (
                        <FinInvoiceFilter
                            handleSearchBtn={handleSearchBtn}
                            currencyData={currencyData}
                            myRef={myRef}
                            fPickrRef={fPickrRef}
                            sParams={searchQueryParams}
                        />
                    )}
                </div>
            </div>
            <div className="content-reload">
                <div className="content-refresh-btn">
                    <button type="button" className="p-relative" onClick={handleReload}>
                        <img src={icons.reloadL} alt="" />
                        <img src={icons.reloadF} alt="" />
                    </button>
                </div>
            </div>
            <div className="create-campaign">
                <button
                    type="button"
                    className="flex-row gap-5 align-center position-middle"
                    onClick={handleCreateInvoice}
                >
                    <span>+</span>
                    <span>Create New</span>
                </button>

                {open ? (
                    <LibModal title="Create Invoice" setOpen={setOpen}>
                        <FinInvoiceCreate currencyData={currencyData} />
                    </LibModal>
                ) : null}
            </div>
        </div>
    );
}

export default FinInvoiceListPageHeader;
