import { useTemplate } from '../../../../../features/template/TemplateProvider';
import FinInvoiceCustomProduct from './components/FinInvoiceCustomProduct';
import FinInvoiceDedicatedServer from './components/FinInvoiceDedicatedServer';
import FinInvoiceDomain from './components/FinInvoiceDomain';
import FinInvoiceEmail from './components/FinInvoiceEmail';
import FinInvoiceHosting from './components/FinInvoiceHosting';
import FinInvoicePremiumContract from './components/FinInvoicePremiumContract';
import FinInvoicePush from './components/FinInvoicePush';
import FinInvoiceSms from './components/FinInvoiceSms';
import FinInvoiceVpsServer from './components/FinInvoiceVpsServer';

function InvoiceItem({ item, sl, isInvoiceActive, onClickEdit, onClickDelete }) {
    const { property } = item || {};

    if (property === 'domain') {
        return (
            <FinInvoiceDomain
                item={item}
                sl={sl}
                isInvoiceActive={isInvoiceActive}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
            />
        );
    }

    if (property === 'hosting') {
        return (
            <FinInvoiceHosting
                item={item}
                sl={sl}
                isInvoiceActive={isInvoiceActive}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
            />
        );
    }

    if (property === 'server_dedicated') {
        return (
            <FinInvoiceDedicatedServer
                item={item}
                sl={sl}
                isInvoiceActive={isInvoiceActive}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
            />
        );
    }

    if (property === 'server_vps') {
        return (
            <FinInvoiceVpsServer
                item={item}
                sl={sl}
                isInvoiceActive={isInvoiceActive}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
            />
        );
    }

    if (property === 'push') {
        return (
            <FinInvoicePush
                item={item}
                sl={sl}
                isInvoiceActive={isInvoiceActive}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
            />
        );
    }

    if (property === 'sms') {
        return (
            <FinInvoiceSms
                item={item}
                sl={sl}
                isInvoiceActive={isInvoiceActive}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
            />
        );
    }

    if (property === 'email') {
        return (
            <FinInvoiceEmail
                item={item}
                sl={sl}
                isInvoiceActive={isInvoiceActive}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
            />
        );
    }

    if (property === 'premium_contract') {
        return (
            <FinInvoicePremiumContract
                item={item}
                sl={sl}
                isInvoiceActive={isInvoiceActive}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
            />
        );
    }

    if (property === 'custom_product') {
        return (
            <FinInvoiceCustomProduct
                item={item}
                sl={sl}
                isInvoiceActive={isInvoiceActive}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
            />
        );
    }
    return null;
}

function FinInvoiceTableBody({ item, sl, isInvoiceActive, handelRemoveProduct, openProductModal }) {
    const { setConfirm, setMessage } = useTemplate();

    const onClickEdit = () => {
        if (!isInvoiceActive) {
            openProductModal(item.property, item);
        }
    };

    const onClickDelete = (ev) => {
        ev.stopPropagation();

        setConfirm({
            text: 'Are you sure you want to delete this product?',
            clickAction: () => {
                setMessage('Deleting...');

                handelRemoveProduct({ ...item })
                    .then(({ error, message }) => {
                        if (error === 0) {
                            setConfirm(null);
                        }
                        setMessage(message);
                    })
                    .catch((err) => {
                        setMessage(err.toString());
                    });
            }
        });
    };

    return (
        <InvoiceItem
            item={item}
            sl={sl}
            isInvoiceActive={isInvoiceActive}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
        />
    );
}

export default FinInvoiceTableBody;
