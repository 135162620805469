import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usersList } from '../../../../data';
import { useAuth } from '../../../features/auth/AuthProvider';
import InfoSidebar from '../../../features/info_sidebar/InfoSidebar';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import UserInfoOverview from '../user_overview/UserInfoOverview';
import { handleLoadUserList } from './UserListOperation';
import UserListBody from './UserListPageBody';
import UserListPageHeader from './UserListPageHeader';
import users from './users.json';

function UserOverviewWrapper({ userId }) {
    const dummyData = users.filter((user) => user.id === userId)[0] || null || {};

    return <UserInfoOverview data={{ ...dummyData, date: Math.floor(Date.now() / 1000) }} />;
}

function UserListPage() {
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState(null);
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const [searchParams] = useSearchParams();
    const [reloadKey, setReloadKey] = useState(Math.random());

    // --Search Params
    const typeParams = searchParams.get('type') || '';
    const queryParams = searchParams.get('q') || '';

    const handleReload = () => {
        setMessage('Loading....');
        setUserList(usersList);
        setReloadKey((cuData) => cuData + 1);
    };

    useEffect(() => {
        handleLoadUserList(authInfo, typeParams, queryParams)
            .then(({ data }) => {
                setUserList(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, setMessage, typeParams, queryParams, reloadKey]);

    useEffect(() => {
        PageProperties('Users List');
    }, []);

    return (
        <div className="page-cont">
            <div className="user-list-page">
                <UserListPageHeader
                    handleReload={handleReload}
                    sParams={{ typeParams, queryParams }}
                />

                <UserListBody userListData={userList} setUserId={setUserId} />

                <InfoSidebar show={userId}>
                    <UserOverviewWrapper userId={userId} />
                </InfoSidebar>
            </div>
        </div>
    );
}

export default UserListPage;
