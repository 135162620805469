import React from 'react';
import { ProductInputField, ProductTextAreaField } from '../components/ProductEditorInputs';
// diskType
function HostingInputs() {
    return (
        <div className="flex-row flex-wrap align-space-between inputs-cont">
            <ProductInputField title="Domain" name="domain" />

            <div className="inp-fill-3">
                <ProductInputField title="Disk" name="disk" unit="GB" />
            </div>

            <div className="inp-fill-3">
                <ProductInputField title="Bandwidth" name="bandwidth" unit="GB/m" />
            </div>

            <div className="inp-fill-3">
                <ProductInputField title="CPU" name="cpu" unit="Core" />
            </div>

            <div className="inp-fill-3">
                <ProductInputField title="RAM" name="ram" unit="GB" />
            </div>

            <div className="inp-fill-3">
                <ProductInputField title="EP" name="ep" />
            </div>

            <div className="inp-fill-3">
                <ProductInputField title="IO" name="io" />
            </div>

            <ProductTextAreaField title="Notes" name="note" />
        </div>
    );
}

export default HostingInputs;
