import { evaluate } from '../../../../utils/Math';

export function FundInputField({ handelChange, formData, name, placeholder }) {
    // const { formData, handelChange } = useFundTransfer();

    const handelOnChange = (ev) => {
        const outputObj = {
            target: {
                name: ev.target.name,
                value: evaluate(ev.target.value)
            }
        };

        handelChange(outputObj);
    };

    return (
        <input
            value={formData[name] || ''}
            onChange={handelOnChange}
            name={name}
            placeholder={placeholder || 'Type Something....'}
            type="text"
            className=""
        />
    );
}

export function FundTextareaField({ handelChange, formData, name, placeholder }) {
    // const { formData, handelChange } = useFundTransfer();

    return (
        <textarea
            value={formData[name] || ''}
            onChange={handelChange}
            name={name}
            placeholder={placeholder || 'Type Something....'}
            type="text"
            className=""
        />
    );
}

export default null;
