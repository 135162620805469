import AxiosAPI from '../../../utils/AxiosAPI';

export function handelLoadDashboardInfo(authInfo, dateStart, dateEnd) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/dashboard/`, { dateStart, dateEnd })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default null;
