export const contractDomainList = [
    {
        id: 10000001,
        userInfo: {
            userId: '',
            name: '----------------',
            photoUrl: '',
            email: '',
            phone: null
        },
        isStar: '',
        vendor: '',
        domain: '-------------------',
        price: 11.990004,
        priceOffer: 11.990004,
        timeExecuted: 1677561986,
        timeRenew: 1671956153,
        status: '-------',
        demo: true
    },
    {
        id: 10000002,
        userInfo: {
            userId: '',
            name: '----------------',
            photoUrl: '',
            email: '',
            phone: null
        },
        isStar: '',
        vendor: '',
        domain: '-------------------',
        price: 11.990004,
        priceOffer: 11.990004,
        timeExecuted: 1677561986,
        timeRenew: 1671956153,
        status: '-------',
        demo: true
    },
    {
        id: 10000003,
        userInfo: {
            userId: '',
            name: '----------------',
            photoUrl: '',
            email: '',
            phone: null
        },
        isStar: '',
        vendor: '',
        domain: '-------------------',
        price: 11.990004,
        priceOffer: 11.990004,
        timeExecuted: 1677561986,
        timeRenew: 1671956153,
        status: '-------',
        demo: true
    },
    {
        id: 10000004,
        userInfo: {
            userId: '',
            name: '----------------',
            photoUrl: '',
            email: '',
            phone: null
        },
        isStar: '',
        vendor: '',
        domain: '-------------------',
        price: 11.990004,
        priceOffer: 11.990004,
        timeExecuted: 1677561986,
        timeRenew: 1671956153,
        status: '-------',
        demo: true
    },
    {
        id: 10000005,
        userInfo: {
            userId: '',
            name: '----------------',
            photoUrl: '',
            email: '',
            phone: null
        },
        isStar: '',
        vendor: '',
        domain: '-------------------',
        price: 11.990004,
        priceOffer: 11.990004,
        timeExecuted: 1677561986,
        timeRenew: 1671956153,
        status: '-------',
        demo: true
    }
];

export default null;
