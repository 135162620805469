import { useEffect, useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import UserSearchField from '../../../../features/user-search/UserSearchField';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField from '../../../../utils/AnimateInputField';
import { handelAddProject } from '../UserProjectsOperation';

// const initialState = {
//     title: '',
//     domain: '',
//     name: '',
//     email: '',
//     phone: ''
// };

function ProjectAddModal({ setOpen, setReloadKey }) {
    const [selectedUser, setSelectedUser] = useState({});
    const [formData, setFormData] = useState({});

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    const handleAddButton = () => {
        const { title, domain, name, email, phone } = formData || {};
        const data = { title, domain, name, email, phone, userId: selectedUser?.id };

        setMessage('Adding Project...');
        handelAddProject(authInfo, data)
            .then(({ message }) => {
                setOpen(false);
                setMessage(message);
                if (setReloadKey) {
                    setReloadKey();
                }
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    useEffect(() => {
        setSelectedUser(null);
    }, []);

    useEffect(() => {
        const { name, email, phone } = selectedUser || {};

        const updatedFormData = {
            ...(name && { name }),
            ...(email && { email }),
            ...(phone && { phone })
        };

        setFormData((cuData) => ({
            ...cuData,
            ...updatedFormData
        }));
    }, [selectedUser]);

    return (
        <LibModal title="Add Project" setOpen={setOpen}>
            <div className="project-add-modal">
                <div>
                    <UserSearchField
                        setSelectedUser={setSelectedUser}
                        selectedUser={selectedUser}
                    />
                    <div className="flex-col gap-20">
                        <AnimateInputField
                            title="Project Title"
                            name="title"
                            required
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <AnimateInputField
                            title="Domain"
                            name="domain"
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>

                    <div className="divider flex-row position-middle gap-10">
                        <h2>Focal Person</h2>
                        <hr />
                    </div>

                    <div className="flex-col gap-20">
                        <AnimateInputField
                            title="Name"
                            name="name"
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <div className="flex-row align-space-between w-100p">
                            <div className="inp-fill-2">
                                <AnimateInputField
                                    title="Email"
                                    name="email"
                                    formData={formData}
                                    handelChange={handelChange}
                                />
                            </div>
                            <div className="inp-fill-2">
                                <AnimateInputField
                                    title="Phone"
                                    name="phone"
                                    formData={formData}
                                    handelChange={handelChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-row align-right gap-10">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />
                    <ButtonGreen evOnClick={handleAddButton} title="Add" />
                </div>
            </div>
        </LibModal>
    );
}

export default ProjectAddModal;
