import React, { useState } from 'react';
import LibModal from '../../../lib/LibModal';
import HostingTable from './components/HostingTable';

function HostingPageBody({ hostingListData, handleStarred }) {
    const [userAssignModal, setUserAssignModal] = useState({ show: false, id: '' });

    return (
        <div className="hosting-page__body">
            <HostingTable
                hostingListData={hostingListData}
                handleStarred={handleStarred}
                setUserAssignModal={setUserAssignModal}
            />

            {userAssignModal.show && <LibModal title="Assign User">hello</LibModal>}
        </div>
    );
}

export default HostingPageBody;
