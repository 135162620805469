import Show, {
    GetDate,
    calculateDiscountPercentage,
    showCurrency,
    showValueWithUnit
} from '../../../../../../utils/Show';
import { DeleteBtn, EditBtn } from '../../_models/ActionButtons';
import PriceDiscountInfo from '../../_models/PriceDiscountInfo';
import showPrice from '../../_models/ShowPrice';

function FinInvoiceDedicatedServer({ item, sl, isInvoiceActive, onClickEdit, onClickDelete }) {
    const {
        title,
        price,
        priceOffer,
        quantity,
        unitName,
        subscriptionStart,
        subscriptionEnd,
        properties,
        vat
    } = item || {};

    const {
        dsBandwidth,
        dsCpu,
        dsLocation,
        dsNetwork,
        dsRam,
        dsOs,
        dsStorage,
        dsRaid,
        dsIp,
        dsHostname,
        note
    } = properties || {};

    const priceDiscount = calculateDiscountPercentage(price, priceOffer);

    return (
        <tr className={!isInvoiceActive ? 'clickable' : ''} onClick={null}>
            <td>{sl}</td>
            <td>
                <h4>{title || '--'}</h4>

                <p>
                    <Show show={dsCpu}>
                        Cpu: <b>{dsCpu} &nbsp;</b>
                        <br />
                    </Show>

                    <Show show={dsRam}>
                        Ram: <b>{dsRam} &nbsp;</b>
                        <br />
                    </Show>

                    <Show show={dsStorage}>
                        Storage: <b>{dsStorage}, &nbsp;</b>
                    </Show>

                    <Show show={dsRaid}>
                        <b>{dsRaid} &nbsp;</b>
                        <br />
                    </Show>
                    <Show show={dsNetwork}>
                        Network: <b>{dsNetwork}, &nbsp;</b>
                    </Show>
                    <Show show={dsBandwidth}>
                        Bandwidth: <b>{dsBandwidth}, &nbsp;</b>
                    </Show>

                    <Show show={dsOs}>
                        Server OS: <b>{dsOs} &nbsp;</b>
                        <br />
                    </Show>

                    <Show show={dsLocation}>
                        Location: <b>{dsLocation?.toUpperCase()} &nbsp;</b>
                        <br />
                    </Show>

                    <Show show={dsIp?.length}>
                        IP: <b>{dsIp}, &nbsp;</b>
                    </Show>

                    <Show show={dsHostname}>
                        Hostname: <b>{dsHostname} &nbsp;</b>
                    </Show>

                    <Show show={note}>
                        <b>{note}</b>
                    </Show>
                </p>

                <span>
                    Duration: {GetDate(subscriptionStart) || 0} to {GetDate(subscriptionEnd) || 0}
                </span>
            </td>
            <td>
                {showPrice(price, quantity)}
                {/* <span className="price-amount">{showCurrency(price * 12)}</span> <br />
                /Year */}
            </td>
            <td>
                {showValueWithUnit(quantity, unitName)}
                {/* {quantity || 0} {unitName} */}
            </td>
            <td>
                <span className="price-amount">{showCurrency(price * quantity)}</span>

                <PriceDiscountInfo priceDiscount={priceDiscount} vat={vat} />

                <div className="info-action-button flex-col position-middle position-space-between">
                    <EditBtn onClickEdit={onClickEdit} />
                    <DeleteBtn onClickDelete={onClickDelete} />
                </div>
            </td>
        </tr>
    );
}

export default FinInvoiceDedicatedServer;
