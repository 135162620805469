import React from 'react';

function DomainListInvoice() {
    return (
        <div className="domain-info-table">
            <h4 className="list-invoices-header mb-10">
                <span>List of Invoices</span>
            </h4>
            <table className="invoice-table" cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th>Invoice ID</th>
                        <th>Date</th>
                        <th>Title</th>
                        <th>Total Due</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>10001059</td>
                        <td>05 Aug 2023</td>
                        <td className="title-data">HRS-domain-hosting</td>
                        <td>62,968.00</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default DomainListInvoice;
