import React from 'react';
import {
    ProductInputField,
    ProductSelectField,
    ProductTextAreaField,
    addOption
} from '../components/ProductEditorInputs';

const locations = [
    { id: 1, title: 'BD', value: 'bd' },
    { id: 2, title: 'SG', value: 'sg' },
    { id: 3, title: 'IN', value: 'in' },
    { id: 4, title: 'AU', value: 'au' },
    { id: 5, title: 'USA', value: 'usa' },
    { id: 6, title: 'CA', value: 'ca' },
    { id: 7, title: 'FR', value: 'fr' },
    { id: 8, title: 'DE', value: 'de' },
    { id: 9, title: 'UK', value: 'uk' }
];

function VpsInputs() {
    return (
        <div className="flex-row flex-wrap align-space-between inputs-cont">
            <div className="inp-fill-3">
                <ProductInputField title="CPU" name="vsCpu" unit="Core" />
            </div>

            <div className="inp-fill-3">
                <ProductInputField title="RAM" name="vsRam" unit="GB" />
            </div>

            <div className="inp-fill-3">
                <ProductInputField title="Storage" name="vsStorage" info="Type Something...." />
            </div>

            <div className="inp-fill-2">
                <ProductInputField title="Network" name="vsNetwork" info="Type Something...." />
            </div>

            <div className="inp-fill-2">
                <ProductInputField title="Server OS" name="vsOs" />
            </div>

            <div className="inp-fill-2">
                <ProductInputField title="Bandwidth" name="vsBandwidth" info="Type Something...." />
            </div>

            <div className="inp-fill-2">
                <ProductSelectField
                    title="Location"
                    name="vsLocation"
                    unit="Location"
                    options={locations?.map((item) => addOption(item.id, item.title, item.value))}
                />
            </div>

            <div className="inp-fill-2">
                <ProductInputField title="IP" name="vsIp" />
            </div>

            <div className="inp-fill-2">
                <ProductInputField title="Hostname" name="vsHostname" />
            </div>

            <ProductTextAreaField title="Notes" name="note" />
        </div>
    );
}

export default VpsInputs;
