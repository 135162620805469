/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { icons } from '../../../../configs/Icons';
import { evaluate } from '../../../utils/Math';
import { useProductEditor } from '../InvoiceProductEditorProvider';

export function addOption(id, title, value) {
    return { id, title, value };
}

const Opt = (formData, name) => {
    const ref = useRef();
    const [focused, setFocused] = useState(false);

    const hOnFocus = () => {
        setFocused(true);
        ref.current.classList.add('focused');
    };

    const hOnBlur = () => {
        setFocused(false);
        ref.current.classList.remove('focused');
    };

    useEffect(() => {
        if (formData[name] || focused) {
            ref.current.classList.add('has-value');
        } else {
            ref.current.classList.remove('has-value');
        }
    }, [focused, formData, name]);

    return { hOnFocus, hOnBlur, ref };
};

function Label({ name, title, info, inputRef }) {
    const iconRef = useRef(null);
    const labelRef = useRef(null);

    const handelClick = () => {
        labelRef.current.classList.add('active');
        const inputElement =
            inputRef?.current?.querySelector('input') || inputRef?.current?.querySelector('select');
        if (inputElement) {
            inputElement.focus();
        }
    };

    const handelHover = () => {
        iconRef.current.src = icons.info;
    };

    const handelHoverOut = () => {
        iconRef.current.src = icons.infoV2;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (labelRef.current && !labelRef.current?.contains(event.target)) {
                labelRef.current.classList.remove('active');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            className="label flex-row position-middle align-right"
            ref={labelRef}
            onClick={handelClick}
        >
            <label htmlFor={name}>{title}</label>

            {info && (
                <span
                    className="animate-input_info"
                    data-tooltip-id="myTooltip"
                    data-tooltip-content={info}
                >
                    <img
                        onMouseEnter={handelHover}
                        onMouseLeave={handelHoverOut}
                        ref={iconRef}
                        src={icons.infoV2}
                        alt="info"
                    />
                </span>
            )}
        </div>
    );
}

export function ProductInputField({ title, name, info, unit }) {
    const { formData, handelChange } = useProductEditor();
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name);

    return (
        <div className="animate-input-area" ref={ref}>
            <Label name={name} title={title} info={info} inputRef={ref} />

            <div className="inp-cont flex-row position-middle">
                <input
                    name={name}
                    value={formData[name] || ''}
                    onChange={handelChange}
                    className="form-input"
                    type="text"
                    onFocus={hOnFocus}
                    onBlur={hOnBlur}
                />
                {unit && <span className="animate-input_unit">{unit}</span>}
            </div>
        </div>
    );
}

export function ProductInputAmountField({ title, name, info, unit }) {
    const { formData, handelChange } = useProductEditor();
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name);

    const handelOnChange = (ev) => {
        const outputObj = {
            target: {
                name: ev.target.name,
                value: evaluate(ev.target.value)
            }
        };

        handelChange(outputObj);
    };

    return (
        <div className="animate-input-area" ref={ref}>
            <Label name={name} title={title} info={info} inputRef={ref} />

            <div className="inp-cont flex-row position-middle">
                <input
                    name={name}
                    min={1}
                    value={formData[name] || ''}
                    onChange={handelOnChange}
                    className="form-input"
                    type="text"
                    onFocus={hOnFocus}
                    onBlur={hOnBlur}
                />
                {unit && <span className="animate-input_unit">{unit}</span>}
            </div>
        </div>
    );
}

export function ProductSelectField({ title, name, options, info, unit }) {
    const { formData, handelChange } = useProductEditor();
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name);

    return (
        <div className="animate-input-area" ref={ref}>
            <Label name={name} title={title} info={info} inputRef={ref} />

            <select
                onChange={handelChange}
                name={name}
                value={formData[name] || ''}
                className="form-input"
                onFocus={hOnFocus}
                onBlur={hOnBlur}
            >
                <option value="">Select {unit}</option>
                {options?.map((option) => (
                    <option key={option?.id} value={option?.value}>
                        {option?.title}
                    </option>
                ))}
            </select>
        </div>
    );
}

export function ProductTextAreaField({ title, name, info }) {
    const { formData, handelChange } = useProductEditor();
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name);

    return (
        <div className="animate-input-area" ref={ref}>
            <Label name={name} title={title} info={info} inputRef={ref} />

            <textarea
                name={name}
                value={formData[name] || ''}
                onChange={handelChange}
                className="form-input"
                rows="20"
                onFocus={hOnFocus}
                onBlur={hOnBlur}
            />
        </div>
    );
}

export function ProductDateField({ title, name, info }) {
    const { formData, handelChange } = useProductEditor();
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name);

    const handelOnChange = ([date]) => {
        const ev = {
            target: {
                name,
                value: date ? dayjs(date).format('YYYY-MM-DD') : ''
            }
        };
        handelChange(ev);
    };

    return (
        <div className="animate-input-area" ref={ref}>
            <Label name={name} title={title} info={info} inputRef={ref} />

            <Flatpickr
                options={{ dateFormat: 'Y-m-d' }}
                value={formData[name]}
                onChange={handelOnChange}
                name={name}
                className="form-input"
                readOnly={false}
                onFocus={hOnFocus}
                onClose={hOnBlur}
            />
        </div>
    );
}

export default null;
