import { createContext, useContext, useMemo, useState } from 'react';
import notificationData from './notificationData';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState(notificationData);
    const [isActive, setIsActive] = useState('all');
    const value = useMemo(() => {
        const handleToggleClick = () => {
            setShow((prev) => !prev);
        };
        const handleAll = () => {
            setIsActive('all');
            setData(notificationData);
        };
        const handleUnread = () => {
            const unreadData = data.slice(0, 7).filter((item) => item.status === 'unread');

            setIsActive('unread');
            setData(unreadData);
        };
        return {
            show,
            setShow,
            handleToggleClick,
            data,
            setData,
            isActive,
            setIsActive,
            handleAll,
            handleUnread
        };
    }, [data, isActive, show]);

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export default NotificationProvider;
