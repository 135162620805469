import React from 'react';
import { useDocs } from '../DocsProvider';

function SubMenu({ item, index }) {
    return (
        <div className="submenu-list flex-col gap-5">
            {item?.subMenu?.map((subItem, SubIndex) => (
                <div className="sub-menu-item" key={index + Math.random()}>
                    <div className="flex-row align-left position-bottom gap-5" key={subItem.id}>
                        <span className="d-block text-16 font-400">
                            {`${index}.${SubIndex + 1}.`}
                        </span>
                        <span className="d-block text-16 font-400">
                            {subItem.title} {subItem.pageId && `(${subItem.pageId})`}
                        </span>
                    </div>
                    <SubMenu item={subItem} index={`${index}.${SubIndex + 1}`} />
                </div>
            ))}
        </div>
    );
}

function MenusList({ item, index }) {
    return (
        <div className="menus-list" key={item.id}>
            <div className="flex-col gap-5">
                <div className="flex-row align-left position-bottom gap-5">
                    <span className="d-block text-16 font-400">{index + 1}.</span>
                    <span className="d-block text-16 font-400">
                        {item.title} {item.pageId && `(${item.pageId})`}
                    </span>
                </div>
                <SubMenu item={item} index={index + 1} key={item} />
            </div>
        </div>
    );
}

function MenusConfig() {
    const { menuConfigData } = useDocs();
    return (
        <div className="menu-config inner-scroll flex-col gap-10">
            <div className="page-title font-500 text-18 text-22">Menus Configuration:</div>
            <div className="flex-col align-left gap">
                <div className="page-subtitle text-18">
                    Admin<small className="text-14 font-400">(College)</small>:
                </div>

                {menuConfigData.map((item, index) => (
                    <MenusList item={item} index={index} key={item.id} />
                ))}
            </div>
        </div>
    );
}

export default MenusConfig;
