import React, { useState } from 'react';
import { ButtonRedLine } from '../../../../../../configs/Buttons';
import { icons } from '../../../../../../configs/Icons';
import { useTemplate } from '../../../../../features/template/TemplateProvider';
import { UserInfoV3 } from '../../../../../features/user-info/UserInfo';
import InstOption from '../../../../../utils/InstOption';
import { GetDate } from '../../../../../utils/Show';
import { useDomain } from '../../DomainProvider';
import { RemoveDomainAccess } from '../Modal/ModalRemove';

const initData = [
    {
        roleId: 10000002,
        userId: 10000001,
        name: 'Kumar Bishojit Paul',
        email: 'bishojit@gmail.com',
        roleKey: 'owner',
        roleTitle: 'Owner',
        isYou: false,
        isPending: false,
        timeAccepted: 1677561986,
        buttons: []
    },
    {
        roleId: 10000003,
        userId: 10000115,
        name: 'Yeasin Arafat',
        email: 'yeasinn2002@gmail.com',
        roleKey: 'manager',
        roleTitle: 'Manager',
        isYou: false,
        isPending: false,
        timeAccepted: 1679302448,
        buttons: []
    },
    {
        roleId: 10000005,
        userId: 10000114,
        name: 'Noman Gazi',
        email: 'numan.islam61@gmail.com',
        roleKey: 'developer',
        roleTitle: 'Developer',
        isYou: true,
        isPending: true,
        timeAccepted: 1691394897,
        buttons: ['remove', 'manage']
    }
];

function TrData({ item }) {
    const { setConfirm } = useTemplate();
    const [showMenu, setShowMenu] = useState(false);
    const handleShowMenu = () => setShowMenu(true);

    const handleRemoveMe = () => {
        setConfirm({
            text: <RemoveDomainAccess />,
            showButton: false
        });
    };
    return (
        <tr key={item?.roleId}>
            <td>{item?.roleId}</td>
            <td>
                <UserInfoV3 img="" name={item?.name} email={item?.email} />
            </td>
            <td>{GetDate(item?.timeAccepted)}</td>
            <td>{item.roleTitle}</td>
            <td>
                <div className="flex-row align-right p-relative action">
                    <span>
                        {item?.isYou && (
                            <button type="button" className="" onClick={handleShowMenu}>
                                <img src={icons?.optionFill} alt="" />
                            </button>
                        )}
                    </span>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        {item?.isPending && item?.roleKey !== 'developer' && (
                            <button type="button">Resend</button>
                        )}

                        {item?.isYou && item?.roleKey !== 'owner' && (
                            <button type="button" onClick={handleRemoveMe}>
                                Remove
                            </button>
                        )}
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}
function DomainAccessInfo() {
    const { setDomainController } = useDomain();
    return (
        <div className="domain-info-access">
            <div className="access__header flex-row align-space-between gap-10 position-middle">
                <div className="access__header-title">Domain Access</div>
                <div className="access__header-underline fill" />
                {/* <div className="access__header-assign">
                    <ButtonPurpleLine title="Assign User" />
                </div> */}
            </div>
            <table className="access__table" cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th width="18%">User SL</th>
                        <th width="40%">User</th>
                        <th width="20%">Assign Date</th>
                        <th width="20%">Role</th>
                        <th width="12%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {initData.map((item) => (
                        <TrData item={item} key={item?.userId} />
                    ))}
                </tbody>
            </table>
            <ButtonRedLine
                title="Assign User"
                evOnClick={() => setDomainController(true)}
                className="mt-15"
            />
        </div>
    );
}

export default DomainAccessInfo;
