import AxiosAPI from '../../../utils/AxiosAPI';

export const handleDedServerListOpe = (authInfo, dateStart, dateEnd, q) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contract/server/dedicated/list/`, {
                dateStart,
                dateEnd,
                q
            })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

export const handleDedServerStarred = (authInfo, id) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(` /api/contract/server/dedicated/${id}/starred/`)
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

export default null;
