import { useEffect, useRef } from 'react';
import AxiosAPI from '../../../utils/AxiosAPI';
import { useAuth } from '../../auth/AuthProvider';
import { useTemplate } from '../../template/TemplateProvider';

function SearchField({ setAdSearch, adSearch, handelOnChange }) {
    return (
        <div className="ad-search-input flex-row position-middle">
            <input
                value={adSearch.searchValue || ''}
                onChange={handelOnChange}
                placeholder="Type something..."
                className=""
                type="text"
                autoComplete="off"
            />
            <button
                className="close"
                type="button"
                onClick={() =>
                    setAdSearch((cuData) => ({
                        ...cuData,
                        mode: false,
                        users: null,
                        searchValue: ''
                    }))
                }
            />
        </div>
    );
}

function SearchedInfo({ item, handelUserClick }) {
    const { name, line1, line2, city, state, country, zip, mobile, email } = item || {};

    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={() => handelUserClick(item)}
            className="user-row flex-row position-middle align-space-between"
        >
            <div>
                <h2 className="user-updater_title">
                    <span>{name}</span>
                </h2>

                <p className="user-updater_sub-title">
                    <span>
                        {[line1, line2, city, state, country, zip].filter((d) => !!d).join(', ') ||
                            '-----------------------------'}
                    </span>
                    <br />
                    <span>
                        {[mobile, email].filter((d) => !!d).join(', ') ||
                            '------------------------'}
                    </span>
                </p>
            </div>
        </div>
    );
}

function AddressSearchArea({ setAdSearch, adSearch }) {
    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();
    const ref = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setAdSearch((cuData) => ({
                    ...cuData,
                    mode: false,
                    users: null,
                    searchValue: ''
                }));
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [adSearch.mode, setAdSearch]);

    // input search value
    const handelOnChange = (ev) => {
        setAdSearch((cuData) => ({ ...cuData, searchValue: ev.target.value }));

        if (ev.target.value.length > 2) {
            setAdSearch((cuData) => ({ ...cuData, selectedUser: null }));
            AxiosAPI(authInfo)
                .setPath(`/api/users/get-address-all`, {
                    q: ev.target.value,
                    limit: 20
                })
                .get()
                .then(({ data }) => {
                    if (data.error === 0) {
                        setAdSearch((cuData) => ({ ...cuData, users: data.data }));
                    } else {
                        setAdSearch((cuData) => ({ ...cuData, users: null }));
                        setMessage(data.message);
                    }
                })
                .catch((err) => {
                    setAdSearch((cuData) => ({ ...cuData, users: null }));
                    setMessage(err.toString());
                });
        } else {
            setAdSearch((cuData) => ({ ...cuData, users: null }));
        }
    };

    // click on user from user-list
    const handelUserClick = (item) => {
        setAdSearch((cuData) => ({
            ...cuData,
            selectedUser: item,
            mode: false,
            users: null,
            searchValue: ''
        }));
    };

    // on click search field focus
    useEffect(() => {
        if (adSearch.mode && ref.current) {
            const inputElement = ref.current.querySelector('input');
            if (inputElement) {
                inputElement.focus();
            }
        }
    }, [adSearch.mode]);

    const show = adSearch.mode;

    if (!show) {
        return null;
    }
    return (
        <div ref={ref} className="ad-search-cont">
            <SearchField
                setAdSearch={setAdSearch}
                adSearch={adSearch}
                handelOnChange={handelOnChange}
            />

            <div className="search-result-cont">
                {adSearch?.users?.map((item) => (
                    <SearchedInfo key={item.id} item={item} handelUserClick={handelUserClick} />
                ))}
            </div>
        </div>
    );
}

export default AddressSearchArea;
