import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../../configs/Icons';
import BikTable from '../../../../features/bik-table/BikTable';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import InstOption from '../../../../utils/InstOption';
import { GetDate, showCurrency } from '../../../../utils/Show';

function FinAccountsTableRow({ user, setStatementId }) {
    const navigate = useNavigate();
    const ref = useRef(null);
    const [showMenu, setShowMenu] = useState(false);

    const handleBgActive = () => {
        setStatementId(user?.facId);
        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };

    const handleShowMoreMenu = (ev) => {
        ev.stopPropagation();

        setShowMenu((cuData) => !cuData);
    };

    const handleStatement = (id) => {
        navigate(mkRelativeUrl(`/finance/accounts/${id}/statement/`, {}));
    };
    return (
        <tr className={user.demo ? 'placeholder-mode' : ''} ref={ref} onClick={handleBgActive}>
            <td className="text-left">
                <span>#{user.userInfo.id}</span>
            </td>
            <td className="text-left">
                <UserInfoV3
                    name={user.userInfo.name}
                    email={user.userInfo.email}
                    img={user.userInfo.photoUrl}
                />
            </td>
            <td>
                <div className="text-center">
                    <span
                        className="fac-text"
                        role="button"
                        tabIndex={-1}
                        onClick={() => handleStatement(user.facId)}
                    >
                        {user.facId}
                    </span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{showCurrency(user?.facCredit)}</span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{showCurrency(user?.facDebit)}</span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{showCurrency(user?.facBalance)}</span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{user?.facCurrency}</span>
                </div>
            </td>
            <td style={{ padding: '0px' }}>
                <div className="flex-row align-center">
                    <span>{GetDate(user.facTime)}</span>
                </div>
            </td>
            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button type="button" className="" onClick={handleShowMoreMenu}>
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        <button type="button" onClick={() => handleStatement(user.facId)}>
                            Statement
                        </button>
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}

function FinUserAccountsTable({ facList, setStatementId }) {
    return (
        <BikTable
            countDataAr={facList}
            dataTh={[
                { title: 'UID', width: '9%', align: 'left' },
                { title: 'User', width: '22%', align: 'left' },
                { title: 'FAC', width: '10%' },
                { title: 'Total Credit', width: '11%' },
                { title: 'Total Debit', width: '11%' },
                { title: 'Balance', width: '9%' },
                { title: 'Currency', width: '9%' },
                { title: 'Last Trans...', width: '10%' },
                { title: 'Action', width: '9%', align: 'right' }
            ]}
        >
            {facList?.map((user) => (
                <FinAccountsTableRow
                    user={user}
                    key={user?.facId}
                    setStatementId={setStatementId}
                />
            ))}
        </BikTable>
    );
}

export default FinUserAccountsTable;
