import React from 'react';
import FinSellItemTable from './components/FinSellItemTable';

function FinSellItemPageBody() {
    return (
        <div className="fin-sell-item__body">
            <FinSellItemTable />
        </div>
    );
}

export default FinSellItemPageBody;
