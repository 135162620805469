import { GetDate } from '../../../../utils/Show';
import SectionDivider from '../_models/SectionDivider';

function InfoRow({ title, value }) {
    return (
        <div className="info-row flex-row position-stretch">
            <span>{title}:</span>
            <span>{value}</span>
        </div>
    );
}

function UserInfoSection({ data }) {
    const { name, usename, gender, email, phone, dob } = data || {};
    return (
        <div className="user-info-cont">
            <SectionDivider title="Personal Information" />

            <div className="personal-info_cont flex-row align-space-between">
                <div className="">
                    <InfoRow title="Name" value={name || '--'} />
                    <InfoRow title="User Name" value={usename || '--'} />
                    <InfoRow title="Gender" value={gender || '--'} />
                </div>
                <div className="">
                    <InfoRow title="Email Address" value={email || '--'} />
                    <InfoRow title="Phone Number" value={phone || '--'} />
                    <InfoRow title="Date of birth" value={GetDate(dob) || '--'} />
                </div>
            </div>

            <SectionDivider title="Location" />
            <div className="personal-info_cont location-info flex-row align-space-between">
                <div>
                    <InfoRow
                        title="Address"
                        value="House-02, Block-F, Shangbadik Abashik Elaka, Kalshi Road, Mirpur-11,
Dhaka-1216, Bangladesh"
                    />
                </div>
            </div>
        </div>
    );
}

export default UserInfoSection;
