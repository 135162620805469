import AxiosAPI from '../../../utils/AxiosAPI';

export const handleHostingListOpa = (authInfo, q) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contracts-hosting/list/`, { q })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

export const handleHostingStarredOpa = (authInfo, id) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contracts-hosting/${id}/starred`)
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
export default null;
