import React from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';

function DedServerPageHeader({ handleReload, sParams }) {
    const { qParam } = sParams;
    const navigate = useNavigate();

    const handleOnSubmit = (e) => {
        if (e.key === 'Enter') {
            const { name, value } = e.target;
            navigate(
                mkRelativeUrl(`/contract/dedicated-server/`, {
                    q: name === 'search' ? value : qParam
                })
            );
        }
    };
    return (
        <div className="dedicated-server-page__header flex-row position-middle align-space-between gap-20">
            <div className="text-30 font-500">Dedicated Server</div>
            <div className="flex-row position-middle gap-20 fill">
                <div className="user-custom-data gap-5">
                    <select className="custom-select" name="type" onChange={null}>
                        <option value="">Select Type</option>
                        <option value="admin">Admin</option>
                        <option value="customer">Customer</option>
                    </select>
                </div>
                <div className="user-custom-data gap-5">
                    <input
                        onKeyDown={handleOnSubmit}
                        defaultValue={qParam}
                        name="search"
                        type="text"
                        className="custom-input"
                        placeholder="Search"
                        autoComplete="off"
                        autoCorrect="off"
                    />
                </div>
            </div>
            <div className="content-reload">
                <div className="content-refresh-btn">
                    <button type="button" className="p-relative" onClick={handleReload}>
                        <img src={icons.reloadL} alt="" />
                        <img src={icons.reloadF} alt="" />
                    </button>
                </div>
            </div>
            <div className="create-campaign">
                <button
                    type="button"
                    className="flex-row gap-5 align-center position-middle"
                    // onClick={handleOpen}
                >
                    <span>+</span>
                    <span>Add New</span>
                </button>

                {/* {open ? (
                        <LibModal title="Create New User" setOpen={setOpen}>
                            <UserCreateModal setOpen={setOpen} />
                        </LibModal>
                    ) : null} */}
            </div>
        </div>
    );
}

export default DedServerPageHeader;
