import React, { useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import BikTable from '../../../../features/bik-table/BikTable';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import NotFound from '../../../../features/not-found/NotFound';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import InstOption from '../../../../utils/InstOption';
import { GetDate, showCurrency } from '../../../../utils/Show';
import UtilFacPreview from '../../../../utils/UtilFacPreview';

function TableRow({ item, queryObj }) {
    const ref = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const handleShowMoreMenu = () => setShowMenu((cuData) => !cuData);

    const { startDate, endDate } = queryObj;

    const url = (id) =>
        mkRelativeUrl(`/finance/accounts/${id}/statement`, {
            'date-start': startDate,
            'date-end': endDate
        });

    const handleBgActive = () => {
        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };

    return (
        <tr className={item.demo ? 'placeholder-mode' : ''} ref={ref} onClick={handleBgActive}>
            <td className="text-left">
                <span>#{item?.id}</span>
            </td>

            <td>
                <UserInfoV3
                    name={item?.user?.name}
                    // email={item?.user?.email}
                    img={item?.user?.photoUrl}
                />
            </td>
            <td className="text-left">
                <div className="flex-row align-center">
                    <span>{GetDate(item.timeCreated)}</span>
                </div>
            </td>
            <td>
                <UtilFacPreview data={item?.debit || null} url={url(item?.debit?.facId)} />
            </td>
            <td>
                <UtilFacPreview data={item?.credit || null} url={url(item?.credit?.facId)} />
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{item?.currency}</span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{showCurrency(item?.amount)}</span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{showCurrency(item?.amountUsd)}</span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <div className={`status ${item?.status}`}>
                        <span>{item?.status}</span>
                    </div>
                </div>
            </td>
            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button type="button" className="" onClick={handleShowMoreMenu}>
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        <button type="button" onClick={null}>
                            View
                        </button>
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}

function FinTransTable({ transData, queryObj }) {
    if (transData && !transData?.length) {
        return <NotFound title="Transaction Not Found" />;
    }

    return (
        <BikTable
            countDataAr={transData}
            dataTh={[
                { title: 'Trans ID', width: '9', align: 'left' },
                { title: 'User', width: '22', align: 'left' },
                { title: 'Date', width: '10' },
                { title: 'Debit Account', width: '9' },
                { title: 'Credit Account', width: '9' },
                { title: 'Currency', width: '9' },
                { title: 'Amount', width: '8' },
                { title: 'Equivalent USD', width: '9' },
                { title: 'Status', width: '8' },
                { title: 'Action', width: '8', align: 'right' }
            ]}
        >
            {transData.map((item) => (
                <TableRow key={item?.id} item={item} queryObj={queryObj} />
            ))}
        </BikTable>
    );
}

export default FinTransTable;
