import React from 'react';
import { ButtonRedLine } from '../../../../../../configs/Buttons';
import { useDomain } from '../../DomainProvider';

const registrant = {
    name: 'Kumar Bishojit',
    org: 'BIKIRAN.COM',
    email: 'beporit@gmail.com',
    mobile: '',
    telephone: '8801925363333',
    fax: '',
    address: {
        line1: '133/3, Bagmara Bankers lane-1, Khulna-9100',
        line2: '',
        line3: '',
        country: 'BD',
        state: 'Khulna',
        city: 'Khulna',
        zip: '9100',
        countryFull: 'Bangladesh'
    },
    resident_type: null,
    hash: '4d05bbf4bf329d58f6bda57b5bc16827',
    contactId: '21215870',
    customerId: '7995378',
    status: 'active'
};
function DomainRegistrantDetails() {
    const { setOpenAddressModal } = useDomain();
    return (
        <div className="tech-info__reg-details">
            <div className="reg-details__header flex-row position-middle align-space-between gap-10">
                <div className="details__header-title">Registrant Details</div>
                <div className="details__header-underline fill" />
                <div className="details__header-edit">
                    <ButtonRedLine title="Edit" evOnClick={() => setOpenAddressModal(true)} />
                </div>
            </div>

            <div className="reg-details__body flex-row">
                <div className="details__cont-left">
                    <div className="details__body-name">
                        <span>Name:</span>
                        <span>{registrant?.name}</span>
                    </div>
                    <div className="details__body-phone">
                        <span>Phone Number:</span>
                        <span>{registrant?.telephone}</span>
                    </div>
                    <div className="details__body-email">
                        <span>Email Address: </span>
                        <span>{registrant?.email}</span>
                    </div>
                </div>

                <div className="details__cont-right">
                    <div className="details__body-address">
                        <span>Address:</span>
                        <span>{registrant?.address?.line1}</span>
                    </div>
                    <div className="details__body-city">
                        <span>City:</span>
                        <span>{registrant?.address?.city}</span>
                    </div>
                    <div className="details__body-state">
                        <span>State: </span>
                        <span>{registrant?.address?.state}</span>
                    </div>
                    <div className="details__body-postal">
                        <span>Postal Code: </span>
                        <span>{registrant?.address?.zip}</span>
                    </div>
                    <div className="details__body-country">
                        <span>Country:</span>
                        <span>{registrant?.address?.countryFull}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DomainRegistrantDetails;
