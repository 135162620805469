import { useEffect, useState } from 'react';
import LibModal from '../../lib/LibModal';
import countries from '../../utils/country.json';
import { useTemplate } from '../template/TemplateProvider';
import AddressHeaderArea from './components/AddressHeaderArea';
import {
    AddressCountryInp,
    AddressInputField,
    AddressLineInp,
    AddressMobileInp,
    FaxInput,
    TelephoneInput
} from './components/AddressInputs';
import { TelFaxIcon, TelMinusIcon } from './components/AddressOptions';
import AddressSearchArea from './components/AddressSearchArea';
import FormatAddress from './components/FormatAddress';
import { ButtonCancel, ButtonSave } from './components/UpdaterButtons';

const initialFormData = {
    name: '',
    org: '',
    email: '',
    mobile: '',
    telephone: '',
    fax: '',
    line1: '',
    country: '',
    state: '',
    city: '',
    zip: ''
};

const initialData = { mode: false, searchValue: '', selectedUser: null, users: [] };

function AddressUpdater({ address, setOpen, handelChangeAddress }) {
    const { setMessage } = useTemplate();
    const [adSearch, setAdSearch] = useState(initialData);
    const [formData, setFormData] = useState({
        ...initialFormData
    });
    const [mode, setMode] = useState({ tel: false, fax: false });

    const handelChange = (ev) => {
        const { name, value } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: value }));
        }
    };

    const handleFormData = () => {
        const result = FormatAddress(formData);

        if (result.error) {
            setMessage(result.message);
        } else {
            setMessage('Saving address info...');
            handelChangeAddress({ ...result.address, id: address.id })
                .then(({ error, message }) => {
                    if (error === 0) {
                        setOpen(false);
                    }
                    setMessage(message);
                })
                .catch((err) => {
                    setMessage(err.toString());
                });
        }
    };

    // formdata state to be updated
    useEffect(() => {
        if (adSearch.selectedUser) {
            setFormData(() => ({ ...adSearch.selectedUser }));
        } else {
            setFormData((d) => ({ ...d, ...address }));
        }
    }, [address, adSearch.selectedUser]);

    return (
        <div className={`address-updater-container ${adSearch.mode ? 'active' : ''}`}>
            <LibModal
                title={<AddressHeaderArea setAdSearch={setAdSearch} adSearch={adSearch} />}
                setOpen={setOpen}
            >
                <div className="address-updater-body p-relative">
                    <AddressSearchArea adSearch={adSearch} setAdSearch={setAdSearch} />

                    <div className="flex-row flex-wrap align-space-between">
                        <AddressInputField
                            title="Full Name"
                            name="name"
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <AddressInputField
                            title="Organization"
                            name="org"
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <AddressInputField
                            title="Email"
                            name="email"
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <AddressMobileInp
                            options={<TelFaxIcon mode={mode} setMode={setMode} />}
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <TelephoneInput
                            mode={mode}
                            option={<TelMinusIcon name="tel" setMode={setMode} />}
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <FaxInput
                            mode={mode}
                            option={<TelMinusIcon name="fax" setMode={setMode} />}
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <AddressLineInp formData={formData} handelChange={handelChange} />

                        <AddressCountryInp
                            formData={formData}
                            handelChange={handelChange}
                            countries={countries}
                        />

                        <AddressInputField
                            title="State"
                            name="state"
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <AddressInputField
                            title="City"
                            name="city"
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <AddressInputField
                            title="Zip"
                            name="zip"
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>

                    <div className="flex-row align-right ad-button-area">
                        <ButtonCancel
                            evOnClick={() => {
                                setOpen(false);
                            }}
                        />

                        <ButtonSave evOnClick={handleFormData} />
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default AddressUpdater;
