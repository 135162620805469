import AxiosAPI from '../../../utils/AxiosAPI';

export const handleUserDBOperation = (authInfo, dateStart, dateEnd) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath('/api/users/dashboard/', { dateStart, dateEnd })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export default null;
