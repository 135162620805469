import AxiosAPI from '../../../utils/AxiosAPI';

export const handleSellItemList = (authInfo, type, prams) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/sell-invoice/product-list/`, { type, ...prams })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

export const handleContractUpdate = (authInfo, invoiceId, itemId) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contract/create-from-invoice/${invoiceId}/${itemId}`)
            .post()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

export const handleSearchContract = (authInfo, itemId, invoiceId, q) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contract/search/`, { itemId, invoiceId, q })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
export default null;
