import React from 'react';
import Show from '../../../../../utils/Show';

function PriceDiscountInfo({ priceDiscount, vat }) {
    return (
        <p className="price-discount non-printable">
            <Show show={priceDiscount}>
                <span className="price-amount">{`${priceDiscount.toFixed(2)}%`}</span> Discount{' '}
                <br />
            </Show>

            <Show show={vat}>
                then <span className="price-amount">{vat}</span>% vat
            </Show>
        </p>
    );
}

export default PriceDiscountInfo;
