import React, { useRef } from 'react';
import { showCurrency } from '../../utils/Show';

const demoData = {
    userDeposit: 1000,
    userWithdraw: 2000,
    userPayment: 5000,
    userRefund: 1000,
    noData: true
};

function SummaryData({ summary }) {
    return (
        <>
            <div className={`w-100p flex-col gap-5 ${summary.noData ? 'placeholder-mode' : ''}`}>
                <div className=" flex-row align-space-between">
                    <div>Deposit</div>
                    <span>{showCurrency(summary?.userDeposit)}</span>
                </div>
                <div className="flex-row align-space-between">
                    <div>Withdraw</div>
                    <span>
                        <small style={{ fontSize: '10px' }}>(-)</small>{' '}
                        {showCurrency(summary?.userWithdraw)}
                    </span>
                </div>
                <div className="flex-row align-space-between">
                    <div className="font-600">Net Deposit</div>
                    <span className="font-600">
                        {showCurrency(summary?.userDeposit - summary?.userWithdraw)}
                    </span>
                </div>
            </div>
            <div className={`w-100p flex-col gap-5 ${summary.noData ? 'placeholder-mode' : ''}`}>
                <div className=" flex-row align-space-between">
                    <div>Sale</div>
                    <span>{showCurrency(summary?.userPayment)}</span>
                </div>
                <div className="flex-row align-space-between">
                    <div>Refund</div>
                    <span>
                        <small style={{ fontSize: '10px' }}>(-)</small>{' '}
                        {showCurrency(summary?.userRefund)}
                    </span>
                </div>
                <div className="flex-row align-space-between">
                    <div className="font-600">Net Sale</div>
                    <span className="font-600">
                        {showCurrency(summary?.userPayment - summary?.userRefund)}
                    </span>
                </div>
            </div>
        </>
    );
}
function TransactionSummary({ cuData, currency, evClose, evOpen, summaryData, children }) {
    const summaryRef = useRef(null);
    const summary = summaryData || demoData;

    const handleOpenSummary = () => {
        summaryRef.current.classList.add('active');
        summaryRef.current.firstChild.classList.add('hide');
    };
    const handleCloseSummary = () => {
        summaryRef.current.classList.remove('active');

        setTimeout(() => {
            summaryRef.current.firstChild.classList.remove('hide');
        }, 400);
    };

    return (
        <div
            className={`feature-trans-summary ${currency ? 'active' : ''} ${
                cuData?.length ? 'active' : ''
            }`}
            ref={summaryRef}
        >
            <button
                type="button"
                className={`feature-summary-open-btn ${currency ? 'hide' : ''} ${
                    cuData?.length ? 'hide' : ''
                }`}
                onClick={evOpen || handleOpenSummary}
            />
            <div className="feature-trans-body">
                <div className="feature-trans-body__header flex-row position-middle align-space-between">
                    <span>Summary {currency && `(${currency})`}</span>
                    <button
                        type="button"
                        className="feature-summary-hide-btn"
                        onClick={evClose || handleCloseSummary}
                    />
                </div>
                <div className={`feature-trans-body__cont flex-col gap-20 `}>
                    {children || <SummaryData summary={summary} />}
                </div>
            </div>
        </div>
    );
}

export default TransactionSummary;
