import React from 'react';
import { icons } from '../../../configs/Icons';
import InstOption from '../../utils/InstOption';
import { useProductEditor } from './InvoiceProductEditorProvider';

function ButtonCommonView({ evOnclick, title, icon }) {
    return (
        <button onClick={evOnclick} type="button">
            <div className="flex-row position-middle">
                <img className="menu-logo" src={icon} alt="button" />
                <span>{title}</span>
            </div>
        </button>
    );
}

function InvoiceProductEditOptions({ showMenu, setShowMenu }) {
    const { handelOpenModal } = useProductEditor();

    const handelOptionClick = (property) => {
        handelOpenModal(property);
        setShowMenu(false);
    };

    return (
        <div className="inst-invoice">
            <InstOption showMenu={showMenu} setShowMenu={setShowMenu}>
                <ButtonCommonView
                    evOnclick={() => handelOptionClick('domain')}
                    title="Domain"
                    icon={icons.domainIcon}
                />

                <ButtonCommonView
                    evOnclick={() => handelOptionClick('hosting')}
                    title="Hosting"
                    icon={icons.hostingIcon}
                />

                <ButtonCommonView
                    evOnclick={() => handelOptionClick('server_dedicated')}
                    title="Dedicated Server"
                    icon={icons.serverIcon}
                />

                <ButtonCommonView
                    evOnclick={() => handelOptionClick('server_vps')}
                    title="VPS"
                    icon={icons.vpsIcon}
                />

                <ButtonCommonView
                    evOnclick={() => handelOptionClick('push')}
                    title="Push"
                    icon={icons.bell}
                />

                <ButtonCommonView
                    evOnclick={() => handelOptionClick('sms')}
                    title="SMS"
                    icon={icons.smsIcon}
                />

                <ButtonCommonView
                    evOnclick={() => handelOptionClick('email')}
                    title="Email"
                    icon={icons.emailIcon}
                />

                <ButtonCommonView
                    evOnclick={() => handelOptionClick('premium_contract')}
                    title="Premium Contract"
                    icon={icons.contract}
                />

                <ButtonCommonView
                    evOnclick={() => handelOptionClick('custom_product')}
                    title="Other Product"
                    icon={icons.product}
                />
            </InstOption>
        </div>
    );
}

export default InvoiceProductEditOptions;
