function UserUpdaterCurrentUser({ data }) {
    // if (!show) {
    //     return null;
    // }
    return (
        <div className="user-row selected flex-row align-space-between position-middle">
            <div>
                <div className="user-updater_avatar ">
                    <img src={data?.photoUrl} alt="avatar" />
                </div>
            </div>
            <div>
                <div className="flex-row position-middle align-space-between">
                    <h2 className="user-updater_title">{data?.name || '--'}</h2>
                    <span className="user-updater_tag">Current</span>
                </div>
                <p className="user-updater_sub-title">{data?.email || '--'}</p>
            </div>
        </div>
    );
}

export default UserUpdaterCurrentUser;
