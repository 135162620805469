/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
// import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';

const initialState = { noteTitle: '', noteDescription: '' };
const toolbarConfig = {
    items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList'
    ]
};

function FinInvoiceAddNotes({ show, setOpen, title, description, handelChangeNote }) {
    const [formData, setFormData] = useState(initialState);

    const { setMessage } = useTemplate();

    const handleValueChange = (ev) => {
        const { name, value } = ev.target;

        if (name) {
            setFormData((cuData) => ({ ...cuData, [name]: value }));
        }
    };

    const handleAddButton = () => {
        setMessage('Adding notes....');
        handelChangeNote(formData.noteTitle, formData.noteDescription)
            .then(({ error, message }) => {
                if (error === 0) {
                    setOpen(false);
                    setFormData(initialState);
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    useEffect(() => {
        if (!show) {
            setFormData(initialState);
        }
        if ((title && title?.length) || (description && description?.length)) {
            setFormData((cuData) => ({
                ...cuData,
                noteTitle: title,
                noteDescription: description
            }));
        }
    }, [description, show, title]);

    if (!show) {
        return null;
    }
    return (
        <div className="invoice-add-notes">
            <LibModal title="Notes" setOpen={setOpen}>
                <div className="invoice-add-notes-popup">
                    <div className="input-area">
                        <div>
                            <label htmlFor="titles" className="label">
                                Title:
                            </label>
                            <br />
                            <input
                                onChange={handleValueChange}
                                value={formData?.noteTitle || ''}
                                type="text"
                                name="noteTitle"
                                placeholder="Type Something...."
                                className="form-input"
                            />
                        </div>
                        <div>
                            <label htmlFor="note" className="label">
                                Notes:
                            </label>
                            <br />

                            <CKEditor
                                editor={ClassicEditor}
                                config={{ toolbar: toolbarConfig }}
                                data={formData?.noteDescription || ''}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor?.getData();
                                    setFormData((cuData) => ({ ...cuData, noteDescription: data }));
                                }}
                                // onBlur={(event, editor) => {
                                //     console.log('Blur.', editor);
                                // }}
                                // onFocus={(event, editor) => {
                                //     console.log('Focus.', editor);
                                // }}
                            />
                        </div>
                    </div>

                    <div className="flex-row align-right">
                        <ButtonGray
                            evOnClick={() => setOpen(false) + setFormData(initialState)}
                            title="Cancel"
                        />

                        <ButtonGreen evOnClick={handleAddButton} title="Update Notes" />
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default FinInvoiceAddNotes;
