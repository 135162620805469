import { createContext, useContext, useMemo } from 'react';

const BikPaginateContext = createContext();

export const useBikPaginate = () => useContext(BikPaginateContext);

function BikPaginateProvider({ children }) {
    const value = useMemo(() => {
        console.log('BikPaginateProvider');
        return {};
    }, []);

    return <BikPaginateContext.Provider value={value}>{children}</BikPaginateContext.Provider>;
}

export default BikPaginateProvider;
