import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import LibModal from '../../../lib/LibModal';
import UserCreateModal from './modal/UserCreateModal';

function UserListPageHeader({ handleReload, sParams }) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const { typeParams, queryParams } = sParams;

    const handleOnChange = (e) => {
        console.log(e);
        const { name, value } = e.target;

        if (name === 'type') {
            navigate(
                mkRelativeUrl('/users/list/', {
                    type: name === 'type' ? value : typeParams,
                    q: name === 'search' ? value : queryParams
                })
            );
        }
    };
    const handleOnSubmit = (e) => {
        if (e.key === 'Enter') {
            const { name, value } = e.target;

            navigate(
                mkRelativeUrl('/users/list/', {
                    type: name === 'type' ? value : typeParams,
                    q: name === 'search' ? value : queryParams
                })
            );
        }
    };
    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div className="user-list-page__header page-header-cont flex-row position-middle align-space-between gap-20">
            <div>
                <h2 className="page-header_title">User List</h2>
            </div>
            <div className="flex-row position-middle gap-20 fill">
                <div className="user-custom-data">
                    <select className="custom-select" name="type" onChange={handleOnChange}>
                        <option value="">Select Type</option>
                        <option value="admin">Admin</option>
                        <option value="customer">Customer</option>
                    </select>
                </div>
                <div className="user-custom-data">
                    <input
                        onKeyDown={handleOnSubmit}
                        defaultValue={queryParams}
                        name="search"
                        className="custom-input"
                        type="text"
                        placeholder="Search"
                        autoComplete="off"
                        autoCorrect="off"
                    />
                </div>
            </div>
            <div className="reload-button">
                <button type="button" className="p-relative" onClick={handleReload}>
                    <img src={icons.reloadLine} alt="" />
                    <img src={icons.reloadFill} alt="" />
                </button>
            </div>

            <div className="create-campaign">
                <button
                    type="button"
                    className="flex-row gap-5 align-center position-middle"
                    onClick={handleOpen}
                >
                    <span>+</span>
                    <span>Create User</span>
                </button>

                {open ? (
                    <LibModal title="Create New User" setOpen={setOpen}>
                        <UserCreateModal setOpen={setOpen} />
                    </LibModal>
                ) : null}
            </div>
        </div>
    );
}

export default UserListPageHeader;
