const menusItem = [
    {
        id: 1,
        title: 'Cover Page',
        path: '/users/docs/cover-page'
    },
    {
        id: 2,
        title: 'Modules',
        path: '/users/docs/modules'
    },
    {
        id: 3,
        title: 'Technology',
        path: '/users/docs/technology'
    },
    {
        id: 4,
        title: 'Menu Configuration',
        path: '/users/docs/menus-config'
    }
];

export default menusItem;
