import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';

const FinSellItemContext = createContext();

// -- Export hook
export const useFinSellItem = () => useContext(FinSellItemContext);

function FinSellItemProvider({ children }) {
    const navigate = useNavigate();
    // -- Reload component when data change
    const [reloadKey, setReloadKey] = useState(0);
    // -- Header filter modal
    const [filterModal, setFilterModal] = useState(false);
    // -- Initially loading data set after load this page
    const [sellItemData, setSellItemData] = useState([]);
    // -- Contract modal show hide
    const [isModalOpen, setIsModalOpen] = useState(false);
    // -- Set invoice sl for sidebar view
    const [invoiceSl, setInvoiceSl] = useState(null);
    // -- Set data all search params
    const [searchQueryParams, setSearchQueryParams] = useState({});

    const valueCtx = useMemo(() => {
        // --Filter modal data
        const handleSearchBtn = (data) => {
            if (
                !data?.invoice &&
                !data?.user &&
                !data?.dueFrom &&
                !data?.dueTo &&
                !data?.currency &&
                !data?.status &&
                !data?.type
            ) {
                return;
            }

            navigate(
                mkRelativeUrl('/finance/sell-item/', {
                    ...(data?.invoice && { invoice: data?.invoice }),
                    ...(data?.user && { user: data?.user }),
                    ...(data?.dueFrom && { 'due-from': data?.dueFrom }),
                    ...(data?.dueTo && { 'due-to': data?.dueTo }),
                    ...(data?.currency && { currency: data?.currency }),
                    ...(data?.status && { status: data?.status }),
                    ...(data?.type && { type: data?.type }),
                    ...(data?.query && { query: data?.query })
                })
            );

            setFilterModal(false);
        };

        // --Reload data when it call
        const handleReload = () => {
            setReloadKey(Math.random());
            setSellItemData([]);
        };

        return {
            isModalOpen,
            setIsModalOpen,
            searchQueryParams,
            setSearchQueryParams,
            sellItemData,
            setSellItemData,
            reloadKey,
            setReloadKey,
            invoiceSl,
            setInvoiceSl,
            filterModal,
            setFilterModal,
            handleReload,
            handleSearchBtn
        };
    }, [filterModal, invoiceSl, isModalOpen, navigate, reloadKey, searchQueryParams, sellItemData]);
    return <FinSellItemContext.Provider value={valueCtx}>{children}</FinSellItemContext.Provider>;
}

export default FinSellItemProvider;
