/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from 'react';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { ButtonCreate } from '../../../../features/user_updater/UserUpdaterButtons';
import UserUpdaterSearchedUser from '../../../../features/user_updater/components/UserUpdaterSearchedUser';
import UserUpdaterSelectedUser from '../../../../features/user_updater/components/UserUpdaterSelectedUser';
import AxiosAPI from '../../../../utils/AxiosAPI';
import { handleDomainAssignUserOpe } from '../DomainOperation';
import { useDomain } from '../DomainProvider';

function DomainAssignUserModal({ userAssignModal, setUserAssignModal }) {
    const { setDomainData } = useDomain();
    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();
    const { domainContractId } = userAssignModal;

    const [searchValue, setSearchValue] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const [formData, setFormData] = useState({
        currentUserId: 0,
        newUserId: ''
    });

    const ref = useRef();

    const handelSearchField = (ev) => {
        setSearchValue(ev.target.value);

        if (ev.target.value.length > 2) {
            setSelectedUser(null);
            AxiosAPI(authInfo)
                .setPath(`/api/users/search/`, {
                    q: ev.target.value,
                    limit: 20
                })
                .get()
                .then(({ data }) => {
                    if (data.error === 0) {
                        setUsers(data.data);
                    } else {
                        setUsers(null);
                        setMessage(data.message);
                    }
                })
                .catch((err) => {
                    setUsers(null);
                    setMessage(err.toString());
                });
        } else {
            setUsers(null);
        }
    };

    const handelUserClick = (item) => {
        setSelectedUser(item);
        setFormData({ ...formData, newUserId: item?.id });
        ref.current.value = '';
    };
    const isSearchedUser = searchValue?.length && users && !selectedUser;

    const handleAssignUser = () => {
        handleDomainAssignUserOpe(authInfo, domainContractId, formData)
            .then(({ message }) => {
                setMessage(message);
                setUserAssignModal(false);
                setDomainData((cuData) => {
                    const nData = [...cuData];
                    return nData.map((d) => {
                        if (d.id === domainContractId) {
                            return {
                                ...d,
                                userInfo: {
                                    id: selectedUser?.id,
                                    name: selectedUser?.name,
                                    email: selectedUser?.email,
                                    phone: selectedUser?.phone,
                                    photoUrl: selectedUser?.photoUrl
                                }
                            };
                        }
                        return d;
                    });
                });
            })
            .catch((error) => {
                setMessage(error.toString(0));
            });
    };

    return (
        <div className="user-updater-container flex-col align-center position-space-around">
            <div className={`updater-search-area w-100p ${isSearchedUser ? 'active' : ''}`}>
                <div>
                    <label htmlFor="search">Search User</label>

                    <input
                        id="search"
                        ref={ref}
                        onChange={handelSearchField}
                        placeholder="Type minimum 3 character"
                        type="text"
                        autoComplete="off"
                    />

                    <UserUpdaterSearchedUser
                        show={isSearchedUser}
                        data={users}
                        handelUserClick={handelUserClick}
                    />
                </div>
            </div>

            <div className="user-updater-body w-100p">
                <UserUpdaterSelectedUser data={selectedUser} />
            </div>
            <div className="flex-row flex-row align-right mt-10 w-100p">
                <ButtonCreate evOnClick={handleAssignUser} />
            </div>
        </div>
    );
}

export default DomainAssignUserModal;
