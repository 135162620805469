import { useEffect, useState } from 'react';
import { useAuth } from '../../../features/auth/AuthProvider';
import InfoSidebar from '../../../features/info_sidebar/InfoSidebar';
import { useAdvMenu } from '../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import { handleLoadProjectList } from './UserProjectsOperation';
import ProjectsModalController from './__models/ProjectsModalController';
import UserProjectPageHeader from './components/UserProjectPageHeader';
import UserProjectsTable from './components/UserProjectsTable';
import ProjectInfoSection from './project_info/ProjectInfoSection';

const initialState = {
    mode: false,
    type: '',
    data: null
};

function UserProjectsPage() {
    const [showModal, setShowModal] = useState(initialState);
    const [infoId, setInfoId] = useState(null);
    const [projectList, setProjectList] = useState(null);
    const [reloadKey, setReloadKey] = useState(Math.random());

    const { handleAddProject } = useAdvMenu();

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handleOpenModal = (type, data) => {
        setShowModal({
            mode: true,
            type,
            data
        });
    };

    const closeModal = () => {
        setShowModal({
            mode: false,
            type: ''
        });
    };

    const handleReload = () => {
        setProjectList(null);
        setReloadKey((cuData) => cuData + 1);
    };

    useEffect(() => {
        setProjectList(null);
        handleLoadProjectList(authInfo)
            .then(({ data }) => {
                setProjectList(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, setMessage, reloadKey]);

    return (
        <div className="page-cont projects-page-cont">
            <UserProjectPageHeader openModal={handleAddProject} handleReload={handleReload} />
            <UserProjectsTable setInfoId={setInfoId} projectList={projectList} />

            <InfoSidebar show={infoId}>
                <ProjectInfoSection infoId={infoId} handlerOpenModal={handleOpenModal} />
            </InfoSidebar>

            {/* modals */}
            <ProjectsModalController
                setReloadKey={setReloadKey}
                modalType={showModal?.type}
                modalProps={showModal?.data}
                closeClick={closeModal}
            />
        </div>
    );
}

export default UserProjectsPage;
