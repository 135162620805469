import { useState } from 'react';
import { ButtonPurpleLine, ButtonSkyLine } from '../../../../../configs/Buttons';
import CustomerFundTransferSection from '../../../../features/customer_fund_transfer/view/CustomerFundTransferSection';
import OpFundTransferSection from '../../../../features/operator-fund-transfer/view/OpFundTransferSection';
import Show from '../../../../utils/Show';
import ChangeStatusModal from '../modals/ChangeStatusModal';

function FinStatementQuickLinks({ statementData }) {
    const [open, setOpen] = useState(false);

    return (
        <div className="invoice-info-cont buttons">
            <div className="info-section_header">
                <h2 className="info-section_title">Quick Links</h2>
            </div>

            <div className="fin-user-statement_buttons-cont flex-row flex-wrap gap-10">
                <Show>
                    <span>
                        <ButtonPurpleLine
                            evOnClick={null}
                            title={`Filter by ${statementData?.fac?.type || '--'}`}
                        />
                    </span>
                </Show>
                <Show>
                    <span>
                        <ButtonSkyLine evOnClick={() => setOpen(true)} title="Change Status" />
                    </span>
                    {open ? (
                        <ChangeStatusModal setOpen={setOpen} statementData={statementData} />
                    ) : null}
                </Show>
            </div>
        </div>
    );
}

function FinUserStatementRightCol({ handelReload, statementData }) {
    const type = statementData?.fac?.type;
    const user = {
        ...statementData?.user,
        currency: statementData?.fac?.currency,
        balance: statementData?.fac?.facBalance
    };

    return (
        <div>
            <CustomerFundTransferSection
                show={statementData?.fac && type === 'customer'}
                user={user}
                reload={handelReload}
            />

            <OpFundTransferSection
                show={statementData && type === 'operator'}
                user={user}
                reload={handelReload}
            />

            <FinStatementQuickLinks statementData={statementData} handelReload={handelReload} />
        </div>
    );
}

export default FinUserStatementRightCol;
