import { useRef, useState } from 'react';
import { ButtonPurple } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import BikTable from '../../../../features/bik-table/BikTable';
import NotFound from '../../../../features/not-found/NotFound';
import { UserInfoV4 } from '../../../../features/user-info/UserInfo';
import InstOption from '../../../../utils/InstOption';
import { GetDate, showCurrency } from '../../../../utils/Show';
import { useFinSellItem } from '../FinSellItemProvider';
import FinSellItemContractModal from '../modal/FinSellItemContractModal';

function TableRow({ item, setInvoiceSl, setIsModalOpen, setTrData }) {
    const ref = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const handleShowMoreMenu = () => setShowMenu((cuData) => !cuData);

    const handleBgActive = () => {
        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };
    const handleAddContract = (ev, itemData) => {
        ev.stopPropagation();
        setIsModalOpen(true);
        setTrData(itemData);
    };
    const handleShowView = (ev, invoiceId) => {
        ev.stopPropagation();
        setInvoiceSl(invoiceId);
    };
    return (
        <tr className={item?.demo ? 'placeholder-mode' : ''} ref={ref} onClick={handleBgActive}>
            <td className="text-left">
                <span
                    role="button"
                    tabIndex={-1}
                    onClick={(ev) => handleShowView(ev, item?.invoiceId)}
                >
                    {item?.invoiceId}
                </span>
            </td>

            <td style={{ padding: ' 0px 2px' }}>
                <UserInfoV4
                    name={item?.userInfo?.name}
                    email={item?.userInfo?.email}
                    img={item?.userInfo?.photoUrl}
                    title={`${item?.title} <br/> ${item?.domain}`}
                />
            </td>
            <td className="text-center">
                <span>{GetDate(item?.subscriptionEnd)}</span>
            </td>
            <td className="text-center">
                <span>{GetDate(item?.timeDue)}</span>
            </td>
            <td
                className="text-center"
                data-tooltip-id="myTooltip"
                data-tooltip-content={`${item?.priceTxt} ${showCurrency(item?.price)}`}
            >
                <span>{showCurrency(item?.price)}</span>
            </td>
            <td className="text-center">
                <span>{showCurrency(item?.currency)}</span>
            </td>
            <td className="text-center">
                <span className={`status ${item?.status}`}>{item?.status}</span>
            </td>
            <td className="text-center">
                <div className="flex-row align-center w-100p">
                    <span>
                        {item?.contract === 0 ? (
                            <ButtonPurple
                                title="Add"
                                evOnClick={(ev) => handleAddContract(ev, item)}
                            />
                        ) : (
                            item?.contract
                        )}
                    </span>
                </div>
            </td>
            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button type="button" className="" onClick={handleShowMoreMenu}>
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        <button type="button" onClick={null}>
                            View
                        </button>
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}

function FinSellItemTable() {
    const { sellItemData, setInvoiceSl, isModalOpen, setIsModalOpen } = useFinSellItem();
    const [trData, setTrData] = useState(null);
    if (sellItemData && !sellItemData?.length) {
        return <NotFound title="Sell Items Not Found" />;
    }

    return (
        <BikTable
            countDataAr={sellItemData}
            dataTh={[
                { title: 'Invoice ID', width: '10%', align: 'left' },
                { title: 'User / Description', width: '30%', align: 'left' },
                { title: 'Sub. End', width: '10%' },
                { title: 'Due Date', width: '10%' },
                { title: 'Amount', width: '9%' },
                { title: 'Currency', width: '9%' },
                { title: 'Status', width: '8%' },
                { title: 'Contract', width: '9%' },
                { title: 'Action', width: '6%', align: 'right' }
            ]}
        >
            {sellItemData?.map((item) => (
                <TableRow
                    key={item?.itemId}
                    item={item}
                    setInvoiceSl={setInvoiceSl}
                    setTrData={setTrData}
                    setIsModalOpen={setIsModalOpen}
                />
            ))}
            {isModalOpen && <FinSellItemContractModal item={trData} />}
        </BikTable>
    );
}

export default FinSellItemTable;
