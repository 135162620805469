import React from 'react';

function HomeCont() {
    return (
        <div className="page-cont">
            <h2>Invoices:</h2>
            <button type="button">Create New Invoice default components</button>
        </div>
    );
}

export default HomeCont;
