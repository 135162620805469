import { useRef } from 'react';
import { ButtonCancel, ButtonCreate } from './UserUpdaterButtons';

function InputField({ label, placeholder, onChange, refName }) {
    return (
        <div className="user-input-area">
            <label htmlFor="user">{label}</label>

            <input
                ref={refName}
                defaultValue={refName?.current?.value || ''}
                onChange={onChange}
                placeholder={placeholder || 'Type something....'}
                type="text"
            />
        </div>
    );
}

function UserCreate({ setMode }) {
    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();

    const handelCreate = () => {
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const phone = phoneRef.current.value;
        const value = {
            name,
            email,
            phone
        };
        console.log(value);
        setMode(false);

        nameRef.current.value = '';
        emailRef.current.value = '';
        phoneRef.current.value = '';
    };

    return (
        <div>
            <div className="user-updater-body">
                <InputField refName={nameRef} label="Name" placeholder="EX: John Wick" />
                <InputField refName={emailRef} label="Email" placeholder="EX: email@gmail.com" />
                <InputField
                    refName={phoneRef}
                    label="Phone Number (optional)"
                    placeholder="EX: 01795615463"
                />
            </div>

            <div className="flex-row align-right">
                <ButtonCancel evOnClick={() => setMode(false)} />
                <ButtonCreate evOnClick={handelCreate} />
            </div>
        </div>
    );
}

export default UserCreate;
