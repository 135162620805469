import React, { useEffect, useState } from 'react';
import { ButtonPurple } from '../../../../configs/Buttons';
import { icons } from '../../../../configs/Icons';
import { useFinAccounts } from './FinAccountsProvider';

function FinAccountsPageHeader() {
    const { sParams, handleShowBankModal, handleFilterSearch, handleReload } = useFinAccounts();
    const [filterVal, setFilterVal] = useState({
        accountType: '',
        currency: '',
        havingTrans: ''
    });

    useEffect(() => {
        setFilterVal(sParams);
    }, [sParams]);

    return (
        <div className="fin-account__header flex-row position-middle align-space-between gap-15">
            <div className="fin-account-header__title font-500 text-30">Accounts</div>
            <div className="fin_account-search flex-row position-middle gap-10 fill">
                <div className="user-custom-data gap-5">
                    <select
                        onChange={(e) =>
                            setFilterVal({ ...filterVal, accountType: e.target.value })
                        }
                        value={filterVal.accountType}
                        className="custom-select"
                    >
                        <option value="system">System Account</option>
                        <option value="customer">Customer Account</option>
                        <option value="operator">Operator Account</option>
                        <option value="bank">Bank Account</option>
                    </select>
                </div>
                <div className="user-custom-data gap-5">
                    <select
                        onChange={(e) => setFilterVal({ ...filterVal, currency: e.target.value })}
                        value={filterVal.currency}
                        className="custom-select"
                    >
                        <option value="">Select Currency</option>
                        <option value="USD">USD</option>
                        <option value="BDT">BDT</option>
                    </select>
                </div>
                <div className="user-custom-data gap-5">
                    <select
                        onChange={(e) =>
                            setFilterVal({ ...filterVal, havingTrans: e.target.value })
                        }
                        value={filterVal.havingTrans}
                        className="custom-select"
                    >
                        <option value="all">All</option>
                        <option value="having-transaction">Having Trans</option>
                        <option value="having-balance">Having Balance</option>
                    </select>
                </div>

                <div className="flex-row user-custom-btn hover">
                    <button
                        type="button"
                        onClick={() => {
                            handleFilterSearch(filterVal);
                        }}
                    >
                        <img
                            src="https://files.bikiran.com/assets/images/Search-icon-black.svg"
                            alt=""
                        />
                    </button>
                </div>
            </div>
            <div className="fin-account-header__btn">
                {sParams.accountType === 'bank' && (
                    <ButtonPurple
                        className="p-10"
                        title="Add Bank Account"
                        evOnClick={handleShowBankModal}
                    />
                )}
            </div>
            <div className="content-reload">
                <div className="content-refresh-btn">
                    <button type="button" className="p-relative" onClick={handleReload}>
                        <img src={icons.reloadL} alt="" />
                        <img src={icons.reloadF} alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FinAccountsPageHeader;
