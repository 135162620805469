import React, { useEffect, useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../configs/Buttons';
import InfoSidebar from '../../../features/info_sidebar/InfoSidebar';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import LibModal from '../../../lib/LibModal';
import AnimateInputField, { AnimateSelectField } from '../../../utils/AnimateInputField';
import PageProperties from '../../../utils/PageProperties';
import FinUserStatementProvider, {
    useStatementInfo
} from '../user_statement/FinUserStatementProvider';
import FinUserStatementView from '../user_statement/components/FinUserStatementView';
import FinAccountsPageBody from './FinAccountsPageBody';
import FinAccountsPageHeader from './FinAccountsPageHeader';
import FinAccountsProvider, { useFinAccounts } from './FinAccountsProvider';

// -- Updated already existing account
function BankAcEditModal({ handelChange, formData, editMode, setEditMode, handleUpdateAc }) {
    if (!editMode) {
        return null;
    }
    return (
        <LibModal title="Update Bank Account" setOpen={setEditMode}>
            <div className=" flex-row flex-wrap gap-20 p-20">
                <AnimateInputField
                    title="Bank Name"
                    name="bankName"
                    formData={formData}
                    handelChange={handelChange}
                    required
                />
                <AnimateInputField
                    title="Account Name"
                    name="accountName"
                    formData={formData}
                    handelChange={handelChange}
                    required
                />
                <AnimateInputField
                    title="Account Number"
                    name="accountNumber"
                    formData={formData}
                    handelChange={handelChange}
                    required
                    disabled
                />
                <AnimateInputField
                    title="Branch Name "
                    name="branchName"
                    formData={formData}
                    handelChange={handelChange}
                />

                <div className="flex-row align-space-between gap-10 w-100p">
                    <AnimateInputField
                        title="Routing Number"
                        name="routingNumber"
                        formData={formData}
                        handelChange={handelChange}
                    />
                    <AnimateInputField
                        title="SWIFT Code"
                        name="swiftCode"
                        formData={formData}
                        handelChange={handelChange}
                    />
                </div>
                <div className="flex-row align-space-between gap-10 w-100p">
                    <AnimateSelectField
                        title="Select Currency"
                        options={[
                            { id: 1, title: 'BDT' },
                            { id: 2, title: 'USD' }
                        ]}
                        name="currency"
                        formData={formData}
                        handelChange={handelChange}
                        required
                        disabled
                    />
                    <AnimateSelectField
                        title="Select Status"
                        options={[
                            { id: 1, title: 'Active', value: 'active' },
                            { id: 2, title: 'Inactive', value: 'inactive' }
                        ]}
                        name="status"
                        formData={formData}
                        handelChange={handelChange}
                        required
                    />
                </div>

                <div style={{ color: 'var(--primary-700)' }} className="text-16 font-400 w-100p">
                    All{' '}
                    <small style={{ color: 'red' }} className="font-400 text-12">
                        (*)
                    </small>{' '}
                    asterisk fields are required.
                </div>
                <div className="flex-row gap-10 align-right w-100p">
                    <ButtonGray title="Cancel" evOnClick={() => setEditMode(false)} />
                    <ButtonGreen title="Update" evOnClick={handleUpdateAc} />
                </div>
            </div>
        </LibModal>
    );
}

// -- Added new bank account modal
function BankAccountModal({
    bankAcModal,
    setBankAcModal,
    formData,
    handelChange,
    handleSaveBankAc
}) {
    if (!bankAcModal) {
        return null;
    }

    return (
        <LibModal title="Add Bank Account" setOpen={setBankAcModal}>
            <div className=" flex-row flex-wrap gap-20 p-20">
                <AnimateInputField
                    title="Bank Name"
                    name="bankName"
                    formData={formData}
                    handelChange={handelChange}
                    required
                />
                <AnimateInputField
                    title="Account Name"
                    name="accountName"
                    formData={formData}
                    handelChange={handelChange}
                    required
                />
                <AnimateInputField
                    title="Account Number"
                    name="accountNumber"
                    formData={formData}
                    handelChange={handelChange}
                    required
                />
                <AnimateInputField
                    title="Brach Name "
                    name="branchName"
                    formData={formData}
                    handelChange={handelChange}
                />

                <div className="flex-row gap-10">
                    <AnimateInputField
                        title="Routing Number"
                        name="routingNumber"
                        formData={formData}
                        handelChange={handelChange}
                    />
                    <AnimateInputField
                        title="SWIFT Code"
                        name="swiftCode"
                        formData={formData}
                        handelChange={handelChange}
                    />
                </div>
                <AnimateSelectField
                    title="Select Currency"
                    options={[
                        { id: 1, title: 'BDT', value: 'BDT' },
                        { id: 2, title: 'USD', value: 'USD' }
                    ]}
                    name="currency"
                    formData={formData}
                    handelChange={handelChange}
                    required
                />
                <div style={{ color: 'var(--primary-700)' }} className="text-16 font-400">
                    All{' '}
                    <small style={{ color: 'red' }} className="font-400 text-12">
                        (*)
                    </small>{' '}
                    asterisk fields are required.
                </div>
                <div className="flex-row gap-10 align-right w-100p">
                    <ButtonGray title="Cancel" evOnClick={() => setBankAcModal(false)} />
                    <ButtonGreen title="Add" evOnClick={handleSaveBankAc} />
                </div>
            </div>
        </LibModal>
    );
}

function StatementViewWrapper() {
    const { statementData } = useStatementInfo();
    return <FinUserStatementView statementData={statementData} />;
}

function PageCont() {
    const {
        formData,
        bankAcModal,
        editMode,
        setFormData,
        setEditMode,
        setBankAcModal,
        handleShowBankModal,
        handelChange,
        handleReload,
        handleSaveBankAc,
        handleUpdateAc
    } = useFinAccounts();
    const [statementId, setStatementId] = useState(null);

    const handleEditModal = (e, item) => {
        const cuData = {
            facId: item?.facId,
            bankName: item?.bankInfo.bankName,
            branchName: item?.bankInfo.branchName,
            accountNumber: item?.customId,
            routingNumber: item?.bankInfo.routingNumber,
            swiftCode: item?.bankInfo.swiftCode,
            accountName: item?.facTitle,
            currency: item?.facCurrency,
            status: item?.status
        };
        e.stopPropagation();
        setEditMode(item?.facId);
        setFormData(cuData);
    };
    return (
        <div className="page-cont">
            <div className="fin-account-page w-100p">
                <FinAccountsPageHeader handleReload={handleReload} />

                <FinAccountsPageBody
                    setStatementId={setStatementId}
                    handleEditModal={handleEditModal}
                />
                {/* -- New Bank account added */}
                <BankAccountModal
                    bankAcModal={bankAcModal}
                    setBankAcModal={setBankAcModal}
                    formData={formData}
                    handleShowBankModal={handleShowBankModal}
                    handelChange={handelChange}
                    handleSaveBankAc={handleSaveBankAc}
                />
                {/* -- Existing bank account edit modal */}
                <BankAcEditModal
                    formData={formData}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    handelChange={handelChange}
                    handleUpdateAc={handleUpdateAc}
                />
                <InfoSidebar
                    show={statementId}
                    path={mkRelativeUrl(`/finance/accounts/${statementId}/statement/`)}
                >
                    <FinUserStatementProvider statementId={statementId}>
                        <StatementViewWrapper />
                    </FinUserStatementProvider>
                </InfoSidebar>
            </div>
        </div>
    );
}

function FinAccountsPage() {
    useEffect(() => {
        PageProperties('Finance Accounts');
    }, []);

    return (
        <FinAccountsProvider>
            <PageCont />
        </FinAccountsProvider>
    );
}

export default FinAccountsPage;
