import AxiosAPI from '../../utils/AxiosAPI';

export function handelSearchFac(authInfo, search, currency, onlySelf) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/fac/search/`, { search, currency, onlySelf })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelLoadDebitOperator(authInfo, currency) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/fac/get-debit-operator/`, { currency })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelLoadCreditOperator(authInfo, userId, currency) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/fac/get-credit-operator/`, { userId, currency })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelSend(authInfo, dFacId, cFacId, currency, amount, note) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/fac/make-transaction/`)
            .post({ dFacId, cFacId, currency, amount, note })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default null;
