import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../../configs/Icons';
import BikTable from '../../../../features/bik-table/BikTable';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import InstOption from '../../../../utils/InstOption';
import { GetDate, showCurrency } from '../../../../utils/Show';

function FinAccountsTableRow({ item, setStatementId }) {
    console.log(item);
    const navigate = useNavigate();
    const ref = useRef(null);
    const [showMenu, setShowMenu] = useState(false);

    const handleBgActive = () => {
        setStatementId(item?.facId);

        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };

    const handleShowMoreMenu = (ev) => {
        ev.stopPropagation();

        setShowMenu((cuData) => !cuData);
    };

    const handleStatement = (id) => {
        navigate(mkRelativeUrl(`/finance/accounts/${id}/statement/`));
    };

    return (
        <tr className={item.demo ? 'placeholder-mode' : ''} ref={ref} onClick={handleBgActive}>
            {/* <td className="text-left">
                <span>#{user.userInfo.id}</span>
            </td>
            */}
            <td>
                <div className="text-left">
                    <span>#{item.facId}</span>
                </div>
            </td>
            <td className="text-left">
                <div className="flex-row">
                    <span>{item.facTitle}</span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{showCurrency(item?.facCredit)}</span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{showCurrency(item?.facDebit)}</span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{showCurrency(item?.facBalance)}</span>
                </div>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{item?.facCurrency}</span>
                </div>
            </td>
            <td style={{ padding: '0px' }}>
                <div className="flex-row align-center">
                    <span>{GetDate(item.facTime)}</span>
                </div>
            </td>
            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button type="button" className="" onClick={handleShowMoreMenu}>
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        <button type="button" onClick={() => handleStatement(item?.facId)}>
                            Statement
                        </button>
                        <button
                            type="button"
                            // onClick={() => handleManage(user.userInfo.id)}
                        >
                            Manage
                        </button>
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}

function FinSystemAccountsTable({ facList, setStatementId }) {
    return (
        <BikTable
            countDataAr={facList}
            dataTh={[
                { title: 'FAC', width: '10', align: 'left' },
                { title: 'FAC Title', width: '22', align: 'left' },
                { title: 'Total Credit', width: '11' },
                { title: 'Total Debit', width: '11' },
                { title: 'Balance', width: '9' },
                { title: 'Currency', width: '9' },
                { title: 'Last Trans...', width: '10' },
                { title: 'Action', width: '9', align: 'right' }
            ]}
        >
            {facList?.map((item) => (
                <FinAccountsTableRow
                    item={item}
                    key={item?.facId}
                    setStatementId={setStatementId}
                />
            ))}
        </BikTable>
    );
}

export default FinSystemAccountsTable;
