const menuConfig = [
    {
        id: 10000001,
        title: 'Dashboard',
        pageId: `1000`
    },
    {
        id: 10000002,
        title: 'Academic',
        pageId: `1101`,
        subMenu: [
            {
                id: 1,
                title: 'Yearbook',
                pageId: `1100`
            },
            {
                id: 2,
                title: 'Class & Department Setup',
                pageId: `0112`
            },
            {
                id: 3,
                title: 'Subjects Setup',
                pageId: `0113`
            },
            {
                id: 4,
                title: 'Grade Setup',
                pageId: `0114`
            },
            {
                id: 5,
                title: 'Academic Calendar',
                pageId: `0121`
            },
            {
                id: 6,
                title: 'Academic Building',
                pageId: `0122`
            },
            {
                id: 7,
                title: 'Schedule Management',
                pageId: `0124`
            },
            {
                id: 8,
                title: 'Routine Manage',
                pageId: `0125`
            }
        ]
    },
    {
        id: 10000003,
        title: 'Cadets',
        pageId: `1102`,
        subMenu: [
            {
                id: 1,
                title: 'Cadet Manage',
                pageId: `0211`
            },
            {
                id: 2,
                title: 'Cadet Attendance Manage',
                pageId: `0216`
            },
            {
                id: 3,
                title: 'ID Card',
                pageId: `0221`
            },
            {
                id: 4,
                title: 'Transfer Certificate',
                pageId: `0222`
            },
            {
                id: 5,
                title: 'Testimonial',
                pageId: `0223`
            },
            {
                id: 6,
                title: 'Cadet Report',
                pageId: `0224`,
                subMenu: [
                    {
                        id: 1,
                        title: 'Cadet Guardian Manage',
                        pageId: `0225`
                    },
                    {
                        id: 2,
                        title: 'Cadet Documents',
                        pageId: `0226`
                    },
                    {
                        id: 3,
                        title: 'Cadet Different View',
                        pageId: `0227`
                    },
                    {
                        id: 4,
                        title: 'Cadet ID Card',
                        pageId: `0228`
                    },
                    {
                        id: 5,
                        title: 'Cadet Transfer Certificate',
                        pageId: `0229`
                    },
                    {
                        id: 6,
                        title: 'Cadet Testimonial',
                        pageId: `0230`
                    }
                ]
            }
        ]
    },
    {
        id: 10000004,
        title: 'Reflections',
        pageId: `1103`,
        subMenu: [
            {
                id: 1,
                title: 'Reflection Setup',
                pageId: `0311`
            },
            {
                id: 2,
                title: 'Personal Information',
                pageId: `0312`,
                subMenu: [
                    {
                        id: 1,
                        title: 'Personal Information (Student Table)',
                        pageId: `0313`
                    },
                    {
                        id: 2,
                        title: 'Parents Data (Student Guardian Table)',
                        pageId: `0314`
                    },
                    {
                        id: 3,
                        title: 'Parents Status',
                        pageId: `0315`
                    },
                    {
                        id: 4,
                        title: 'Siblings',
                        pageId: `0316`
                    },
                    {
                        id: 5,
                        title: 'Address (Student Address Table)',
                        pageId: `0317`
                    },
                    {
                        id: 6,
                        title: 'Hobby',
                        pageId: `0318`
                    },
                    {
                        id: 7,
                        title: 'Aim in Life',
                        pageId: `0319`
                    },
                    {
                        id: 8,
                        title: 'Dream in Life',
                        pageId: `0320`
                    },
                    {
                        id: 9,
                        title: 'The Person You Like Most',
                        pageId: `0321`
                    },
                    {
                        id: 10,
                        title: 'The Person You Want to Be',
                        pageId: `0322`
                    },
                    {
                        id: 11,
                        title: 'Profile Pictures',
                        pageId: `0323`
                    },
                    {
                        id: 12,
                        title: 'Notes',
                        pageId: `0324`
                    }
                ]
            },
            {
                id: 3,
                title: 'Academic Profile',
                pageId: `0410`,
                subMenu: [
                    {
                        id: 1,
                        title: 'Academic CGPA',
                        pageId: `0411`
                    },
                    {
                        id: 2,
                        title: 'Comments from the Master',
                        pageId: `0412`
                    },
                    {
                        id: 3,
                        title: 'Handwriting (Upload Images)',
                        pageId: `0413`
                    },
                    {
                        id: 4,
                        title: 'Attentiveness in the class',
                        pageId: `0414`
                    },
                    {
                        id: 5,
                        title: 'Attentiveness during Prep-Hours',
                        pageId: `0415`
                    },
                    {
                        id: 6,
                        title: 'Oral Expression',
                        pageId: `0416`
                    },
                    {
                        id: 7,
                        title: 'Written Expression',
                        pageId: `0417`
                    },
                    {
                        id: 8,
                        title: 'Department-wise Grading',
                        pageId: `0418`
                    },
                    {
                        id: 9,
                        title: 'Remarks of the Vice Principal',
                        pageId: `0419`
                    },
                    {
                        id: 10,
                        title: 'Notes',
                        pageId: `0420`
                    }
                ]
            },
            {
                id: 4,
                title: 'Psychological Profile',
                pageId: `0510`,
                subMenu: [
                    {
                        id: 1,
                        title: 'Observations about the college',
                        pageId: `0511`
                    },
                    {
                        id: 2,
                        title: 'Observations about the foot of Cadets’ Mess',
                        pageId: `0512`
                    },
                    {
                        id: 3,
                        title: 'Relationship status',
                        pageId: `0513`
                    },
                    {
                        id: 4,
                        title: 'Relation with family members',
                        pageId: `0514`
                    },
                    {
                        id: 5,
                        title: 'Adjustment',
                        pageId: `0515`
                    },
                    {
                        id: 6,
                        title: 'Integrity',
                        pageId: `0516`
                    },
                    {
                        id: 7,
                        title: 'Responsibility',
                        pageId: `0517`
                    },
                    {
                        id: 8,
                        title: 'Self confidence',
                        pageId: `0518`
                    },
                    {
                        id: 9,
                        title: 'Emotional Stability',
                        pageId: `0519`
                    },
                    {
                        id: 10,
                        title: 'Co-operation',
                        pageId: `0520`
                    },
                    {
                        id: 11,
                        title: 'Originality',
                        pageId: `0521`
                    },
                    {
                        id: 12,
                        title: 'Interactive/Self Endeavour',
                        pageId: `0522`
                    },
                    {
                        id: 13,
                        title: 'Advice Acceptability',
                        pageId: `0523`
                    },
                    {
                        id: 14,
                        title: 'Classroom Behavior',
                        pageId: `0524`
                    },
                    {
                        id: 15,
                        title: 'Dormitory Behavior',
                        pageId: `0525`
                    },
                    {
                        id: 16,
                        title: 'Playground Behavior',
                        pageId: `0526`
                    },
                    {
                        id: 17,
                        title: 'Depression Scale',
                        pageId: `0527`
                    },
                    {
                        id: 18,
                        title: 'Anxiety Scale',
                        pageId: `0528`
                    },
                    {
                        id: 19,
                        title: 'Social Interaction Anxiety Scale',
                        pageId: `0529`
                    },
                    {
                        id: 20,
                        title: 'Hopelessness Scale',
                        pageId: `0530`
                    },
                    {
                        id: 21,
                        title: 'Obsessive Compulsive Scale',
                        pageId: `0531`
                    },
                    {
                        id: 22,
                        title: 'Remarks of the Psychologist',
                        pageId: `0532`
                    },
                    {
                        id: 23,
                        title: 'Remarks of the Medical Office',
                        pageId: `0533`
                    }
                ]
            },
            {
                id: 5,
                title: 'Medical Profile',
                pageId: `0610`,
                subMenu: [
                    {
                        id: 1,
                        title: 'List of known disease',
                        pageId: `0611`
                    },
                    {
                        id: 2,
                        title: 'Health Records',
                        pageId: `0612`
                    },
                    {
                        id: 3,
                        title: 'State of Sickness',
                        pageId: `0613`
                    },
                    {
                        id: 4,
                        title: 'Physical Endurance',
                        pageId: `0614`
                    },
                    {
                        id: 5,
                        title: 'Personal Hygiene',
                        pageId: `0615`
                    },
                    {
                        id: 6,
                        title: 'Remarks of Medical Officer',
                        pageId: `0616`
                    },
                    {
                        id: 7,
                        title: 'Notes',
                        pageId: `0617`
                    }
                ]
            },
            {
                id: 6,
                title: 'Discipline Profile',
                pageId: `0710`,
                subMenu: [
                    {
                        id: 1,
                        title: 'Discipline Records',
                        pageId: `0711`
                    },
                    {
                        id: 2,
                        title: 'Overall Discipline Status',
                        pageId: `0712`
                    },
                    {
                        id: 3,
                        title: 'Remarks of the house master',
                        pageId: `0713`
                    },
                    {
                        id: 4,
                        title: 'Remarks of the Adjutant',
                        pageId: `0714`
                    },
                    {
                        id: 5,
                        title: 'Notes',
                        pageId: `0715`
                    }
                ]
            },
            {
                id: 7,
                title: 'Co-Curricular Activities',
                pageId: `0810`,
                subMenu: [
                    {
                        id: 1,
                        title: 'Participation of different programmes',
                        pageId: `0811`
                    },
                    {
                        id: 2,
                        title: 'Music',
                        pageId: `0812`
                    },
                    {
                        id: 3,
                        title: 'Musical Instrument',
                        pageId: `0813`
                    },
                    {
                        id: 4,
                        title: 'Language Skill',
                        pageId: `0814`
                    },
                    {
                        id: 5,
                        title: 'Remarks of OIC',
                        pageId: `0815`
                    },
                    {
                        id: 6,
                        title: 'Notes',
                        pageId: `0816`
                    }
                ]
            },
            {
                id: 8,
                title: 'Extra-Curricular Activities',
                pageId: `0910`,
                subMenu: [
                    {
                        id: 1,
                        title: 'Outdoor Games & Sports',
                        pageId: `0911`
                    },
                    {
                        id: 2,
                        title: 'Athletics',
                        pageId: `0912`
                    },
                    {
                        id: 3,
                        title: 'Swimming',
                        pageId: `0913`
                    },
                    {
                        id: 4,
                        title: 'Indoor Games & Sports',
                        pageId: `0914`
                    },
                    {
                        id: 5,
                        title: 'Drill & PT',
                        pageId: `0915`
                    },
                    {
                        id: 6,
                        title: 'Remarks of the Adjutant',
                        pageId: `0916`
                    }
                ]
            },
            {
                id: 9,
                title: 'Achievements',
                pageId: `1010`,
                subMenu: [
                    {
                        id: 1,
                        title: 'Achievements',
                        pageId: `1011`
                    },
                    {
                        id: 2,
                        title: 'Photo gallery',
                        pageId: `1012`
                    },
                    {
                        id: 3,
                        title: 'Remarks of the principal',
                        pageId: `1013`
                    }
                ]
            }
        ]
    },
    {
        id: 10000005,
        title: 'Examination',
        pageId: `1104`,
        subMenu: [
            {
                id: 1,
                title: 'Examination Configuration',
                pageId: `1101`
            },
            {
                id: 2,
                title: 'Examination Mange',
                pageId: `1102`
            },
            {
                id: 3,
                title: 'Examination Routine',
                pageId: `1103`
            },
            {
                id: 4,
                title: 'Examination Attendance',
                pageId: `1104`
            },
            {
                id: 5,
                title: 'Marks Entry',
                pageId: `1105`
            },
            {
                id: 6,
                title: 'Results',
                pageId: `1106`
            }
        ]
    },
    {
        id: 10000006,
        title: 'Human Resource',
        pageId: `1105`,
        subMenu: [
            {
                id: 1,
                title: 'Organogram',
                pageId: `0411`
            },
            {
                id: 2,
                title: 'Employees Manage',
                pageId: `0412`
            },
            {
                id: 3,
                title: 'Office Calendar',
                pageId: `0413`
            },
            {
                id: 4,
                title: 'Employee Leaves',
                pageId: `0414`
            },
            {
                id: 5,
                title: 'Employee Attendance',
                pageId: `0415`
            }
        ]
    }
];

export default menuConfig;
