import { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';

function FinReportPage() {
    useEffect(() => {
        PageProperties('Reports');
    }, []);

    return <div>Report</div>;
}

export default FinReportPage;
