import AxiosAPI from '../../../utils/AxiosAPI';

export function handelLoadStatementInfo(
    authInfo,
    facId,
    dateStart,
    dateEnd,
    pageNo = 1,
    cpp = 100
) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/transactions/${facId}/statement/`, {
                dateStart,
                dateEnd,
                'page-no': pageNo,
                cpp
            })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelSetStatus(authInfo, facId, status) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/transactions/statement/set-status/`)
            .post({ facId, status })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default null;
