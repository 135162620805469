import React from 'react';
import VPSTable from './components/VPSTable';

function VPSPageBody() {
    return (
        <div className="vps-page__body">
            <VPSTable />
        </div>
    );
}

export default VPSPageBody;
