import React, { useEffect, useState } from 'react';
import { ButtonPurpleLine } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import InstOption from '../../../../utils/InstOption';
import { handleDomainInfoOpe } from '../DomainOperation';
import DomainAccessInfo from './components/DomainAccessInfo';
import DomainListInvoice from './components/DomainListInvoice';
import DomainRegistrantDetails from './components/DomainRegistrantDetails';
import DomainTechStatus from './components/DomainTechStatus';

function DomainInfoStatus({ domainId }) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const [showMenu, setShowMenu] = useState(false);
    const [showAssignUser] = useState(true);

    useEffect(() => {
        handleDomainInfoOpe(authInfo, domainId)
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((error) => {
                setMessage(error.toString(0));
            });
    }, [authInfo, domainId, setMessage]);

    const handleShowMenu = () => {
        setShowMenu(true);
    };
    return (
        <div>
            <div className="domain-info-status">
                <div className="domain-info__header flex-row w-100p align-space-between position-middle mb-30">
                    <div className="domain-info__left-col flex-row position-middle gap-15">
                        <div className="screen-short">
                            <img
                                src="https://io.bikiran.com/pagespeed/d6e0a53ee3c1979db736d3a927663a06.jpg"
                                alt="Bikiran.com"
                            />
                        </div>
                        <div className="details flex-col">
                            <div className="title">Bikiran.com</div>
                            <div className="renew-date">Renew: 17 Sep 2023</div>
                            <ButtonPurpleLine title="Renew" />
                        </div>
                    </div>

                    {/* domain */}
                    <div className="domain-info__right-col flex-row position-middle gap-10">
                        {showAssignUser ? (
                            <div className="seller-info flex-row gap-10">
                                <div className="vendor-icon">
                                    <img src={icons.bikLogoV2} alt="" />
                                </div>
                                <div className="flex-col align-left">
                                    <div className="title">Resell Biz</div>
                                    <div className="execution-date">Execution: 25 Jan 2025</div>
                                    <button type="button" className=" updated-vendor">
                                        Update From Vendor
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <ButtonPurpleLine title="Link with vendor" />
                        )}

                        <div className="more-button p-relative">
                            <button type="button" onClick={handleShowMenu}>
                                <img src={icons.dotVh} alt="Option" />
                            </button>
                            <InstOption showMenu={showMenu} setShowMenu={setShowMenu}>
                                <button type="button">Renew</button>
                                <button type="button">Suspend</button>
                                <button type="button">Delete</button>
                            </InstOption>
                        </div>
                    </div>
                </div>
                <DomainListInvoice />

                {/* Domain Technical Information */}
                <div className="domain-tech-info">
                    {/* Domain Tech Status */}
                    <DomainTechStatus />

                    {/* Domain Registrant Details */}
                    <DomainRegistrantDetails />
                </div>

                {/* Domain Access information */}
                <DomainAccessInfo />
            </div>
        </div>
    );
}

export default DomainInfoStatus;
