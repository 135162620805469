import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonPurple } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';

function UserProjectPageHeader({ openModal, handleReload }) {
    const inputRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        inputRef.current.onchange = (ev) => {
            ev.preventDefault();
            const { value } = ev.target;
            navigate(
                mkRelativeUrl('/users/projects/', {
                    q: value
                })
            );
        };
    }, [navigate]);

    const handleFocus = () => {
        inputRef.current.style.borderColor = 'rgba(245, 3, 3, 0.5)';
    };

    const handleBlur = () => {
        inputRef.current.style.borderColor = 'rgb(28, 38, 73, 0.2)';
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            inputRef.current.style.borderColor = 'rgb(28, 38, 73, 0.2)';
        }
    };

    // const handleReload = () => {
    //     console.log('reload');
    // };

    return (
        <div className="page-header-cont flex-row position-middle align-space-between">
            <div className="flex-row position-middle gap-50">
                <div>
                    <h2 className="page-header_title">Projects</h2>
                </div>
                <div className="page-header_search">
                    <input
                        ref={inputRef}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                        className="form-input"
                        type="text"
                        placeholder="Search"
                    />
                </div>
            </div>
            <div className="flex-row position-stretch gap-10">
                <div className="reload-button">
                    <button type="button" className="p-relative" onClick={handleReload}>
                        <img src={icons.reloadLine} alt="" />
                        <img src={icons.reloadFill} alt="" />
                    </button>
                </div>

                <ButtonPurple evOnClick={() => openModal()} title="Add Project" />
                {/* {open ? <ProjectAddModal setOpen={setOpen} /> : null} */}
            </div>
        </div>
    );
}

export default UserProjectPageHeader;
