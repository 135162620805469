import { useState } from 'react';
import { UserInfoV2 } from '../../user-info/UserInfo';
import OpFundTransferModal from '../modal/OpFundTransferModal';
import UserBalanceInfo from './components/UserBalanceInfo';

function OpFundTransferSection({ show = true, user, reload }) {
    const [open, setOpen] = useState(false);

    const handelBalanceType = () => {
        setOpen(true);
    };

    if (!show) {
        return null;
    }
    return (
        <>
            <div className="fund-transfer-view-cont">
                <div className="fund-transfer-view_header">
                    <h2>Operator Balance</h2>
                </div>

                <div className="">
                    <UserInfoV2 img={user?.photoUrl} name={user?.name} email={user?.email} />

                    <UserBalanceInfo
                        evSendClick={handelBalanceType}
                        currency={user?.currency}
                        balance={user?.balance || 0}
                    />
                </div>
            </div>

            <OpFundTransferModal
                show={open}
                setOpen={setOpen}
                customerId={user?.id}
                currency={user?.currency}
                reload={reload}
            />
        </>
    );
}

export default OpFundTransferSection;
