/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import AxiosAPI from '../../utils/AxiosAPI';
import { useAuth } from '../auth/AuthProvider';
import { useTemplate } from '../template/TemplateProvider';
import SearchedUser from './components/SearchedUser';
import SelectedUser from './components/SelectedUser';

function UserSearchField({ selectedUser, setSelectedUser }) {
    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();
    const [searchValue, setSearchValue] = useState('');
    const [users, setUsers] = useState([]);
    // const [selectedUser, setSelectedUser] = useState(null);

    const handelSearchField = (ev) => {
        setSearchValue(ev.target.value);

        if (ev.target.value.length > 2) {
            setSelectedUser(null);
            AxiosAPI(authInfo)
                .setPath(`/api/users/search/`, {
                    q: ev.target.value,
                    limit: 20
                })
                .get()
                .then(({ data }) => {
                    if (data.error === 0) {
                        setUsers(data.data);
                    } else {
                        setUsers(null);
                        setMessage(data.message);
                    }
                })
                .catch((err) => {
                    setUsers(null);
                    setMessage(err.toString());
                });
        } else {
            setUsers(null);
        }
    };

    const handelUserClick = (item) => {
        setSelectedUser(item);
        setSearchValue('');
    };

    // const handelSaveUser = () => {
    //     setMessage('Saving user....');
    //     handelChangeUser(user.id, selectedUser.id)
    //         .then(({ error, message }) => {
    //             if (error === 0) {
    //                 evCloseClick();
    //             }
    //             setMessage(message);
    //         })
    //         .catch((err) => {
    //             setMessage(err.toString());
    //         });
    // };

    const isSearchedUser = searchValue?.length && users && !selectedUser;
    return (
        <div className="user-search-field-cont">
            <div className={`row-1 ${isSearchedUser ? 'active' : ''}`}>
                <label htmlFor="user">Search User</label>

                <input
                    className="search-input"
                    value={searchValue}
                    onChange={handelSearchField}
                    placeholder="Type minimum 3 character"
                    type="text"
                />

                <SearchedUser
                    show={isSearchedUser}
                    data={users}
                    handelUserClick={handelUserClick}
                />
            </div>
            <SelectedUser data={selectedUser} cancelClick={handelUserClick} />
        </div>
    );
}

export default UserSearchField;
