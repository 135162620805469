import React, { useRef, useState } from 'react';
import { ButtonPurpleLine } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import BikTable from '../../../../features/bik-table/BikTable';
import NotFound from '../../../../features/not-found/NotFound';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import InstOption from '../../../../utils/InstOption';
import { GetDate, showCurrency } from '../../../../utils/Show';
import { useDomain } from '../DomainProvider';

function TableRow({ item, handleStarred, setUserAssignModal, setDomainId }) {
    const ref = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const handleShowMoreMenu = () => setShowMenu((cuData) => !cuData);

    const handleBgActive = () => {
        setDomainId(item?.id);
        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };
    const handleStatement = (id) => {
        console.log(id);
    };
    return (
        <tr className={item?.demo ? 'placeholder-mode' : ''} ref={ref} onClick={handleBgActive}>
            <td className="text-left p-5">
                <div className="flex-row position-middle align-space-around">
                    <span>
                        <button
                            type="button"
                            className="started-btn"
                            onClick={(e) => handleStarred(e, item?.id)}
                        >
                            <img
                                src={item?.isStar ? icons.startFill : icons.startLine}
                                alt=""
                                className="d-block"
                            />
                        </button>
                    </span>
                    <span>#{item?.id}</span>
                </div>
            </td>
            <td className="text-left">
                {item?.userInfo ? (
                    <UserInfoV3
                        name={item?.userInfo?.name}
                        email={item?.userInfo?.email}
                        img={item?.userInfo?.photoUrl}
                    />
                ) : (
                    <ButtonPurpleLine
                        title="Assign User"
                        evOnClick={(e) => {
                            e.stopPropagation();
                            setUserAssignModal({ show: true, domainContractId: item?.id });
                        }}
                    />
                )}
            </td>
            <td>
                <span className="d-block">
                    <b>{item?.domain}</b>
                </span>
                <span className="d-block">{item?.vendor}</span>
            </td>
            <td
                className="text-center"
                data-tooltip-id="myTooltip"
                data-tooltip-content={`$${showCurrency(item?.priceOffer)}`}
            >
                <span>{showCurrency(item?.price)}</span>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{GetDate(item?.timeExecuted)}</span>
                </div>
            </td>
            <td className="text-center">
                <span>{GetDate(item?.timeRenew)}</span>
            </td>
            <td className="text-center text-capitalize">
                <span className={`status ${item?.status}`}>{item?.status}</span>
            </td>

            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button type="button" className="" onClick={handleShowMoreMenu}>
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        <button type="button" onClick={() => handleStatement(item?.id)}>
                            Manage
                        </button>
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}
function DomainTable({ setUserAssignModal, setDomainId }) {
    const { domainData, handleStarred } = useDomain();

    if (domainData?.length === 0) {
        return <NotFound title="Domain data not found" />;
    }

    return (
        <BikTable
            countDataAr={domainData}
            dataTh={[
                { title: 'SL', width: '12', align: 'left' },
                { title: 'User', width: '20', align: 'left' },
                { title: 'Domain / Vendor', width: '20' },
                { title: 'Price', width: '8' },
                { title: 'Executed', width: '10' },
                { title: 'Renew', width: '10' },
                { title: 'Status', width: '7' },
                { title: 'Action', width: '8', align: 'right' }
            ]}
        >
            {domainData.map((item) => (
                <TableRow
                    key={item.id}
                    item={item}
                    handleStarred={handleStarred}
                    setUserAssignModal={setUserAssignModal}
                    setDomainId={setDomainId}
                />
            ))}
        </BikTable>
    );
}

export default DomainTable;
