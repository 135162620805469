import React from 'react';
import { showCurrency } from '../../../../../utils/Show';

function FinInvoicePaymentTable({
    priceTotal,
    vatTotal,
    discount,
    billPaid,
    billPayable,
    currency
}) {
    return (
        <table className="summery-table pdf-no-break" cellPadding={3} cellSpacing={0}>
            <thead>
                <tr>
                    <th colSpan={2}>
                        <div className="flex-row align-space-between">
                            <span>Payable Amount:</span>
                            <span>({currency})</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Total:</td>
                    <td>
                        <span className="price-amount">{showCurrency(priceTotal)}</span>
                    </td>
                </tr>

                {discount ? (
                    <tr>
                        <td>Discount:</td>
                        <td>
                            <small>(-)</small>{' '}
                            <span className="price-amount">{showCurrency(discount)}</span>
                        </td>
                    </tr>
                ) : null}

                {vatTotal ? (
                    <tr>
                        <td>VAT (Where Applicable):</td>
                        <td>
                            <small>(+)</small>{' '}
                            <span className="price-amount">{showCurrency(vatTotal)}</span>
                        </td>
                    </tr>
                ) : null}

                <tr>
                    <td>Paid:</td>
                    <td>
                        <small>(-)</small>{' '}
                        <span className="price-amount">{showCurrency(billPaid)}</span>
                    </td>
                </tr>
                <tr>
                    <td>Payable:</td>
                    <td>
                        <span className="price-amount">{showCurrency(billPayable)}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default FinInvoicePaymentTable;
