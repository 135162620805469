import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';

const MessagingContext = createContext();

export const useMessaging = () => useContext(MessagingContext);

function MessagingProvider({ children }) {
    const { setMessage } = useTemplate();
    // this state using for modal show and hide
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [searchPage, setSearchPage] = useState(null);

    useEffect(() => {
        PageProperties('Messaging Conversation');
    }, []);

    const value = useMemo(() => {
        const handelChange = (ev) => {
            const { name } = ev.target;
            if (name) {
                setFormData((d) => ({ ...d, [name]: ev.target.value }));
            }
        };

        const handleMessaging = () => {
            console.log(handleMessaging);
        };
        const handleModalControl = () => {
            setOpen((cuData) => !cuData);
        };
        return {
            handleMessaging,
            setMessage,
            formData,
            handelChange,
            open,
            setOpen,
            handleModalControl,
            searchPage,
            setSearchPage
        };
    }, [formData, open, searchPage, setMessage]);

    return <MessagingContext.Provider value={value}>{children}</MessagingContext.Provider>;
}
export default MessagingProvider;
