import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import FinDashboardBody from './FinDashboardBody';
import FinDashboardHeader from './FinDashboardHeader';
import { handelLoadDashboardInfo } from './FinDashboardOperation';

function FinDashboardPage() {
    const [dashboardData, setDashboardData] = useState(null);
    const [reloadKey, setReloadKey] = useState(Math.random());

    const handelReload = () => {
        setDashboardData(null);
        setReloadKey(Math.random());
    };

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const [searchParams] = useSearchParams();
    const endDate = searchParams.get('date-end') || dayjs().format('YYYY-MM-DD');
    const startDate =
        searchParams.get('date-start') || dayjs(endDate).subtract(6, 'Month').format('YYYY-MM-DD');
    // const datesArray = [];
    // for (let date = dayjs(startDate); date.isBefore(dayjs(endDate)); date = date.add(1, 'day')) {
    //     datesArray.push(date.format('DD'));
    // }

    useEffect(() => {
        setDashboardData(null);
        handelLoadDashboardInfo(authInfo, startDate, endDate)
            .then(({ data }) => {
                setDashboardData(data);
            })
            .catch((err) => {
                setDashboardData({});
                setMessage(err.toString(0));
            });
    }, [authInfo, setMessage, reloadKey, startDate, endDate]);

    useEffect(() => {
        PageProperties('Dashboard');
    }, []);

    return (
        <div className="fin-dashboard-page">
            <FinDashboardHeader
                startDate={startDate}
                endDate={endDate}
                handelReload={handelReload}
            />
            <FinDashboardBody
                dashboardData={dashboardData}
                startDate={startDate}
                endDate={endDate}
            />
        </div>
    );
}

export default FinDashboardPage;
