import { useEffect, useRef } from 'react';

export function InstOptionV2({ showMenu, setShowMenu, buttonRef, align = 'left', children }) {
    const optionRef = useRef(null);

    // Effect to set the position of the editor
    useEffect(() => {
        const button = buttonRef?.current;
        const option = optionRef?.current;

        if (button && option) {
            // Calculate dimensions and positioning based on the specified alignment
            const buttonRect = button.getBoundingClientRect();
            const editorRect = option.getBoundingClientRect();
            const buttonWidth = buttonRect.width;
            const buttonHeight = buttonRect.height;
            const editorHeight = editorRect.height;
            const editorWidth = editorRect.width;

            let calculatedTop;
            let calculatedLeft;

            // Calculate the top and left position based on the specified alignment
            if (align === 'left') {
                calculatedTop = buttonRect.top + 24 + buttonHeight;
                calculatedLeft = buttonRect.left - 29;
            } else if (align === 'center') {
                calculatedTop = buttonRect.top + 24 + buttonHeight;
                calculatedLeft = buttonRect.left + buttonWidth / 2 - 35;
            } else if (align === 'right') {
                calculatedTop = buttonRect.top + 24 + buttonHeight;
                calculatedLeft = buttonRect.left + buttonWidth - 35;
            } else if (align === 'right-middle') {
                calculatedTop = buttonRect.top + (buttonHeight - editorHeight) / 2;
                calculatedLeft = buttonRect.left - editorWidth + buttonWidth;
            }

            // Apply calculated position to the editor element
            option.style.top = `${calculatedTop}px`;
            option.style.left = `${calculatedLeft}px`;
        }
    }, [showMenu, align, buttonRef]);

    // Effect to handle clicks and scrolls outside the editor
    useEffect(() => {
        function handleDocumentClick(event) {
            if (optionRef.current && !optionRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        }

        function handleScrollAndResize() {
            setShowMenu(false);
        }

        if (showMenu) {
            // Add event listeners for clicks and scrolls
            document.addEventListener('mousedown', handleDocumentClick);

            const specificElements = document.querySelectorAll('div.info-scroll');
            specificElements.forEach((element) => {
                element.addEventListener('scroll', handleScrollAndResize);
            });

            window.addEventListener('resize', handleScrollAndResize);
        }

        // Clean up event listeners when the component unmounts or the show state changes
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);

            const specificElements = document.querySelectorAll('ul, div.info-scroll');
            specificElements.forEach((element) => {
                element.removeEventListener('scroll', handleScrollAndResize);
            });

            window.removeEventListener('resize', handleScrollAndResize);
        };
    }, [setShowMenu, showMenu]);

    if (!showMenu) return null;

    return (
        <div className="inst-option-cont v2" ref={optionRef}>
            {children}
        </div>
    );
}

function InstOption({ showMenu, setShowMenu, children }) {
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                setShowMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowMenu]);

    if (!showMenu) return null;

    return (
        <div className="inst-option-cont" ref={ref}>
            {children}
        </div>
    );
}

export default InstOption;
