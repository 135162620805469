import { icons } from '../configs/Icons';

export const fakeUserProjects = [
    {
        id: 1000011,
        name: 'Yeasin Arafat',
        email: 'email@email.com',
        projectTitle: 'Bikiran Push',
        projectLogo: icons.callingBell,
        applications: [
            {
                id: 10000111,
                created: 1690172340,
                name: 'Bahanno News',
                logo: icons.bahannoNews,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000112,
                created: 1690172340,
                name: 'Calling Bell',
                logo: icons.callingBell,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000113,
                created: 1690172340,
                name: 'Bahanno News',
                logo: icons.bahannoNews,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000114,
                created: 1690172340,
                name: 'Calling Bell',
                logo: icons.callingBell,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000115,
                created: 1690172340,
                name: 'Bahanno News',
                logo: icons.bahannoNews,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000116,
                created: 1690172340,
                name: 'Calling Bell',
                logo: icons.callingBell,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            }
        ],
        created: 1690172340,
        apps: '-----------',
        status: 'Active'
    },
    {
        id: 1000012,
        name: 'John Doe',
        email: 'john.doe@email.com',
        projectTitle: 'EcoSaver',
        projectLogo: icons.bahannoNews,
        applications: [
            {
                id: 10000117,
                created: 1690172340,
                name: 'Bahanno News',
                logo: icons.bahannoNews,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000118,
                created: 1690172340,
                name: 'Calling Bell',
                logo: icons.callingBell,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000119,
                created: 1690172340,
                name: 'Bahanno News',
                logo: icons.bahannoNews,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000112,
                created: 1690172340,
                name: 'Calling Bell',
                logo: icons.callingBell,
                applications: [
                    {
                        id: 10000111,
                        created: 1690172340,
                        name: 'Bahanno News',
                        logo: icons.bahannoNews,
                        price: 100,
                        subcriptionStart: 1690172340,
                        expire: 1690172340
                    },
                    {
                        id: 10000112,
                        created: 1690172340,
                        name: 'Calling Bell',
                        logo: icons.callingBell,
                        price: 100,
                        subcriptionStart: 1690172340,
                        expire: 1690172340
                    },
                    {
                        id: 10000113,
                        created: 1690172340,
                        name: 'Bahanno News',
                        logo: icons.bahannoNews,
                        price: 100,
                        subcriptionStart: 1690172340,
                        expire: 1690172340
                    },
                    {
                        id: 10000114,
                        created: 1690172340,
                        name: 'Calling Bell',
                        logo: icons.callingBell,
                        price: 100,
                        subcriptionStart: 1690172340,
                        expire: 1690172340
                    },
                    {
                        id: 10000115,
                        created: 1690172340,
                        name: 'Bahanno News',
                        logo: icons.bahannoNews,
                        price: 100,
                        subcriptionStart: 1690172340,
                        expire: 1690172340
                    },
                    {
                        id: 10000116,
                        created: 1690172340,
                        name: 'Calling Bell',
                        logo: icons.callingBell,
                        price: 100,
                        subcriptionStart: 1690172340,
                        expire: 1690172340
                    }
                ],
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            }
        ],
        created: 1728325430,
        apps: '-----------',
        status: 'Active'
    },
    {
        id: 1000013,
        name: 'Jane Smith',
        email: 'jane.smith@email.com',
        projectTitle: 'HealthTrack',
        projectLogo: icons.callingBell,
        applications: [
            {
                id: 10000112,
                created: 1690172340,
                name: 'Bahanno News',
                logo: icons.bahannoNews,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },

            {
                id: 10000113,
                created: 1690172340,
                name: 'Calling Bell',
                logo: icons.callingBell,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            }
        ],
        created: 1769511230,
        apps: '-----------',
        status: 'Inactive'
    },
    {
        id: 1000014,
        name: 'Alex Johnson',
        email: 'alex.johnson@email.com',
        projectTitle: 'GardenJoy',
        projectLogo: icons.bahannoNews,
        applications: [
            {
                id: 10000111,
                created: 1690172340,
                name: 'Bahanno News',
                logo: icons.bahannoNews,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000112,
                created: 1690172340,
                name: 'Calling Bell',
                logo: icons.callingBell,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000113,
                created: 1690172340,
                name: 'Bahanno News',
                logo: icons.bahannoNews,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000114,
                created: 1690172340,
                name: 'Calling Bell',
                logo: icons.callingBell,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000115,
                created: 1690172340,
                name: 'Bahanno News',
                logo: icons.bahannoNews,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            },
            {
                id: 10000116,
                created: 1690172340,
                name: 'Calling Bell',
                logo: icons.callingBell,
                price: 100,
                subcriptionStart: 1690172340,
                expire: 1690172340
            }
        ],
        created: 1796812870,
        apps: '-----------',
        status: 'Active'
    }
];

export default null;
