import React, { useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';

function MenuList({ item, contId }) {
    const navigate = useNavigate();
    const itemRef = useRef(null);
    const handleView = (itemId) => {
        navigate(mkRelativeUrl(`/finance/api/${itemId}/view`));

        const currentActive = itemRef.current;
        const allItems = itemRef?.current?.parentElement?.childNodes;
        allItems.forEach((itemData) => {
            itemData.classList.remove('active');
        });
        currentActive.classList.add('active');
    };

    return (
        <div
            ref={itemRef}
            role="button"
            tabIndex={-1}
            key={item.id}
            onClick={() => handleView(item.id)}
            className={`api-menu-item flex-row position-middle gap-15 p-20 ${
                item.id === contId ? 'active' : ''
            }
            `}
        >
            <div className="icon">
                <img src={item.icon} alt="" />
            </div>
            <div className="content">
                <h3 className="">{item.title}</h3>
                <p className="">{item.subTitle}</p>
            </div>
        </div>
    );
}
function FinApiPageBody({ menu, contId }) {
    return (
        <div className="api-page__body flex-row gap-30 align-space-between">
            <div className="api-page__body--menu">
                {menu.map((item) => (
                    <MenuList item={item} key={item.id} contId={contId} />
                ))}
            </div>
            <div className="api-page__body--content">
                <Outlet />
            </div>
        </div>
    );
}

export default FinApiPageBody;
