import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../configs/Icons';
import NotFound from '../not-found/NotFound';
import InfoSidebarProvider, { useInfoSidebar } from './InfoSidebarProvider';

function EditButton({ path }) {
    if (!path) {
        return null;
    }
    return (
        <Link to={path} className="edit-float-btn">
            <img src={icons.editFill} alt="edit" />
        </Link>
    );
}

function ToggleButton({ drawerOpen, toggle }) {
    return (
        <div className="layer-1 flex-col align-center position-space-between h-100p">
            <div className="btn-menu flex-row align-space-between position-middle">
                <button type="button" onClick={toggle} className="no-style">
                    <img src={drawerOpen ? icons.crossGray : icons.leftArrow} alt="Menu" />
                </button>
            </div>
        </div>
    );
}

function Sidebar({ show, path, children }) {
    const { toggle, drawerOpen } = useInfoSidebar();

    const cName = drawerOpen ? 'drawer-opened' : 'drawer-closed';

    return (
        <div className={`info-sidebar-cont flex-row align-space-between position-stretch ${cName}`}>
            <ToggleButton toggle={toggle} drawerOpen={drawerOpen} />

            <div className="sidebar-content-area">
                {show && !!children ? children : <NotFound title="No Data Found" />}

                <EditButton path={path} />
            </div>
        </div>
    );
}

function InfoSidebar({ children, show, path }) {
    return (
        <InfoSidebarProvider show={show}>
            <Sidebar show={show} path={path}>
                <div>{children}</div>
            </Sidebar>
        </InfoSidebarProvider>
    );
}

export default InfoSidebar;
