import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import menu from './components/menu';

const InfoSidebarContext = createContext();
export function useInfoSidebar() {
    return useContext(InfoSidebarContext);
}

function InfoSidebarProvider({ children, show }) {
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        setDrawerOpen(!!show);
    }, [show]);

    const value = useMemo(() => {
        const toggle = () => {
            setDrawerOpen((st) => !st);
        };

        return {
            menu,
            drawerOpen,
            setDrawerOpen,
            toggle
        };
    }, [drawerOpen]);

    return <InfoSidebarContext.Provider value={value}>{children}</InfoSidebarContext.Provider>;
}

export default InfoSidebarProvider;
