import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../auth/AuthProvider';
import {
    handelLoadCustomer,
    handelLoadOperator,
    handelTransaction
} from '../FinInvoiceCustomerFtOperation';

const OperatorToCustomerContext = createContext();

export function useFtO2C() {
    return useContext(OperatorToCustomerContext);
}

const initialState = {
    amount: '',
    note: '',
    currency: '',
    debitInfo: null,
    creditInfo: null
};

function CustomerFtO2CProvider({ children, customerId, currency }) {
    const { authInfo } = useAuth();
    const [formData, setFormData] = useState({
        ...initialState,
        currency
    });

    // amount without comma
    const transferAmount = parseFloat(formData?.amount?.toString()?.replace(/,/g, ''));

    // --Load Debit Section
    useEffect(() => {
        handelLoadOperator(authInfo, currency)
            .then(({ data }) => {
                setFormData((cData) => ({ ...cData, debitInfo: data }));
            })
            .catch((err) => {
                console.log(err);
            });
    }, [authInfo, currency]);

    // --Load Credit Section
    useEffect(() => {
        handelLoadCustomer(authInfo, customerId, currency)
            .then(({ data }) => {
                setFormData((cData) => ({ ...cData, creditInfo: data }));
            })
            .catch((err) => {
                console.log(err);
            });
    }, [authInfo, currency, customerId]);

    const value = useMemo(() => {
        const handelChange = (ev) => {
            const { name } = ev.target;
            if (name) {
                setFormData((d) => ({ ...d, [name]: ev.target.value }));
            }
        };

        const handelFundTransfer = () =>
            new Promise((resolve, reject) => {
                handelTransaction(
                    authInfo,
                    formData.debitInfo.facId,
                    formData.creditInfo.facId,
                    currency,
                    transferAmount,
                    formData.note
                )
                    .then((data) => {
                        resolve(data);
                        if (data.error === 0) {
                            setFormData(initialState);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        return {
            formData,
            setFormData,
            handelChange,
            handelFundTransfer
        };
    }, [authInfo, currency, formData, transferAmount]);

    return (
        <OperatorToCustomerContext.Provider value={value}>
            {children}
        </OperatorToCustomerContext.Provider>
    );
}

export default CustomerFtO2CProvider;
