import React from 'react';
import MessagingPageHeader from './MessagingPageHeader';
import MessagingProvider from './MessagingProvider';
import UserConversationView from './components/UserConversationView';
import UserProfileList from './components/UserProfileList';
import UserProfileView from './components/UserProfileView';

function PageCont() {
    return (
        <div className="page-cont">
            <div className="user-messaging flex-col position-space-between">
                <MessagingPageHeader />
                <div className="flex-row w-100p">
                    <div>
                        <UserProfileList />
                    </div>
                    <div className="fill">
                        <UserConversationView />
                    </div>
                    <div>
                        <UserProfileView />
                    </div>
                </div>
            </div>
        </div>
    );
}
function MessagingPage() {
    return (
        <MessagingProvider>
            <PageCont />
        </MessagingProvider>
    );
}

export default MessagingPage;
