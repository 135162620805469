import React from 'react';
import { useParams } from 'react-router-dom';

function FinDocView() {
    const { contId } = useParams();
    return (
        <div>
            <div className="content-header">
                <h3>{contId}</h3>
                <p>View in Doc </p>
            </div>
        </div>
    );
}

export default FinDocView;
