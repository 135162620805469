import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../../configs/Icons';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import { DateRange } from '../../../../lib/LibDatePicker';

function UserDashboardHeader({ handelReload, startDate, endDate }) {
    const [showMenu, setShowMenu] = useState(false);

    const navigate = useNavigate();

    const handelDateChange = (e) => {
        if (e.length === 2) {
            const url = mkRelativeUrl('/finance/dashboard/', {
                'date-start': dayjs(e[0]).format('YYYY-MM-DD'),
                'date-end': dayjs(e[1]).format('YYYY-MM-DD')
            });
            navigate(url);
        }
    };

    return (
        <div className="page-header-cont flex-row position-middle align-space-between gap-50">
            <div className="flex-row position-middle align-left gap-50">
                <h2 className="page-header_title">Dashboard</h2>
                <div>
                    <DateRange
                        startDate={startDate}
                        endDate={endDate}
                        handelOnChange={handelDateChange}
                        setShowMenu={setShowMenu}
                        showMenu={showMenu}
                        navigatePath="/users/dashboard/"
                    />
                </div>
            </div>

            <div className="reload-button">
                <button type="button" className="p-relative" onClick={handelReload}>
                    <img src={icons.reloadLine} alt="" />
                    <img src={icons.reloadFill} alt="" />
                </button>
            </div>
        </div>
    );
}

export default UserDashboardHeader;
