import Show, {
    GetDate,
    calculateDiscountPercentage,
    showCurrency,
    showValueWithUnit
} from '../../../../../../utils/Show';
import { DeleteBtn, EditBtn } from '../../_models/ActionButtons';
import PriceDiscountInfo from '../../_models/PriceDiscountInfo';
import showPrice from '../../_models/ShowPrice';

function FinInvoiceHosting({ item, sl, isInvoiceActive, onClickEdit, onClickDelete }) {
    const {
        title,
        price,
        priceOffer,
        quantity,
        unitName,
        subscriptionStart,
        subscriptionEnd,
        properties,
        vat
    } = item || {};

    const { domain, disk, diskType, bandwidth, cpu, ram, ep, io, note } = properties || {};

    const priceDiscount = calculateDiscountPercentage(price, priceOffer);

    return (
        <tr className={!isInvoiceActive ? 'clickable' : ''} onClick={null}>
            <td>{sl}</td>
            <td>
                <h4>{title || '--'}</h4>

                <p>
                    <Show show={disk}>
                        Disk: <b>{`${disk} GB ${diskType}` || '--'}, &nbsp;</b>
                    </Show>

                    <Show show={bandwidth}>
                        Bandwidth: <b>{`${bandwidth}GB` || '--'}, &nbsp;</b>
                        <br />
                    </Show>

                    <Show show={cpu}>
                        Cpu: <b>{`${cpu} Core` || '--'}, &nbsp;</b>
                    </Show>

                    <Show show={ram}>
                        Ram: <b>{`${ram} GB` || '--'}, &nbsp;</b>
                    </Show>

                    <br />

                    <Show show={ep}>
                        EP: <b>{ep || '--'}, &nbsp;</b>
                    </Show>

                    <Show show={io}>
                        IO: <b>{io || '--'}, &nbsp;</b>
                        <br />
                    </Show>

                    <Show show={domain}>
                        Domain: <b>{domain || '--'}, &nbsp;</b>
                        <br />
                    </Show>

                    <Show show={note}>
                        <b>{note}</b>
                    </Show>
                </p>

                <span>
                    Duration: {GetDate(subscriptionStart) || 0} to {GetDate(subscriptionEnd) || 0}
                </span>
            </td>
            <td>
                {showPrice(price, quantity)}
                {/* <span className="price-amount">{showCurrency(price * 12)}</span> <br />
                /Year */}
            </td>
            <td>
                {showValueWithUnit(quantity, unitName)}
                {/* {quantity || 0} {unitName} */}
            </td>
            <td>
                <span className="price-amount">{showCurrency(price * quantity)}</span>

                <PriceDiscountInfo priceDiscount={priceDiscount} vat={vat} />

                <div className="info-action-button flex-col position-middle position-space-between">
                    <EditBtn onClickEdit={onClickEdit} />
                    <DeleteBtn onClickDelete={onClickDelete} />
                </div>
            </td>
        </tr>
    );
}

export default FinInvoiceHosting;
