import React from 'react';
import LibModal from '../../lib/LibModal';
import InvoiceProductEditor from './InvoiceProductEditor';
import { useProductEditor } from './InvoiceProductEditorProvider';
import { PropertyChanger } from './components/PropertyController';

function InvoiceProductEditModal() {
    const { formData, modalProperty, handelCloseModal } = useProductEditor() || {};

    const setOpen = (st) => {
        if (st === false) {
            handelCloseModal();
        }
    };

    if (!modalProperty?.show) {
        return null;
    }

    return (
        <LibModal
            title={modalProperty?.mode === 'add' ? 'Add Product' : 'Update Product'}
            subTitle={
                modalProperty?.mode === 'add' ? (
                    <PropertyChanger modalProperty={modalProperty} />
                ) : (
                    `${modalProperty?.title} (${modalProperty?.property})`
                )
            }
            setOpen={setOpen}
        >
            <InvoiceProductEditor
                formData={formData}
                modalProperty={modalProperty}
                handleCancelClick={() => setOpen(false)}
            />
        </LibModal>
    );
}

export default InvoiceProductEditModal;
