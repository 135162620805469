import React from 'react';
import { icons } from '../../../configs/Icons';
import { useAdvMenu } from './AdvMenuProvider';
import AdvLabel1 from './components/AdvLabel1';
import AdvLabel2 from './components/AdvLabel2';

function AdvMenu() {
    const { links, toggle, drawerOpen, activeMenu } = useAdvMenu();

    return (
        <div
            className={`adv-menu flex-row align-space-between position-stretch ${
                drawerOpen ? 'drawer-opened' : 'drawer-closed'
            }`}
        >
            <div className="layer-1 flex-col align-center position-space-between h-100p">
                <div className="btn-menu flex-row align-space-between position-middle">
                    <button type="button" onClick={toggle} className="no-style">
                        <img src={icons.menu} alt="Menu" />
                    </button>
                </div>
                <AdvLabel1 links={links} activeMenu={activeMenu} />
            </div>

            <div className="layer-2 fill">
                <AdvLabel2 menu={activeMenu} />
            </div>
        </div>
    );
}

export default AdvMenu;
