/* eslint-disable import/no-extraneous-dependencies */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect, useRef } from 'react';
import { useProductEditor } from '../InvoiceProductEditorProvider';

const toolbarConfig = {
    items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList'
    ]
};

function CustomProductInputs() {
    const { setFormData, formData } = useProductEditor();

    const contRef = useRef();

    useEffect(() => {
        if (contRef.current) {
            const modalParentDiv = contRef.current.closest('.modal-box-content');
            if (modalParentDiv) {
                modalParentDiv.style.width = '500px';
            }
        }
    }, []);

    return (
        <div ref={contRef} className="inputs-cont flex-row flex-wrap align-space-between">
            <div className="custom-product-input">
                <CKEditor
                    editor={ClassicEditor}
                    data={formData?.cpDescription}
                    config={{ toolbar: toolbarConfig }}
                    onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor?.getData();
                        setFormData((cuData) => ({ ...cuData, cpDescription: data }));
                    }}
                />
            </div>
        </div>
    );
}

export default CustomProductInputs;
