import { useMemo, useState } from 'react';

function PageController() {
    const [pageAttr, setPageAttr] = useState({
        pageNo: 1,
        cpp: 100
    });

    return useMemo(() => {
        const changePage = (pageNo) => ({ ...pageAttr, pageNo });
        const changeCpp = (cpp) => ({ ...pageAttr, cpp });

        return { pageAttr, setPageAttr, changePage, changeCpp };
    }, [pageAttr]);
}

export default PageController;
