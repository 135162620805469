/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from 'react';
import { ButtonGray, ButtonRed } from '../../../../../configs/Buttons';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import { handelMakeDelete } from '../FinInvoiceOperation';

const initialState = {
    reason: ''
};
function FinInvoiceDelete({ invoiceId, navigate, authInfo, setMessage, setConfirm }) {
    const [formData, setFormData] = useState(initialState);

    const ref = useRef();

    const handelDeleteClick = (ev) => {
        if (ev && !formData?.reason?.length) {
            ref.current.classList.add('required');
        } else {
            setMessage('Deleting...');
            handelMakeDelete(authInfo, invoiceId, formData.reason)
                .then(({ message }) => {
                    setMessage(message);
                    setConfirm(null);
                    navigate(mkRelativeUrl(`/finance/sell-invoice/`));
                })
                .catch((err) => {
                    setMessage(err.toString());
                });
        }

        console.log(formData);
    };

    const onFocus = () => {
        ref.current.classList.remove('required');
    };

    return (
        <div className="inv-delete-body">
            <p className="text-center">Are you sure you want to delete this invoice!</p>

            <div className="input-area">
                <textarea
                    ref={ref}
                    onChange={(ev) =>
                        setFormData((cData) => ({ ...cData, reason: ev.target.value }))
                    }
                    onFocus={onFocus}
                    className="form-input"
                    placeholder="Reason why you want to delete this invoice..."
                    name="note"
                />
            </div>

            <div className="action flex-row align-center position-middle">
                <ButtonGray evOnClick={() => setConfirm(null)} title="Cancel" />

                <ButtonRed evOnClick={handelDeleteClick} title="Delete" />
            </div>
        </div>
    );
}

export default FinInvoiceDelete;
