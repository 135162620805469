import dayjs from 'dayjs';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import { handelLoadStatementInfo } from './FinUserSatementOperation';

const FinUserStatementContext = createContext();
export function useStatementInfo() {
    return useContext(FinUserStatementContext);
}

function FinUserStatementProvider({ children, statementId }) {
    const [statementData, setStatementData] = useState(null);
    const [reloadKey, setReloadKey] = useState(Math.random());

    const { authInfo } = useAuth();
    const [searchParams] = useSearchParams();

    const { setMessage } = useTemplate();

    const endDate = searchParams.get('date-end') || dayjs().endOf('month').format('YYYY-MM-DD');
    const startDate =
        searchParams.get('date-start') || dayjs(endDate).startOf('month').format('YYYY-MM-DD');

    useEffect(() => {
        handelLoadStatementInfo(authInfo, statementId, startDate, endDate)
            .then(({ data }) => {
                setStatementData(data);
            })
            .catch((err) => {
                setStatementData({});
                setMessage(err.toString(0));
            });
    }, [authInfo, endDate, setMessage, startDate, statementId, reloadKey]);

    const value = useMemo(() => {
        const handelReload = () => {
            setStatementData(null);
            setReloadKey(Math.random());
        };

        return {
            statementData,
            handelReload,
            startDate,
            endDate
        };
    }, [endDate, startDate, statementData]);

    return (
        <FinUserStatementContext.Provider value={value}>
            {children}
        </FinUserStatementContext.Provider>
    );
}

export default FinUserStatementProvider;
