import React from 'react';
import { useDocs } from '../DocsProvider';

function SubList({ item }) {
    return item?.map((it) => (
        <div className="technology-sub-category text-indent-25" key={it.sl}>
            <input type="checkbox" name="" id={it.sl} />
            <label className="text-16 font-400" htmlFor={it.sl}>
                {it.sub_category}
            </label>
        </div>
    ));
}
function Technology() {
    const { technologyData } = useDocs();
    return (
        <div className="flex-col align-left gap-10">
            <div className="technology-title font-500 text-22">Technology:</div>
            <div className="technology-cont">
                {Object.keys(technologyData?.dataAr)?.map((item, key) => (
                    <div className="flex-col gap-5" key={item}>
                        <div className="technology-category text-16 font-500">
                            {key === 0 ? '' : `${key}. ${technologyData?.catAr[item]?.category}`}
                        </div>
                        {key !== 0 && <SubList item={technologyData?.dataAr[item]} />}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Technology;
