import React, { useState } from 'react';
import AddressUpdater from '../../../features/address_updater/AddressUpdater';
import InfoSidebar from '../../../features/info_sidebar/InfoSidebar';
import LibModal from '../../../lib/LibModal';
import { useDomain } from './DomainProvider';
import DomainTable from './components/DomainTable';
import DomainInfoStatus from './domain_status/DomainInfoStatus';
import ModalDomainAccess from './domain_status/Modal/ModalDomainAccess';
import DomainAssignUserModal from './modal/DomainAssignUserModal';

function DomainPageBody() {
    const [userAssignModal, setUserAssignModal] = useState({ show: false, domainContractId: '' });
    const [domainId, setDomainId] = useState(null);
    const { openAddressModal, setOpenAddressModal, domainController, setDomainController } =
        useDomain();
    const handelChangeAddress = () => {
        console.log('handelChangeAddress');
    };
    return (
        <div className="domain-page__body">
            {/* All Domain List Table */}
            <DomainTable setUserAssignModal={setUserAssignModal} setDomainId={setDomainId} />

            {/* Single Domain Information view */}
            <InfoSidebar show={domainId}>
                <DomainInfoStatus domainId={domainId} />
            </InfoSidebar>

            {/* Domain Default User Set */}
            {userAssignModal.show && (
                <LibModal title="Assign User" setOpen={setUserAssignModal}>
                    <DomainAssignUserModal
                        userAssignModal={userAssignModal}
                        setUserAssignModal={setUserAssignModal}
                    />
                </LibModal>
            )}

            {/* Updated Domain Registrant Details */}
            {openAddressModal && (
                // <LibModal title="Modify Address" setOpen={setOpenAddressModal}>
                //     <ModalAddressUpdate />
                // </LibModal>
                <AddressUpdater
                    setOpen={setOpenAddressModal}
                    handelChangeAddress={handelChangeAddress}
                />
            )}

            {/* The accessible user who can control this domain. */}
            {domainController && (
                <LibModal title="Domain Controller" setOpen={setDomainController}>
                    <ModalDomainAccess />
                </LibModal>
            )}
        </div>
    );
}

export default DomainPageBody;
