import React from 'react';
import { Link } from 'react-router-dom';

function UtilFacPreview({ data, url }) {
    return (
        // <Link to={mkRelativeUrl(`/finance/accounts/${data?.facId || 0}/view/`)}>
        <div className="fac-preview">
            <span>
                {data ? (
                    <>
                        <b>{data?.name}</b>
                        {url && (
                            <div>
                                <Link to={url}>{data?.facId}</Link>/{data?.type}
                            </div>
                        )}
                    </>
                ) : (
                    `----------`
                )}
            </span>
        </div>
        // </Link>
    );
}

export default UtilFacPreview;
