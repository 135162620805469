import { icons } from '../../../../configs/Icons';

const projects = [
    {
        id: 101123,
        name: 'Calling Bell',
        icon: icons.callingBell
    },
    {
        id: 233224,
        name: 'Bahanno News',
        icon: icons.bahannoNews
    }
];

export default projects;
