import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import { DateRange } from '../../../lib/LibDatePicker';

function FinTransHeader({ currencyData, handleReload, sParams }) {
    const [showMenu, setShowMenu] = useState(false);

    const { startDate, endDate, transType, currency } = sParams;

    const navigate = useNavigate();

    // const handelDateChange = (e) => {
    //     if (e.length === 2) {
    //         const url = mkRelativeUrl('/finance/transactions', {
    //             'date-start': dayjs(e[0]).format('YYYY-MM-DD'),
    //             'date-end': dayjs(e[1]).format('YYYY-MM-DD'),
    //             type: transType,
    //             currency
    //         });
    //         navigate(url);
    //     }
    // };

    // const handleTypeSelect = (e) => {
    //     const url = mkRelativeUrl('/finance/transactions', {
    //         'date-start': startDate,
    //         'date-end': endDate,
    //         type: e.target.value,
    //         currency
    //     });
    //     navigate(url);
    // };

    // const handleCurrencySelect = (e) => {
    //     const url = mkRelativeUrl('/finance/transactions', {
    //         'date-start': startDate,
    //         'date-end': endDate,
    //         type: transType,
    //         currency: e.target.value
    //     });
    //     navigate(url);
    // };

    const handleOnChange = (ev) => {
        const { name, value } = ev?.target || {};

        if (name) {
            const url = mkRelativeUrl('/finance/transactions', {
                'date-start': startDate,
                'date-end': endDate,
                type: name === 'type' ? value : transType,
                currency: name === 'currency' ? value : currency
            });
            navigate(url);
        } else if (ev.length === 2) {
            const url = mkRelativeUrl('/finance/transactions', {
                'date-start': dayjs(ev[0]).format('YYYY-MM-DD'),
                'date-end': dayjs(ev[1]).format('YYYY-MM-DD'),
                type: transType,
                currency
            });
            navigate(url);
        }
    };

    return (
        <div className="fin-trans-page__header flex-row position-middle align-space-between gap-50">
            <div className="text-30 font-500">Transaction</div>
            <div className="flex-row position-middle gap-20 fill">
                <div className="user-custom-data gap-5">
                    <select
                        className="custom-select"
                        name="type"
                        onChange={handleOnChange}
                        value={transType}
                    >
                        <option value="">Select Type</option>
                        <option value="user-deposit">User Deposit</option>
                        <option value="user-withdraw">User Withdraw</option>
                        <option value="user-payment">User Payment</option>
                        <option value="user-refund">User Refund</option>
                        <option value="external-vat">External Vat</option>
                        <option value="internal-vat">Internal Vat</option>
                        <option value="other">Others</option>
                    </select>
                </div>
                <div className="user-custom-data gap-5">
                    <select
                        className="custom-select"
                        name="currency"
                        onChange={handleOnChange}
                        value={currency}
                    >
                        <option value="">Select Currency</option>
                        {currencyData?.map((item) => (
                            <option key={Math.random()} value={item?.currency}>
                                {item?.currency}
                            </option>
                        ))}
                    </select>
                </div>
                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    handelOnChange={handleOnChange}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                    navigatePath="/finance/transactions"
                    queryObject={{ type: transType, currency }}
                />
            </div>
            <div className="content-reload">
                <div className="content-refresh-btn">
                    <button type="button" className="p-relative" onClick={handleReload}>
                        <img src={icons.reloadL} alt="" />
                        <img src={icons.reloadF} alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FinTransHeader;
