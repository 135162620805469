export const facAccounts = [
    {
        facId: 10000001,
        facDebit: 1000,
        facCredit: 1000,
        facBalance: 1000,
        facCurrency: '------',
        facTime: 1689159015,
        facTitle: '--------------',
        userInfo: {
            id: 10000001,
            name: null,
            photoUrl: null,
            email: ''
        },
        bankInfo: {
            bankName: '----------------',
            branchName: '-------',
            routingNumber: '',
            swiftCode: ''
        },
        status: '------',
        demo: true
    },
    {
        facId: 10000002,
        facDebit: 1000,
        facCredit: 1000,
        facBalance: 1000,
        facCurrency: '------',
        facTime: 1689159015,
        facTitle: '--------------',
        userInfo: {
            id: 10000002,
            name: null,
            photoUrl: null,
            email: ''
        },
        bankInfo: {
            bankName: '----------------',
            branchName: '-------',
            routingNumber: '',
            swiftCode: ''
        },
        status: '------',
        demo: true
    },
    {
        facId: 10000003,
        facDebit: 1000,
        facCredit: 1000,
        facBalance: 1000,
        facCurrency: '------',
        facTime: 1689159015,
        facTitle: '--------------',
        userInfo: {
            id: 10000003,
            name: null,
            photoUrl: null,
            email: ''
        },
        bankInfo: {
            bankName: '----------------',
            branchName: '-------',
            routingNumber: '',
            swiftCode: ''
        },
        status: '------',
        demo: true
    },
    {
        facId: 10000004,
        facDebit: 1000,
        facCredit: 1000,
        facBalance: 1000,
        facCurrency: '------',
        facTime: 1689159015,
        facTitle: '--------------',
        userInfo: {
            id: 10000004,
            name: null,
            photoUrl: null,
            email: ''
        },
        bankInfo: {
            bankName: '----------------',
            branchName: '-------',
            routingNumber: '',
            swiftCode: ''
        },
        status: '------',
        demo: true
    },
    {
        facId: 10000005,
        facDebit: 1000,
        facCredit: 1000,
        facBalance: 1000,
        facCurrency: '------',
        facTime: 1689159015,
        facTitle: '--------------',
        userInfo: {
            id: 10000005,
            name: null,
            photoUrl: null,
            email: ''
        },
        bankInfo: {
            bankName: '----------------',
            branchName: '-------',
            routingNumber: '',
            swiftCode: ''
        },
        status: '------',
        demo: true
    }
];

export default null;
