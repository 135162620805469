import { useState } from 'react';
import { ButtonGray, ButtonPurple } from '../../../../../configs/Buttons';
import { useInit } from '../../../../features/init/InitProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';

function InfoCommonView({ title, content }) {
    return (
        <div className="invoice-input-area flex-row align-space-between position-middle">
            <div>
                <h2 className="invoice-info_title">{title}</h2>
            </div>
            <div>{content}</div>
        </div>
    );
}

function ActionContainer({ show, children }) {
    return (
        <div className={`invoice-info_action-cont flex-row align-right ${show ? 'show' : ''}`}>
            {children}
        </div>
    );
}

const initialState = {
    currency: '',
    customRate: ''
};

function FinInvoiceInfoCurrencyChanger({ show, currency, customRate, handelChangeCurrency }) {
    const { setMessage } = useTemplate();
    const { initInfo } = useInit();
    const currencies = initInfo?.initData?.currencies || [];

    // --Form Info
    const [formData, setFormData] = useState({ ...initialState, currency, customRate });

    const handelCurrencyValue = (ev) => {
        const { name, value } = ev.target;
        if (name) {
            // const cuRate = currencies?.find((el) => el.currency === value)?.rate;
            const updatedRate = value === 'BDT' ? customRate : 1;

            setFormData((cuData) => ({
                ...cuData,
                [name]: value,
                customRate: updatedRate?.toString()
            }));
        }
    };

    const handelRateValue = (ev) => {
        const { name, value } = ev.target;
        if (name) {
            setFormData((cuData) => ({ ...cuData, [name]: value }));
        }
    };

    const handelCancel = () => {
        setFormData({ currency, customRate });
    };

    const handelSave = () => {
        console.log(formData);

        setMessage('Saving currency information...');

        handelChangeCurrency(formData.currency, formData.customRate)
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    if (!show) {
        return null;
    }

    const isValueUpdate =
        formData.currency !== currency ||
        parseFloat(formData?.customRate) !== parseFloat(customRate);

    return (
        <div className="invoice-info-cont">
            <div className="info-section_header">
                <h2 className="info-section_title">Change Currency</h2>
            </div>

            <InfoCommonView
                title="Currency"
                content={
                    <select
                        name="currency"
                        onChange={handelCurrencyValue}
                        value={formData.currency}
                    >
                        {currencies?.map((item) => (
                            <option key={item?.currency} value={item?.currency}>
                                {item?.currency}
                            </option>
                        ))}
                    </select>
                }
            />

            <InfoCommonView
                title="Custom Rate"
                content={
                    <input
                        name="customRate"
                        disabled={formData.currency !== 'BDT'}
                        value={formData.customRate}
                        onChange={handelRateValue}
                        placeholder={customRate}
                        type="text"
                    />
                }
            />
            {/* <small className="invoice-info_currency-rate">Current Rate 1USD = 108</small> */}

            <ActionContainer show={isValueUpdate}>
                <ButtonGray evOnClick={handelCancel} title="Cancel" />

                <ButtonPurple evOnClick={handelSave} title="Save" />
            </ActionContainer>
        </div>
    );
}

export default FinInvoiceInfoCurrencyChanger;
