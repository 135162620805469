import React from 'react';

const initData = [
    {
        id: 10000001,
        userName: 'Noman Gazi',
        email: 'numan.islam61@gmail.com',
        photoUrl: ''
    }
];
function TableBody() {
    return (
        <tbody>
            {/* {dataList.map((item) => (
                <TableRow
                    key={item.id}
                    item={item}
                    handleUpdatedAddress={handleUpdatedAddress}
                    setUserInfo={setUserInfo}
                />
            ))} */}
        </tbody>
    );
}
function TableHeader() {
    return (
        <thead>
            <tr>
                <th width="" className="text-left">
                    SL
                </th>
                <th width="" className="text-left">
                    User
                </th>
                <th width="">Title</th>
                <th width="">Server</th>
                <th width="">Date Created</th>
                <th width="">Expired</th>
                <th width="">Domain</th>
                <th width="">Price</th>
                <th width="">Status</th>
                <th width="" className="text-right">
                    Action
                </th>
            </tr>
        </thead>
    );
}
function VPSTable({ VPSData }) {
    const data = VPSData || initData;
    return (
        <div className="bik-table-parent">
            <table className="bik-table">
                <TableHeader />
                <TableBody data={data} />
            </table>
        </div>
    );
}

export default VPSTable;
