import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DedServerPage from './app/contract/dedicated_server/DedServerPage';
import DomainPage from './app/contract/domain/DomainPage';
import HostingPage from './app/contract/hosting/HostingPage';
import PackagePage from './app/contract/package/PackagePage';
import VPSPage from './app/contract/vps/VPSPage';
import FinAccounts from './app/finance/accounts/FinAccountsPage';
import FinApiPage from './app/finance/api/FinApiPage';
import FinDocView from './app/finance/api/components/FinDocView';
import FinContractPage from './app/finance/contracts/FinContractPage';
import FinDashboardPage from './app/finance/dashboard/FinDashboardPage';
import FinGatewayTransPage from './app/finance/gateway_transactions/FinGatewayTransPage';
import FinInvoiceListPage from './app/finance/invoice/FinInvoiceListPage';
import FinInvoiceUpdatePage from './app/finance/invoice_update/FinInvoiceUpdatePage';
import FinReportPage from './app/finance/reports/FinReportPage';
import FinSellItemPage from './app/finance/sell_items/FinSellItemPage';
import FinTransactionPage from './app/finance/transactions/FinTransactionPage';
import FinUserStatement from './app/finance/user_statement/FinUserStatementPage';
import HomePage from './app/home/HomePage';
import MessagingPage from './app/users/messaging/MessagingPage';
import UserProjectsPage from './app/users/projects/UserProjectsPage';
import UserListPage from './app/users/user/UserListPage';
import UserDashboardPage from './app/users/user_dashboard/UserDashboardPage';
import DocsPage from './docs/DocsPage';
import CoverPage from './docs/docs_pages/CoverPage';
import DocsTableViewPage from './docs/docs_pages/DocsTableViewPage';
import MenusConfig from './docs/docs_pages/MenusConfig';
import Modules from './docs/docs_pages/Modules';
import Technology from './docs/docs_pages/Technology';
import AuthPage from './features/auth/AuthPage';

function RouteHandler() {
    return (
        <BrowserRouter>
            <AuthPage>
                <Routes>
                    {/* Index Page */}
                    <Route path="/*" element={<HomePage />} />

                    {/* Finance page */}
                    <Route path="/finance/dashboard/" element={<FinDashboardPage />} />
                    <Route path="/finance/accounts/" element={<FinAccounts />} />
                    <Route
                        path="/finance/accounts/:statementId/statement"
                        element={<FinUserStatement />}
                    />
                    <Route path="/finance/sell-invoice/" element={<FinInvoiceListPage />} />
                    <Route path="/finance/sell-item/" element={<FinSellItemPage />} />
                    <Route
                        path="/finance/gateway-transactions/"
                        element={<FinGatewayTransPage />}
                    />
                    <Route
                        path="/finance/sell-invoice/:invoiceId/update"
                        element={<FinInvoiceUpdatePage />}
                    />
                    <Route path="/finance/sell-invoice/list" element={<FinInvoiceListPage />} />
                    <Route path="/finance/transactions/" element={<FinTransactionPage />} />
                    <Route path="/finance/contracts/" element={<FinContractPage />} />
                    <Route path="/finance/reports/" element={<FinReportPage />} />
                    <Route path="/finance/api/" element={<FinApiPage />}>
                        <Route path=":contId/view" element={<FinDocView />} />
                    </Route>

                    {/* Users */}
                    <Route path="/users/projects/" element={<UserProjectsPage />} />
                    <Route path="/users/dashboard/" element={<UserDashboardPage />} />
                    <Route path="/users/list/" element={<UserListPage />} />
                    <Route path="/users/messaging/" element={<MessagingPage />} />

                    {/* Documentations */}
                    <Route path="/users/docs/" element={<DocsPage />}>
                        <Route path="cover-page" element={<CoverPage />} />
                        <Route path="modules" element={<Modules />} />
                        <Route path="technology" element={<Technology />} />
                        <Route path="menus-config" element={<MenusConfig />} />
                        <Route path="module/:pageId" element={<DocsTableViewPage />} />
                    </Route>

                    {/* Contract */}
                    <Route path="/contract/dashboard/" element={<FinDashboardPage />} />
                    <Route path="/contract/domain/" element={<DomainPage />} />
                    <Route path="/contract/hosting/" element={<HostingPage />} />
                    <Route path="/contract/dedicated-server/" element={<DedServerPage />} />
                    <Route path="/contract/vps/" element={<VPSPage />} />
                    <Route path="/contract/package/" element={<PackagePage />} />
                </Routes>
            </AuthPage>
        </BrowserRouter>
    );
}
export default RouteHandler;
