import dayjs from 'dayjs';
import 'flatpickr/dist/themes/light.css';
import Flatpickr from 'react-flatpickr';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../configs/Icons';
import { mkRelativeUrl } from '../features/menus/AdvMenuProvider';
import InstOption from '../utils/InstOption';

export function DatePicker() {
    return (
        <div className="date-picker">
            <Flatpickr
                options={{
                    // mode: 'range',
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'F j, Y',
                    // clickOpens: false,
                    // inline: true,
                    locale: { firstDayOfWeek: 6 },
                    showMonths: 1,
                    monthSelectorType: 'static'
                }}
                value={new Date()}
                onChange={(e) => console.log(e)}
            />
        </div>
    );
}

export function DateRange({
    startDate,
    endDate,
    navigatePath,
    queryObject,
    handelOnChange,
    setShowMenu,
    showMenu,
    fRef
}) {
    const navigate = useNavigate();

    const handleDateFilter = (interval) => {
        let startDt;
        let endDt;

        if (interval === 'nextMonth') {
            startDt = dayjs().add(1, 'month').startOf('month').format('YYYY-MM-DD');
            endDt = dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD');
        } else if (interval === 'thisYear') {
            const year = dayjs().format('YYYY');
            startDt = `${year}-01-01`;
            endDt = `${year}-12-31`;
        } else if (interval === 'thisMonth') {
            startDt = dayjs().startOf('month').format('YYYY-MM-DD');
            endDt = dayjs().endOf('month').format('YYYY-MM-DD');
        } else if (interval === 'lastYear') {
            const year = dayjs().subtract(1, 'year').format('YYYY');
            startDt = `${year}-01-01`;
            endDt = `${year}-12-31`;
        } else if (interval === 'lastMonth') {
            startDt = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            endDt = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        } else {
            // Handle invalid interval value here, if needed
            return;
        }

        if (navigatePath) {
            navigate(
                mkRelativeUrl(navigatePath, {
                    ...queryObject,
                    'date-start': startDt,
                    'date-end': endDt
                })
            );
        } else {
            handelOnChange([startDt, endDt]);
        }

        // handelReload();
        setShowMenu(false);
    };
    return (
        <div className="date-range-cont flex-row position-middle align-space-between">
            <Flatpickr
                ref={fRef}
                options={{
                    mode: 'range'
                }}
                value={[startDate, endDate]}
                onChange={handelOnChange}
            />
            <div className="dot-menu" role="button" tabIndex={-1} onClick={() => setShowMenu(true)}>
                <img src={icons?.optionFill} alt="" />
            </div>
            <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                <button type="button" onClick={() => handleDateFilter('nextMonth')}>
                    Next Month
                </button>

                <button type="button" onClick={() => handleDateFilter('thisMonth')}>
                    This Month
                </button>

                <button type="button" onClick={() => handleDateFilter('lastMonth')}>
                    Last Month
                </button>
                <button type="button" onClick={() => handleDateFilter('thisYear')}>
                    This Year
                </button>
                <button type="button" onClick={() => handleDateFilter('lastYear')}>
                    Last Year
                </button>
            </InstOption>
        </div>
    );
}

function LibDatePicker() {
    return null;
}

export default LibDatePicker;
