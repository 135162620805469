import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const UserUpdaterContext = createContext();

export function useUserUpdater() {
    return useContext(UserUpdaterContext);
}

const usersArray = [
    {
        id: 1,
        name: 'Unknow User-1',
        email: 'user1@bikiran.com',
        photoUrl:
            'https://variety.com/wp-content/uploads/2022/02/Screen-Shot-2022-05-09-at-10.04.13-AM.png'
    },
    {
        id: 2,
        name: 'Unknow User-2',
        email: 'user2@bikiran.com',
        photoUrl:
            'https://variety.com/wp-content/uploads/2022/02/Screen-Shot-2022-05-09-at-10.04.13-AM.png'
    },
    {
        id: 3,
        name: 'Unknow User-3',
        email: 'user3@bikiran.com',
        photoUrl:
            'https://variety.com/wp-content/uploads/2022/02/Screen-Shot-2022-05-09-at-10.04.13-AM.png'
    },
    {
        id: 4,
        name: 'Unknow User-4',
        email: 'user4@bikiran.com',
        photoUrl:
            'https://variety.com/wp-content/uploads/2022/02/Screen-Shot-2022-05-09-at-10.04.13-AM.png'
    },
    {
        id: 5,
        name: 'Unknow User-5',
        email: 'user5@bikiran.com',
        photoUrl:
            'https://variety.com/wp-content/uploads/2022/02/Screen-Shot-2022-05-09-at-10.04.13-AM.png'
    },
    {
        id: 6,
        name: 'Unknow User-6',
        email: 'user6@bikiran.com',
        photoUrl:
            'https://variety.com/wp-content/uploads/2022/02/Screen-Shot-2022-05-09-at-10.04.13-AM.png'
    },
    {
        id: 7,
        name: 'Unknow User-7',
        email: 'user7@bikiran.com',
        photoUrl:
            'https://variety.com/wp-content/uploads/2022/02/Screen-Shot-2022-05-09-at-10.04.13-AM.png'
    },
    {
        id: 8,
        name: 'Unknow User-8',
        email: 'user8@bikiran.com',
        photoUrl:
            'https://variety.com/wp-content/uploads/2022/02/Screen-Shot-2022-05-09-at-10.04.13-AM.png'
    }
];

// const initialState = {
//     displayName: '',
//     email: '',
//     photoUrl: ''
// };

function UserUpdaterProvider({ children }) {
    // const { authInfo } = useAuth();
    const [searchValue, setSearchValue] = useState('');
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (searchValue.length) {
            const searchedData = usersArray.filter((item) =>
                item?.name?.toLowerCase().includes(searchValue.toLowerCase())
            );
            setUserData(searchedData);
        } else {
            setUserData(null);
        }
    }, [searchValue]);

    // useEffect(() => {
    //     if (!authInfo.loading) {
    //         AxiosAPI(authInfo)
    //             .setPath(`user/data/`)
    //             .post(searchValue)
    //             .then(({ data }) => {
    //                 setUserData(data);
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     }
    // }, [searchValue]);

    const value = useMemo(() => {
        const saveUser = () => {};

        // const saveUser = (userId, uData) =>
        //     new Promise((resolve, reject) => {
        //         AxiosAPI(authInfo)
        //             .setPath(`/user/update/`)
        //             .post({ userId, uData })
        //             .then(({ data }) => {
        //                 resolve(data);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });

        return {
            saveUser,
            setSearchValue,
            searchValue,
            userData,
            setUserData
        };
    }, [searchValue, userData]);

    return <UserUpdaterContext.Provider value={value}>{children}</UserUpdaterContext.Provider>;
}

export default UserUpdaterProvider;
