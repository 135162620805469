import React from 'react';
import UserInfo from '../../../../features/user-info/UserInfo';
import { GetDate, UpperCase } from '../../../../utils/Show';

function InfoRow({ title, value }) {
    return (
        <div className="flex-row align-space-between">
            <span>{title || '-----------------'}</span>
            <span> {value || '----------'} </span>
        </div>
    );
}

function ProjectInfoRow1({ data }) {
    const { title, status, created, userInfo } = data || {};

    return (
        <div className="row-1 flex-row align-space-between">
            <UserInfo
                name={userInfo?.name}
                email={userInfo?.email || '-----------------'}
                img={userInfo?.photoUrl}
            />

            <div className="project-info">
                <h2>Project Information</h2>

                <InfoRow title="Project Title:" value={title} />
                <InfoRow title="Created:" value={GetDate(created)} />
                <InfoRow title="Status:" value={UpperCase(status)} />
            </div>
        </div>
    );
}

export default ProjectInfoRow1;
