import AxiosAPI from '../../../utils/AxiosAPI';

export const handleLoadProjectList = (authInfo) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/users/projects/list/`)
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export function handelAddProject(authInfo, formData) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/users/projects/add-project/`)
            .post({ ...formData })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}
export const handleProjectInfo = (authInfo, projectId) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/users/projects/${projectId}/details`)
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export function handelAddApp(authInfo, projectId, formData) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/users/projects/${projectId}/add-app`)
            .post({ ...formData })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default null;
