/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import AxiosAPI from '../../utils/AxiosAPI';
import { useAuth } from '../auth/AuthProvider';
import { useTemplate } from '../template/TemplateProvider';
import { ButtonChange, ButtonNew } from './UserUpdaterButtons';
import UserUpdaterCurrentUser from './components/UserUpdaterCurrentUser';
import UserUpdaterSearchedUser from './components/UserUpdaterSearchedUser';
import UserUpdaterSelectedUser from './components/UserUpdaterSelectedUser';

function UserChange({ user, setMode, evCloseClick, handelChangeUser }) {
    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();
    const [searchValue, setSearchValue] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const handelSearchField = (ev) => {
        setSearchValue(ev.target.value);

        if (ev.target.value.length > 2) {
            setSelectedUser(null);
            AxiosAPI(authInfo)
                .setPath(`/api/users/search/`, {
                    q: ev.target.value,
                    limit: 20
                })
                .get()
                .then(({ data }) => {
                    if (data.error === 0) {
                        setUsers(data.data);
                    } else {
                        setUsers(null);
                        setMessage(data.message);
                    }
                })
                .catch((err) => {
                    setUsers(null);
                    setMessage(err.toString());
                });
        } else {
            setUsers(null);
        }
    };

    const handelUserClick = (item) => {
        setSelectedUser(item);
        setSearchValue('');
    };

    const handelSaveUser = () => {
        setMessage('Saving user....');
        handelChangeUser(user.id, selectedUser.id)
            .then(({ error, message }) => {
                if (error === 0) {
                    evCloseClick();
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const isSearchedUser = searchValue?.length && users && !selectedUser;
    // const isCurrentUser = !selectedUser && !users?.length && !searchValue.length;
    // const isSelectedUser = selectedUser;

    return (
        <div className="flex-col position-space-between align-stretch">
            <div className={`updater-search-area ${isSearchedUser ? 'active' : ''}`}>
                <div>
                    <label htmlFor="user">Search User</label>

                    <input
                        value={searchValue}
                        onChange={handelSearchField}
                        placeholder="Type minimum 3 character"
                        type="text"
                    />

                    <UserUpdaterSearchedUser
                        show={isSearchedUser}
                        data={users}
                        handelUserClick={handelUserClick}
                    />
                </div>
            </div>

            <div className="user-updater-body">
                <UserUpdaterCurrentUser data={user} />

                <UserUpdaterSelectedUser data={selectedUser} />
            </div>

            <div className="flex-row align-space-between flex-row align-right">
                <ButtonNew evOnClick={() => setMode(true)} disabled={selectedUser} />

                <ButtonChange disabled={!selectedUser} evOnClick={handelSaveUser} />
            </div>
        </div>
    );
}

export default UserChange;
