import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import { handleUserDBOperation } from './UserDashboardOperation';
import UserDashboardBody from './components/UserDashboardBody';
import UserDashboardHeader from './components/UserDashboardHeader';

function UserDashboardPage() {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const [searchParams] = useSearchParams();
    const [dashboardData, setDashboardData] = useState(null);
    const [reloadKey, setReloadKey] = useState(0);

    const endDate = searchParams.get('date-end') || dayjs().format('YYYY-MM-DD');
    const startDate =
        searchParams.get('date-start') || dayjs(endDate).subtract(6, 'Month').format('YYYY-MM-DD');
    const handelReload = () => {
        setDashboardData(null);
        setReloadKey(Math.random());
    };

    useEffect(() => {
        handleUserDBOperation(authInfo, startDate, endDate)
            .then((data, message, error) => {
                if (error === 0) {
                    setDashboardData(data);
                } else {
                    setMessage(message);
                }
            })
            .catch((error) => {
                setMessage(error.toString());
            });
    }, [authInfo, endDate, setMessage, startDate, reloadKey]);
    return (
        <div className="page-cont user-dasboard-cont">
            <UserDashboardHeader
                startDate={startDate}
                endDate={endDate}
                handelReload={handelReload}
            />

            <UserDashboardBody dashboardData={dashboardData} />
        </div>
    );
}

export default UserDashboardPage;
