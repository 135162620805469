/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../../configs/Icons';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import { UserInfoV4 } from '../../../../features/user-info/UserInfo';
import InstOption from '../../../../utils/InstOption';
import { GetDate, GetTime, showCurrency } from '../../../../utils/Show';
import { useFinInvoice } from '../FinInvoiceProvider';

function FinInvoiceListTableRows({
    item,
    handleSelectInvoice,
    isActive,
    isDisabled = false,
    setInfoId
}) {
    const ref = useRef(null);
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);

    const handleBgActive = () => {
        setInfoId(item?.id);
        if (!ref.current.classList.contains('activeClick')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('activeClick');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('activeClick');
        }
    };

    const handleShowMoreMenu = (ev) => {
        ev.stopPropagation();
        setShowMenu((cuData) => !cuData);
    };

    const handleManage = (id) => {
        navigate(mkRelativeUrl(`/finance/sell-invoice/${id}/update/`));
    };
    return (
        <tr
            className={`${item.demo ? 'placeholder-mode' : ''} ${isActive ? 'isChecked' : ''}`}
            onClick={handleBgActive}
            ref={ref}
        >
            <td>
                <span className="flex-row position-middle gap-5">
                    <input
                        id={item?.id}
                        onChange={(ev) => handleSelectInvoice(ev, item.id)}
                        onClick={(ev) => handleSelectInvoice(ev, item.id)}
                        checked={isActive}
                        type="checkbox"
                        disabled={isDisabled && isDisabled !== item?.currency}
                    />
                    <label htmlFor={item?.id}>{item?.id}</label>
                </span>
            </td>
            <td>
                <UserInfoV4
                    name={item?.userInfo?.name}
                    email={item?.userInfo?.email}
                    img={item.userInfo.photoUrl}
                    title={item?.title}
                />
            </td>
            <td>
                <div
                    className="issue-date text-center"
                    data-tooltip-id="myTooltip"
                    data-tooltip-content={GetTime(item?.timeIssue)}
                    data-tooltip-position-strategy="absolute"
                >
                    <span>{GetDate(item?.timeIssue)}</span>
                </div>
            </td>
            <td>
                <div
                    className="due-date text-center"
                    data-tooltip-id="myTooltip"
                    data-tooltip-content={GetTime(item?.timeDue)}
                >
                    <span>{GetDate(item.timeDue)}</span>
                </div>
            </td>
            <td>
                <div className="payable-amount text-center">
                    <span>{showCurrency(item?.amountBilled)}</span>
                </div>
            </td>
            <td>
                <div className="paid-amount text-center">
                    <span>{showCurrency(item?.amountPaid)}</span>
                </div>
            </td>
            <td>
                <div
                    className="currency text-center"
                    data-tooltip-id="myTooltip"
                    data-tooltip-content={
                        item?.currency === 'BDT' ? `1 USD = ${item.ratio} Taka` : null
                    }
                >
                    <span>{item?.currency}</span>
                </div>
            </td>
            <td>
                <div className={`text-center status ${item?.status}`}>
                    <span>{item?.status}</span>
                </div>
            </td>
            <td>
                <div className="action flex-row align-right position-middle p-relative">
                    <span>
                        <button type="button" className="" onClick={handleShowMoreMenu}>
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption showMenu={showMenu} setShowMenu={setShowMenu}>
                        <button type="button" onClick={() => handleManage(item?.id)}>
                            Manage
                        </button>
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}
function FinInvoiceListTableBody({ invoices, handleSelectInvoice, selectInvoice, setInfoId }) {
    const id = selectInvoice?.[0];
    const isDisabled = invoices?.find((el) => el.id === id)?.currency;

    return (
        <tbody className="">
            {invoices?.map((item) => (
                <FinInvoiceListTableRows
                    item={item}
                    key={item.id}
                    handleSelectInvoice={handleSelectInvoice}
                    isActive={!!selectInvoice?.find((el) => el === item.id)}
                    selectInvoice={selectInvoice}
                    isDisabled={isDisabled}
                    setInfoId={setInfoId}
                />
            ))}
        </tbody>
    );
}

// .sort((a, b) => a.id - b.id)

function FinInvoiceListTableHeader({
    handleSelectAllInvoice,
    invoices,
    isCheckedAll,
    selectInvoice
}) {
    return (
        <thead>
            <tr>
                <th width="12%" className="text-left flex">
                    <span className="flex-row position-middle gap-5">
                        <input
                            type="checkbox"
                            onClick={handleSelectAllInvoice}
                            disabled={invoices?.length === 0 ? false : invoices?.[0]?.demo}
                            checked={selectInvoice?.length === 0 ? false : isCheckedAll}
                        />
                        <label>Invoice ID</label>
                    </span>
                </th>
                <th width="22%" className="text-left">
                    User
                </th>
                <th width="10%">Issue Date</th>
                <th width="10%">Due Date</th>
                <th width="11%">Bill Amount</th>
                <th width="12%">Paid Amount</th>
                <th width="8%">Currency</th>
                <th width="8%">Status</th>
                <th width="7%" className="text-right">
                    Action
                </th>
            </tr>
        </thead>
    );
}

function FinInvoiceListTable({ setInfoId }) {
    const { invoices, selectInvoice, setSelectInvoice } = useFinInvoice();

    const handleSelectInvoice = (ev, id) => {
        ev.stopPropagation();
        const currency = invoices.find((el) => el.id === selectInvoice[0])?.currency;

        if (selectInvoice?.length > 0) {
            if (currency === invoices.find((el) => el.id === id)?.currency) {
                if (selectInvoice?.find((el) => el === id)) {
                    setSelectInvoice((cuData) => cuData?.filter((el) => el !== id));
                } else {
                    setSelectInvoice((cuData) => [...cuData, id]);
                }
            }
        } else {
            setSelectInvoice((cuData) => [...cuData, id]);
        }
    };

    const handleSelectAllInvoice = () => {
        const selectItemCurrency = invoices.find((el) => el.id === selectInvoice[0])?.currency;
        const currency = selectItemCurrency || invoices[0]?.currency;

        // Get the IDs of all invoices with the same currency
        const invoicesWithSameCurrency = invoices.filter(
            (invoice) => invoice.currency === currency
        );
        const invoiceIdsWithSameCurrency = invoicesWithSameCurrency.map((invoice) => invoice.id);

        // If all invoices with the same currency are currently selected, deselect them all
        const allSelected =
            invoicesWithSameCurrency.length > 0 &&
            invoicesWithSameCurrency.every((invoice) => selectInvoice.includes(invoice.id));

        if (allSelected) {
            setSelectInvoice((cuData) =>
                cuData.filter((id) => !invoiceIdsWithSameCurrency.includes(id))
            );
        } else {
            // Select all invoices with the same currency that are not currently selected
            const newSelectedIds = invoiceIdsWithSameCurrency.filter(
                (id) => !selectInvoice.includes(id)
            );
            setSelectInvoice((cuData) => [...cuData, ...newSelectedIds]);
        }
    };

    // -- check all checkbox
    const isCheckedAll =
        invoices?.length > 0 &&
        invoices?.every((el) => {
            if (el.currency === invoices.find((el2) => el2.id === selectInvoice[0])?.currency) {
                return selectInvoice?.includes(el.id);
            }
            return true;
        });

    // --
    return (
        <table className="bik-table">
            <FinInvoiceListTableHeader
                handleSelectAllInvoice={handleSelectAllInvoice}
                invoices={invoices}
                isCheckedAll={isCheckedAll}
                selectInvoice={selectInvoice}
            />
            <FinInvoiceListTableBody
                invoices={invoices}
                handleSelectInvoice={handleSelectInvoice}
                selectInvoice={selectInvoice}
                setInfoId={setInfoId}
            />
        </table>
    );
}

export default FinInvoiceListTable;
