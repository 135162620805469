const unitOptions = [
    {
        id: 1,
        title: 'No Unit',
        value: 'noUnit'
    },
    {
        id: 2,
        title: 'USD',
        value: 'usd'
    },
    {
        id: 3,
        title: 'Pcs',
        value: 'pcs'
    },
    {
        id: 4,

        title: 'SET',
        value: 'set'
    },
    {
        id: 5,
        title: 'SMS',
        value: 'sms'
    },
    {
        id: 6,
        title: 'Emails',
        value: 'emails'
    },

    {
        id: 7,
        title: 'Package',
        value: 'package'
    },

    {
        id: 8,
        title: 'Year',
        value: 'year'
    },

    {
        id: 9,
        title: 'Month',
        value: 'month'
    }
];

export default unitOptions;
