import { useState } from 'react';
import { ButtonPurple } from '../../../../../../configs/Buttons';
import { useAuth } from '../../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../../features/template/TemplateProvider';
import UserSearchField from '../../../../../features/user-search/UserSearchField';
import { AnimateSelectField } from '../../../../../utils/AnimateInputField';
import { handleDomainControllerOpe } from '../../DomainOperation';
import { useDomain } from '../../DomainProvider';

function ModalDomainAccess({ domainContractId = 10000023 }) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const { setDomainController } = useDomain();
    const [selectedUser, setSelectedUser] = useState(null);

    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target || {};
        if (name) {
            setFormData((d) => ({ ...d, [name]: value }));
        }
    };

    const handleAssignUser = () => {
        if (selectedUser) {
            handleDomainControllerOpe(authInfo, domainContractId, selectedUser?.id, formData)
                .then(({ message }) => {
                    setDomainController(false);
                    setMessage(message);
                })
                .catch((error) => {
                    setMessage(error.toString(0));
                });
        } else {
            setMessage('Please select a user');
        }
    };

    return (
        <div className="domain-controller-modal">
            <UserSearchField selectedUser={selectedUser} setSelectedUser={setSelectedUser} />

            <AnimateSelectField
                name="role"
                title="Select Role"
                options={[
                    { id: 1, value: 'owner', title: 'Owner' },
                    { id: 2, value: 'manager', title: 'Manager' },
                    { id: 3, value: 'developer', title: 'Developer' }
                ]}
                formData={formData}
                handelChange={handleChange}
            />

            <div className="flex-row position-middle align-right w-100p mt-30">
                <ButtonPurple title="Assign User" evOnClick={handleAssignUser} />
            </div>
        </div>
    );
}

export default ModalDomainAccess;
