import { icons } from '../../../../configs/Icons';
import { GetDate } from '../../../utils/Show';
import UserActivitySummery from './components/UserActivitySummery';
import UserInfoSection from './components/UserInfoSection';

function UserPhoto({ percentage, img }) {
    const color = [];
    const colorIdentifier = () => {
        if (percentage <= 25) {
            color.push(
                '#ae00b9',
                'rgba(19, 15, 64, 0.2)',
                'rgba(19, 15, 64, 0.2)',
                'rgba(19, 15, 64, 0.2)'
            );
        }
        if (percentage > 25 && percentage <= 50) {
            color.push('#ae00b9', '#ae00b9', 'rgba(19, 15, 64, 0.2)', 'rgba(19, 15, 64, 0.2)');
        }
        if (percentage > 50 && percentage < 100) {
            color.push('#ae00b9', '#ae00b9', '#ae00b9', 'rgba(19, 15, 64, 0.2)');
        }
        if (percentage === 100) {
            color.push('#ae00b9', '#ae00b9', '#ae00b9', '#ae00b9');
        }
        return color;
    };

    return (
        <div
            className="user-img flex-row align-center"
            style={{ borderColor: colorIdentifier().join(' ') }}
        >
            <img src={img} alt="dp" />
            <span className="progress">{percentage}%</span>
        </div>
    );
}

function HeadSection({ title, date, img }) {
    const percentage = 51;

    return (
        <div className="user-overview_header">
            <div className="flex-row position-middle">
                <UserPhoto percentage={percentage || 0} img={img || icons.avatar} />
                {/* <div className="user-img">
                    <span>
                        <img src={img || icons.avatar} alt="avatar" />
                    </span>
                </div> */}
                <div>
                    <h2 className="user-title">{title}</h2>
                    <p className="user-join-date">Joined: {GetDate(date)}</p>
                </div>
            </div>
        </div>
    );
}

function UserInfoOverview({ data }) {
    return (
        <div className="user-overview-cont">
            <HeadSection
                title={data?.name || '--'}
                img={data?.photoUrl}
                date={data?.date || '--'}
            />

            <div className="user-overview_body">
                <UserActivitySummery data={data} />

                <UserInfoSection data={data} />
            </div>
        </div>
    );
}

export default UserInfoOverview;
