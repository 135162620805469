import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { mkRelativeUrl } from '../AdvMenuProvider';
import AdvLabel2 from './AdvLabel2';

function Item({ menu, activeMenu }) {
    const { id, title, miniTitle, iconFill, iconLine, subMenu } = menu;
    const ref = useRef();

    return (
        <li key={id} className={id === activeMenu.id ? 'active' : ''} ref={ref}>
            <Link to={mkRelativeUrl(`/${subMenu[0].id}/`)}>
                <div title={title}>
                    <img src={iconFill} alt="icon fill" />
                    <img src={iconLine} alt="icon line" />
                </div>
                <span className="text-uppercase">{miniTitle}</span>
            </Link>
            <AdvLabel2 menu={menu} />
        </li>
    );
}

function AdvLabel1({ links, activeMenu }) {
    return (
        <ul className="fill adv-list-1">
            {links.map((menu) => (
                <Item menu={menu} key={menu.id} activeMenu={activeMenu} />
            ))}
        </ul>
    );
}

export default AdvLabel1;
