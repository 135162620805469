import React from 'react';
import BikTable from '../../../../features/bik-table/BikTable';

const initData = [
    {
        id: 10000001,
        userName: 'Noman Gazi',
        email: 'numan.islam61@gmail.com',
        photoUrl: ''
    }
];
function TableBody() {
    return (
        <tbody>
            {initData.map((item) => (
                <tr key={item.id}>
                    <td>{item.id}</td>
                </tr>
            ))}
        </tbody>
    );
}

function DedServerTable({ domainListData }) {
    const dataList = domainListData || initData;
    return (
        <BikTable
            countDataAr={dataList}
            dataTh={[
                { width: '12', title: 'SL', align: 'left' },
                { width: '18', title: 'User', align: 'left' },
                { width: '8', title: 'Vendor' },
                { width: '9', title: 'Domain' },
                { width: '8', title: 'Price' },
                { width: '10', title: 'Executed' },
                { width: '10', title: 'Renew' },
                { width: '10', title: 'Expired' },
                { width: '7', title: 'Status' },
                { width: '8', title: 'Action', align: 'right' }
            ]}
        >
            <TableBody />
        </BikTable>
    );
}

export default DedServerTable;
