import { showCurrency } from '../../../../utils/Show';

function BalanceTypeButton({ title, evOnClick, cName }) {
    return (
        <div role="button" tabIndex={-1} onClick={evOnClick} className={cName}>
            {title}
        </div>
    );
}

function UserBalanceInfo({ currency, balance, evBalanceType }) {
    return (
        <div className="flex-row align-space-between position-middle fund-transfer-balance-info">
            <div>
                <h2 className="title">Balance</h2>
            </div>
            <div className="flex-row align-right position-middle">
                <div className="fund-transfer_balance-view flex-row  position-middle align-center">
                    <p>
                        {currency} {showCurrency(balance)}
                    </p>
                </div>

                <div className="flex-row position-middle fund-transfer-view_action">
                    <BalanceTypeButton
                        title="-"
                        evOnClick={() => evBalanceType('debit')}
                        cName="fund-transfer_debit-btn"
                    />

                    <BalanceTypeButton
                        title="+"
                        evOnClick={() => evBalanceType('credit')}
                        cName="fund-transfer_credit-btn"
                    />
                </div>
            </div>
        </div>
    );
}

export default UserBalanceInfo;
