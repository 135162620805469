import React, { useEffect } from 'react';
import { sellItem } from '../../../../data';
import { useAuth } from '../../../features/auth/AuthProvider';
import InfoSidebar from '../../../features/info_sidebar/InfoSidebar';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import FinInvoiceUpdateProvider, {
    useInvoiceUpdate
} from '../invoice_update/FinInvoiceUpdateProvider';
import FinInvoiceView from '../invoice_update/invoice_view/FinInvoiceView';
import { handleSellItemList } from './FinSellItemOperation';
import FinSellItemPageBody from './FinSellItemPageBody';
import FinSellItemPageHeader from './FinSellItemPageHeader';
import FinSellItemProvider, { useFinSellItem } from './FinSellItemProvider';

function InvoiceViewWrapper() {
    const { invoiceData } = useInvoiceUpdate();

    return <FinInvoiceView invoiceData={!invoiceData?.loading ? invoiceData : null} />;
}

function PageCont() {
    const { setSellItemData, reloadKey, invoiceSl, itemType, searchQueryParams } = useFinSellItem();
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    useEffect(() => {
        PageProperties('Sell Items');
    }, []);

    useEffect(() => {
        setSellItemData(sellItem);
        handleSellItemList(authInfo, itemType, searchQueryParams)
            .then(({ data }) => {
                setSellItemData(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, setMessage, reloadKey, setSellItemData, itemType, searchQueryParams]);

    return (
        <div className="page-cont">
            <div className="fin-sell-item">
                <FinSellItemPageHeader />
                <FinSellItemPageBody />

                <InfoSidebar
                    show={invoiceSl}
                    path={
                        invoiceSl
                            ? mkRelativeUrl(`/finance/sell-invoice/${invoiceSl}/update/`)
                            : null
                    }
                >
                    <FinInvoiceUpdateProvider id={invoiceSl}>
                        <InvoiceViewWrapper />
                    </FinInvoiceUpdateProvider>
                </InfoSidebar>
            </div>
        </div>
    );
}

function FinSellItemPage() {
    return (
        <FinSellItemProvider>
            <PageCont />
        </FinSellItemProvider>
    );
}

export default FinSellItemPage;
