import { useRef } from 'react';
import { icons } from '../../../../../../configs/Icons';

export function DeleteBtn({ onClickDelete }) {
    const imgRef = useRef();

    const handelHover = () => {
        imgRef.current.src = icons.crossRed;
    };

    const handelHoverOut = () => {
        imgRef.current.src = icons.crossGray;
    };

    return (
        <div
            onClick={onClickDelete}
            onMouseEnter={handelHover}
            onMouseLeave={handelHoverOut}
            role="button"
            tabIndex={-1}
            className="del-button"
        >
            <img ref={imgRef} src={icons.crossGray} alt="cross" />
        </div>
    );
}

export function EditBtn({ onClickEdit }) {
    const imgRef = useRef();

    const handelHover = () => {
        imgRef.current.src = icons.editFill;
    };

    const handelHoverOut = () => {
        imgRef.current.src = icons.editGray;
    };

    return (
        <div
            onClick={onClickEdit}
            onMouseEnter={handelHover}
            onMouseLeave={handelHoverOut}
            role="button"
            tabIndex={-1}
            className="edit-button"
        >
            <img ref={imgRef} src={icons.editGray} alt="edit" />
        </div>
    );
}

export default null;
