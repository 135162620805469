import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { finTransList } from '../../../../data';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useInit } from '../../../features/init/InitProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import FinTransBody from './FinTransBody';
import FinTransHeader from './FinTransHeader';
import { handelLoadTrans } from './FinTransactionOperation';

function FinTransactionPage() {
    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();
    const { initInfo } = useInit();
    const [transData, setTransData] = useState([]);
    const [transSummary, setTransSummary] = useState(null);
    const [reloadKey, setReloadKey] = useState(null);
    const [searchParams] = useSearchParams();
    const currencyData = initInfo?.initData?.currencies;

    const handleReload = () => {
        setReloadKey(Math.random());
        setTransData(finTransList);
    };
    // --Search Params
    const endDate = searchParams.get('date-end') || dayjs().endOf('month').format('YYYY-MM-DD');
    const startDate =
        searchParams.get('date-start') || dayjs(endDate).startOf('month').format('YYYY-MM-DD');
    const transType = searchParams.get('type') || '';
    const currency = searchParams.get('currency') || '';

    useEffect(() => {
        setTransData(finTransList);
        setTransSummary(null);
        if (startDate && endDate) {
            handelLoadTrans(authInfo, transType, startDate, endDate, currency)
                .then(({ data, summary }) => {
                    setTransData(data);
                    setTransSummary(summary);
                })
                .catch((err) => {
                    setMessage(err.toString(0));
                });
        }
    }, [authInfo, endDate, startDate, reloadKey, setMessage, transType, currency]);

    useEffect(() => {
        PageProperties('Transaction');
    }, []);
    return (
        <div className="page-cont">
            <div className="fin-trans-page">
                <FinTransHeader
                    handleReload={handleReload}
                    currencyData={currencyData}
                    sParams={{ startDate, endDate, transType, currency }}
                />
                <FinTransBody
                    transData={transData}
                    transSummary={transSummary}
                    currency={currency}
                    queryObj={{ startDate, endDate, transType, currency }}
                />
            </div>
        </div>
    );
}

export default FinTransactionPage;
