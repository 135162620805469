import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { useInit } from '../../../features/init/InitProvider';
import { DateRange } from '../../../lib/LibDatePicker';
import { camelCaseToHyphen } from '../../../utils/TextConvert';
import { useFinSellItem } from './FinSellItemProvider';

function FinInvoiceFilter({ sParams, handleFilterSearch, currencyData, myRef, fPickrRef }) {
    const { invoice, user, dueFrom, dueTo, currency, type, status } = sParams;
    const [showMenu, setShowMenu] = useState(false);

    const [filterData, setFilterData] = useState({
        invoice: invoice || '',
        user: user || '',
        dueFrom: dueFrom || '',
        dueTo: dueTo || '',
        currency: currency || '',
        status: status || '',
        type: type || '',
        query: ''
    });
    const handleOnChange = (e) => {
        if (e.length === 2) {
            setFilterData({
                ...filterData,
                dueFrom: dayjs(e[0]).format('YYYY-MM-DD') || '',
                dueTo: dayjs(e[1]).format('YYYY-MM-DD') || ''
            });
        }
    };

    return (
        <div className="fin-invoice-filter p-absolute" ref={myRef}>
            <div className="fin-invoice-filter-box flex-col position-space-between align-left w-100p gap-10">
                <div className="inv-filter__id">
                    <span>Invoice</span>
                    <input
                        type="text"
                        placeholder="Ex: 10000000 or Domain"
                        onChange={(e) => setFilterData({ ...filterData, invoice: e.target.value })}
                        value={filterData?.invoice}
                    />
                </div>
                <div className="inv-filter__user">
                    <span>User</span>
                    <input
                        type="text"
                        placeholder="Ex: Name, Organization or E-mail"
                        onChange={(e) => setFilterData({ ...filterData, user: e.target.value })}
                        value={filterData?.user}
                    />
                </div>
                <div className="inv-filter__dueDate ">
                    <span>Due Date</span>
                    <DateRange
                        startDate={
                            filterData.dueFrom || dayjs().subtract(1, 'month').format('YYYY-MM-DD')
                        }
                        endDate={filterData.dueTo || dayjs().format('YYYY-MM-DD')}
                        handelOnChange={handleOnChange}
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                        fRef={fPickrRef}
                    />
                </div>
                <div className="inv-filter__currency">
                    <span>Currency</span>
                    <select
                        value={filterData?.currency}
                        onChange={(e) => setFilterData({ ...filterData, currency: e.target.value })}
                    >
                        <option value="">Any</option>
                        {currencyData?.map((item) => (
                            <option key={item?.currency} value={item?.currency}>
                                {item.currency}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="inv-filter__status">
                    <span>Status</span>
                    <select
                        value={filterData?.status}
                        onChange={(e) => setFilterData({ ...filterData, status: e.target.value })}
                    >
                        <option value="">Any</option>
                        <option value="pending">Pending</option>
                        <option value="active">Active</option>
                    </select>
                </div>
                <div className="inv-filter__type">
                    <span>Type</span>
                    <select
                        value={filterData?.type}
                        onChange={(e) => setFilterData({ ...filterData, type: e.target.value })}
                    >
                        <option value="">Select Type</option>
                        <option value="domain">Domain</option>
                        <option value="hosting">Hosting</option>
                        <option value="software">Software</option>
                        <option value="send">Send</option>
                        <option value="security">Security</option>
                        <option value="storage">Storage</option>
                    </select>
                </div>
            </div>
            <div className="search-button flex-row align-right mt-20 w-100p">
                <button type="button" onClick={() => handleFilterSearch(filterData)}>
                    Search
                </button>
            </div>
        </div>
    );
}
function FinSellItemPageHeader() {
    const {
        handleReload,
        handleSearchBtn,
        searchQueryParams,
        setSearchQueryParams,
        filterModal,
        setFilterModal
    } = useFinSellItem();
    const myRef = useRef(null);
    const fPickrRef = useRef(null);
    const { initInfo } = useInit();
    const currencyData = initInfo?.initData?.currencies;
    const [searchParams] = useSearchParams();

    const handleFilterModal = () => {
        setFilterModal((cuData) => !cuData);
    };
    // Outside click handler
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (fPickrRef?.current && !fPickrRef?.current?.node?.classList.contains('active')) {
                if (myRef?.current && !myRef?.current?.contains(e.target)) {
                    setFilterModal(false);
                }
            }
        };
        document.addEventListener('pointerdown', handleClickOutside);
        return () => {
            document.removeEventListener('pointerdown', handleClickOutside);
        };
    }, [setFilterModal]);

    useEffect(() => {
        setSearchQueryParams({
            ...(searchParams.get('invoice') && { invoice: searchParams.get('invoice') }),
            ...(searchParams.get('user') && { user: searchParams.get('user') }),
            ...(searchParams.get('due-from') && { dueFrom: searchParams.get('due-from') }),
            ...(searchParams.get('due-to') && { dueTo: searchParams.get('due-to') }),
            ...(searchParams.get('currency') && { currency: searchParams.get('currency') }),
            ...(searchParams.get('status') && { status: searchParams.get('status') }),
            ...(searchParams.get('type') && { type: searchParams.get('type') }),
            ...(searchParams.get('query') && { query: searchParams.get('query') })
        });
    }, [searchParams, setSearchQueryParams]);

    const handleFilterInput = (e) => {
        const { value } = e.target;
        const [key, val] = value.split(':');
        setSearchQueryParams((cuData) => ({ ...cuData, [camelCaseToHyphen(key)]: val }));
    };

    return (
        <div className="fin-sell-item__header flex-row position-middle align-space-between gap-50">
            <div className="text-30 font-500">Sell Item</div>
            <div className="invoice-search flex-row align-space-between position-middle p-relative">
                <div
                    className={`invoice-search_box flex-row align-space-between fill ${
                        filterModal ? 'filter' : ''
                    }
                        `}
                >
                    <input
                        onFocus={handleFilterModal}
                        onChange={handleFilterInput}
                        type="text"
                        className="fill"
                        placeholder="Search Anything....."
                        autoComplete="off"
                        defaultValue={Object.keys(searchQueryParams).reduce((acc, key) => {
                            if (searchQueryParams[key] !== '') {
                                return `${acc} ${camelCaseToHyphen(key)}:${searchQueryParams[key]}`;
                            }
                            return acc;
                        }, '')}
                    />
                    <button
                        type="button"
                        onClick={handleFilterModal}
                        className={`flex-row position-middle align-center ${
                            filterModal ? 'active' : ''
                        }`}
                    >
                        <img src={icons.filter} alt="Search" />
                    </button>
                </div>
                {filterModal && (
                    <FinInvoiceFilter
                        handleFilterSearch={handleSearchBtn}
                        currencyData={currencyData}
                        myRef={myRef}
                        fPickrRef={fPickrRef}
                        sParams={searchQueryParams}
                    />
                )}
            </div>
            <div className="content-reload">
                <div className="content-refresh-btn">
                    <button type="button" className="p-relative" onClick={handleReload}>
                        <img src={icons.reloadL} alt="" />
                        <img src={icons.reloadF} alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FinSellItemPageHeader;
