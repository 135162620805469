export const contractHostingList = [
    {
        id: 10000001,
        userInfo: {
            name: 'Noman Gazi',
            email: 'numan.islam61@gmail.com',
            phone: '',
            photoUrl: ''
        },
        title: '',
        server: '',
        domain: '',
        expireTime: '',
        status: 'active',
        favorite: true,
        demo: true
    },
    {
        id: 10000002,
        userInfo: {
            name: 'Noman Gazi',
            email: 'numan.islam61@gmail.com',
            phone: '',
            photoUrl: ''
        },
        title: '',
        server: '',
        domain: '',
        expireTime: '',
        status: 'active',
        favorite: false,
        demo: true
    }
];

export default null;
