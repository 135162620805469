function SummeryItem({ title, value }) {
    return (
        <div className="flex-row position-middle align-space-between">
            <div>
                <h2>
                    <span>{title}</span>
                </h2>
            </div>

            <div>
                <span>{value}</span>
            </div>
        </div>
    );
}

function UserActivitySummery() {
    return (
        <div className="user-activity-summery flex-row flex-wrap align-space-between">
            <SummeryItem title="Total Invoive" value={12} />
            <SummeryItem title="Total Billed" value="1.2k" />
            <SummeryItem title="Total Paid" value="1.2k" />
            <SummeryItem title="Total Due" value="1.2k" />
            <SummeryItem title="Support Ticket" value="1.2k" />
            <SummeryItem title="Reward Point" value="1.2k" />
        </div>
    );
}

export default UserActivitySummery;
