import React from 'react';
import { ButtonGray, ButtonPurple } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../template/TemplateProvider';
import { FundTextareaField } from '../model/UserInfoInputs';
import ReceiverInfo from './ReceiverInfo';
import SenderInfo from './SenderInfo';

function NotesInput({ handelChange, formData }) {
    return (
        <div className="notes-cont flex-row align-space-between ">
            <div>
                <span>Note</span>
            </div>
            <div>
                <FundTextareaField
                    handelChange={handelChange}
                    formData={formData}
                    name="note"
                    placeholder="Please Write the Reason"
                />
            </div>
        </div>
    );
}

function FundTransferBody({ closeClick, currency, ft, reload }) {
    const { handelFundTransfer, handelChange, formData, newBalance } = ft;

    const { setMessage } = useTemplate();

    const handelTransfer = () => {
        setMessage('Transfering....');
        handelFundTransfer()
            .then(({ error, message }) => {
                if (error === 0) {
                    closeClick();
                    reload();
                }
                setMessage(message);
            })
            .catch((error) => console.log(error));
    };

    return (
        <div
            className={`fund-transfer-body ${
                !formData?.debitInfo || !formData?.creditInfo ? 'placeholder-mode' : ''
            }`}
        >
            <div className="row-1 flex-row align-space-between position-stretch">
                <SenderInfo
                    handelChange={handelChange}
                    formData={formData}
                    userInfo={formData?.debitInfo}
                    newBalance={newBalance}
                    currency={currency}
                />
                <div className="divider-column" />
                <ReceiverInfo
                    handelChange={handelChange}
                    formData={formData}
                    userInfo={formData?.creditInfo}
                    currency={currency}
                />
            </div>

            <NotesInput handelChange={handelChange} formData={formData} />

            <div className="action-cont flex-row align-right">
                <ButtonGray evOnClick={closeClick} title="Cancel" />

                <ButtonPurple evOnClick={handelTransfer} title="Transfer" />
            </div>
        </div>
    );
}

export default FundTransferBody;
