import React from 'react';
import UserListTable from './components/UserListTable';

function UserListPageBody({ userListData, setUserId }) {
    const handleUpdatedAddress = (id) => {
        console.log(id);
    };

    return (
        <div className="user-list-page__body">
            <UserListTable
                userListData={userListData}
                handleUpdatedAddress={handleUpdatedAddress}
                setUserId={setUserId}
            />
        </div>
    );
}

export default UserListPageBody;
