/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tableData } from '../data/tableData';

function TableTextAre({ value, setValue }) {
    const textBoxRef = useRef();
    const handleOnChange = (ev) => {
        console.log(ev);
    };
    console.log(textBoxRef);
    return (
        <textarea
            type="text"
            className="table-add-task-box"
            placeholder="Write your notes"
            ref={textBoxRef}
            onChange={handleOnChange}
        />
    );
}
export const CommonTable = () => {
    const [noteList, setNoteList] = useState([]);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const handleNewNotesModal = () => setOpen((cuData) => !cuData);

    const handleNewTaskAdd = () => {
        setNoteList([...noteList, { id: new Date(), parent: '', note: '' }]);
    };
    return (
        <div className="common-table bik-table-parent">
            <table className="bik-table">
                <thead>
                    <tr>
                        <th>Planning</th>
                        <th>Developer</th>
                        <th>Tester</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="33%">
                            {noteList.length > 0 && (
                                <ul className="table-custom-list">
                                    {noteList.map((item) => (
                                        <li key={`id${item.id}`}>{item.note}</li>
                                    ))}
                                </ul>
                            )}
                            {open && <TableTextAre value={value} setValue={value} />}
                            <button
                                type="button"
                                className="table-add-btn"
                                onClick={handleNewNotesModal}
                            >
                                + New
                            </button>
                        </td>
                        <td width="33%">
                            <span>Hello Developer</span>
                        </td>
                        <td width="33%">
                            <span>Tester</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export const ButtonActivityTable = ({ buttonActivityTable }) => (
    <div className="button-activity bik-table-parent w-100p">
        <table className="bik-table">
            <thead>
                <tr>
                    <th width="20%">Button</th>
                    <th width="20%">Modal Name</th>
                    <th width="60%">Input fields of Form</th>
                </tr>
            </thead>
            <tbody>
                {buttonActivityTable.map((item) => (
                    <tr key={item.id}>
                        <td>
                            <span>{item.buttonType}</span>
                        </td>
                        <td>
                            <span>{item.modalName}</span>
                        </td>
                        <td>
                            <span>{item.formInputField}</span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
export const ControllerTable = ({ controllerTableData }) => (
    <div className="bik-table-parent ">
        <table className="bik-table">
            <thead>
                <tr>
                    <th width="10%" className="text-left">
                        Output
                    </th>
                    <th width="20%">Feature</th>
                    <th width="10%">Method</th>
                    <th width="40%">Routes</th>
                    <th width="20%" className="text-right">
                        Method
                    </th>
                </tr>
            </thead>
            <tbody>
                {controllerTableData.map((item) => (
                    <tr key={`id${item.id}`}>
                        <td className="text-left">
                            <span>{item.output}</span>
                        </td>
                        <td>
                            <span>{item.feature}</span>
                        </td>
                        <td className="text-center">
                            <span>{item.method}</span>
                        </td>
                        <td>
                            <span>{item.route}</span>
                        </td>
                        <td className="text-left">
                            <span>{item.fileMethod}</span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

function DocsTableViewPage() {
    const { pageId } = useParams();
    return (
        <div className="doc-view">
            <div className="doc-view__page-title text-24 mb-25 text-primary">
                {pageId}:{tableData.controllerName} (Academic Module)
            </div>
            <div className="doc-view__page-content flex-col gap-30">
                <div className="doc-view__page-content__section flex-col">
                    <div className="font-500 text-18 text-primary ">Description:</div>
                    <div className="text-16 font-400 text-indent-100 text-primary p-20 w-100p">
                        {tableData.des}
                    </div>
                </div>
                <div className="doc-view-controller w-100p">
                    <div className="text-18 font-500 mb-20 text-primary">
                        Controller: ClassSetup
                    </div>
                    <ControllerTable controllerTableData={tableData.controllerTable} />
                </div>
                <div className="doc-view-button-activity w-100p">
                    <div className="font-500 text-18 mb-20 text-primary">
                        Buttons & Its activity:
                    </div>
                    <ButtonActivityTable buttonActivityTable={tableData.buttonActivity} />
                </div>
                <div className="doc-view-role w-100p">
                    <div className="font-500 text-18 mb-20 text-primary">Role:</div>
                    {/* <CommonTable /> */}
                </div>
                <div className="doc-view-notes w-100p">
                    <div className="font-500 text-18 mb-20 text-primary">Notes:</div>
                    <CommonTable />
                </div>
                <div className="doc-view-user-manual w-100p">
                    <div className="font-500 text-18 mb-20 text-primary">User Manual:</div>
                    <div className="text-16 font-400 text-indent-50 text-primary" />
                </div>
                <div className="doc-view-faq w-100p">
                    <div className="font-500 text-18 mb-20 text-primary">FAQ:</div>
                    <div className="text-16 font-400 text-indent-50 text-primary" />
                </div>
            </div>
        </div>
    );
}

export default DocsTableViewPage;
