import React, { useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import BikTable from '../../../../features/bik-table/BikTable';
import NotFound from '../../../../features/not-found/NotFound';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import InstOption from '../../../../utils/InstOption';
import { GetDate, showCurrency } from '../../../../utils/Show';
import FinGwtStatusUpdateModal from '../modal/FinGwtStatusUpdateModal';

function TableRow({ item, setReloadKey }) {
    const ref = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const handleShowMoreMenu = () => setShowMenu((cuData) => !cuData);
    const { setConfirm, setMessage } = useTemplate();
    const { authInfo } = useTemplate();

    const handleBgActive = () => {
        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };

    const handelGwtCancel = (gtwTranData) => {
        setConfirm({
            text: (
                <FinGwtStatusUpdateModal
                    authInfo={authInfo}
                    setConfirm={setConfirm}
                    setMessage={setMessage}
                    gtwTranData={gtwTranData}
                    setReloadKey={setReloadKey}
                />
            ),
            showButton: false
        });
    };

    return (
        <tr className={item.demo ? 'placeholder-mode' : ''} ref={ref} onClick={handleBgActive}>
            <td className="text-left">
                <span>#{item?.id}</span>
            </td>

            <td>
                <UserInfoV3
                    name={item?.user?.name}
                    email={item?.user?.email}
                    img={item?.user?.photoUrl}
                />
            </td>
            <td className="text-center">
                <span>{item?.invoice}</span>
            </td>
            <td className="text-center">
                <span>{item?.gateway}</span>
            </td>
            <td className="text-center">
                <span>{item?.currency}</span>
            </td>
            <td className="text-center">
                <span>{showCurrency(item?.amount)}</span>
            </td>
            <td className="text-center" style={{ padding: '0px' }}>
                <span>{GetDate(item?.timeSuccess)}</span>
            </td>
            <td className="text-center">
                <span className={`status ${item?.status}`}>{item?.status}</span>
            </td>

            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button type="button" className="" onClick={handleShowMoreMenu}>
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        {item?.status === 'pending' && (
                            <button type="button" onClick={() => handelGwtCancel(item)}>
                                Cancel
                            </button>
                        )}
                        <button type="button" onClick={null}>
                            View
                        </button>
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}

function FinGatewayTransTable({ gwTranData, setReloadKey }) {
    if (gwTranData && !gwTranData?.length) {
        return <NotFound title="Gateway Transaction Not Found" />;
    }
    return (
        <BikTable
            countDataAr={gwTranData}
            dataTh={[
                { title: 'Trans ID', width: '9', align: 'left' },
                { title: 'User', width: '22', align: 'left' },
                { title: 'Invoice ID', width: '10' },
                { title: 'Gateway', width: '9' },
                { title: 'Currency', width: '9' },
                { title: 'Amount', width: '9' },
                { title: 'Time Success', width: '9' },
                { title: 'Status', width: '9' },
                { title: 'Action', width: '9', align: 'right' }
            ]}
        >
            {gwTranData.map((item) => (
                <TableRow key={item?.id} item={item} setReloadKey={setReloadKey} />
            ))}
        </BikTable>
    );
}

export default FinGatewayTransTable;
