import React, { useEffect, useState } from 'react';
import { ButtonGreenLine, ButtonPurpleLine, ButtonRedLine } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import UserInfo from '../../../../features/user-info/UserInfo';
import LibModal from '../../../../lib/LibModal';
import { GetDate } from '../../../../utils/Show';
import { handleContractUpdate, handleSearchContract } from '../FinSellItemOperation';
import { useFinSellItem } from '../FinSellItemProvider';

function FinSellItemContractModal({ item }) {
    const { userInfo, subscriptionEnd, timeDue, invoiceId, itemId, domain, currency } = item || {};
    const { setReloadKey, setIsModalOpen } = useFinSellItem();
    const [searchData, setSearchData] = useState([]);
    const [inputValue, setInputValue] = useState(null);
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    // -- set value when onchange
    useEffect(() => {
        handleSearchContract(authInfo, itemId, invoiceId, inputValue)
            .then(({ data }) => {
                setSearchData(data);
            })
            .catch((error) => {
                setMessage(error.toString(0));
            });
    }, [authInfo, inputValue, invoiceId, itemId, setMessage]);

    const handleSubmit = () => {
        handleContractUpdate(authInfo, invoiceId, itemId)
            .then(({ message }) => {
                setMessage(message);
                setIsModalOpen(false);
                setReloadKey((cuData) => cuData + 1);
            })
            .catch((error) => {
                setMessage(error.toString(0));
            });
    };

    const handleCreateLink = (data) => {
        if (data.contractId === 'new') {
            handleSubmit();
        }
        if (typeof data.contractId === 'number') {
            handleSubmit();
        }
    };

    return (
        <LibModal title="Add Contract" setOpen={setIsModalOpen}>
            <div className="p-25 fin-sell-contract-modal">
                <div className="inputs-cont flex-row flex-wrap align-space-between row-gap-15">
                    <div className="user-info-div w-100p mb-10">
                        <UserInfo
                            name={userInfo?.name}
                            email={`${userInfo?.email}, ${userInfo?.phone}`}
                        />
                        <div className="flex-row align-space-between position-middle">
                            <span>Invoice</span>
                            <span>{`${invoiceId}, ${currency}`}</span>
                        </div>
                        {domain && (
                            <div className="flex-row align-space-between position-middle">
                                <span>Domain</span>
                                <span>{domain}</span>
                            </div>
                        )}
                        <div className="flex-row align-space-between position-middle">
                            <span>Duration</span>
                            <span>{`${GetDate(timeDue)} to ${GetDate(subscriptionEnd)}`}</span>
                        </div>
                    </div>

                    <div className="w-100p">
                        <div className={`search-cont ${searchData.length ? 'active' : ''}`}>
                            <div className="input-search">
                                <input
                                    type="text"
                                    name="search"
                                    value={inputValue || ''}
                                    autoComplete="off"
                                    placeholder="Type something to search"
                                    className="contract-search"
                                    onChange={(ev) => setInputValue(ev.target.value)}
                                />
                            </div>

                            <div className="search-data">
                                {searchData.map((data) => (
                                    <div
                                        key={data.contractId}
                                        role="button"
                                        tabIndex={-1}
                                        className="search-data__item flex-row flex-wrap align-space-between position-middle"
                                    >
                                        <div>
                                            <div className="item__title">{data.contractTitle}</div>
                                            <div className="item__domain">
                                                {data.contractDomain}
                                            </div>
                                        </div>
                                        <div>
                                            {data.contractId === 'new' && (
                                                <ButtonGreenLine
                                                    title="Create Contract"
                                                    evOnClick={() => handleCreateLink(data)}
                                                />
                                            )}
                                            {data.contractId === 'package' && (
                                                <ButtonPurpleLine
                                                    title="Create Package"
                                                    evOnClick={() => handleCreateLink(data)}
                                                />
                                            )}
                                            {typeof data.contractId === 'number' && (
                                                <ButtonRedLine
                                                    title="Link to Contract"
                                                    evOnClick={() => handleCreateLink(data)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LibModal>
    );
}

export default FinSellItemContractModal;
