import React from 'react';
import { icons } from '../../../../configs/Icons';

function FinApiPageHeader({ handleReload }) {
    return (
        <div className="api-page__header flex-row position-middle align-space-between gap-50">
            <div className="text-30 font-500">API</div>
            <div className="content-reload">
                <div className="content-refresh-btn">
                    <button type="button" className="p-relative" onClick={handleReload}>
                        <img src={icons.reloadL} alt="" />
                        <img src={icons.reloadF} alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FinApiPageHeader;
