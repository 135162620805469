import React from 'react';
import { NavLink } from 'react-router-dom';
import { mkRelativeUrl } from '../../features/menus/AdvMenuProvider';
import { useDocs } from '../DocsProvider';
import menuConfig from '../data/menu_config';

function SubItem({ item }) {
    return (
        <ul key={Math.random()}>
            {item?.subMenu?.map((subItem) => (
                <li key={Math.random()}>
                    {/* {console.log(subItem)} */}
                    <NavLink to={mkRelativeUrl(`/users/docs/module/${subItem?.pageId}`)}>
                        {subItem?.title}
                        {subItem?.pageId && ` (${subItem?.pageId})`}
                    </NavLink>
                    <SubItem subItem={subItem} key={Math.random()} />
                </li>
            ))}
        </ul>
    );
}

function Menus() {
    const { menus } = useDocs();

    return (
        <div className="docs-menus file">
            <div className="docs-menu-list">
                <ul className="flex-col">
                    {menus.map((item) => (
                        <li key={item.path}>
                            <NavLink to={mkRelativeUrl(item.path)}>{item.title}</NavLink>
                        </li>
                    ))}
                    {menuConfig?.map((item) => (
                        <SubItem item={item} key={Math.random()} />
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Menus;
