import { createContext, useContext, useMemo, useState } from 'react';
import ConfirmTemplate from './ConfirmTemplate';
import MessageTemplate from './MessageTemplate';

const TemplateContext = createContext();

export function useTemplate() {
    return useContext(TemplateContext);
}

const initialState = { type: '', index: 0 };

function TemplateProvider({ children }) {
    const [message, setMessage] = useState(null);
    const [confirm, setConfirm] = useState(null);
    const [mode, setMode] = useState(initialState);

    const value = useMemo(() => {
        const openConfirm = (icon, text, buttons) => {
            setConfirm({ icon, text, buttons });
        };
        const closeConfirm = () => {
            setConfirm(null);
        };

        const createEditorFunction = (setValue) => {
            const openEditor = (type, name, val, index) => {
                if (type.includes('add') || type.includes('delete')) {
                    setMode({ type, index: name });
                } else {
                    setMode({ type, index });
                    setValue({ [name]: val });
                }
            };

            const closeEditor = (st) => {
                if (!st) {
                    setMode({ type: '' });
                    setValue({});
                }
            };

            return { mode, openEditor, closeEditor };
        };

        return {
            message,
            setMessage,
            confirm,
            setConfirm,
            openConfirm,
            closeConfirm,
            createEditorFunction
        };
    }, [confirm, message, mode]);

    return (
        <TemplateContext.Provider value={value}>
            {children}
            <MessageTemplate message={message} setMessage={setMessage} />
            <ConfirmTemplate confirm={confirm} setConfirm={setConfirm} />
        </TemplateContext.Provider>
    );
}

export default TemplateProvider;
