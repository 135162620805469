import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../features/auth/AuthProvider';
import AxiosAPI from '../utils/AxiosAPI';
import menuConfig from './data/menu_config';
import menusItem from './data/menusItem';

const DocsContext = createContext();
export const useDocs = () => useContext(DocsContext);

export const hrmURl = { base: 'https://hrm.edusoft.com.bd' };

const DocsProvider = ({ children }) => {
    const [menus, setMenus] = useState(menusItem);
    const [modulesData, setModulesData] = useState([]);
    const [technologyData, setTechnologyData] = useState({ dateAr: '' });
    const [menuConfigData, setMenuConfigData] = useState(menuConfig);
    const { authInfo } = useAuth();

    // -- modules data show
    useEffect(() => {
        AxiosAPI(authInfo)
            .setUrl(`${hrmURl.base}/modules`)
            .post()
            .then((res) => {
                setModulesData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [authInfo]);

    // -- technology data
    useEffect(() => {
        AxiosAPI(authInfo)
            .setUrl(`${hrmURl.base}/technology`)
            .post()
            .then((res) => {
                setTechnologyData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [authInfo]);

    const value = useMemo(
        () => ({
            menus,
            setMenus,
            modulesData,
            setModulesData,
            technologyData,
            setTechnologyData,
            menuConfigData,
            setMenuConfigData
        }),
        [menuConfigData, menus, modulesData, technologyData]
    );

    return <DocsContext.Provider value={value}>{children}</DocsContext.Provider>;
};

export default DocsProvider;
