import { icons } from '../../../../configs/Icons';

const menu = [
    {
        id: 'info',
        title: 'Info',
        iconFill: icons.infoV3,
        iconLine: icons.infoV2
    }
];

export default menu;
