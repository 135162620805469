function SectionDivider({ title }) {
    return (
        <div className="divider-cont flex-row position-bottom">
            <h2 className="divider-title">{title}</h2>
            <hr className="divider-line" />
        </div>
    );
}

export default SectionDivider;
