import React from 'react';
import FinGatewayTransTable from './components/FinGatewayTransTable';

function FinGatewayTransBody({ gwTranData, setReloadKey }) {
    return (
        <div className="fin-gateway-trans-body">
            <FinGatewayTransTable gwTranData={gwTranData} setReloadKey={setReloadKey} />
        </div>
    );
}

export default FinGatewayTransBody;
