import { ButtonPurple } from '../../../../../configs/Buttons';
import NotFound from '../../../../features/not-found/NotFound';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import { GetDate } from '../../../../utils/Show';

function Tr({ data }) {
    // const [showMenu, setShowMenu] = useState(0);

    // no data
    // const arr = [] || demoData;

    // null data
    // const arr = null || demoData;

    // data
    // const arr = fakeUserProjects || demoData;

    // dot menu click
    // const handleMenuClick = (ev, item) => {
    //     ev.stopPropagation();

    //     setShowMenu(item?.id);
    // };

    // if (arr && !arr?.length) {
    //     return (
    //         <tr>
    //             <td colSpan={7}>
    //                 <span>No data found</span>
    //             </td>
    //         </tr>
    //     );
    // }

    return data?.map((item) => (
        <tr key={item.appId}>
            <td className="text-left">
                <span>{item.appId}</span>
            </td>
            <td className="text-left">
                <UserInfoV3 img={item?.logo} name={item?.appTitle} />
            </td>
            <td className="text-left app-type">
                <span className="">{item?.appKey}</span>
            </td>
            <td className="text-center">
                <span>{GetDate(item?.appTimeStart)}</span>
            </td>

            <td className="text-right">
                <span>{GetDate(item?.appTimeEnd)}</span>
            </td>
        </tr>
    ));
}

function ProjectInfoRow2({ data, handlerOpenModal }) {
    if (data?.apps && !data?.apps?.length) {
        return <NotFound title="No App Found!" />;
    }
    return (
        <div className="row-2">
            <div className="flex-row align-space-between position-middle">
                <div className="table-label">Applications:</div>
                <div>
                    <ButtonPurple
                        evOnClick={() => handlerOpenModal('addApp', data)}
                        title="Add App"
                    />
                    {/* {open && <ProjectAppAddModal data={data} setOpen={setOpen} />} */}
                </div>
            </div>

            <div className="bik-table-parent ">
                <table className="bik-table user-projects">
                    <thead>
                        <tr>
                            <th width="18%" className="text-left">
                                SL
                            </th>
                            <th width="30%" className="text-left">
                                Apps
                            </th>
                            <th width="25%" className="text-left">
                                Type
                            </th>
                            <th width="22%">Created</th>

                            {/* <th width="15%">Price</th> */}
                            <th width="22%" className="text-right">
                                Expired
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <Tr data={data?.apps} />
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ProjectInfoRow2;
