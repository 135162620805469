import { showCurrency } from '../../../../utils/Show';
import { UserInfoV3 } from '../../../user-info/UserInfo';
import InfoItem from '../model/InfoItem';

function Header() {
    return (
        <div className="header flex-row align-space-between position-middle o-hide">
            <div>
                <h2 className="header_title">Reciever</h2>
            </div>
        </div>
    );
}

function ReceiverInfo({ userInfo, currency, formData }) {
    const { photoUrl, name, email, facBalance, facId, facType } = userInfo || {};

    const inputAmount = formData.amount?.toString();

    const newBalance = inputAmount?.length
        ? parseFloat(facBalance) + parseFloat(inputAmount.replace(/,/g, ''))
        : 0;

    const recieveAmount = inputAmount?.length
        ? `+${showCurrency(parseFloat(inputAmount.replace(/,/g, '')))}`
        : 0;

    return (
        <div>
            <Header />

            <UserInfoV3
                img={photoUrl}
                name={name || '------------------'}
                email={email || '--------------------'}
            />

            <div className="info">
                <InfoItem title="AC Number" value={facId || 0} />

                <InfoItem title="AC Type" value={facType || '--'} />

                <InfoItem
                    title="Balance"
                    value={`${currency || ''} ${showCurrency(facBalance || 0)}`}
                />

                <InfoItem
                    title="Amount"
                    value={
                        <span className="receiver-amount-cont flex-row position-middle align-center">
                            {formData.debitInfo && formData.creditInfo ? recieveAmount : ''}
                        </span>
                    }
                />

                <InfoItem
                    title="New Balance"
                    value={`${currency || ''} ${showCurrency(newBalance)}`}
                />
            </div>
        </div>
    );
}

export default ReceiverInfo;
