export const usersList = [
    {
        id: 10000001,
        name: '-----------------',
        photoUrl: '',
        username: '----------',
        organization: '------------',
        address: '------------',
        phone: '------------',
        source: '------------',
        status: '-------',
        demo: true
    },
    {
        id: 10000002,
        name: '-----------------',
        photoUrl: '',
        username: '----------',
        organization: '------------',
        address: '------------',
        phone: '------------',
        source: '------------',
        status: '-------',
        demo: true
    },
    {
        id: 10000003,
        name: '-----------------',
        photoUrl: '',
        username: '----------',
        organization: '------------',
        address: '------------',
        phone: '------------',
        source: '------------',
        status: '-------',
        demo: true
    },
    {
        id: 10000004,
        name: '-----------------',
        photoUrl: '',
        username: '----------',
        organization: '------------',
        address: '------------',
        phone: '------------',
        source: '------------',
        status: '-------',
        demo: true
    },
    {
        id: 10000005,
        name: '-----------------',
        photoUrl: '',
        username: '----------',
        organization: '------------',
        address: '------------',
        phone: '------------',
        source: '------------',
        status: '-------',
        demo: true
    }
];

export default null;
