import React from 'react';

const data = [
    {
        id: 1,
        title: 'Minimal Student Information Preview for page top with Image, Name, Batch, Resident, Class, Department'
    },
    {
        id: 2,
        title: 'Minimal Student Information Preview for a table with Image, Name, Batch, Resident, Class, Department'
    },
    {
        id: 3,
        title: 'Floating Option'
    },
    {
        id: 4,
        title: 'Top menu with a responsive view.'
    },
    {
        id: 5,
        title: 'CSV import system'
    }
];
function ObjectsCreate() {
    return (
        <div className="objects-create flex-col gap-10">
            <div className="objects-create-title text-18">Objects to be created:</div>
            <div className="objects-create-data flex-col gap-5">
                {data.map((item) => (
                    <div className="flex-row gap-10 text-16" key={item.id}>
                        <div className="objects-number font-400 pl">{item.id}.</div>
                        <div className="font-400">{item.title}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
function Duration() {
    return (
        <div className="duration flex-row position-middle gap-15">
            <div className="duration-title text-18">Duration:</div>
            <div className="duration-des text-16 font-400">6 month</div>
        </div>
    );
}

function Objective() {
    return (
        <div className="doc-objective">
            <div className="objective-title">Objective:</div>
            <div className="objective-des font-400 text-16 text-indent-100">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem perspiciatis, omnis
                repudiandae nostrum temporibus architecto obcaecati! Vel ab hic voluptate natus
                laborum ipsum sint, doloribus id commodi, quaerat suscipit voluptates modi possimus,
                fuga alias dignissimos? Consectetur perferendis exercitationem mollitia doloribus
                temporibus culpa? Qui modi enim, harum doloremque porro labore? Aperiam.
            </div>
        </div>
    );
}

function OverView() {
    return (
        <div className="doc-overview">
            <div className="overview_title">Overview:</div>
            <div className="overview-des font-400 text-16 text-indent-100">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem perspiciatis, omnis
                repudiandae nostrum temporibus architecto obcaecati! Vel ab hic voluptate natus
                laborum ipsum sint, doloribus id commodi, quaerat suscipit voluptates modi possimus,
                fuga alias dignissimos? Consectetur perferendis exercitationem mollitia doloribus
                temporibus culpa? Qui modi enim, harum doloremque porro labore? Aperiam.
            </div>
        </div>
    );
}

function CoverPage() {
    return (
        <div className="cover-page inner-scroll flex-col gap-40">
            <OverView />
            <Objective />
            <Duration />
            <ObjectsCreate />
        </div>
    );
}

export default CoverPage;
