export const contractPackageList = [
    {
        id: 10000001,
        favorite: true,
        userInfo: {
            name: 'Noman Gazi',
            email: 'numan.islam61@gmail.com',
            phone: '',
            photoURL: ''
        },
        title: 'Contract VPS 1',
        server: 'server-1',
        dateCreate: '1674950400',
        dateExpire: '1774950400',
        domain: 'domain-1.com',
        price: '30',
        currency: 'USD',
        status: 'active',
        demo: true
    },
    {
        id: 10000002,
        favorite: true,
        userInfo: {
            name: 'Noman Gazi',
            email: 'numan.islam61@gmail.com',
            phone: '',
            photoURL: ''
        },
        title: 'Contract VPS 1',
        server: 'server-1',
        dateCreate: '1674950400',
        dateExpire: '1774950400',
        domain: 'domain-1.com',
        price: '30',
        currency: 'USD',
        status: 'active',
        demo: true
    },
    {
        id: 10000003,
        favorite: false,
        userInfo: {
            name: 'Noman Gazi',
            email: 'numan.islam61@gmail.com',
            phone: '',
            photoURL: ''
        },
        title: 'Contract VPS 1',
        server: 'server-1',
        dateCreate: '1674950400',
        dateExpire: '1774950400',
        domain: 'domain-1.com',
        price: '30',
        currency: 'USD',
        status: 'active',
        demo: true
    }
];

export default null;
