import React from 'react';

function HiddenInputButton({ setMode, mode }) {
    return (
        <div className="flex-row">
            <div
                onClick={() => setMode({ tel: true, fax: !!mode.fax })}
                role="button"
                tabIndex={-1}
                className="button-tel"
            />

            <div
                onClick={() => setMode({ fax: true, tel: !!mode.tel })}
                role="button"
                tabIndex={-1}
                className="button-fax"
            />
        </div>
    );
}

export default HiddenInputButton;
