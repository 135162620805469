import { useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import NotFound from '../../../../features/not-found/NotFound';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import InstOption from '../../../../utils/InstOption';
import { GetDate } from '../../../../utils/Show';

function DotMenu({ show, setShowMenu, handleMenuClick }) {
    return (
        <div className="flex-row align-right p-relative">
            <span>
                <div role="button" tabIndex={-1} onClick={handleMenuClick}>
                    <img src={icons?.optionFill} alt="" />
                </div>
            </span>
            <InstOption showMenu={show} setShowMenu={setShowMenu}>
                <button type="button" onClick={(ev) => ev.stopPropagation()}>
                    Manage
                </button>
                <button type="button" onClick={(ev) => ev.stopPropagation()}>
                    Delete
                </button>
            </InstOption>
        </div>
    );
}

// function AppsRow({ arr }) {
//     const length = arr?.length > 4 ? arr?.length - 4 : '';

//     return (
//         <div className="flex-row position-middle apps-row-cont">
//             {arr?.slice(0, 4)?.map((data, index) => (
//                 <span
//                     key={MD5(`${Math.random()}/${dayjs.unix()}`)}
//                     className={`apps-logo ${arr?.length > 4 && index === 3 ? 'length' : ''}`}
//                     style={{
//                         left: index === 0 ? 0 : -index * 5,
//                         content: '5'
//                     }}
//                 >
//                     <img className="d-block" src={data?.logo} alt="" />
//                     {length && index === 3 && <div className="apps-length">{length}</div>}
//                 </span>
//             ))}
//         </div>
//     );
// }
const demoData = [
    {
        id: 5555555555,
        title: '---------------',
        email: '---------------------',
        projectTitle: '----------------------',
        created: 1690172340,
        apps: '-------------',
        status: '------------',
        empty: true
    }
];

function Tr({ setInfoId, item }) {
    const [showMenu, setShowMenu] = useState(0);
    const ref = useRef();

    const handleTrClick = () => {
        setInfoId(item?.id);
        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };

    // dot menu click
    const handleMenuClick = (ev, i) => {
        ev.stopPropagation();

        setShowMenu(i?.id);
    };

    const { id, title, faviconUrl, created, status, userInfo } = item || {};

    return (
        <tr
            ref={ref}
            key={id}
            onClick={handleTrClick}
            className={item.empty ? 'placeholder-mode' : ''}
        >
            <td>
                <span>{item.id}</span>
            </td>
            <td>
                <div className="flex-row position-middle gap-10">
                    <span className="project-logo">
                        <img src={faviconUrl || icons.defaultIcon} alt="logo" />
                    </span>
                    <span>{title}</span>
                </div>
            </td>
            <td>
                <span>
                    <UserInfoV3
                        name={userInfo?.name}
                        email={userInfo?.email}
                        img={userInfo?.photoUrl}
                    />
                </span>
            </td>

            <td className="text-center">
                <span>{GetDate(created)}</span>
            </td>
            <td>
                {/* <AppsRow arr={apps[0]} /> */}
                --
                {/* <span>{item.apps}</span> */}
            </td>
            <td className="text-center">
                <span className={`status ${status}`}>{item.status}</span>
            </td>
            <td className="text-right">
                <DotMenu
                    show={showMenu === id}
                    setShowMenu={setShowMenu}
                    handleMenuClick={(ev) => handleMenuClick(ev, item)}
                />
            </td>
        </tr>
    );
}

function UserProjectsTable({ setInfoId, projectList }) {
    // data
    const arr = projectList || demoData;

    if (arr && !arr?.length) {
        return <NotFound title="No Project Found!" />;
    }
    return (
        <div className={`bik-table-parent ${arr?.empty ? 'placeholder-mode' : ''}`}>
            <table className="bik-table user-projects">
                <thead>
                    <tr>
                        <th width="9%" className="text-left">
                            SL
                        </th>
                        <th width="20%" className="text-left">
                            Title
                        </th>
                        <th width="20%" className="text-left">
                            User
                        </th>

                        <th width="12%">Created</th>
                        <th width="10%">Apps</th>
                        <th width="10%">Status</th>
                        <th width="10%" className="text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {arr?.map((item) => (
                        <Tr key={item?.id} item={item} setInfoId={setInfoId} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default UserProjectsTable;
