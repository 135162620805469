// Create Dummy Notification data
const notificationData = [
    {
        id: 1,
        logo: 'https://via.placeholder.com/150',
        title: 'Notification 1',
        description:
            'Notification 1 description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae,',
        date: '2021-01-01',
        type: 'info',
        status: 'unread'
    },
    {
        id: 2,
        logo: 'https://via.placeholder.com/150',
        title: 'Notification 2',
        description:
            'Notification 2 description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae,',
        date: '2021-01-02',
        type: 'warning',
        status: 'read'
    },
    {
        id: 3,
        logo: 'https://via.placeholder.com/150',
        title: 'Notification 3',
        description:
            'Notification 3 description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae,',
        date: '2021-01-03',
        type: 'error',
        status: 'unread'
    },
    {
        id: 4,
        logo: 'https://via.placeholder.com/150',
        title: 'Notification 4',
        description:
            'Notification 4 description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae,',
        date: '2021-01-04',
        type: 'info',
        status: 'read'
    },
    {
        id: 5,
        logo: 'https://via.placeholder.com/150',
        title: 'Notification 5',
        description:
            'Notification 5 description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae,',
        date: '2021-01-05',
        type: 'warning',
        status: 'unread'
    },
    {
        id: 6,
        logo: 'https://via.placeholder.com/150',
        title: 'Notification 6',
        description:
            'Notification 6 description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae,',
        date: '2021-01-06',
        type: 'error',
        status: 'read'
    },
    {
        id: 7,
        logo: 'https://via.placeholder.com/150',
        title: 'Notification 7',
        description:
            'Notification 7 description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae,',
        date: '2021-01-07',
        type: 'info',
        status: 'unread'
    },
    {
        id: 8,
        logo: 'https://via.placeholder.com/150',
        title: 'Notification 8',
        description:
            'Notification 8 description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae,',
        date: '2021-01-08',
        type: 'warning',
        status: 'read'
    },
    {
        id: 9,
        logo: 'https://via.placeholder.com/150',
        title: 'Notification 9',
        description:
            'Notification 9 description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae,',
        date: '2021-01-09',
        type: 'error',
        status: 'unread'
    },
    {
        id: 10,
        logo: 'https://via.placeholder.com/150',
        title: 'Notification 10',
        description:
            'Notification 10 description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae,',
        date: '2021-01-10',
        type: 'info',
        status: 'read'
    }
];

export default notificationData;
