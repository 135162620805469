import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageProperties from '../../../utils/PageProperties';
import FinUserStatementBody from './FinUserStatementBody';
import FinUserStatementHeader from './FinUserStatementHeader';
import FinUserStatementProvider, { useStatementInfo } from './FinUserStatementProvider';

function StatementContent({ statementId }) {
    const { statementData, startDate, endDate, handelReload } = useStatementInfo();

    return (
        <div className="user-statement-page">
            <FinUserStatementHeader
                statementData={statementData}
                statementId={statementId}
                startDate={startDate}
                endDate={endDate}
                handelReload={handelReload}
            />
            <FinUserStatementBody handelReload={handelReload} statementData={statementData} />
        </div>
    );
}

function FinUserStatement() {
    const { statementId } = useParams();

    useEffect(() => {
        PageProperties('User Statement');
    }, []);

    return (
        <FinUserStatementProvider statementId={statementId}>
            <div className="page-cont">
                <StatementContent statementId={statementId} />
            </div>
        </FinUserStatementProvider>
    );
}

export default FinUserStatement;
