import Show, {
    GetDate,
    UpperCase,
    calculateDiscountPercentage,
    showCurrency,
    showValueWithUnit
} from '../../../../../../utils/Show';
import { DeleteBtn, EditBtn } from '../../_models/ActionButtons';
import PriceDiscountInfo from '../../_models/PriceDiscountInfo';

function FinInvoiceEmail({ item, sl, isInvoiceActive, onClickEdit, onClickDelete }) {
    const {
        title,
        price,
        priceOffer,
        quantity,
        unitName,
        subscriptionStart,
        subscriptionEnd,
        properties,
        vat
    } = item || {};

    const { emailQuantity, emailValidate, note } = properties || {};

    const priceDiscount = calculateDiscountPercentage(price, priceOffer);

    return (
        <tr className={!isInvoiceActive ? 'clickable' : ''} onClick={null}>
            <td>{sl}</td>
            <td>
                <h4>{title}</h4>
                <p>
                    <Show show={emailValidate}>
                        Duration: <b>{`${emailValidate} Month` || 0} &nbsp;</b>,
                    </Show>

                    <Show show={emailQuantity}>
                        Quantity:
                        <b>{emailQuantity || 0}</b>
                        <br />
                    </Show>

                    <Show show={note}>
                        <b>{note}</b>
                    </Show>
                </p>
                <span>
                    Duration: {GetDate(subscriptionStart) || 0} to {GetDate(subscriptionEnd) || 0}
                </span>
            </td>
            <td>
                <span className="price-amount">{showCurrency(price)}</span> <br />/{' '}
                {UpperCase(unitName)}
            </td>
            <td>
                {showValueWithUnit(quantity, unitName)}
                {/* {quantity || 0} {UpperCase(unitName)} */}
            </td>
            <td>
                <span className="price-amount">{showCurrency(price * quantity)}</span>

                <PriceDiscountInfo priceDiscount={priceDiscount} vat={vat} />

                <div className="info-action-button flex-col position-middle position-space-between">
                    <EditBtn onClickEdit={onClickEdit} />
                    <DeleteBtn onClickDelete={onClickDelete} />
                </div>
            </td>
        </tr>
    );
}

export default FinInvoiceEmail;
