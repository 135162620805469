import Show, {
    GetDate,
    calculateDiscountPercentage,
    showCurrency,
    showValueWithUnit
} from '../../../../../../utils/Show';
import { DeleteBtn, EditBtn } from '../../_models/ActionButtons';
import PriceDiscountInfo from '../../_models/PriceDiscountInfo';
import showPrice from '../../_models/ShowPrice';

function FinInvoiceVpsServer({ item, sl, isInvoiceActive, onClickEdit, onClickDelete }) {
    const {
        title,
        price,
        priceOffer,
        quantity,
        unitName,
        subscriptionStart,
        subscriptionEnd,
        properties,
        vat
    } = item || {};

    const { vsBandwidth, vsCpu, vsLocation, vsNetwork, vsRam, vsOs, vsStorage, note } =
        properties || {};

    const priceDiscount = calculateDiscountPercentage(price, priceOffer);

    return (
        <tr className={!isInvoiceActive ? 'clickable' : ''} onClick={null}>
            <td>{sl}</td>
            <td>
                <h4>{title || '--'}</h4>

                <p>
                    <Show show={vsCpu}>
                        Cpu: <b>{vsCpu}, &nbsp;</b>
                    </Show>

                    <Show show={vsRam}>
                        Ram: <b>{vsRam}, &nbsp;</b>
                    </Show>

                    <Show show={vsStorage}>
                        Storage: <b>{vsStorage}, &nbsp;</b>
                        <br />
                    </Show>

                    <Show show={vsNetwork}>
                        Network: <b>{vsNetwork || '--'}, &nbsp;</b>
                    </Show>

                    <Show show={vsOs}>
                        OS: <b>{vsOs || '--'}, &nbsp;</b>
                        <br />
                    </Show>

                    <Show show={vsBandwidth}>
                        Bandwidth: <b>{vsBandwidth || '--'}, &nbsp;</b>
                    </Show>

                    <Show show={vsLocation}>
                        Location: <b>{vsLocation || '--'}, &nbsp;</b>
                        <br />
                    </Show>

                    <Show show={note}>
                        <b>{note}</b>
                    </Show>
                </p>

                <span>
                    Duration: {GetDate(subscriptionStart) || 0} to {GetDate(subscriptionEnd) || 0}
                </span>
            </td>
            <td>
                {showPrice(price, quantity)}
                {/* <span className="price-amount">{showCurrency(price * 12)}</span> <br />
                /Year */}
            </td>
            <td>
                {showValueWithUnit(quantity, unitName)}
                {/* {quantity || 0} {unitName} */}
            </td>
            <td>
                <span className="price-amount">{showCurrency(price * quantity)}</span>

                <PriceDiscountInfo priceDiscount={priceDiscount} vat={vat} />

                <div className="info-action-button flex-col position-middle position-space-between">
                    <EditBtn onClickEdit={onClickEdit} />
                    <DeleteBtn onClickDelete={onClickDelete} />
                </div>
            </td>
        </tr>
    );
}

export default FinInvoiceVpsServer;
