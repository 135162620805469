import dayjs from 'dayjs';
import { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { ButtonGray, ButtonPurple } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../features/template/TemplateProvider';

function InfoCommonView({ title, content }) {
    return (
        <div className="invoice-input-area flex-row align-space-between position-middle">
            <div>
                <h2 className="invoice-info_title">{title}</h2>
            </div>
            <div>{content}</div>
        </div>
    );
}

function ActionContainer({ show, children }) {
    return (
        <div className={`invoice-info_action-cont flex-row align-right ${show ? 'show' : ''}`}>
            {children}
        </div>
    );
}

const initialState = {
    invoiceId: '',
    dateIssue: '',
    dateDue: ''
};

function FinInvoiceInfoEditor({ show, invoiceData, handelChangeBasic }) {
    const { setMessage } = useTemplate();

    // --Invoice Info
    const { id, timeIssue, timeDue } = invoiceData.info;
    const invoiceId = id.toString();
    const dateIssue = timeIssue ? dayjs.unix(timeIssue).format('YYYY-MM-DD') : '';
    const dateDue = timeDue ? dayjs.unix(timeDue).format('YYYY-MM-DD') : '';

    // --Form Info
    const [formData, setFormData] = useState({ ...initialState, invoiceId, dateIssue, dateDue });

    // const handelChangeValue = (ev) => {
    //     const { name, value } = ev.target;
    //     if (name) {
    //         setFormData((cuData) => ({ ...cuData, [name]: value }));
    //     }
    // };

    const handelCancel = () => {
        setFormData({ invoiceId, dateIssue, dateDue });
    };

    const handelSave = () => {
        setMessage('Saving basic information...');

        console.log(formData);

        handelChangeBasic(formData.invoiceId, formData.dateIssue, formData.dateDue)
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const isValueUpdate =
        formData.invoiceId !== invoiceId ||
        formData?.dateIssue !== dateIssue ||
        formData?.dateDue !== dateDue;

    if (!show) {
        return null;
    }
    return (
        <div className="invoice-info-cont">
            <div className="info-section_header">
                <h2 className="info-section_title">Invoice Info</h2>
            </div>

            {/* <InfoCommonView
                title="Invoice"
                content={
                    <input
                        name="invoiceId"
                        onChange={handelChangeValue}
                        value={formData.invoiceId}
                        placeholder={`#${invoiceId}`}
                        type="text"
                        readOnly={false}
                    />
                }
            /> */}

            <InfoCommonView
                title="Date of Issue"
                content={
                    <Flatpickr
                        options={{ dateFormat: 'Y-m-d' }}
                        value={formData.dateIssue}
                        onChange={([date]) =>
                            setFormData((cuData) => ({
                                ...cuData,
                                dateIssue: dayjs(date).format('YYYY-MM-DD')
                            }))
                        }
                        id="flatpickrInput"
                        name="dateIssue"
                    />
                    // <input
                    //     name="dateIssue"
                    //     value={formData.dateIssue}
                    //     onChange={handelChangeValue}
                    //     type="date"
                    // />
                }
            />

            <InfoCommonView
                title="Due Date"
                content={
                    <Flatpickr
                        options={{ dateFormat: 'Y-m-d' }}
                        value={formData.dateDue}
                        onChange={([date]) =>
                            setFormData((cuData) => ({
                                ...cuData,
                                dateDue: dayjs(date).format('YYYY-MM-DD')
                            }))
                        }
                        id="flatpickrInput"
                        name="dateDue"
                    />
                    // <input
                    //     name="dateDue"
                    //     value={formData.dateDue}
                    //     onChange={handelChangeValue}
                    //     type="date"
                    // />
                }
            />

            <ActionContainer show={isValueUpdate}>
                <ButtonGray evOnClick={handelCancel} title="Cancel" />

                <ButtonPurple evOnClick={handelSave} title="Save" />
            </ActionContainer>
        </div>
    );
}

export default FinInvoiceInfoEditor;
