import { Outlet } from 'react-router-dom';
import DocsProvider from './DocsProvider';
import Menus from './docs_menus/Menus';

function DocsPage() {
    return (
        <DocsProvider>
            <div className="docs-page h-100p flex-row">
                <Menus />
                <div className="docs-cont">
                    <Outlet />
                </div>
            </div>
        </DocsProvider>
    );
}

export default DocsPage;
