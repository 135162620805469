// Note: Bik Table Component
/**
 *--If the user wants to show how much data is in the table, only then will it be displayed.
 *
 */

/**
 * Bik Table Component Receive only 2 props and 1 children
 *
 * @param {array} dataListAr - array of objects
 * @param {array} dataTh - array of objects
 * @param {children} children - BikTableBody Component
 * @returns BikTable Component
 * @example
 * <BikTable
 *     dataListAr={dataListAr}
 *    dataTh={[
                { width: '12', title: 'SL', class: 'optional' },
            ]}
 * >
 *    <BikTableBody dataListAr={dataListAr} />
 * </BikTable>
 */

export function CountDataTbody({ data = [] }) {
    if (data.length === 0) return <div className="mb-30" />;

    if (Array.isArray(data)) {
        return (
            <div
                style={{
                    width: '25%',
                    marginLeft: '25px',
                    color: 'var(--primary-700)',
                    opacity: data[0]?.demo ? 0 : 1
                }}
                className={`text-16 w-100p ${data[0]?.demo ? 'mb-5' : 'm-10'}`}
            >
                Showing <span className="font-500">{data.length}</span> Items
            </div>
        );
    }

    return (
        <div
            style={{
                width: '25%',
                marginLeft: '25px',
                color: 'var(--primary-700)',
                opacity: data?.demo ? 0 : 1
            }}
            className={`text-16 ${data?.demo ? 'mb-5' : 'm-10'}`}
        >
            Input only array of objects.
        </div>
    );
}

// -- Bik Table Header Component

// -- it only takes an array of objects
export function BikTableHead({ dataTh }) {
    if (!Array.isArray(dataTh)) return 'Only array of objects';

    return (
        <thead>
            <tr>
                {dataTh.map((item) => (
                    <th
                        width={item?.width ? `${item?.width}%` : 'auto'}
                        className={item?.align && `text-align-${item?.align}`}
                        key={item?.title}
                    >
                        {item?.title}
                    </th>
                ))}
            </tr>
        </thead>
    );
}

function BikTable({ countDataAr = [], dataTh = [], paginate, children }) {
    return (
        <div className="bik-table-parent">
            <table className="bik-table">
                <BikTableHead dataTh={dataTh} />
                <tbody>{children}</tbody>
            </table>
            <div className="flex-row align-space-between position-middle">
                <CountDataTbody data={countDataAr} />
                {paginate}
            </div>
        </div>
    );
}

export default BikTable;
