import { ProductInputField, ProductTextAreaField } from '../components/ProductEditorInputs';

// emailValidate
function EmailInputs() {
    return (
        <div className="inputs-cont flex-row flex-wrap align-space-between">
            {/* <ProductInputField title="Number of Email" name="emailQuantity" /> */}

            <ProductInputField title="Validate" name="emailValidate" />

            <ProductTextAreaField title="Notes" name="note" />
        </div>
    );
}

export default EmailInputs;
