import { useNavigate } from 'react-router-dom';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import NotFound from '../../../features/not-found/NotFound';
import FinUserStatementRightCol from './components/FinUserStatementRightCol';
import FinUserStatementView from './components/FinUserStatementView';

function FinUserStatementBody({ handelReload, statementData }) {
    const navigate = useNavigate();

    if (statementData && Object.keys(statementData).length === 0) {
        return (
            <NotFound
                title="Statement Not Found"
                btnTitle="Back to List"
                evButtonClick={() => navigate(mkRelativeUrl(`/finance/accounts`))}
            />
        );
    }
    return (
        <div
            className={`fin-user-statement-cont flex-row position-stretch align-space-between ${
                !statementData ? 'placeholder-mode' : ''
            }`}
        >
            <div>
                <FinUserStatementView statementData={statementData} />
            </div>
            <div>
                <FinUserStatementRightCol
                    handelReload={handelReload}
                    statementData={statementData}
                />
            </div>
        </div>
    );
}

export default FinUserStatementBody;
