/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getLoginUrl } from '../../utils/Env';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

const goForLogin = () => {
    const url = `${getLoginUrl()}/account/login/?continue=${window.location.href}`;
    window.location.href = url;
};

const iframeHandler = (authUrl) => {
    const iframe = document.createElement('iframe');
    iframe.src = `${authUrl}/i/`;
    iframe.title = 'Auth';
    iframe.style.cssText = 'width:0px; height:0px; border:0px solid;position:fixed;';
    iframe.onload = () => {
        // console.log('Loaded...');
    };
    return iframe;
};

const initialState = {
    loading: true,
    refreshToken: null,
    userUid: null,
    currentUser: {
        name: '',
        username: '',
        gender: '',
        genderTxt: '',
        dob: 0,
        dobText: '',
        phone: '',
        email: '',
        photoUrl: ''
    },
    error: 1,
    message: ''
};

const authUrl = getLoginUrl();

function AuthProvider({ children }) {
    const [authInfo, setAuthInfo] = useState(initialState);

    // Iframe Auth (Logging In...)
    useEffect(() => {
        const iframe = iframeHandler(authUrl);

        // Receiving Message from AuthURL
        const handelMessageListener = ({ data, origin }) => {
            if (origin !== authUrl) {
                return;
            }

            if (data.action === 'ready') {
                iframe.contentWindow.postMessage({ action: 'login-required' }, authUrl);
            } else if (data.action === 'login') {
                if (data.error === 0) {
                    setAuthInfo((d) => ({ ...d, ...data, loading: false }));
                } else {
                    setAuthInfo((d) => ({ ...initialState, loading: false }));
                }
                // --Listener Removed
                document.body.removeChild(iframe);
                window.removeEventListener('message', handelMessageListener);
            }
        };

        // --Listener Added
        window.addEventListener('message', handelMessageListener);
        document.body.appendChild(iframe);
    }, []);

    // --Redirect to Accounts
    useEffect(() => {
        if (authInfo.loading === false && authInfo.error !== 0) {
            goForLogin();
        }
    }, [authInfo]);

    const value = useMemo(() => {
        const logOut = () =>
            new Promise((resolve, reject) => {
                const iframe = iframeHandler(authUrl);

                // Receiving Message from AuthURL
                const handelMessageListener = ({ data, origin }) => {
                    if (origin !== authUrl) {
                        return;
                    }

                    if (data.action === 'ready') {
                        iframe.contentWindow.postMessage({ action: 'logout-required' }, authUrl);
                    } else if (data.action === 'logout') {
                        if (data.error === 0) {
                            setAuthInfo((d) => ({ ...d, ...initialState, loading: false }));
                            resolve(data);
                        } else {
                            reject(Error(data.message));
                        }

                        // --Listener Removed
                        window.removeEventListener('message', handelMessageListener);
                        document.body?.removeChild(iframe);
                    }
                };

                // --Listener Added
                window.addEventListener('message', handelMessageListener);
                document.body.appendChild(iframe);
            });

        return { authInfo, setAuthInfo, logOut };
    }, [authInfo]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
