import NotFound from '../../../features/not-found/NotFound';
import FinBankAccountsTable from './FinAccountTables/FinBankAccountsTable';
import FinSystemAccountsTable from './FinAccountTables/FinSystemAccountsTable';
import FinUserAccountsTable from './FinAccountTables/FinUserAccountsTable';
import { useFinAccounts } from './FinAccountsProvider';

function Tables({ type, facList, setStatementId, handleEditModal }) {
    if (type === 'customer' || type === 'operator') {
        return (
            <FinUserAccountsTable setStatementId={setStatementId} facList={facList} type={type} />
        );
    }
    if (type === 'bank') {
        return (
            <FinBankAccountsTable
                setStatementId={setStatementId}
                facList={facList}
                type={type}
                handleEditModal={handleEditModal}
            />
        );
    }
    return <FinSystemAccountsTable setStatementId={setStatementId} facList={facList} type={type} />;
}

function FinAccountsPageBody({ setStatementId, handleEditModal }) {
    const { facList, sParams, handleShowBankModal } = useFinAccounts();

    if (facList?.length === 0)
        return (
            <NotFound
                title="Finance account transaction not found"
                btnTitle="Add Bank Account"
                evButtonClick={handleShowBankModal}
            />
        );

    return (
        <Tables
            setStatementId={setStatementId}
            type={sParams.accountType || ''}
            facList={facList}
            handleEditModal={handleEditModal}
        />
    );
}

export default FinAccountsPageBody;
