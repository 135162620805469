/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import HiddenInputButton from './HiddenInputButton';

export function TelFaxIcon({ mode, setMode }) {
    return (
        <div className="flex-row align-space-between position-middle hidden-input-btn">
            <label htmlFor="mobile">Mobile </label>
            <HiddenInputButton mode={mode} setMode={setMode} />
        </div>
    );
}

export function TelMinusIcon({ setMode, name }) {
    return (
        <div
            onClick={() => setMode((d) => ({ ...d, [name]: false }))}
            role="button"
            tabIndex={-1}
            className="button-minus"
        />
    );
}

export default null;
