import React from 'react';
import { showCurrency } from '../../../../../utils/Show';
import FinInvoiceTableBody from './FinInvoiceTableBody';

function FinInvoiceProductTable({
    currency,
    items = [],
    status,
    openProductModal,
    handelRemoveProduct
}) {
    const isInvoiceActive = status === 'active' || openProductModal === undefined;

    return (
        <table className="inv-table" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>#</th>
                    <th className="details">Item</th>
                    <th>Price ({currency})</th>
                    <th>Quantity</th>
                    <th>Total ({currency})</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) => (
                    <FinInvoiceTableBody
                        item={item}
                        key={item.id}
                        sl={index + 1}
                        isInvoiceActive={isInvoiceActive}
                        openProductModal={openProductModal}
                        handelRemoveProduct={handelRemoveProduct}
                    />
                ))}
                <tr className="tfoot-cont">
                    <td colSpan={4} className="text-right">
                        <b>Total:</b>
                    </td>
                    <td className="text-right">
                        <b>
                            <span className="price-amount">
                                {showCurrency(
                                    items.reduce(
                                        (total, item) => item.price * item.quantity + total,
                                        0
                                    )
                                )}
                            </span>
                        </b>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default FinInvoiceProductTable;
