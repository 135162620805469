/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { icons } from '../../../../../configs/Icons';
import { GetDate } from '../../../../utils/Show';

const activityArr = [
    {
        id: 1,
        image: icons.avatar,
        displayName: 'Bishojit',
        activity: 'has changed Invoice Info',
        timestamp: 1335019491
    },
    {
        id: 2,
        image: icons.avatar,
        displayName: 'Saurav',
        activity: 'has changed Invoice Info',
        timestamp: 1335019491
    },
    {
        id: 3,
        image: icons.avatar,
        displayName: 'Moni',
        activity: 'has changed Invoice Info',
        timestamp: 1335019491
    },
    {
        id: 4,
        image: icons.avatar,
        displayName: 'Fahim',
        activity: 'has changed Invoice Info',
        timestamp: 1335019491
    }
];

function ActivityRow({ activity }) {
    return (
        <div className="invoice-activity_row flex-row align-space-between postion-stretch">
            <div className="invoice-activity_avatar">
                <img src={activity?.image} alt="avatar" />
            </div>
            <div>
                <p className="invoice-info_title">
                    <span className="invoice-activity_displayName">{activity?.displayName}</span>{' '}
                    {activity?.activity}
                </p>
                <p className="invoice-activity_date">{GetDate(activity?.timestamp)}</p>
            </div>
        </div>
    );
}

function FinInvoiceInfoActivity() {
    return (
        <div className="invoice-info-cont">
            <div className="info-section_header flex-row align-space-between position-middle">
                <h2 className="info-section_title">Activities</h2>
                <h4 onClick={null} role="button" tabIndex={-1} className="invoice-info_title">
                    View All
                </h4>
            </div>

            <div>
                {activityArr?.slice(0, 6)?.map((activity) => (
                    <ActivityRow key={activity.id} activity={activity} />
                ))}
            </div>
        </div>
    );
}

export default FinInvoiceInfoActivity;
