import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import { handleDedServerListOpe, handleDedServerStarred } from './DedServerOperation';
import DedServerPageBody from './DedServerPageBody';
import DedServerPageHeader from './DedServerPageHeader';

function DedServerPage() {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const [searchParams] = useSearchParams();
    const [dedServerData, setDedServerData] = useState(null);
    const [reloadKey, setReloadKey] = useState(0);

    const startDate = searchParams.get('date-start') || '';
    const endDate = searchParams.get('date-end') || '';
    const qParam = searchParams.get('q') || '';

    const handleReload = () => {
        setDedServerData(null);
        setMessage('Loading...');
        setReloadKey(Math.random());
    };

    // -- Fetch data from API
    useEffect(() => {
        handleDedServerListOpe(authInfo, startDate, endDate, qParam)
            .then(({ data, message, error }) => {
                if (error === 0) {
                    setDedServerData(data);
                } else {
                    setMessage(message);
                }
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    }, [authInfo, endDate, qParam, setMessage, startDate, reloadKey]);

    // -- set starred status
    const handleStarred = (id) => {
        handleDedServerStarred(authInfo, id)
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    };

    // -- set page title
    useEffect(() => {
        PageProperties('Dedicated Server');
    }, []);

    return (
        <div className="page-cont">
            <div className="dedicated-server-page">
                <DedServerPageHeader handleReload={handleReload} sParams={{ qParam }} />
                <DedServerPageBody dedServerData={dedServerData} handleStarred={handleStarred} />
            </div>
        </div>
    );
}

export default DedServerPage;
