import React, { useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import AddressUpdater from '../../../../features/address_updater/AddressUpdater';
import { useAuth } from '../../../../features/auth/AuthProvider';
import BikPaginate from '../../../../features/bik-paginate/BikPaginate';
import BikTable from '../../../../features/bik-table/BikTable';
import NotFound from '../../../../features/not-found/NotFound';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import InstOption from '../../../../utils/InstOption';
import UserStatusChangeModal from '../modal/UserStatusChangeModal';

function TableRow({ item, handleUpdatedAddress, setUserId }) {
    const ref = useRef(null);
    const { authInfo } = useAuth();
    const { setConfirm, setMessage } = useTemplate();
    const [showMenu, setShowMenu] = useState(false);
    const [setShowAddress] = useState(true);

    const handleShowMoreMenu = (ev) => {
        ev.stopPropagation();
        setShowMenu((cuData) => !cuData);
    };

    const handleUpdateAddress = (ev, itemData) => {
        ev.stopPropagation();

        return (
            <AddressUpdater
                setOpen={setShowAddress}
                address={itemData}
                handelChangeAddress={handleUpdatedAddress}
            />
        );
    };

    const handleChangeStatus = (ev, itemData) => {
        ev.stopPropagation();

        setConfirm({
            text: (
                <UserStatusChangeModal
                    authInfo={authInfo}
                    setConfirm={setConfirm}
                    setMessage={setMessage}
                    itemData={itemData}
                />
            ),
            showButton: false
        });
    };

    const handleBgActive = () => {
        setUserId(item?.id);
        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };

    return (
        <tr className={item.demo ? 'placeholder-mode' : ''} ref={ref} onClick={handleBgActive}>
            <td className="text-left">
                <span>{item?.id}</span>
            </td>

            <td>
                <UserInfoV3 name={item?.name} email={item?.email} img={item?.photoUrl} />
            </td>
            <td className="text-left">
                <span>{item?.username}</span>
            </td>
            <td>
                <span className={item.noData ? '' : 'text-dot'}>{item?.organization}</span>
            </td>
            <td>
                <span className={item.noData ? '' : 'text-dot'}>{item?.address}</span>
            </td>
            <td className="text-center">
                <span>{item?.phone || `---`}</span>
            </td>
            <td className="text-center">
                <span className={item.noData ? '' : 'text-dot'}>{item?.source}</span>
            </td>
            <td>
                <div className={`status ${item?.status}`}>
                    <span>{item?.status}</span>
                </div>
            </td>
            <td className="text-center">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button type="button" className="" onClick={handleShowMoreMenu}>
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        <button type="button" onClick={(ev) => handleUpdateAddress(ev, item)}>
                            Address Update
                        </button>
                        <button type="button" onClick={null}>
                            Add Source
                        </button>
                        <button type="button" onClick={(ev) => handleChangeStatus(ev, item)}>
                            Change Status
                        </button>
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}

function UserListTable({ userListData, setUserId }) {
    // const [currentPage, setCurrentPage] = useState(1);

    if (userListData && !userListData?.length) {
        return <NotFound title="User Data Not Found" />;
    }
    // const handleCurrentNoPage = (pageNo) => {
    //     // -- if current page is not equal to pageNo then call api
    //     if (currentPage !== pageNo) {
    //         setCurrentPage(pageNo);
    //     }
    // };

    return (
        <BikTable
            countDataAr={userListData}
            paginate={<BikPaginate position="right" className="fill" />}
            dataTh={[
                { title: 'SL', width: '9', align: 'left' },
                { title: 'User', width: '20', align: 'left' },
                { title: 'Username', width: '10' },
                { title: 'Organization', width: '12' },
                { title: 'Address', width: '12' },
                { title: 'Mobile', width: '13' },
                { title: 'Source', width: '12' },
                { title: 'Status', width: '9' },
                { title: 'Action', width: '9', align: 'right' }
            ]}
        >
            {userListData.map((item) => (
                <TableRow key={item.id} item={item} setUserId={setUserId} />
            ))}
        </BikTable>
    );
}

export default UserListTable;
