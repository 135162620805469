export const sellItem = [
    {
        itemId: 10000001,
        invoiceId: 10001068,
        subscriptionEnd: 1690740000,
        timeDue: 1690740000,
        price: 12345,
        priceTxt: '----------------',
        title: '------------------',
        domain: '',
        currency: '------',
        ratio: 109,
        contract: 12345,
        status: '------',
        userInfo: {
            name: '--------------',
            photoUrl: '',
            email: '-----------',
            phone: ''
        },
        demo: true
    },
    {
        itemId: 10000002,
        invoiceId: 10001068,
        subscriptionEnd: 1690740000,
        timeDue: 1690740000,
        price: 12345,
        priceTxt: '----------------',
        title: '------------------',
        domain: '',
        currency: '------',
        ratio: 109,
        contract: 12345,
        status: '------',
        userInfo: {
            name: '--------------',
            photoUrl: '',
            email: '-----------',
            phone: ''
        },
        demo: true
    },
    {
        itemId: 10000003,
        invoiceId: 10001068,
        subscriptionEnd: 1690740000,
        timeDue: 1690740000,
        price: 12345,
        priceTxt: '----------------',
        title: '------------------',
        domain: '',
        currency: '------',
        ratio: 109,
        contract: 12345,
        status: '------',
        userInfo: {
            name: '--------------',
            photoUrl: '',
            email: '-----------',
            phone: ''
        },
        demo: true
    },
    {
        itemId: 10000004,
        invoiceId: 10001068,
        subscriptionEnd: 1690740000,
        timeDue: 1690740000,
        price: 12345,
        priceTxt: '----------------',
        title: '------------------',
        domain: '',
        currency: '------',
        ratio: 109,
        contract: 12345,
        status: '------',
        userInfo: {
            name: '--------------',
            photoUrl: '',
            email: '-----------',
            phone: ''
        },
        demo: true
    },
    {
        itemId: 10000005,
        invoiceId: 10001068,
        subscriptionEnd: 1690740000,
        timeDue: 1690740000,
        price: 12345,
        priceTxt: '----------------',
        title: '------------------',
        domain: '',
        currency: '------',
        ratio: 109,
        contract: 12345,
        status: '------',
        userInfo: {
            name: '--------------',
            photoUrl: '',
            email: '-----------',
            phone: ''
        },
        demo: true
    }
];

export default null;
