import UserUpdater from '../../../../features/user_updater/UserUpdater';
import LibModal from '../../../../lib/LibModal';

function FinInvoiceEditUser({ setOpen, user, handelChangeUser }) {
    return (
        <div className="user-updater">
            <LibModal title="Change User" setOpen={setOpen}>
                <UserUpdater
                    user={user}
                    evCloseClick={() => setOpen(false)}
                    handelChangeUser={handelChangeUser}
                />
            </LibModal>
        </div>
    );
}

export default FinInvoiceEditUser;
