import { useEffect, useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import AxiosAPI from '../../../../utils/AxiosAPI';
import { showCurrency } from '../../../../utils/Show';
import { useAuth } from '../../../auth/AuthProvider';
import { useTemplate } from '../../../template/TemplateProvider';
import { UserInfoV3 } from '../../../user-info/UserInfo';
import InfoItem from '../_models/InfoItem';

function SearchPopup({
    show,
    setShow,
    handelUserClick,
    placeholder,
    inputValue,
    handelonChange,
    users
}) {
    const ref = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShow]);

    if (!show) {
        return null;
    }
    return (
        <div ref={ref} className="user-search-cont">
            <input
                value={inputValue || ''}
                onChange={handelonChange}
                placeholder={placeholder || 'Type something...'}
                className=""
                type="text"
            />

            <div className="search-result-cont">
                {users?.map((item) => (
                    <div
                        key={item.id}
                        role="button"
                        tabIndex={-1}
                        onClick={() => handelUserClick(item)}
                        className="user-row flex-row position-middle align-space-between"
                    >
                        <div>
                            <div className="user-updater_avatar ">
                                <span>
                                    <img src={icons.avatar} alt="avatar" />
                                </span>
                            </div>
                        </div>
                        <div>
                            <h2 className="user-updater_title">
                                <span>{item.name || '--'}</span>
                            </h2>
                            <p className="user-updater_sub-title">
                                <span>{item?.email || '--'}</span>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function Header({ selectedUser, setSelectedUser }) {
    const [mode, setMode] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const handelSearchClick = () => {
        setMode(true);
        setSelectedUser(null);
    };

    const [users, setUsers] = useState([]);

    const handelonChange = (ev) => {
        setSearchValue(ev.target.value);

        if (ev.target.value.length > 2) {
            setSelectedUser(null);
            AxiosAPI(authInfo)
                .setPath(`/api/users/search/`, {
                    q: ev.target.value,
                    limit: 20
                })
                .get()
                .then(({ data }) => {
                    if (data.error === 0) {
                        setUsers(data.data);
                    } else {
                        setUsers(null);
                        setMessage(data.message);
                    }
                })
                .catch((err) => {
                    setUsers(null);
                    setMessage(err.toString());
                });
        } else {
            setUsers(null);
        }
    };

    const handelUserClick = (item) => {
        setSelectedUser(item);
        setSearchValue('');
    };

    return (
        <div className="header flex-row align-left position-middle p-relative">
            <div>
                <h2 className="header_title">Reciever</h2>
            </div>

            <div className={`header_search-cont flex-row position-middle ${mode ? 'active' : ''}`}>
                <div
                    onClick={handelSearchClick}
                    role="button"
                    tabIndex={-1}
                    className="header_search-icon"
                >
                    <img src={icons.search} alt="search" />
                </div>
            </div>
            <SearchPopup
                show={mode && !selectedUser}
                setShow={setMode}
                inputValue={searchValue}
                handelonChange={handelonChange}
                handelUserClick={handelUserClick}
                users={users}
                placeholder="Type email or number...."
            />
        </div>
    );
}

function ReceiverInfo({ userInfo, currency, formData }) {
    const { photoUrl, name, email, facBalance, facId, facType } = userInfo || {};

    const [selectedUser, setSelectedUser] = useState(null);

    const inputAmount = formData.amount?.toString();

    const newBalance = inputAmount?.length
        ? parseFloat(facBalance || 0) + parseFloat(inputAmount.replace(/,/g, ''))
        : 0;

    const recieveAmount = inputAmount?.length
        ? `+${showCurrency(parseFloat(inputAmount.replace(/,/g, '')))}`
        : 0;

    return (
        <div>
            <Header selectedUser={selectedUser} setSelectedUser={setSelectedUser} />

            <UserInfoV3
                img={photoUrl || selectedUser?.photoUrl}
                name={name || selectedUser?.name || '------------------'}
                email={email || selectedUser?.email || '--------------------'}
            />

            <div className="info">
                <InfoItem title="AC Number" value={facId || 0} />

                <InfoItem title="AC Type" value={facType || '--'} />

                <InfoItem
                    title="Balance"
                    value={`${currency || ''} ${showCurrency(facBalance || 0)}`}
                />

                <InfoItem
                    title="Amount"
                    value={
                        <span className="receiver-amount-cont flex-row position-middle align-center">
                            {formData.debitInfo && formData.creditInfo ? recieveAmount : ''}
                        </span>
                    }
                />

                <InfoItem
                    title="New Balance"
                    value={`${currency || ''} ${showCurrency(newBalance)}`}
                />
            </div>
        </div>
    );
}

export default ReceiverInfo;
