/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';

function FinInvoiceEditTitle({ setOpen, invoiceData, handelChangeTitle }) {
    const defaultTitle = `Invoice-${invoiceData?.info?.id || ''}-`;

    const invTitle = invoiceData?.info?.title;

    const [formData, setFormData] = useState({
        title: invTitle.split(defaultTitle).join('')
    });

    const { setMessage } = useTemplate();

    const handleValueChange = (ev) => {
        const { name, value } = ev.target;

        if (name) {
            setFormData((cuData) => ({
                ...cuData,
                [name]: value
            }));
        }
    };

    const handelAddTitle = () => {
        setMessage('Adding title....');
        handelChangeTitle(formData.title)
            .then(({ error, message }) => {
                if (error === 0) {
                    setOpen(false);
                    setFormData({});
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title={invTitle?.length ? 'Update Title' : 'Add Title'} setOpen={setOpen}>
            <div className="invoice-add-title-popup">
                <div>
                    <label className="label" htmlFor="title">
                        Info Title:
                    </label>
                    <div className="input-area flex-row position-middle">
                        <p className="flex-row position-middle">{defaultTitle}</p>
                        <input
                            onChange={handleValueChange}
                            value={formData?.title}
                            name="title"
                            // placeholder="Enter Info Title...."
                            type="text"
                        />
                    </div>
                </div>

                <div className="flex-row align-right">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />
                    <ButtonGreen
                        evOnClick={handelAddTitle}
                        title={invTitle?.length ? 'Update' : 'Add'}
                    />
                </div>
            </div>
        </LibModal>
    );
}

export default FinInvoiceEditTitle;
