import React, { useState } from 'react';
import TransactionSummary from '../../../features/amount_summary/TransactionSummary';
import { CountDataTbody } from '../../../features/bik-table/BikTable';
import InfoSidebar from '../../../features/info_sidebar/InfoSidebar';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import NotFound from '../../../features/not-found/NotFound';
import { showCurrency } from '../../../utils/Show';
import FinInvoiceUpdateProvider, {
    useInvoiceUpdate
} from '../invoice_update/FinInvoiceUpdateProvider';
import FinInvoiceView from '../invoice_update/invoice_view/FinInvoiceView';
import FinInvoiceListPageHeader from './FinInvoiceListPageHeader';
import FinInvoiceProvider, { useFinInvoice } from './FinInvoiceProvider';
import FinInvoiceListTable from './components/FinInvoiceListTable';

function CustomChildren({ summary }) {
    return (
        <div className={`w-100p flex-col gap-5 ${summary?.noData ? 'placeholder-mode' : ''}`}>
            <div className=" flex-row align-space-between w-100p">
                <div>Product Price</div>
                <span>{showCurrency(summary?.productPrice)}</span>
            </div>
            <div className=" flex-row align-space-between w-100p">
                <div>Discount</div>
                <span>{showCurrency(summary?.discount)}</span>
            </div>
            <div className=" flex-row align-space-between w-100p">
                <div>
                    VAT <small style={{ fontSize: '12px' }}>(Where Applicable)</small>
                </div>
                <span>{showCurrency(summary?.vat)}</span>
            </div>
            <div className=" flex-row align-space-between w-100p">
                <div>Paid</div>
                <span>{showCurrency(summary?.paid)}</span>
            </div>
            <div className=" flex-row align-space-between w-100p">
                <div className="font-600">Payable</div>
                <span className="font-600">{showCurrency(summary?.payable)}</span>
            </div>
        </div>
    );
}
function NoFoundDataTable({ invoices, setInfoId }) {
    if (invoices?.length === 0) {
        return <NotFound title="Invoice not found" />;
    }

    return (
        <div className="bik-table-parent">
            <FinInvoiceListTable setInfoId={setInfoId} />
            <CountDataTbody data={invoices} />
        </div>
    );
}

function PageContent({ setInfoId }) {
    const { invoices, selectInvoice, invoiceSummary, handleReload } = useFinInvoice();
    const summary = invoiceSummary;
    return (
        <div className="page-cont">
            <div className="fin-invoice-list-container flex-col align-space-between">
                <FinInvoiceListPageHeader handleReload={handleReload} />
                <NoFoundDataTable invoices={invoices} setInfoId={setInfoId} />

                <TransactionSummary cuData={selectInvoice}>
                    <CustomChildren summary={summary} />
                </TransactionSummary>
            </div>
        </div>
    );
}

function InvoiceViewWrapper() {
    const { invoiceData } = useInvoiceUpdate();

    return <FinInvoiceView invoiceData={!invoiceData?.loading ? invoiceData : null} />;
}

function FinInvoiceListPage() {
    const [infoId, setInfoId] = useState(null);

    return (
        <FinInvoiceProvider>
            <PageContent setInfoId={setInfoId} />

            <InfoSidebar
                show={infoId}
                path={infoId ? mkRelativeUrl(`/finance/sell-invoice/${infoId}/update/`) : null}
            >
                <FinInvoiceUpdateProvider id={infoId}>
                    <InvoiceViewWrapper />
                </FinInvoiceUpdateProvider>
            </InfoSidebar>
        </FinInvoiceProvider>
    );
}

export default FinInvoiceListPage;
