import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import { DateRange } from '../../../lib/LibDatePicker';

function FinGatewayTransHeader({ handleReload, status, startDate, endDate }) {
    const [showMenu, setShowMenu] = useState(false);

    const navigate = useNavigate();

    const handleOnChange = (e) => {
        const { name, value } = e?.target || {};

        if (name) {
            const url = mkRelativeUrl('/finance/gateway-transactions/', {
                status: name === 'status' ? value : status,
                'date-start': startDate,
                'date-end': endDate
            });
            navigate(url);
        } else if (e.length === 2) {
            const url = mkRelativeUrl('/finance/gateway-transactions/', {
                status,
                'date-start': dayjs(e[0]).format('YYYY-MM-DD'),
                'date-end': dayjs(e[1]).format('YYYY-MM-DD')
            });
            navigate(url);
        }
    };

    return (
        <div className="fin-gateway-trans__header flex-row position-middle align-space-between gap-50">
            <div className="text-30 font-500">Gateway Transaction</div>
            <div className="flex-row position-middle gap-20 fill">
                <div className="user-custom-data gap-5">
                    <select
                        className="custom-select"
                        name="status"
                        onChange={handleOnChange}
                        value={status}
                    >
                        <option value="">Select Status</option>
                        <option value="success">Success</option>
                        <option value="pending">Pending</option>
                        <option value="cancel">Cancel</option>
                    </select>
                </div>
                <div className="fin-gateway_date-range">
                    <DateRange
                        startDate={startDate}
                        endDate={endDate}
                        handelOnChange={handleOnChange}
                        setShowMenu={setShowMenu}
                        showMenu={showMenu}
                        navigatePath="/finance/gateway-transactions/"
                        queryObject={{ status }}
                    />
                </div>
            </div>
            <div className="content-reload">
                <div className="content-refresh-btn">
                    <button type="button" className="p-relative" onClick={handleReload}>
                        <img src={icons.reloadL} alt="" />
                        <img src={icons.reloadF} alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FinGatewayTransHeader;
