import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { UserInfoV2 } from '../../../../features/user-info/UserInfo';
import LibModal from '../../../../lib/LibModal';
import { AnimateSelectField, addOption } from '../../../../utils/AnimateInputField';
import { handelSetStatus } from '../FinUserSatementOperation';
import { useStatementInfo } from '../FinUserStatementProvider';

const statusOptions = [
    {
        id: 1,

        title: 'Active',
        value: 'active'
    },
    {
        id: 2,
        title: 'Inactive',
        value: 'inactive'
    },
    {
        id: 3,
        title: 'Pending',
        value: 'pending'
    }
];

const initialState = {
    status: ''
};

function ChangeStatusModal({ setOpen, statementData }) {
    const [formData, setFormData] = useState({
        ...initialState,
        status: statementData?.fac?.status || ''
    });

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const { handelReload } = useStatementInfo();

    const handelChange = (ev) => {
        const { name, value } = ev.target;
        setFormData({ ...formData, [name]: value });
    };

    const handelSave = () => {
        handelSetStatus(authInfo, statementData?.fac?.id, formData.status)
            .then(({ message }) => {
                setMessage(message);
                setFormData(initialState);
                setOpen(false);
                handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="Change Status" subTitle={statementData?.fac?.id} setOpen={setOpen}>
            <div className="status-modal-container">
                <div className="flex-col gap-20">
                    <UserInfoV2
                        img={statementData?.user?.photoUrl}
                        name={statementData?.user?.name}
                        email={statementData?.user?.email}
                    />
                    <AnimateSelectField
                        title="Select Status"
                        name="status"
                        unit="Status"
                        options={statusOptions.map((item) =>
                            addOption(item.id, item.title, item.value)
                        )}
                        formData={formData}
                        handelChange={handelChange}
                    />
                    <div className="flex-row align-right gap-10 w-100p">
                        <ButtonGray title="Cancel" evOnClick={() => setOpen(false)} />
                        <ButtonGreen title="Save" evOnClick={handelSave} />
                    </div>
                </div>
            </div>
        </LibModal>
    );
}

export default ChangeStatusModal;
