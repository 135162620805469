export function ButtonSave({ evOnClick, disabled }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className="ad-button button-save"
            disabled={disabled}
        >
            Save
        </button>
    );
}

export function ButtonCancel({ evOnClick, disabled }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className="ad-button button-cancel"
            disabled={disabled}
        >
            Cancel
        </button>
    );
}

function UpdaterButtons() {
    return null;
}

export default UpdaterButtons;
