import React from 'react';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import { useMessaging } from '../MessagingProvider';

const demoData = {
    name: '-------------------------',
    pageTitle: '---------------------------------',
    img: '',
    empty: true
};

function FetchPage() {
    const { searchPage } = useMessaging();
    const data = searchPage || demoData;
    return (
        <div className={`fetched-page ${data.empty ? 'placeholder-mode' : ''}`}>
            <UserInfoV3 name={data?.name} email={data?.pageTitle} img={data.img} />
        </div>
    );
}

export default FetchPage;
