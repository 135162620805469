import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { finGwtTransList } from '../../../../data';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import FinGatewayTransBody from './FinGatewayTransBody';
import FinGatewayTransHeader from './FinGatewayTransHeader';
import { handelLoadGwTrans } from './FinGatewayTransOperation';

function FinGatewayTransPage() {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const [gwTranData, setGwTranData] = useState([]);
    const [reloadKey, setReloadKey] = useState(Math.random());
    const [searchParams] = useSearchParams();

    // --Search Params
    const status = searchParams.get('status') || '';
    const endDate = searchParams.get('date-end') || dayjs().endOf('month').format('YYYY-MM-DD');
    const startDate =
        searchParams.get('date-start') || dayjs(endDate).startOf('month').format('YYYY-MM-DD');

    const handleReload = () => {
        setGwTranData(finGwtTransList);
        setReloadKey(Math.random());
    };

    useEffect(() => {
        if (startDate && endDate) {
            setGwTranData(finGwtTransList);
            handelLoadGwTrans(authInfo, status, startDate, endDate)
                .then(({ data }) => {
                    setGwTranData(data);
                })
                .catch((err) => {
                    setMessage(err.toString(0));
                });
        }
    }, [authInfo, endDate, setMessage, reloadKey, startDate, status]);

    useEffect(() => {
        PageProperties('Gateway Transaction');
    }, []);

    return (
        <div className="page-cont">
            <div className="gateway-trans-page">
                <FinGatewayTransHeader
                    handleReload={handleReload}
                    status={status}
                    startDate={startDate}
                    endDate={endDate}
                />

                <FinGatewayTransBody gwTranData={gwTranData} setReloadKey={setReloadKey} />
            </div>
        </div>
    );
}

export default FinGatewayTransPage;
