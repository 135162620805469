export function ButtonChange({ evOnClick, disabled }) {
    if (disabled) {
        return (
            <button
                onClick={null}
                type="button"
                className="user-action-button button-save disabled-view"
                disabled
            >
                Change
            </button>
        );
    }
    return (
        <button
            onClick={evOnClick}
            type="button"
            className="user-action-button button-save"
            disabled={disabled}
        >
            Change
        </button>
    );
}

export function ButtonCreate({ evOnClick, disabled }) {
    if (disabled) {
        return (
            <button
                onClick={null}
                type="button"
                className="user-action-button button-create disabled-view"
                disabled
            >
                Create
            </button>
        );
    }
    return (
        <button
            onClick={evOnClick}
            type="button"
            className="user-action-button button-save"
            disabled={disabled}
        >
            Create
        </button>
    );
}

export function ButtonNew({ evOnClick, disabled }) {
    if (disabled) {
        return (
            <button
                onClick={evOnClick}
                type="button"
                className="user-action-button button-create disabled-view"
                disabled
            >
                New User
            </button>
        );
    }
    return (
        <button
            onClick={evOnClick}
            type="button"
            className="user-action-button button-create"
            disabled={disabled}
        >
            New User
        </button>
    );
}

export function ButtonCancel({ evOnClick }) {
    // if (disabled) {
    //     return (
    //         <button
    //             onClick={evOnClick}
    //             type="button"
    //             className="user-action-button button-create disabled-view"
    //             disabled
    //         >
    //             New User
    //         </button>
    //     );
    // }
    return (
        <button onClick={evOnClick} type="button" className="user-action-button button-cancel">
            Cancel
        </button>
    );
}

function UserUpdaterButtons() {
    return null;
}

export default UserUpdaterButtons;
