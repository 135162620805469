import React from 'react';
import LibModal from '../../../lib/LibModal';
import AnimateInputField from '../../../utils/AnimateInputField';
import { useMessaging } from './MessagingProvider';
import FetchPage from './components/FetchPage';

function AddConversation({ setOpen, formData, handelChange }) {
    return (
        <LibModal title="Add Conversation" setOpen={setOpen}>
            <div className="messaging-modal flex-col gap-20 w-100p">
                <div className="messaging-page__id flex-row align-space-between w-100p">
                    <AnimateInputField
                        title="Page ID"
                        name="pageId"
                        formData={formData}
                        handelChange={handelChange}
                    />
                </div>
                <div className="messaging-page__access-token flex-row align-space-between w-100p">
                    <AnimateInputField
                        title="Access Token"
                        name="token"
                        formData={formData}
                        handelChange={handelChange}
                    />
                </div>
                <div className="messaging-page__fetch w-100p">
                    <FetchPage />
                </div>
                <div className="messaging-page-button w-100p flex-row flex-row align-right mt-10">
                    <button
                        onClick={() => console.log(formData)}
                        type="button"
                        className="user-action-button button-save"
                    >
                        Add
                    </button>
                </div>
            </div>
        </LibModal>
    );
}
function MessagingPageHeader() {
    const { open, setOpen, handleModalControl, formData, handelChange } = useMessaging();
    return (
        <div className="messaging-page__header flex-row position-middle align-space-between gap-20 w-100p">
            <div className="text-30 font-500">Messaging</div>
            <div className="flex-row position-middle gap-20 fill">
                <div className="user-custom-data gap-5">
                    <input
                        type="text"
                        className="custom-input"
                        placeholder="Please search your conversation"
                    />
                </div>
            </div>
            {/* <div className="content-reload">
                <div className="content-refresh-btn">
                    <button type="button" className="p-relative" onClick={null}>
                        <img src={icons.reloadL} alt="" />
                        <img src={icons.reloadF} alt="" />
                    </button>
                </div>
            </div> */}
            <div className="create-campaign">
                <button
                    type="button"
                    className="flex-row gap-5 align-center position-middle"
                    onClick={handleModalControl}
                >
                    <span>+</span>
                    <span>Add Conversation</span>
                </button>
            </div>
            {open ? (
                <AddConversation
                    setOpen={setOpen}
                    formData={formData}
                    handelChange={handelChange}
                />
            ) : (
                ''
            )}
        </div>
    );
}

export default MessagingPageHeader;
