/* eslint-disable jsx-a11y/label-has-associated-control */

export function AddressUserSearchField({ name, placeholder, formData, handelChange }) {
    return (
        <div className="">
            {/* <label htmlFor="fullName">{title}</label> */}
            <input
                autoComplete="off"
                name={name}
                value={formData[name] || ''}
                onChange={handelChange}
                placeholder={placeholder || name}
                type="text"
            />
        </div>
    );
}

export function AddressInputField({ title, name, placeholder, formData, handelChange }) {
    return (
        <div className="ad-input-area">
            <label htmlFor="fullName">{title}</label>
            <input
                name={name}
                value={formData[name] || ''}
                onChange={handelChange}
                placeholder={placeholder || name}
                type="text"
            />
        </div>
    );
}

export function AddressMobileInp({ options, formData, handelChange }) {
    return (
        <div className="ad-input-area">
            {options}
            <input
                name="mobile"
                value={formData.mobile || ''}
                onChange={handelChange}
                placeholder="8801234567890"
                type="text"
            />
        </div>
    );
}

export function AddressLineInp({ formData, handelChange }) {
    return (
        <div className="ad-input-area fill">
            <label htmlFor="address">Address Line</label>

            <textarea
                name="line1"
                value={formData.line1 || ''}
                onChange={handelChange}
                placeholder="Address Line"
                type="text"
            />
        </div>
    );
}

export function AddressCountryInp({ formData, handelChange, countries }) {
    return (
        <div className="ad-input-area">
            <label htmlFor="country">Country</label>

            <select name="country" onChange={handelChange} value={formData.country || ''}>
                <option value="">Select Country</option>
                {countries.map((item) => (
                    <option key={item.code} value={item.code}>
                        {item.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

export function TelephoneInput({ formData, handelChange, mode, option }) {
    let cls = '';

    if (mode.tel && mode.fax) {
        cls = 'ad-input-area ad-field-active';
    } else {
        cls = `ad-input-area fill ${mode.tel ? 'ad-field-active' : 'ad-field-inactive'}`;
    }

    return (
        <div className={cls}>
            <div className="flex-row align-space-between position-middle hidden-input-btn">
                <label htmlFor="telephone">Telephone</label>
                {option}
            </div>

            <input
                name="telephone"
                value={formData.value || ''}
                onChange={handelChange}
                placeholder="Enter telephone"
                type="text"
            />
        </div>
    );
}

export function FaxInput({ formData, handelChange, option, mode }) {
    let cls = '';
    if (mode.tel && mode.fax) {
        cls = 'ad-input-area ad-field-active';
    } else {
        cls = `ad-input-area fill ${mode.fax ? 'ad-field-active' : 'ad-field-inactive'}`;
    }

    return (
        <div className={cls}>
            <div className="flex-row align-space-between position-middle hidden-input-btn">
                <label htmlFor="fax">Fax</label>
                {option}
            </div>

            <input
                name="fax"
                value={formData.fax || ''}
                onChange={handelChange}
                placeholder="Enter fax"
                type="text"
            />
        </div>
    );
}

export default null;
