export function validateEmail(mail) {
    const found = mail.match(/^((?!\.)[\w_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm);

    if (found !== null && found[0] === mail) {
        return true;
    }

    return false;
}

function ValidatePatterns() {
    return null;
}

export default ValidatePatterns;
