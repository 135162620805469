import {
    ProductInputField,
    ProductSelectField,
    ProductTextAreaField,
    addOption
} from '../components/ProductEditorInputs';

const locations = [
    { id: 1, title: 'BD', value: 'bd' },
    { id: 2, title: 'SG', value: 'sg' },
    { id: 3, title: 'IN', value: 'in' },
    { id: 4, title: 'AU', value: 'au' },
    { id: 5, title: 'US', value: 'us' },
    { id: 6, title: 'CA', value: 'ca' },
    { id: 7, title: 'FR', value: 'fr' },
    { id: 8, title: 'DE', value: 'de' },
    { id: 9, title: 'UK', value: 'uk' }
];

const raidLevels = [
    {
        id: 0,
        title: 'RAID 0 - Soft',
        value: 'RAID0-SOFT'
    },
    {
        id: 1,
        title: 'RAID 0 - Hardware',
        value: 'RAID0-HARDWARE'
    },
    {
        id: 2,
        title: 'RAID 1 - Soft',
        value: 'RAID1-SOFT'
    },
    {
        id: 3,
        title: 'RAID 1 - Hardware',
        value: 'RAID1-HARDWARE'
    },
    {
        id: 4,
        title: 'RAID 5 - Soft',
        value: 'RAID5-SOFT'
    },
    {
        id: 5,
        title: 'RAID 5 - Hardware',
        value: 'RAID5-HARDWARE'
    },
    {
        id: 6,
        title: 'RAID 6 - Soft',
        value: 'RAID6-SOFT'
    },
    {
        id: 7,
        title: 'RAID 6 - Hardware',
        value: 'RAID6-HARDWARE'
    },
    {
        id: 8,
        title: 'RAID 10 - Soft',
        value: 'RAID10-SOFT'
    },
    {
        id: 9,
        title: 'RAID 10 - Hardware',
        value: 'RAID10-HARDWARE'
    }
];

function DedicatedServerInputs() {
    return (
        <div className="flex-row flex-wrap align-space-between inputs-cont">
            <ProductInputField
                title="CPU"
                name="dsCpu"
                info="EX: Intel Xeon-E 2136 - 6c/12t - 3.3GHz/4.5GHz"
            />

            <ProductInputField title="RAM" name="dsRam" info="EX: 32GB DDR4 ECC 2666MHz" />

            <ProductInputField title="Network" name="dsNetwork" info="EX: IPv4 x 3, 1 Gbps " />

            <div className="inp-fill">
                <ProductInputField
                    title="Storage"
                    name="dsStorage"
                    info="EX: 2× 500GB SSD NVMe Soft RAID"
                />
            </div>

            <div className="inp-fill-3">
                <ProductSelectField
                    title="Raid"
                    name="dsRaid"
                    unit="Level"
                    options={raidLevels?.map((item) => addOption(item.id, item.title, item.value))}
                />
            </div>

            <div className="inp-fill-3">
                <ProductInputField title="Server OS" name="dsOs" />
            </div>

            <div className="inp-fill-3">
                <ProductInputField title="Bandwidth" name="dsBandwidth" info="EX: 25 Tb/Month" />
            </div>

            <div className="inp-fill-3">
                <ProductSelectField
                    title="Location"
                    name="dsLocation"
                    unit="Location"
                    options={locations?.map((item) => addOption(item.id, item.title, item.value))}
                />
            </div>

            <div className="inp-fill-2">
                <ProductInputField title="IP" name="dsIp" />
            </div>

            <div className="inp-fill-2">
                <ProductInputField title="Hostname" name="dsHostname" />
            </div>

            <ProductTextAreaField title="Notes" name="note" />
        </div>
    );
}

export default DedicatedServerInputs;
