/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { images } from '../../../configs/Icons';
import WebMenus from '../../app/home/components/WebMenus';
import AdvMenu from '../menus/AdvMenu';
import { setProject, useAdvMenu } from '../menus/AdvMenuProvider';
import { useAuth } from './AuthProvider';

function AuthPage({ children }) {
    const { handelMenu, drawerOpen, activeProject, bodyRef } = useAdvMenu();
    const { authInfo } = useAuth();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    // --Menu Control
    useEffect(() => {
        handelMenu(pathname);
    }, [handelMenu, pathname, search]);

    // --Project Control
    useEffect(() => {
        setProject(navigate, activeProject);
    }, [activeProject, navigate]);

    if (authInfo.loading || authInfo?.error !== 0) {
        return (
            <div className="screen-center">
                <img src={images.loading} alt="Loading..." />
            </div>
        );
    }

    return (
        <div className="page">
            <WebMenus />
            <AdvMenu />
            <div ref={bodyRef} className={`menu-cont ${drawerOpen ? 'd-opened' : 'd-closed'}`}>
                {children}
            </div>
            <Tooltip id="myTooltip" style={{ maxWidth: '250px' }} />
        </div>
    );
}

export default AuthPage;
