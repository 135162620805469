import {
    ProductInputField,
    ProductSelectField,
    ProductTextAreaField,
    addOption
} from '../components/ProductEditorInputs';

const types = [
    {
        id: 1,
        title: 'Masking',
        value: 'maskking'
    },
    {
        id: 2,
        title: 'Non-Masking',
        value: 'nonMaskking'
    }
];

function SmsInputs() {
    return (
        <div className="inputs-cont flex-row flex-wrap align-space-between">
            <div className="inp-fill-2">
                <ProductInputField title="Validity" name="smsValidate" />
            </div>

            <div className="inp-fill-2">
                <ProductSelectField
                    title="Type Of SMS"
                    name="smsType"
                    options={types.map((item) => addOption(item.id, item.title, item.value))}
                />
            </div>

            <ProductTextAreaField title="Notes" name="note" />
        </div>
    );
}

export default SmsInputs;
