import axios from 'axios';
import URLParse from 'url-parse';
import { getApiUrl } from './Env';

class AxiosAPI {
    url = '';

    setHeaders = () => {
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Init-Id'] = localStorage.getItem('init-id');
        axios.defaults.headers.common.Locale = localStorage.getItem('locale');
        return this;
    };

    currentUserAuth = (authInfo) => {
        axios.defaults.headers.common['user-uid'] = authInfo?.userUid || '';
        axios.defaults.headers.common['refresh-token'] = authInfo?.refreshToken || '';
        return this;
    };

    setUrl = (url) => {
        this.url = url;
        return this;
    };

    setPath = (path, query = {}) => {
        const rUrl = `${getApiUrl()}/${path}`
            .split('/')
            .filter((item) => !!item)
            .join('/');

        const URL = new URLParse(rUrl, true);
        URL.query = { ...URL.query, ...query };
        this.url = URL.toString();

        return this;
    };

    getUrl = () => this.url;

    get = (options) => {
        this.setHeaders();

        return new Promise((resolve, reject) => {
            axios
                .get(this.url, options)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    post = (params, options) => {
        this.setHeaders();

        return new Promise((resolve, reject) => {
            axios
                .post(this.url, params, options)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export default (authInfo) => {
    const axiosApi = new AxiosAPI();

    if (authInfo) {
        axiosApi.currentUserAuth(authInfo);
    }

    return axiosApi;
};
