import { icons, menuIcon } from '../../../../configs/Icons';

const links = [
    {
        id: 'finance',
        miniTitle: 'F&A',
        title: 'Finance & Account',
        iconFill: menuIcon.financeFillS,
        iconLine: menuIcon.financeLineS,
        subMenu: [
            {
                id: 'finance/dashboard',
                title: 'Dashboard',
                iconL: menuIcon.dashboardLine,
                iconF: menuIcon.dashboardFill
            },
            {
                id: 'finance/accounts',
                title: 'Accounts',
                iconL: menuIcon.userLine,
                iconF: menuIcon.userFill
            },
            {
                id: 'finance/sell-invoice',
                title: 'Sell Invoices',
                iconL: menuIcon.invoiceLine,
                iconF: menuIcon.invoiceFill
            },
            {
                id: 'finance/sell-item',
                title: 'Sell Items',
                iconL: menuIcon.productLine,
                iconF: menuIcon.productFill
            },
            {
                id: 'finance/gateway-transactions',
                title: 'Gateway Transactions',
                iconL: menuIcon.gatewayTransactionsLine,
                iconF: menuIcon.gatewayTransactionsFill
            },
            {
                id: 'finance/transactions',
                title: 'Transactions',
                iconL: menuIcon.transactionsLine,
                iconF: menuIcon.transactionsFill
            },
            // {
            //     id: 'finance/user-statement',
            //     title: 'User Statement',
            //     iconL: menuIcon.userReportLine,
            //     iconF: menuIcon.userReportFill
            // },
            {
                id: 'finance/reports',
                title: 'Reports',
                iconL: menuIcon.reportsLine,
                iconF: menuIcon.reportsFill
            },
            { id: 'finance/api', title: 'API', iconL: menuIcon.codeLine, iconF: menuIcon.codeFill }
        ]
    },
    {
        id: 'users',
        miniTitle: 'USERS',
        title: 'USERS',
        iconFill: menuIcon.accountFillS,
        iconLine: menuIcon.accountLineS,
        subMenu: [
            {
                id: 'users/dashboard',
                title: 'Dashboard',
                iconL: menuIcon.dashboardLine,
                iconF: menuIcon.dashboardFill
            },
            {
                id: 'users/list',
                title: 'Users',
                iconF: menuIcon.userFill,
                iconL: menuIcon.userLine
            },
            {
                id: 'users/reports',
                title: 'Reports',
                iconL: menuIcon.reportsLine,
                iconF: menuIcon.reportsFill
            },
            {
                id: 'users/projects',
                title: 'Projects',
                iconL: menuIcon.codeLine,
                iconF: menuIcon.codeFill
            },
            { id: 'users/api', title: 'API', iconL: menuIcon.codeLine, iconF: menuIcon.codeFill },
            {
                id: 'users/docs/cover-page',
                title: 'DOCS',
                iconL: menuIcon.codeLine,
                iconF: menuIcon.codeFill
            },
            {
                id: 'users/messaging',
                title: 'Messaging',
                iconL: icons.defaultIcon,
                iconF: icons.defaultIcon
            }
        ]
    },
    {
        id: 'contract',
        miniTitle: 'CONT..',
        title: 'CONTACT',
        iconFill: menuIcon.contractFillS,
        iconLine: menuIcon.contractLineS,
        subMenu: [
            {
                id: 'contract/dashboard',
                title: 'Dashboard',
                iconL: menuIcon.dashboardLine,
                iconF: menuIcon.dashboardFill
            },
            {
                id: 'contract/domain',
                title: 'Domain',
                iconL: menuIcon.domainLine,
                iconF: menuIcon.domainFill
            },
            {
                id: 'contract/hosting',
                title: 'Hosting',
                iconL: menuIcon.hostingLine,
                iconF: menuIcon.hostingFill
            },
            {
                id: 'contract/dedicated-server',
                title: 'Dedicated Server',
                iconL: menuIcon.serverLine,
                iconF: menuIcon.serverFill
            },
            {
                id: 'contract/vps',
                title: 'VPS',
                iconL: menuIcon.vpsLine,
                iconF: menuIcon.vpsFill
            },
            {
                id: 'contract/package',
                title: 'Package',
                iconL: menuIcon.premiumLine,
                iconF: menuIcon.premiumFill
            },
            {
                id: 'contract/reports',
                title: 'Reports',
                iconL: menuIcon.reportsLine,
                iconF: menuIcon.reportsFill
            },
            {
                id: 'contract/api',
                title: 'API',
                iconL: menuIcon.codeLine,
                iconF: menuIcon.codeFill
            }
        ]
    }
];

export default links;
