import React, { useEffect, useRef, useState } from 'react';
import { icons } from '../../../configs/Icons';
import InstOption from '../../utils/InstOption';
import { useNotification } from './NotificationProvider';

function EachNotificationOption({ showMenu, myRef }) {
    const ref = myRef;
    if (!showMenu) return null;
    return (
        <div className="inst-option-cont each-notification" ref={ref}>
            <button type="button">Mark as Read</button>
            <button type="button">Remove this Notification</button>
        </div>
    );
}

function SeeAllNotification() {
    return (
        <div className="see-all-notification">
            <button type="button">See All</button>
        </div>
    );
}

function NotificationList() {
    const myRef = useRef(null);
    const iconRef = useRef(null);
    const { data } = useNotification();
    const [showMenu, setShowMenu] = useState(0);

    const handleShowMoreMenu = (id) => setShowMenu((cuData) => (cuData === id ? 0 : id));

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (myRef.current && !myRef.current?.contains(event?.target)) {
                setShowMenu(0);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="notification-list">
            {/* 1st 7 item show only */}
            {data.slice(0, 7).map((item) => (
                <div className="notification-item" key={item.id}>
                    <div
                        className={`notification-push ${
                            item.status === 'unread' ? 'unread' : 'read'
                        }`}
                    >
                        <div className="push-icon">
                            <img src={icons.pushNotification} alt="" />
                        </div>
                    </div>
                    <div className="notification-data">
                        <h3>{item.description}</h3>
                        <p>{item.date}</p>
                    </div>
                    <div className="p-relative">
                        <div
                            className={`notification-item-option  ${
                                showMenu === item.id ? 'active' : ''
                            }`}
                            tabIndex={-1}
                            role="button"
                            onClick={() => handleShowMoreMenu(item.id)}
                            ref={iconRef}
                        >
                            <img src={icons.option} alt="" />
                        </div>
                        <EachNotificationOption showMenu={showMenu === item.id} myRef={myRef} />
                    </div>
                </div>
            ))}
        </div>
    );
}

function NotificationCont({ show, isActive, handleAll, handleUnread }) {
    const [showMenu, setShowMenu] = useState(false);
    const handleShowMoreMenu = () => setShowMenu((cuData) => !cuData);

    if (!show) return null;

    return (
        <div className="notification-cont">
            <div className="notification-head">
                <div className="notification-title">Notification</div>
                <div className="p-relative">
                    <div
                        className={`notification-option ${showMenu ? 'active' : ''}`}
                        tabIndex={-1}
                        role="button"
                        onClick={handleShowMoreMenu}
                    >
                        <img src={icons.option} alt="" />
                    </div>
                    <InstOption showMenu={showMenu} setShowMenu={setShowMenu}>
                        <button type="button" className="mark-all-btn">
                            Mark all as Read
                        </button>
                        <button type="button" className="all-notification-btn">
                            Open Notification
                        </button>
                    </InstOption>
                </div>
            </div>
            <div className="notification-type">
                <button
                    type="button"
                    className={isActive === 'all' ? 'active' : ''}
                    onClick={handleAll}
                >
                    All
                </button>
                <button
                    type="button"
                    className={isActive === 'unread' ? 'active' : ''}
                    onClick={handleUnread}
                >
                    Unread
                </button>
            </div>
            <NotificationList />
            <SeeAllNotification />
        </div>
    );
}

function NotificationBox() {
    const ref = useRef(null);
    const { show, setShow, handleToggleClick, data, isActive, handleAll, handleUnread } =
        useNotification();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                setShow(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShow]);

    return (
        <div className="notification-box flex-row position-middle align-center" ref={ref}>
            <button
                type="button"
                className={`notification-bell ${data.length > 0 ? 'active' : ''}`}
                onClick={handleToggleClick}
            >
                <img src={icons.bell} alt="Notification Bell" />
            </button>
            <NotificationCont
                show={show}
                isActive={isActive}
                handleAll={handleAll}
                handleUnread={handleUnread}
            />
        </div>
    );
}

export default NotificationBox;
