export const tableData = {
    id: 1,
    title: 'Description',
    des: 'The academic module starts with a controlled database system. We call this feature as Yearbooks Management. Each year the administrator can open some virtual books on this software. It is planned for archiving the academic data on the database. Classes, Subjects, Marks, Grading System, Attendance, Examination & Results will be organized with this feature.',
    controllerName: 'ClassSetup',

    controllerTable: [
        {
            id: 1,
            output: 'html',
            feature: 'Yearbook',
            method: 'GET',
            route: '/manage/academy/yearbook/',
            fileMethod: 'yearBookList'
        },
        {
            id: 2,
            output: 'json',
            feature: 'Add new year',
            method: 'POST',
            route: '/manage/academy/yearbook/create/',
            fileMethod: 'yearBookCreate'
        },
        {
            id: 3,
            output: 'json',
            feature: 'Add class books',
            method: 'POST',
            route: '/manage/academy/yearbook/add-class-books/',
            fileMethod: 'yearBookAddClass'
        },
        {
            id: 4,
            output: 'json',
            feature: 'Add student books',
            method: 'POST',
            route: '/manage/academy/yearbook/add-student-books/',
            fileMethod: 'yearBookAddStudent'
        },
        {
            id: 5,
            output: 'json',
            feature: 'Add examination books',
            method: 'POST',
            route: '/manage/academy/yearbook/add-exam-books/',
            fileMethod: 'yearBookAddExam'
        }
    ],
    buttonActivity: [
        {
            id: 1,
            buttonType: `Add new year`,
            modalName: `New Year Modal`,
            formInputField: `N/A`
        },
        {
            id: 2,
            buttonType: `Add class books`,
            modalName: `New Class Modal`,
            formInputField: `N/A`
        },
        {
            id: 3,
            buttonType: `Add student books`,
            modalName: `New Student Book Modal`,
            formInputField: `N/A`
        },
        {
            id: 4,
            buttonType: `Add examination books`,
            modalName: `New Examination book Modal`,
            formInputField: `N/A`
        }
    ]
};

export default null;
