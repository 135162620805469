import { icons } from '../../../../configs/Icons';

const demoData = [
    {
        email: '-------------------',
        id: 10000115,
        name: '--------------',
        photoUrl: icons.avatar,
        demo: true
    }
];

function UserUpdaterSearchedUser({ show, data, handelUserClick }) {
    const arr = data || demoData;

    // if (!data) {
    //     return null;
    // }
    if (!show) {
        return null;
    }
    if (data && !data?.length) {
        return (
            <div className="searched-user-container">
                <div className="user-row selected">
                    <h2 className="user-updater_title">No User Found!</h2>
                </div>
            </div>
        );
    }
    return (
        <div className="searched-user-container">
            {arr?.map((item) => (
                <div
                    key={item.id}
                    role="button"
                    tabIndex={-1}
                    onClick={() => handelUserClick(item)}
                    className="user-row flex-row align-space-between"
                >
                    <div>
                        <div className="user-updater_avatar ">
                            <span>
                                <img src={icons.avatar} alt="avatar" />
                            </span>
                        </div>
                    </div>
                    <div>
                        <h2 className="user-updater_title">
                            <span>{item.name || '--'}</span>
                        </h2>
                        <p className="user-updater_sub-title">
                            <span>{item?.email || '--'}</span>
                        </p>
                    </div>
                </div>
            ))}{' '}
        </div>
    );
}

export default UserUpdaterSearchedUser;
