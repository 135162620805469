import React, { useState } from 'react';
import { ButtonOrange, ButtonRed } from '../../../../../configs/Buttons';
import { AnimateTextareaField } from '../../../../utils/AnimateInputField';
import { handleGtwTranStatusUpdate } from '../FinGatewayTransOperation';

function FinGwtStatusUpdateModal({ authInfo, setConfirm, setMessage, gtwTranData, setReloadKey }) {
    const { id } = gtwTranData;
    const [formData, setFormData] = useState({ status: 'cancel' || '', note: '' });

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };
    const handleGwtCancel = () => {
        handleGtwTranStatusUpdate(authInfo, id, formData)
            .then(({ message }) => {
                setMessage(message);
                setConfirm(null);
                setReloadKey((cuData) => cuData + 1);
            })
            .catch((error) => {
                setMessage(error.toString(0));
            });
    };
    return (
        <div className="inv-delete-body flex-col gap-25 align-center">
            <p className="text-center">Are you sure you want to cancel this transaction?</p>

            {/* <AnimateSelectField
                formData={formData}
                handelChange={handelChange}
                title="Select Title"
                name="status"
                required
                options={[
                    { id: 1, title: 'Success', value: 'success' },
                    { id: 2, title: 'Pending', value: 'pending' },
                    { id: 3, title: 'Cancel', value: 'cancel' }
                ]}
            /> */}
            <AnimateTextareaField
                // Reason why you want to cancel this transaction...
                title="Note"
                name="note"
                formData={formData}
                handelChange={handelChange}
                required
            />

            <div className="action flex-row align-center position-middle">
                <ButtonOrange evOnClick={() => setConfirm(null)} title="No" />

                <ButtonRed evOnClick={handleGwtCancel} title="Yes" />
            </div>
        </div>
    );
}

export default FinGwtStatusUpdateModal;
