/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonPurple } from '../../../configs/Buttons';
import { icons } from '../../../configs/Icons';
import { setProject, useAdvMenu } from './AdvMenuProvider';

export function addItem(id, icon, title) {
    return { id, icon, title };
}

function DropdownToggleMenu({ handleDropdownClick }) {
    const { activeProject } = useAdvMenu();

    return (
        <button type="button" className="dropdown-toggle" onClick={handleDropdownClick}>
            <div className="project-dropdown_option flex-row position-middle align-space-between">
                <div className="flex-row position-middle">
                    <div className="project-dropdown_logo flex-row align-center position-middle">
                        <img src={activeProject?.icon} alt={activeProject?.name} />
                    </div>
                </div>
                <div className="flex-row position-middle">
                    <h2 className="project-dropdown_title">
                        {activeProject?.name || 'Select Project'}
                    </h2>
                </div>
                <div className="flex-row position-middle align-right">
                    <img className="arrow" src={icons.downArrow} alt="arrow" />
                </div>
            </div>
        </button>
    );
}

function Option({ handleOptionClick, item }) {
    const { activeProject } = useAdvMenu();
    const isProjectActive = activeProject?.id === item?.id;

    return (
        <li
            role="button"
            tabIndex={-1}
            onClick={() => handleOptionClick(item)}
            className={`project-dropdown_option flex-row position-stretch align-space-between ${
                isProjectActive ? 'active' : ''
            }`}
        >
            <div className="flex-row position-middle">
                <div className="project-dropdown_logo flex-row align-center position-middle">
                    <img src={item.icon} alt="" />
                </div>
            </div>
            <div className="flex-row position-middle">
                <h2 className="project-dropdown_title">{item.title}</h2>
            </div>
            <div className="flex-row position-middle align-right">
                {isProjectActive && (
                    <div className="project-dropdown_tick-icon flex-row position-middle ">
                        <img src={icons.tickIcon} alt="tick" />
                    </div>
                )}
            </div>
        </li>
    );
}

function ProjectDropdown({ projects }) {
    const ref = useRef();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const { handleAddProject, handleManageProjects } = useAdvMenu();

    const handleDropdownClick = () => {
        setShow((st) => !st);
    };

    const handleOptionClick = (item) => {
        setProject(navigate, item);
        setShow(false);
    };

    useEffect(() => {
        function handelClickOutside(event) {
            if (ref?.current && !ref?.current.contains(event?.target)) {
                setShow(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [ref]);

    return (
        <div ref={ref} className={`project-dropdown-container ${show ? 'active' : ''}`}>
            <DropdownToggleMenu handleDropdownClick={handleDropdownClick} />

            <ul className="">
                {projects?.map((item) => (
                    <Option key={item.id} handleOptionClick={handleOptionClick} item={item} />
                ))}
                <div className="flex-row align-space-between m-5 manage-project-button col-gap-5">
                    <ButtonPurple
                        title="Add Project"
                        evOnClick={handleAddProject}
                        className="fill"
                    />
                    <button onClick={handleManageProjects} type="button" className="button">
                        <img src={icons.defaultIconWhite} alt="" className="w-100p" />
                    </button>
                </div>
            </ul>
        </div>
    );
}

export default ProjectDropdown;
