import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useInit } from '../../../../features/init/InitProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { UserInfoV2 } from '../../../../features/user-info/UserInfo';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, {
    AnimateSelectField,
    addOption
} from '../../../../utils/AnimateInputField';
import { handelAddApp } from '../UserProjectsOperation';

const durations = [
    {
        id: 1,
        title: '1 Month',
        value: 1
    },
    {
        id: 2,
        title: '2 Months',
        value: 2
    },
    {
        id: 3,
        title: '3 Months',
        value: 3
    },
    {
        id: 4,
        title: '4 Months',
        value: 4
    },
    {
        id: 5,
        title: '5 Months',
        value: 5
    },
    {
        id: 6,
        title: '6 Months',
        value: 6
    },
    {
        id: 7,
        title: '12 Months',
        value: 12
    },
    {
        id: 8,
        title: '24 Months',
        value: 24
    },
    {
        id: 9,
        title: '36 Months',
        value: 36
    },
    {
        id: 10,
        title: '48 Months',
        value: 48
    }
];

const initialState = {
    appTitle: '',
    duration: '',
    appKey: ''
};

function ProjectAppAddModal({ setOpen, data, setReloadKeyInfo }) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const { initInfo } = useInit();
    const { appList } = initInfo;
    const [formData, setFormData] = useState(initialState);

    const { id, title, faviconUrl, status } = data || {};

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    const handleAddButton = () => {
        setMessage('Adding App...');
        handelAddApp(authInfo, id, formData)
            .then(({ message }) => {
                setOpen(false);
                setFormData(initialState);
                setMessage(message);
                setReloadKeyInfo((cuData) => cuData + 1);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    // useEffect(() => {
    //     setSelectedUser(null);
    // }, []);

    // useEffect(() => {
    //     setFormData((cuData) => ({ ...cuData, ...selectedUser }));
    // }, [selectedUser]);

    return (
        <LibModal title="Add App" setOpen={setOpen}>
            <div className="project-app-add-modal">
                <UserInfoV2 img={faviconUrl} name={title} email={status} />

                <div>
                    <div className="flex-col gap-20">
                        <AnimateInputField
                            title="App Title"
                            name="appTitle"
                            required
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <AnimateSelectField
                            title="Duration"
                            unit="Duration"
                            name="duration"
                            formData={formData}
                            handelChange={handelChange}
                            options={durations?.map((item) =>
                                addOption(item.id, item.title, item.value)
                            )}
                        />
                        <AnimateSelectField
                            title="App Key"
                            name="appKey"
                            formData={formData}
                            handelChange={handelChange}
                            options={appList?.map((item) =>
                                addOption(item.id, item.title, item.id)
                            )}
                        />
                    </div>
                </div>

                <div className="flex-row align-right gap-10">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />
                    <ButtonGreen evOnClick={handleAddButton} title="Add" />
                </div>
            </div>
        </LibModal>
    );
}

export default ProjectAppAddModal;
