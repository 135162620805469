import LibModal from '../../../lib/LibModal';
import FundTransferO2CProvider from './OpFundTransferProvider';
import FundTransferBody from './components/FundTransferBody';

function OpFundTransferModal({ show, setOpen, customerId, currency, reload }) {
    if (!show) {
        return null;
    }

    return (
        <FundTransferO2CProvider customerId={customerId} currency={currency}>
            <div className="fund-transfer-cont">
                <LibModal title="Fund Transfer" setOpen={setOpen}>
                    <FundTransferBody
                        closeClick={() => setOpen(false)}
                        currency={currency}
                        reload={reload}
                    />
                </LibModal>
            </div>
        </FundTransferO2CProvider>
    );
}

export default OpFundTransferModal;
