import { showCurrency } from '../../../../utils/Show';
import { UserInfoV3 } from '../../../user-info/UserInfo';
import InfoItem from '../model/InfoItem';
import { FundInputField } from '../model/UserInfoInputs';

function Header() {
    return (
        <div className="header flex-row align-space-between position-middle o-hide">
            <div>
                <h2 className="header_title">Sender</h2>
            </div>
        </div>
    );
}

function SenderInfo({ handelChange, formData, userInfo, currency }) {
    const { photoUrl, name, email, facBalance, facId, facType } = userInfo || {};

    const inputAmount = formData.amount?.toString();

    const newBalance = inputAmount?.length
        ? parseFloat(facBalance) - parseFloat(inputAmount.replace(/,/g, ''))
        : 0;

    return (
        <div>
            <Header />

            <UserInfoV3
                img={photoUrl}
                name={name || '------------------'}
                email={email || '--------------------'}
            />

            <div className="info">
                <InfoItem title="AC Number" value={facId || '--'} />

                <InfoItem title="AC Type" value={facType || '--'} />

                <InfoItem
                    title="Balance"
                    value={`${currency || ''} ${showCurrency(facBalance || 0)}`}
                />

                <InfoItem
                    title="Amount"
                    value={
                        <FundInputField
                            handelChange={handelChange}
                            formData={formData}
                            name="amount"
                            placeholder={100}
                        />
                    }
                />

                <InfoItem
                    title="New Balance"
                    value={`${currency || ''} ${showCurrency(newBalance)}`}
                />
            </div>
        </div>
    );
}

export default SenderInfo;
