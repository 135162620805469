import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { contractHostingList } from '../../../../data';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageProperties from '../../../utils/PageProperties';
import { handleHostingListOpa, handleHostingStarredOpa } from './HostingOperation';
import HostingPageBody from './HostingPageBody';
import HostingPageHeader from './HostingPageHeader';

function HostingPage() {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const [searchParams] = useSearchParams();
    const [hostingListData, setHostingListData] = useState(contractHostingList);
    const [reloadKey, setReloadKey] = useState(null);

    const qParam = searchParams.get('q') || '';

    const handleReload = () => {
        setMessage('Loading...');
        setHostingListData(contractHostingList);
        setReloadKey(Math.random());
    };
    // Initially set page title
    useEffect(() => {
        PageProperties('Hosting');
    }, []);

    // -- Fetch data
    useEffect(() => {
        handleHostingListOpa(authInfo, qParam)
            .then(({ data }) => {
                setHostingListData(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, qParam, setMessage, reloadKey]);

    // -- when favorite yes or no
    const handleStarred = (ev, id) => {
        ev.stopPropagation();
        handleHostingStarredOpa(authInfo, id)
            .then(({ message }) => {
                setMessage(message);
                setReloadKey((cuData) => cuData + 1);
            })
            .catch((err) => {
                setHostingListData(contractHostingList);
                setMessage(err.toString(0));
            });
    };
    return (
        <div className="page-cont">
            <div className="hosting-page">
                <HostingPageHeader handleReload={handleReload} sParams={{ qParam }} />
                <HostingPageBody hostingListData={hostingListData} handleStarred={handleStarred} />
            </div>
        </div>
    );
}

export default HostingPage;
