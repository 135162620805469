import AxiosAPI from '../../../utils/AxiosAPI';

export function handelMakePayment(authInfo, userId, invoiceId, amount) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/transactions/make-payment/`)
            .post({ userId, invoiceId, amount })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelMakeVatPayment(authInfo, userId, invoiceId, type, amount, note) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/transactions/make-vat-payment/`)
            .post({ userId, invoiceId, type, amount, note })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelMakeRefund(authInfo, userId, invoiceId, type, amount) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/transactions/make-refund/`)
            .post({ userId, invoiceId, type, amount })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelMakeActive(authInfo, invoiceId) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/invoice/${invoiceId}/make-active/`)
            .post({ status: 'active' })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelMakeReopen(authInfo, invoiceId, note) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/invoice/${invoiceId}/make-reopen/`)
            .post({ status: 'open', note })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelMakeDelete(authInfo, invoiceId, note) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/invoice/${invoiceId}/make-delete/`)
            .post({ status: 'delete', note })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelMakeDuplicate(authInfo, invoiceId, options) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/invoice/${invoiceId}/make-duplicate/`)
            .post({ ...options })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelSendEmail(authInfo, invoiceId, emails, subject, body) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/invoice/${invoiceId}/send-email/`)
            .post({ emails, subject, body })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelSendSms(authInfo, invoiceId, mobileNumbers, message) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/invoice/${invoiceId}/send-sms/`)
            .post({ mobileNumbers, message })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelSendPush(authInfo, invoiceId, title, description, logo, image) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/finance/invoice/${invoiceId}/send-push/`)
            .post({ title, description, logo, image })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default null;
