import React from 'react';
import NotificationBox from './NotificationBox';
import NotificationProvider from './NotificationProvider';
import './css/style.css';

function Notification() {
    return (
        <NotificationProvider>
            <NotificationBox />
        </NotificationProvider>
    );
}

export default Notification;
