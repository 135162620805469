import { icons } from '../configs/Icons';

export const apiPageMenu = [
    {
        id: 10000001,
        icon: icons.campaignBg,
        title: 'Campaign',
        subTitle: 'View in Doc'
    },
    {
        id: 10000002,
        icon: icons.campaignBg,
        title: 'Configure Domain',
        subTitle: 'View in Doc'
    },
    {
        id: 10000003,
        icon: icons.campaignBg,
        title: 'Get Campaign Status',
        subTitle: 'View in Doc'
    },
    {
        id: 10000004,
        icon: icons.campaignBg,
        title: 'Report',
        subTitle: 'View in Doc'
    },
    {
        id: 10000005,
        icon: icons.campaignBg,
        title: 'Event Trigger',
        subTitle: 'View in Doc'
    }
];

export default null;
