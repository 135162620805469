import ReactHtmlParser from 'react-html-parser';
import Show, {
    UpperCase,
    calculateDiscountPercentage,
    showCurrency,
    showValueWithUnit
} from '../../../../../../utils/Show';
import { DeleteBtn, EditBtn } from '../../_models/ActionButtons';
import PriceDiscountInfo from '../../_models/PriceDiscountInfo';

function FinInvoiceCustomProduct({ item, sl, isInvoiceActive, onClickEdit, onClickDelete }) {
    const { title, price, priceOffer, quantity, unitName, properties, vat } = item || {};

    const { cpDescription } = properties || {};

    const priceDiscount = calculateDiscountPercentage(price, priceOffer);

    return (
        <>
            <tr className={!isInvoiceActive ? 'clickable' : ''} onClick={null}>
                <td>{sl}</td>

                <td>
                    <h4>{title}</h4>
                    <div>
                        <Show show={cpDescription}>
                            <b>{ReactHtmlParser(cpDescription)}</b>
                        </Show>
                    </div>
                </td>

                <td>
                    <span className="price-amount">{showCurrency(price)}</span> <br />
                    {unitName.length ? `/${UpperCase(unitName)}` : null}
                </td>

                <td>{showValueWithUnit(quantity, unitName)}</td>

                <td>
                    <span className="price-amount">{showCurrency(price * quantity)}</span>

                    <PriceDiscountInfo priceDiscount={priceDiscount} vat={vat} />

                    <div className="info-action-button flex-col position-middle position-space-between">
                        <EditBtn onClickEdit={onClickEdit} />
                        <DeleteBtn onClickDelete={onClickDelete} />
                    </div>
                </td>
            </tr>
        </>
    );
}

export default FinInvoiceCustomProduct;
