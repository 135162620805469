/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import UserChange from './UserChange';
import UserCreate from './UserCreate';
import UserUpdaterProvider from './UserUpdaterProvider';

function Container({ show, children }) {
    return <div className={show ? 'show' : 'hide'}>{children}</div>;
}

// function SearchFiled({ show, onChange, searchRef, users, handelUserClick }) {
//     return (
//         <div className="user-input-area">
//             <label htmlFor="user">Search User</label>

//             <input ref={searchRef} onChange={onChange} placeholder="Name, email, ID" type="text" />

//             <UserUpdaterSearchedUser show={show} data={users} handelUserClick={handelUserClick} />
//         </div>
//     );
// }

function UserUpdater({ evCloseClick, user, handelChangeUser }) {
    const [mode, setMode] = useState(false);

    return (
        <UserUpdaterProvider>
            <div className="user-updater-container" style={{ overflow: mode ? 'hidden' : 'unset' }}>
                <Container show={!mode}>
                    <UserChange
                        setMode={setMode}
                        evCloseClick={evCloseClick}
                        user={user}
                        handelChangeUser={handelChangeUser}
                    />
                </Container>

                <Container show={mode}>
                    <UserCreate setMode={setMode} />
                </Container>
            </div>
        </UserUpdaterProvider>
    );
}

export default UserUpdater;
