import InstOption from '../../../../utils/InstOption';
import { useInvoiceUpdate } from '../FinInvoiceUpdateProvider';

function ButtonCommonView({ evOnclick, title }) {
    return (
        <button onClick={evOnclick} type="button">
            <span>{title}</span>
        </button>
    );
}

function FinInvoicePrintOptions({ showMenu, setShowMenu }) {
    const { setPrintVersion } = useInvoiceUpdate() || { setPrintVersion: null };

    const handelPrintVersion = (v) => {
        if (v) {
            setPrintVersion(v);
            setTimeout(() => {
                window.print();
            }, 0);
        }
    };

    return (
        <div className="print-option">
            <InstOption showMenu={showMenu} setShowMenu={setShowMenu}>
                <ButtonCommonView evOnclick={() => handelPrintVersion(1)} title="With Headers" />
                <ButtonCommonView evOnclick={() => handelPrintVersion(2)} title="Without Headers" />
            </InstOption>
        </div>
    );
}

export default FinInvoicePrintOptions;
