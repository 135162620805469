import { icons } from '../../../../configs/Icons';

function SelectedUser({ data, cancelClick }) {
    const isShow = data && Object.keys(data)?.length !== 0;

    const cName = isShow ? 'show' : 'p-mode';

    return (
        <div
            className={`searched-user-row selected flex-row align-space-between position-middle ${cName}`}
        >
            <div>
                <div className="user-updater_avatar ">
                    <span>
                        <img src={data?.photoUrl} alt="avatar" referrerPolicy="no-referrer" />
                    </span>
                </div>
            </div>
            <div style={{ width: isShow ? 'calc(100% - 100px)' : 'calc(100% - 60px)' }}>
                <div className="flex-row position-middle align-space-between">
                    <h2 className="title">
                        <span>{data?.name || '-------------------'}</span>
                    </h2>
                    {/* <p className="tag new">New</p> */}
                </div>

                <p className="sub-title">
                    <span>{data?.email || '------------------------'}</span>
                </p>
            </div>

            {isShow ? (
                <div
                    onClick={() => cancelClick(null)}
                    role="button"
                    tabIndex={-1}
                    className="flex-row align-right"
                >
                    <img src={icons.crossRed} alt="cross" />
                </div>
            ) : null}
        </div>
    );
}

export default SelectedUser;
