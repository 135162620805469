import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import URLParse from 'url-parse';
import ProjectAddModal from '../../app/users/projects/modals/ProjectAddModal';
import links from './components/links';
import projects from './components/projects';

const InitContext = createContext();

export function useAdvMenu() {
    return useContext(InitContext);
}

export function getBreadcrumb() {
    const cuPath = window.location.pathname;
    return cuPath.split('/').slice(1, 3);
}

export function setProject(navigate, project) {
    const currentUrl = window.location.href;
    const URL = new URLParse(currentUrl, true);

    if (project?.id) {
        URL.query['project-id'] = project.id;

        const newUrl = URL.toString();
        if (newUrl !== currentUrl) {
            navigate(newUrl.split(URL.origin)[1]);
        }
    }
}
export function getProjectId() {
    const URL = new URLParse(window.location.href, true);
    return parseInt(URL.query['project-id'] || 0, 10);
}

export function mkUrl(path) {
    const URL = new URLParse(window.location.href, true);
    URL.pathname = path;
    return URL.toString();
}

export function mkRelativeUrl(path, queryParams = {}) {
    const URL = new URLParse(window.location.href, true);
    URL.pathname = path;
    URL.query = { 'project-id': getProjectId(), ...queryParams };

    return URL.toString().split(URL.origin)[1];
}

function AdvMenuProvider({ children }) {
    // Project Operation
    const [activeProject, setActiveProject] = useState(projects[0]);

    // project add
    const [showProjectAddModal, setShowProjectAddModal] = useState(false);

    // Menu Operation
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [activeMenu, setActiveMenu] = useState(links[0]);

    const bodyRef = useRef();

    // Function to update the bodyWidth
    const updateWidth = () => {
        if (bodyRef.current) {
            const newWidth = parseInt(bodyRef.current.offsetWidth, 10);
            if (newWidth < 1230) {
                setDrawerOpen(false);
            } else if (newWidth > 1460) {
                setDrawerOpen(true);
            }
        }
    };

    useEffect(() => {
        // Add event listener on component mount
        window.addEventListener('resize', updateWidth);

        // Call the updateWidth function initially
        updateWidth();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    const value = useMemo(() => {
        const toggle = () => {
            setDrawerOpen((st) => !st);
        };

        const handelMenu = (location) => {
            // --Menu
            setActiveMenu((cuData) => {
                const loInfo = location.split('/').filter((d) => !!d);
                const menuSelected = links.find((data) => data.id === loInfo[0]);
                if (cuData.location !== location && menuSelected) {
                    const subMenu = menuSelected.subMenu.map((data) => ({
                        ...data,
                        active: data.id === `${loInfo[0]}/${loInfo[1]}`
                    }));

                    return { ...menuSelected, subMenu, location };
                }
                return cuData;
            });

            // --Project
            setActiveProject(() => {
                const id = getProjectId();
                return projects.find((item) => item.id === id);
            });
        };

        const handleAddProject = () => {
            setShowProjectAddModal(true);
        };
        const handleManageProjects = () => {
            console.log('manage Projects');
        };

        return {
            links,
            drawerOpen,
            setDrawerOpen,
            toggle,
            activeMenu,
            setActiveMenu,
            handelMenu,
            projects,
            activeProject,
            bodyRef,
            handleAddProject,
            handleManageProjects
        };
    }, [activeMenu, activeProject, drawerOpen]);

    return (
        <InitContext.Provider value={value}>
            {children}
            {showProjectAddModal ? <ProjectAddModal setOpen={setShowProjectAddModal} /> : null}
        </InitContext.Provider>
    );
}

export default AdvMenuProvider;
