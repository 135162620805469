const durationOptions = [
    {
        id: 1,
        title: '1 Month',
        value: 1
    },
    {
        id: 2,
        title: '2 Month',
        value: 2
    },
    {
        id: 3,
        title: '3 Month',
        value: 3
    },
    {
        id: 4,
        title: '4 Month',
        value: 4
    },
    {
        id: 5,
        title: '5 Month',
        value: 5
    },
    {
        id: 6,
        title: '6 Month',
        value: 6
    },
    {
        id: 7,
        title: '12 Month',
        value: 12
    },
    {
        id: 8,
        title: '24 Month',
        value: 24
    },
    {
        id: 9,
        title: '36 Month',
        value: 36
    },
    {
        id: 10,
        title: '48 Month',
        value: 48
    },
    {
        id: 11,
        title: '60 Month',
        value: 60
    },
    {
        id: 12,
        title: '120 Month',
        value: 120
    }
];

export default durationOptions;
