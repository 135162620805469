import { ProductInputField, ProductTextAreaField } from '../components/ProductEditorInputs';

// pushQuantity
// pushValidate

function PushInputs() {
    return (
        <div className="inputs-cont flex-row flex-wrap align-space-between">
            <div className="inp-fill-2">
                <ProductInputField title="Number of Subscribers" name="pushQuantity" />
            </div>

            <div className="inp-fill-2">
                <ProductInputField title="Validate" name="pushValidate" />
            </div>

            <ProductTextAreaField title="Notes" name="note" />
        </div>
    );
}

export default PushInputs;
