import React, { useState } from 'react';
import { ButtonGray, ButtonRed } from '../../../../../configs/Buttons';
import { AnimateSelectField, AnimateTextareaField } from '../../../../utils/AnimateInputField';
import { handleUserStatusChange } from '../UserListOperation';

function UserStatusChangeModal({ authInfo, setConfirm, setMessage, itemData, setReloadKey }) {
    const { status, id } = itemData;
    const [formData, setFormData] = useState({ status: status || '', note: '' });

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    const handleUserStatusUpdate = () => {
        handleUserStatusChange(authInfo, id, formData)
            .then(({ message }) => {
                setMessage(message);
                setConfirm(null);
                setReloadKey((cuData) => cuData + 1);
            })
            .catch((error) => {
                setMessage(error.toString(0));
            });
    };
    return (
        <div className="inv-delete-body flex-col gap-25 align-center">
            <p className="text-center">Are you sure you want to change status this user?</p>

            <AnimateSelectField
                formData={formData}
                handelChange={handelChange}
                title="Select Status"
                name="status"
                required
                options={[
                    { id: 1, title: 'Active', value: 'active' },
                    { id: 2, title: 'Inactive', value: 'Inactive' }
                ]}
            />
            <AnimateTextareaField
                // Reason why you want to cancel this transaction...
                title="Note"
                name="note"
                formData={formData}
                handelChange={handelChange}
                required
            />

            <div className="action flex-row align-center position-middle">
                <ButtonGray evOnClick={() => setConfirm(null)} title="Cancel" />
                <ButtonRed evOnClick={handleUserStatusUpdate} title="Yes" />
            </div>
        </div>
    );
}

export default UserStatusChangeModal;
