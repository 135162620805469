export const finTransList = [
    {
        id: 1000001,
        type: '-------',
        invoice: '-------',
        currency: '-------',
        amount: '-------',
        amountUsd: '-------',
        status: '-------',
        acDebit: '-------',
        acCredit: '-------',
        user: {
            id: '-------',
            name: '-------------------',
            photoUrl: '',
            email: '----------------------'
        },
        demo: true
    },
    {
        id: 1000002,
        type: '-------',
        invoice: '-------',
        currency: '-------',
        amount: '-------',
        amountUsd: '-------',
        status: '-------',
        acDebit: '-------',
        acCredit: '-------',
        user: {
            id: '-------',
            name: '-------------------',
            photoUrl: '',
            email: '----------------------'
        },
        demo: true
    },
    {
        id: 1000003,
        type: '-------',
        invoice: '-------',
        currency: '-------',
        amount: '-------',
        amountUsd: '-------',
        status: '-------',
        acDebit: '-------',
        acCredit: '-------',
        user: {
            id: '-------',
            name: '-------------------',
            photoUrl: '',
            email: '----------------------'
        },
        demo: true
    },
    {
        id: 1000004,
        type: '-------',
        invoice: '-------',
        currency: '-------',
        amount: '-------',
        amountUsd: '-------',
        status: '-------',
        acDebit: '-------',
        acCredit: '-------',
        user: {
            id: '-------',
            name: '-------------------',
            photoUrl: '',
            email: '----------------------'
        },
        demo: true
    },
    {
        id: 1000005,
        type: '-------',
        invoice: '-------',
        currency: '-------',
        amount: '-------',
        amountUsd: '-------',
        status: '-------',
        acDebit: '-------',
        acCredit: '-------',
        user: {
            id: '-------',
            name: '-------------------',
            photoUrl: '',
            email: '----------------------'
        },
        demo: true
    }
];

export default null;
