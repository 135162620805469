import React from 'react';

const Header = ({ title, subTitle, handleCloseClick }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">
                {title}
                <span>{subTitle}</span>
            </div>
            <div className="modal-box-close">
                <button className="close" type="button" onClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

const Body = ({ children }) => (
    <div className="modal-box-body">
        <div>{children}</div>
    </div>
);

function LibModal({ children, title, subTitle, header, setOpen }) {
    const body = document.querySelector('body');
    body.classList.add('stop-scroll');

    const handleCloseClick = () => {
        setOpen(false);
        body.classList.remove('stop-scroll');
    };

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                {header || (
                    <Header title={title} subTitle={subTitle} handleCloseClick={handleCloseClick} />
                )}

                <Body>{children}</Body>
            </div>
        </div>
    );
}

export default LibModal;
