import { useState } from 'react';
import { ButtonOrange, ButtonRed } from '../../../../../../configs/Buttons';
import { useTemplate } from '../../../../../features/template/TemplateProvider';
import { AnimateTextareaField } from '../../../../../utils/AnimateInputField';

export const RemoveDomainAccess = () => {
    const { setConfirm } = useTemplate();
    const [formData, setFormData] = useState({ status: 'cancel' || '', note: '' });

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    const handleGwtCancel = () => {};
    return (
        <div className="remove-domain-modal flex-col gap-25 align-center">
            <p className="text-center">
                Are you certain that you want to revoke access from this domain?
            </p>
            <AnimateTextareaField
                // Reason why you want to cancel this transaction...
                title="Note"
                name="note"
                formData={formData}
                handelChange={handelChange}
                required
            />

            <div className="action flex-row align-center position-middle gap-10">
                <ButtonOrange evOnClick={() => setConfirm(null)} title="No" />

                <ButtonRed evOnClick={handleGwtCancel} title="Yes" />
            </div>
        </div>
    );
};

export default null;
