import AxiosAPI from '../../../utils/AxiosAPI';

export const handleDomainOperation = (authInfo, domain) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contracts-domain/list/`, { domain })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

export const handleDomainStarredOpa = (authInfo, domainContractId) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contracts-domain/update-star/${domainContractId}/`)
            .post()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

export const handleDomainAssignUserOpe = (authInfo, domainContractId, formData) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contracts-domain/update-user/${domainContractId}/`)
            .post(formData)
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

// Domain Controller User List Assign User
export const handleDomainControllerOpe = (authInfo, domainId, selectedUserId, formData) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contracts-domain/accessible-user/${domainId}/${selectedUserId}/`)
            .post({ ...formData })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

// when click domain tr show domain info
export const handleDomainInfoOpe = (authInfo, domainId) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api/contracts-domain/info/${domainId}/view`)
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
export default null;
