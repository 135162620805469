import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, { AnimateTextareaField } from '../../../../utils/AnimateInputField';
import { handelSendSms } from '../FinInvoiceOperation';

const initialState = {
    number: '',
    message: ''
};

function FinInvoiceSendSms({ setOpen, invoiceId, handelReload }) {
    const [formData, setFormData] = useState(initialState);

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    // handel send button
    const handelSend = () => {
        setMessage('Sending...');

        handelSendSms(authInfo, invoiceId, formData.number, formData.message)
            .then(({ message }) => {
                setMessage(message);
                setFormData(initialState);
                setOpen(false);
                handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="SMS" setOpen={setOpen}>
            <div className="sms-send-cont">
                <div>
                    <AnimateInputField
                        title="Number"
                        name="number"
                        formData={formData}
                        handelChange={handelChange}
                    />

                    <AnimateTextareaField
                        title="Message"
                        name="message"
                        formData={formData}
                        handelChange={handelChange}
                    />
                </div>
                <div className="action flex-row align-right">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />

                    <ButtonGreen evOnClick={handelSend} title="Send" />
                </div>
            </div>
        </LibModal>
    );
}

export default FinInvoiceSendSms;
