import LibModal from '../../../lib/LibModal';
import CustomerFtC2OProvider, { useFtC2O } from './CustomerFtC2OProvider';
import CustomerFtO2CProvider, { useFtO2C } from './CustomerFtO2CProvider';
import FundTransferBody from './components/FundTransferBody';

function C2O({ setOpen, currency, reload }) {
    const ft = useFtC2O();

    return (
        <div className="fund-transfer-cont">
            <LibModal title="Fund Transfer" setOpen={setOpen}>
                <FundTransferBody
                    closeClick={() => setOpen(false)}
                    currency={currency}
                    ft={ft}
                    reload={reload}
                />
            </LibModal>
        </div>
    );
}

function O2C({ setOpen, currency, reload }) {
    const ft = useFtO2C();

    return (
        <div className="fund-transfer-cont">
            <LibModal title="Fund Transfer" setOpen={setOpen}>
                <FundTransferBody
                    closeClick={() => setOpen(false)}
                    currency={currency}
                    ft={ft}
                    reload={reload}
                />
            </LibModal>
        </div>
    );
}

function CustomerFundTransferModal({ show, mode, setOpen, customerId, currency, reload }) {
    if (!show || !mode) {
        return null;
    }

    if (mode === 'debit') {
        return (
            <CustomerFtC2OProvider customerId={customerId} currency={currency}>
                <C2O setOpen={setOpen} currency={currency} reload={reload} />
            </CustomerFtC2OProvider>
        );
    }

    return (
        <CustomerFtO2CProvider customerId={customerId} currency={currency}>
            <O2C setOpen={setOpen} currency={currency} reload={reload} />
        </CustomerFtO2CProvider>
    );
}

export default CustomerFundTransferModal;
