import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { facAccounts } from '../../../../data';
import { useAuth } from '../../../features/auth/AuthProvider';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import PageController from '../../../utils/PageController';
import {
    handelLoadFac,
    handleSaveBankAccOperation,
    handleUpdateBankAccOperation
} from './FinAccountsOperation';

const FinAccountsContext = createContext();

export const useFinAccounts = () => useContext(FinAccountsContext);

const initState = {
    bankName: '',
    accountName: '',
    accountNumber: '',
    branchName: '',
    routingNumber: '',
    swiftCode: '',
    currency: ''
};
function FinAccountsProvider({ children }) {
    // Build in hook
    const [reloadKey, setReloadKey] = useState(null);
    const [bankAcModal, setBankAcModal] = useState(false);
    // -- Bank Account edit mode
    const [editMode, setEditMode] = useState(0);
    const [formData, setFormData] = useState(initState);
    const [facList, setFacList] = useState(facAccounts);

    // -- React Dom event and hook
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Custom hook
    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();
    const { pageAttr } = PageController();

    // --Search Params
    const accountType = searchParams.get('account-type') || 'customer';
    const currency = searchParams.get('currency') || '';
    const havingTrans = searchParams.get('transaction') || 'having-transaction';

    // -- Use hook
    // added bank account value when value onchange
    useEffect(() => {
        setFacList(facAccounts);
        handelLoadFac(authInfo, accountType, currency, havingTrans, pageAttr.pageNo, pageAttr.cpp)
            .then(({ data }) => {
                setFacList(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, setMessage, reloadKey, pageAttr, accountType, currency, havingTrans]);

    // -- Using Memo
    const value = useMemo(() => {
        // -- New Bank Account added modal show
        const handleShowBankModal = () => {
            setFormData({});
            setBankAcModal((cuData) => !cuData);
        };

        // -- Bank Account Form added add when change
        const handelChange = (ev) => {
            const { name } = ev.target;
            if (name) {
                setFormData((d) => ({ ...d, [name]: ev.target.value }));
            }
        };

        // -- Reload Handler
        const handleReload = () => {
            setFacList(facAccounts);
            setReloadKey(Math.random());
        };

        // -- Click search then
        const handleFilterSearch = (filterVal) => {
            navigate(
                mkRelativeUrl('/finance/accounts/', {
                    'account-type': filterVal.accountType,
                    currency: filterVal.currency,
                    transaction: filterVal.havingTrans
                })
            );
        };
        const handleSaveBankAc = () => {
            setMessage('Saving....');
            handleSaveBankAccOperation(authInfo, formData)
                .then(({ message }) => {
                    setBankAcModal(false);
                    setMessage(message);
                    setFormData(initState);
                    setReloadKey(Math.random());
                })
                .catch((err) => {
                    setMessage(err.toString(0));
                });
        };

        // -- Update Bank Account Handler
        const handleUpdateAc = () => {
            setMessage('Updating.....');
            handleUpdateBankAccOperation(authInfo, formData)
                .then(({ message }) => {
                    setMessage(message);
                    setEditMode(false);
                    setFormData(initState);
                    setReloadKey(Math.random());
                })
                .catch((err) => {
                    setMessage(err.toString(0));
                });
        };

        return {
            authInfo,

            // -- get params
            sParams: {
                accountType,
                currency,
                havingTrans
            },
            // hook's
            editMode,
            setEditMode,
            facList,
            formData,
            setFormData,
            bankAcModal,
            setBankAcModal,
            reloadKey,
            setMessage,

            // -- handler's
            handleFilterSearch,
            handleShowBankModal,
            handelChange,
            handleReload,
            handleSaveBankAc,
            handleUpdateAc
        };
    }, [
        accountType,
        authInfo,
        bankAcModal,
        currency,
        editMode,
        facList,
        formData,
        havingTrans,
        navigate,
        reloadKey,
        setMessage
    ]);

    return <FinAccountsContext.Provider value={value}>{children}</FinAccountsContext.Provider>;
}

export default FinAccountsProvider;
