import React from 'react';
import HostingTable from './components/DedServerTable';

function DedServerPageBody() {
    return (
        <div className="dedicated-server-page__body">
            <HostingTable />
        </div>
    );
}

export default DedServerPageBody;
