import { ButtonPurple } from '../../../../../configs/Buttons';
import { showCurrency } from '../../../../utils/Show';

function UserBalanceInfo({ currency, balance, evSendClick }) {
    return (
        <div className="flex-row align-space-between position-middle fund-transfer-balance-info">
            <div>
                <h2 className="title">Balance</h2>
            </div>
            <div className="flex-row align-right position-middle">
                <div className="fund-transfer_balance-view op flex-row  position-middle align-center">
                    <p>
                        {currency} {showCurrency(balance)}
                    </p>
                </div>

                <ButtonPurple evOnClick={evSendClick} title="Send" />
            </div>
        </div>
    );
}

export default UserBalanceInfo;
