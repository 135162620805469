import React from 'react';
import UserInfo from '../../../../features/user-info/UserInfo';
import { GetDate, UpperCase, showCurrency } from '../../../../utils/Show';

const demoArr = [
    {
        tranId: 1688206929,
        time: 1688206929,
        description: '---------------',
        amountDebit: 521132102,
        amountCredit: 214254152341,
        empty: true
    }
];

function Tr({ item }) {
    return (
        <tr className={item?.empty ? 'placeholder-mode' : ''}>
            <td>
                <span>{item?.tranId}</span>
            </td>
            <td>
                <span>{GetDate(item?.time)}</span>
            </td>
            <td>
                <span>{item?.description}</span>
            </td>
            <td>
                <span>{showCurrency(item?.amountDebit || 0)}</span>
            </td>
            <td>
                <span>{showCurrency(item?.amountCredit || 0)}</span>
            </td>
            <td>
                <span>{showCurrency(item?.balance || 0)}</span>
            </td>
        </tr>
    );
}

function FinUserStatementTable({ transactions }) {
    const arr = transactions || demoArr;
    return (
        <table className="inv-table" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>SL</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                </tr>
            </thead>
            <tbody>
                {arr?.map((item) => (
                    <Tr key={item?.tranId} item={item} />
                ))}
            </tbody>
        </table>
    );
}

function FinUserStatementInfoRight({ fac, user }) {
    return (
        <div className="fin-statement-right-col w-100p">
            <div className="fin-user-statement-info">
                <div className="statement-info-title font-500 text-22">Information</div>
                <div className="statement-info-data">
                    {user?.id ? (
                        <div className="w-100p flex-row align-space-between">
                            <span className="d-block">User ID:</span>
                            <span className="d-block">#{user?.id || '-------------'}</span>
                        </div>
                    ) : null}
                    <div className="w-100p flex-row align-space-between">
                        <span className="d-block">Date of Opening:</span>
                        <span className="d-block">
                            {GetDate(fac?.timeAccOpening) || '-------------'}
                        </span>
                    </div>
                    <div className="w-100p flex-row align-space-between">
                        <span className="d-block">AC Number:</span>
                        <span className="d-block">{fac?.id || '-------------'}</span>
                    </div>
                    <div className="w-100p flex-row align-space-between">
                        <span className="d-block">Status:</span>
                        <span className="d-block">{UpperCase(fac?.status) || '-------------'}</span>
                    </div>
                    <div className="w-100p flex-row align-space-between">
                        <span className="d-block">Currency:</span>
                        <span className="d-block">{fac?.currency || '-------------'}</span>
                    </div>
                    <div className="w-100p flex-row align-space-between">
                        <span className="d-block">Last Transaction:</span>
                        <span className="d-block">
                            {GetDate(fac?.timeLastTran) || '-------------'}
                        </span>
                    </div>
                    <div className="w-100p flex-row align-space-between">
                        <span className="d-block">Account Type:</span>
                        <span className="d-block">{UpperCase(fac?.type) || '-------------'}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FinUserStatementInfoLeft({ address, user, title }) {
    const { line1, line2, city, state, country, zip, mobile, email } = address || {};

    if (user && !user?.id) {
        return (
            <div className="fin-statement-left-col flex-col gap-20 w-100p">
                <div className="fin-user-statement__data bik">
                    <UserInfo
                        img="https://files.bikiran.com/assets/images/icon/icon-bik-logoV1.svg"
                        name="Bikiran"
                        email="info@bikiran.com"
                    />
                </div>
                <div className="fin-user-statement__address-sec">
                    <div className="fin-user-statement-address flex-col">
                        <div className="statement-title">Address</div>
                        <h2 className="ac-title">
                            <span>{title || '--------'}</span>
                        </h2>
                        <div className="statement-address">
                            <span>
                                Office No. 401, Sheikh Kamal IT Incubation & Training Center KUET,
                                KUET Road, Khulna 9203
                            </span>
                            <br />
                            <span>
                                <small>+880 1717 416667, info@bikiran.com</small>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="fin-statement-left-col flex-col gap-20 w-100p">
            <UserInfo name={user?.name} email={user?.email} />
            <div className="fin-user-statement__address-sec">
                <div className="fin-user-statement-address flex-col">
                    <div className="statement-title">Address</div>
                    <h2 className="ac-title">
                        <span>{title || '--------'}</span>
                    </h2>
                    <div className="statement-address">
                        <span>
                            {' '}
                            {[line1, line2, city, state, country, zip]
                                .filter((d) => !!d)
                                .join(', ') || '----------------------'}
                        </span>
                        <br />
                        <span>
                            <small>
                                {[mobile, email].filter((d) => !!d).join(', ') ||
                                    '----------------------'}
                            </small>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FinUserStatementView({ statementData }) {
    const { address, fac, user, transactions } = statementData || {};

    return (
        <div className={`fin-user-statement__body ${!statementData ? 'placeholder-mode' : ''}`}>
            <div className="flex-row align-space-between gap-20">
                <FinUserStatementInfoLeft address={address} title={fac?.title} user={user} />

                <FinUserStatementInfoRight fac={fac} user={user} />
            </div>

            <div className="fin-user-statement-table mt-20">
                <div className="title mb-10">Order Summery</div>

                <FinUserStatementTable transactions={transactions} />
            </div>
        </div>
    );
}

export default FinUserStatementView;
