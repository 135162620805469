import '../assets/style.css';

import RouteHandler from './RouteHandler';
import AuthProvider from './features/auth/AuthProvider';
import InitProvider from './features/init/InitProvider';
import AdvMenuProvider from './features/menus/AdvMenuProvider';
import TemplateProvider from './features/template/TemplateProvider';
import ComposeProviders from './utils/ComposeProviders';

function App() {
    return (
        <ComposeProviders
            components={[TemplateProvider, InitProvider, AuthProvider, AdvMenuProvider]}
        >
            <RouteHandler />
        </ComposeProviders>
    );
}

export default App;
