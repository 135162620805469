import React, { useState } from 'react';
import { ButtonGray, ButtonPurple } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import UserInfo from '../../../../features/user-info/UserInfo';
import LibModal from '../../../../lib/LibModal';
import { evaluate } from '../../../../utils/Math';
import { showCurrency } from '../../../../utils/Show';
import { handelMakePayment } from '../FinInvoiceOperation';

function InfoItem({ title, value }) {
    return (
        <div className="flex-row align-space-between position-middle">
            <span>{title}</span>
            <span>{value}</span>
        </div>
    );
}

const initialState = {
    amount: 0
};

function FinInvoiceAddPayment({ invoiceData, handelReload, setOpen }) {
    const { user, info } = invoiceData || {};

    const [formData, setFormData] = useState({
        ...initialState,
        amount: info?.amountPayable
    });

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const { currency, balance } = user || {};

    const inputAmount = formData?.amount?.toString();

    const newBalance = inputAmount?.length
        ? parseFloat(balance || 0) - parseFloat(inputAmount.replace(/,/g, '') || 0)
        : 0;

    const handelChange = (ev) => {
        const { name, value } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: evaluate(value) }));
        }
    };

    // amount without comma
    const payAmount = parseFloat(formData?.amount?.toString()?.replace(/,/g, ''));

    const handlePayClick = () => {
        setMessage('Paying....');
        handelMakePayment(authInfo, user.id, info?.id, payAmount)
            .then(({ message }) => {
                setMessage(message);
                setFormData(initialState);
                setOpen(false);
                handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="Add Payment" setOpen={setOpen}>
            <div className="inv-add-payment">
                <div>
                    <UserInfo img={user?.photoUrl} name={user?.name} email={user?.email} />
                </div>

                <div className="info">
                    <InfoItem
                        title="Balance"
                        currency="BDT"
                        value={`${currency} ${showCurrency(balance || 0)}`}
                    />

                    <InfoItem
                        title="Pay Amount"
                        value={
                            <div>
                                <input
                                    onChange={handelChange}
                                    value={formData.amount || ''}
                                    // onFocus={handleOnFocus}
                                    name="amount"
                                    placeholder="10"
                                    className="form-input"
                                    type="text"
                                    autoComplete="off"
                                />
                                <p className="info-text">
                                    Maximum amount {currency}&nbsp;
                                    {info?.amountPayable} (No VAT)
                                </p>
                            </div>
                        }
                    />

                    <InfoItem
                        title="New Balance"
                        currency="BDT"
                        value={`${currency} ${showCurrency(newBalance)}`}
                    />
                </div>

                <div className="action flex-row align-right">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />

                    <ButtonPurple evOnClick={handlePayClick} title="Pay" />
                </div>
            </div>
        </LibModal>
    );
}

export default FinInvoiceAddPayment;
