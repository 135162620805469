import { calculateDiscountPercentage, showCurrency } from '../../../utils/Show';
import {
    ProductDateField,
    ProductInputAmountField,
    ProductInputField
} from './ProductEditorInputs';

// total price inc. vat
const totalPrice = (data) => {
    const { priceOffer, quantity, vat } = data || {};

    const priceTotal = quantity
        ? parseFloat(priceOffer) * parseFloat(quantity)
        : parseFloat(priceOffer);

    const total = priceTotal + (priceTotal * parseInt(vat, 10)) / 100;

    if (!total) {
        return 0;
    }
    return showCurrency(total);
};

function Title() {
    return (
        <div className="flex-row gap-10 align-space-between position-middle">
            <h2 className="title">Property</h2>
            <hr className="w-100" />
        </div>
    );
}

function Info({ show, children }) {
    return <div className={`info-text ${show ? 'show' : ' '}`}>{children}</div>;
}

function DefaultFields({ formData }) {
    const discount = calculateDiscountPercentage(formData.price, formData.priceOffer);

    return (
        <div className="inputs-cont flex-row flex-wrap align-space-between">
            <ProductInputField title="Title" name="title" />

            <div className="inp-fill-2">
                <ProductDateField title="Date" name="subscriptionStart" />
            </div>

            <div className="inp-fill-2">
                <ProductInputField title="Quantity" name="quantity" />
            </div>

            <div className="inp-fill-3">
                <ProductInputAmountField
                    title="Price"
                    name="price"
                    unit={`BDT${formData?.unit ? `/${formData?.unit}` : ''}`}
                />
            </div>

            <div className="inp-fill-3">
                <ProductInputAmountField
                    title="Offer Price"
                    name="priceOffer"
                    unit={`BDT${formData?.unit ? `/${formData?.unit}` : ''}`}
                />
                <Info show={discount}>{`Discount: ${showCurrency(discount)}%`}</Info>
            </div>

            <div className="inp-fill-3">
                <ProductInputField title="Vat(%)" name="vat" unit="%    " />
                <Info show={totalPrice(formData)}>{`Total: ${totalPrice(formData)}`}</Info>
            </div>
        </div>
    );
}

function ProductEditorBodyEmail({ formData, modalProperty, propertyInputs }) {
    return (
        <div>
            <div className="default-field">
                <DefaultFields formData={formData} modalProperty={modalProperty} />
            </div>

            <div className="property-field">
                <Title />
                {propertyInputs || null}
            </div>
        </div>
    );
}

export default ProductEditorBodyEmail;
