import { useEffect, useState } from 'react';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { handleProjectInfo } from '../UserProjectsOperation';
import ProjectInfoRow1 from './ProjectInfoRow1';
import ProjectInfoRow2 from './ProjectInfoRow2';

const demoData = {
    id: 10000009,
    title: '-------------',
    faviconUrl: '',
    domain: '-------------',
    userInfo: {
        userId: 10000002,
        name: '--------------',
        photoUrl: '',
        email: '-----------------',
        phone: '-----------------'
    },
    focalPerson: {
        name: '------------------',
        email: '-------------------',
        phone: '------------------'
    },
    apps: [
        {
            appId: 10000126,
            appKey: '-----------------',
            appTitle: '-----------------',
            appTimeStart: 1691590998,
            appTimeEnd: 1707488598,
            status: 'active'
        },
        {
            appId: 10000127,
            appKey: '--------------------',
            appTitle: '--------------',
            appTimeStart: 1691591097,
            appTimeEnd: 1699539897,
            status: 'active'
        }
    ],
    created: 1691585000,
    status: 'active',
    empty: true
};

function ProjectInfoSection({ infoId, handlerOpenModal }) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const [projectData, setProjectData] = useState(null);

    // get project info by id
    useEffect(() => {
        setProjectData(null);
        handleProjectInfo(authInfo, infoId)
            .then(({ data }) => {
                setProjectData(data);
            })
            .catch((error) => {
                setMessage(error.toString(0));
            });
    }, [authInfo, infoId, setMessage]);

    const dataArr = projectData || demoData;
    // console.log(dataArr);

    return (
        <div className={`project-info-cont ${dataArr.empty ? 'placeholder-mode' : ''}`}>
            <ProjectInfoRow1 data={dataArr} />

            <ProjectInfoRow2 data={dataArr} handlerOpenModal={handlerOpenModal} />
        </div>
    );
}

export default ProjectInfoSection;
