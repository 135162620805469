import ProjectAddModal from '../modals/ProjectAddModal';
import ProjectAppAddModal from '../modals/ProjectAppAddModal';

function ProjectsModalController({
    modalType,
    closeClick,
    modalProps,
    setReloadKey,
    setReloadKeyInfo
}) {
    if (modalType === 'addProject') {
        return <ProjectAddModal setOpen={closeClick} setReloadKey={setReloadKey} />;
    }
    if (modalType === 'addApp') {
        return (
            <ProjectAppAddModal
                setOpen={closeClick}
                data={modalProps}
                setReloadKeyInfo={setReloadKeyInfo}
            />
        );
    }
    return null;
}

export default ProjectsModalController;
