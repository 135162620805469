/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { ButtonGray, ButtonPurple } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import UserInfo from '../../../../features/user-info/UserInfo';
import LibModal from '../../../../lib/LibModal';
import RadioButton from '../../../../utils/RadioButton';
import { showCurrency } from '../../../../utils/Show';
import { handelMakeVatPayment } from '../FinInvoiceOperation';

function PayOption({ evOnClick, title, value, checked }) {
    return (
        <div
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
            className={`pay-option flex-row align-space-between position-middle ${
                checked ? 'active' : ''
            }`}
        >
            <div className="flex-row position-middle">
                <RadioButton checked={checked} /> <span className="ml-1">{title}</span>
            </div>
            <div>
                <span>{value}</span>
            </div>
        </div>
    );
}

// function InfoItem({ title, value }) {
//     return (
//         <div className="flex-row align-space-between position-middle">
//             <span>{title}</span>
//             <span>{value}</span>
//         </div>
//     );
// }
const initialState = {
    type: '',
    amount: 0,
    note: ''
};

function FinInvoiceVatPay({ invoiceData, handelReload, setOpen }) {
    const [formData, setFormData] = useState({ ...initialState });

    const { user, info } = invoiceData || {};

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handelChange = (ev) => {
        const { name, value } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: value }));
        }
    };

    //   pay option click
    const handelPayOptionClick = (type, value) => {
        setFormData((d) => ({ ...d, type, amount: value }));
    };

    // when click on input field pay option view inactive
    // const handleOnFocus = () => {
    //     setFormData(initialState);
    // };

    const handlePayClick = () => {
        setMessage('Paying....');

        handelMakeVatPayment(
            authInfo,
            user.id,
            info?.id,
            formData.type,
            formData.amount,
            formData.note
        )
            .then(({ message }) => {
                setMessage(message);
                setFormData(initialState);
                setOpen(false);
                handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="Vat Pay" setOpen={setOpen}>
            <div className="inv-add-payment">
                <div>
                    <UserInfo img={user?.photoUrl} name={user?.name} email={user?.email} />
                </div>

                <div className="info">
                    <PayOption
                        evOnClick={() => handelPayOptionClick('we', info?.vatPayable)}
                        title="Processed by Bikiran"
                        value={
                            formData.type === 'we' &&
                            `${user?.currency} ${showCurrency(formData.amount)}`
                        }
                        checked={formData.type === 'we'}
                    />

                    <PayOption
                        evOnClick={() => handelPayOptionClick('customer', info?.vatPayable)}
                        title="Processed by Customer"
                        value={
                            formData.type === 'customer' &&
                            `${user?.currency} ${showCurrency(formData.amount)}`
                        }
                        checked={formData.type === 'customer'}
                    />
                    {formData.type === 'we' && user?.balance > 0 ? (
                        <div className="flex-row align-space-between position-middle">
                            <span>New Balance</span>
                            <span>{`${user?.currency} ${showCurrency(
                                parseFloat(user?.balance) - parseFloat(formData.amount)
                            )}`}</span>
                        </div>
                    ) : null}
                    {/* <InfoItem
                    title="Pay Amount"
                    value={
                        <input
                            onChange={handelChange}
                            onFocus={handleOnFocus}
                            value={formData.amount || ''}
                            name="amount"
                            placeholder="10"
                            className="form-input"
                            type="text"
                            autoComplete="off"
                        />
                    }
                /> */}

                    <div>
                        <label className="label" htmlFor="note">
                            Notes:
                        </label>
                        <textarea
                            onChange={handelChange}
                            value={formData.note || ''}
                            placeholder="Type Notes...."
                            className="form-input"
                            name="note"
                        />
                    </div>
                </div>

                <div className="action flex-row align-right">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />

                    <ButtonPurple evOnClick={handlePayClick} title="Pay" />
                </div>
            </div>
        </LibModal>
    );
}

export default FinInvoiceVatPay;
