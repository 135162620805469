import dayjs from 'dayjs';
import { createContext, useContext, useMemo, useState } from 'react';

const ProductEditorContext = createContext();

export function useProductEditor() {
    return useContext(ProductEditorContext);
}

const initialState = {
    show: false,
    property: '',
    mode: '',
    title: ''
};

function InvoiceProductEditorProvider({ children }) {
    const [modalProperty, setModalProperty] = useState(initialState);
    const [formData, setFormData] = useState({});

    const value = useMemo(() => {
        const handelChange = (ev) => {
            const { name } = ev.target;
            if (name) {
                setFormData((d) => ({ ...d, [name]: ev.target.value }));
            }
        };

        const handelCloseModal = () => {
            setModalProperty(initialState);
            setFormData({});
        };

        const handelOpenModal = (property, data = null) => {
            setModalProperty({
                show: true,
                property,
                mode: data === null ? 'add' : 'edit',
                title: data?.title || ''
            });

            if (data) {
                if (data?.subscriptionStart && typeof data?.subscriptionStart === 'number') {
                    const subStartDateString = dayjs(data?.subscriptionStart * 1000).format(
                        'YYYY-MM-DD'
                    );

                    setFormData({
                        ...data,
                        ...data?.properties,
                        subscriptionStart: subStartDateString
                    });
                } else {
                    setFormData({ ...data, ...data?.properties });
                }
            } else {
                setFormData({});
            }
        };

        return {
            setFormData,
            formData,
            handelChange,
            modalProperty,
            handelCloseModal,
            handelOpenModal
        };
    }, [formData, modalProperty]);

    return <ProductEditorContext.Provider value={value}>{children}</ProductEditorContext.Provider>;
}

export default InvoiceProductEditorProvider;
