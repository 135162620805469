import dayjs from 'dayjs';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../features/auth/AuthProvider';
import AxiosAPI from '../../../utils/AxiosAPI';

const InvoiceContext = createContext();

export function useInvoiceUpdate() {
    return useContext(InvoiceContext);
}

const initialState = {
    loading: true,
    error: 1,
    message: 'Data not fetched',
    invoiceId: 0,
    info: {
        id: 0,
        currency: '',
        paymentStatus: '',
        paymentTime: 0,
        timeIssue: 0,
        timeDue: 0,

        discount: 0,
        amountPaid: 0,
        amountPayable: 0,
        priceOffer: 0,
        priceTotal: 0,

        status: ''
    },
    addresses: {
        billing: null,
        shipping: null
    },
    items: [],
    user: null
};

function FinInvoiceUpdateProvider({ children, id }) {
    const { authInfo } = useAuth();
    const [invoiceData, setInvoiceData] = useState({ ...initialState, invoiceId: id });
    const [reloadKey, setReloadKey] = useState(Math.random());
    const [printVersion, setPrintVersion] = useState(0);
    const [modal, setModal] = useState(null);

    useEffect(() => {
        if (!authInfo.loading) {
            setInvoiceData(initialState);
            AxiosAPI(authInfo)
                .setPath(`/api/finance/invoice/${id}/info/`)
                .get()
                .then(({ data }) => {
                    setInvoiceData({ ...initialState, ...data, loading: false });
                })
                .catch((err) => {
                    setInvoiceData((d) => ({
                        ...d,
                        loading: false,
                        error: 2,
                        message: err.toString()
                    }));
                });
        }
    }, [authInfo, id, reloadKey]);

    const value = useMemo(() => {
        // console.log(invoiceData);

        // open modal
        const openModal = (type) => {
            setModal(type);
        };

        // reload
        const handelReload = () => {
            setInvoiceData({ ...initialState, invoiceId: id });
            setReloadKey(Math.random());
        };

        const handelChangeBasic = (customId, dateIssue, dateDue) =>
            new Promise((resolve, reject) => {
                // --Validate Custom ID
                // --Validate Date Issue
                // --Validate Date Due
                if (customId && customId.length < 4) {
                    reject(Error('Custom ID must be 4 character long.'));
                } else if (!dayjs(dateIssue || '---').isValid()) {
                    reject(Error('Invalid Issue Date'));
                } else if (!dayjs(dateDue || '---').isValid()) {
                    reject(Error('Invalid Due Date'));
                } else {
                    AxiosAPI(authInfo)
                        .setPath(`/api/finance/invoice/${id}/update-basic/`)
                        .post({
                            customId,
                            dateIssue,
                            dateDue
                        })
                        .then(({ data }) => {
                            if (data.error === 0) {
                                setInvoiceData((d) => ({ ...d, ...data, loading: false }));
                                resolve(data);
                            } else {
                                reject(Error(data.message));
                            }
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });

        const handelChangeCurrency = (currency, customRate) =>
            new Promise((resolve, reject) => {
                // --Validate Custom ID
                // --Validate Date Issue
                // --Validate Date Due
                if (!currency) {
                    reject(Error('Please select currency.'));
                } else if (!customRate) {
                    reject(Error('Please enter rate'));
                } else if (customRate < 0.01) {
                    reject(Error('Please enter valid rate'));
                } else {
                    AxiosAPI(authInfo)
                        .setPath(`/api/finance/invoice/${id}/update-currency/`)
                        .post({
                            currency,
                            ratio: customRate
                        })
                        .then(({ data }) => {
                            if (data.error === 0) {
                                setInvoiceData((d) => ({ ...d, ...data, loading: false }));
                                resolve(data);
                            } else {
                                reject(Error(data.message));
                            }
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });

        const handelChangeAddress = (addressData) =>
            new Promise((resolve, reject) => {
                AxiosAPI(authInfo)
                    .setPath(`/api/finance/invoice/${id}/update-address/`)
                    .post({ id: addressData.id, addressInfo: addressData })
                    .then(({ data }) => {
                        if (data.error === 0) {
                            setInvoiceData((d) => ({ ...d, ...data, loading: false }));
                            resolve(data);
                        } else {
                            reject(Error(data.message));
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        const handelChangeUser = (currentUserId, newUserId) =>
            new Promise((resolve, reject) => {
                AxiosAPI(authInfo)
                    .setPath(`/api/finance/invoice/${id}/update-user/`)
                    .post({ currentUserId, newUserId })
                    .then(({ data }) => {
                        if (data.error === 0) {
                            setInvoiceData((d) => ({ ...d, ...data, loading: false }));
                            resolve(data);
                        } else {
                            reject(Error(data.message));
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        const handelChangeTitle = (title) =>
            new Promise((resolve, reject) => {
                AxiosAPI(authInfo)
                    .setPath(`/api/finance/invoice/${id}/update-title/`)
                    .post({ title })
                    .then(({ data }) => {
                        if (data.error === 0) {
                            setInvoiceData((d) => ({ ...d, ...data, loading: false }));
                            resolve(data);
                        } else {
                            reject(Error(data.message));
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        const handelChangeNote = (noteTitle, noteDescription) =>
            new Promise((resolve, reject) => {
                AxiosAPI(authInfo)
                    .setPath(`/api/finance/invoice/${id}/update-note/`)
                    .post({ noteTitle, noteDescription })
                    .then(({ data }) => {
                        if (data.error === 0) {
                            setInvoiceData((d) => ({ ...d, ...data, loading: false }));
                            resolve(data);
                        } else {
                            reject(Error(data.message));
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        const handelAddProduct = (formData) =>
            new Promise((resolve, reject) => {
                AxiosAPI(authInfo)
                    .setPath(`/api/finance/invoice/${id}/add-product/`)
                    .post({ ...formData })
                    .then(({ data }) => {
                        if (data.error === 0) {
                            setInvoiceData((d) => ({ ...d, ...data, loading: false }));
                            resolve(data);
                        } else {
                            reject(Error(data.message));
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        const handelUpdateProduct = (formData) =>
            new Promise((resolve, reject) => {
                AxiosAPI(authInfo)
                    .setPath(`/api/finance/invoice/${id}/update-product/`)
                    .post({ ...formData })
                    .then(({ data }) => {
                        if (data.error === 0) {
                            setInvoiceData((d) => ({ ...d, ...data, loading: false }));
                            resolve(data);
                        } else {
                            reject(Error(data.message));
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        const handelRemoveProduct = (formData) =>
            new Promise((resolve, reject) => {
                AxiosAPI(authInfo)
                    .setPath(`/api/finance/invoice/${id}/remove-product/`)
                    .post({ ...formData })
                    .then(({ data }) => {
                        if (data.error === 0) {
                            setInvoiceData((d) => ({ ...d, ...data, loading: false }));
                            resolve(data);
                        } else {
                            reject(Error(data.message));
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        return {
            modal,
            setModal,
            openModal,
            invoiceData,
            handelChangeBasic,
            handelChangeCurrency,
            handelChangeAddress,
            handelChangeUser,
            handelChangeTitle,
            handelChangeNote,
            handelAddProduct,
            handelUpdateProduct,
            handelRemoveProduct,
            handelReload,
            printVersion,
            setPrintVersion
        };
    }, [authInfo, id, invoiceData, modal, printVersion]);

    return <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>;
}

export default FinInvoiceUpdateProvider;
