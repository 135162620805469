/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import BikPaginateProvider from './BikPaginateProvider';
import './assets/bik-paginate.css';

function BikPaginate({
    position = 'center',
    className = '',
    pageCount = 1,
    currentPage = 1,
    handleFirstPage,
    handlePreviousPage,
    handleCurrentNoPage,
    handleNextPage,
    handleLastPage
}) {
    return (
        <BikPaginateProvider>
            <div className={`bik-paginate ${position} ${className}`}>
                <ul className="bik-paginate__item">
                    <li onClick={handleFirstPage} className="">
                        &lt;&lt;
                    </li>
                    <li onClick={handlePreviousPage}>&lt;</li>
                    {Array.from({ length: pageCount }, (_, i) => i + 1).map((item) => (
                        <li
                            key={Math.random()}
                            onClick={() => handleCurrentNoPage(item)}
                            className={currentPage === item ? 'active' : ''}
                        >
                            {item}
                        </li>
                    ))}
                    <li onClick={handleNextPage}>&gt;</li>
                    <li onClick={handleLastPage}>&gt;&gt;</li>
                </ul>
            </div>
        </BikPaginateProvider>
    );
}

export default BikPaginate;
