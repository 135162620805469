import React from 'react';
import { icons } from '../../../../configs/Icons';

// const initialState = { mode: false, searchValue: '', selectedUser: null, users: [] };

function AddressHeaderArea({ setAdSearch, adSearch }) {
    // search icon click
    const handelSearchClick = () => {
        setAdSearch((cuData) => ({ ...cuData, mode: !adSearch.mode }));
        // setAdSearch((cuData) => ({ ...cuData, selectedUser: null }));
    };

    return (
        <div className="flex-row position-middle m-title">
            <div>Modify Address</div>

            <div onClick={handelSearchClick} role="button" tabIndex={-1} className="search-icon">
                <img src={icons.search} alt="search" />
            </div>
        </div>
    );
}

export default AddressHeaderArea;
