/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthProvider';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import { ButtonCreate } from '../../../features/user_updater/UserUpdaterButtons';
import UserUpdaterSearchedUser from '../../../features/user_updater/components/UserUpdaterSearchedUser';
import UserUpdaterSelectedUser from '../../../features/user_updater/components/UserUpdaterSelectedUser';
import AxiosAPI from '../../../utils/AxiosAPI';
import { handelCreateInvoice } from './FinInvoiceOperation';

function FinInvoiceCreate({ currencyData }) {
    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const [searchValue, setSearchValue] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const navigate = useNavigate();
    const [currencyRate, setCurrencyRate] = useState(null);

    const [formData, setFormData] = useState({
        user: '',
        // dateIssue: dayjs().format('YYYY-MM-DD'),
        // dateDue: dayjs().format('YYYY-MM-DD'),
        currency: '',
        ratio: currencyRate
    });

    const ref = useRef();
    const rateRef = useRef();

    const handelSearchField = (ev) => {
        setSearchValue(ev.target.value);

        if (ev.target.value.length > 2) {
            setSelectedUser(null);
            AxiosAPI(authInfo)
                .setPath(`/api/users/search/`, {
                    q: ev.target.value,
                    limit: 20
                })
                .get()
                .then(({ data }) => {
                    if (data.error === 0) {
                        setUsers(data.data);
                    } else {
                        setUsers(null);
                        setMessage(data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setUsers(null);
                    setMessage(err.toString());
                });
        } else {
            setUsers(null);
        }
    };

    const handelUserClick = (item) => {
        setSelectedUser(item);
        setFormData({ ...formData, user: item?.id });
        ref.current.value = '';
    };

    const isSearchedUser = searchValue?.length && users && !selectedUser;

    const handleCreateInvoice = () => {
        formData.ratio = rateRef.current.value;

        setMessage('Creating Invoice...');
        handelCreateInvoice(authInfo, formData)
            .then(({ error, message, invoiceId }) => {
                if (error === 0) {
                    navigate(mkRelativeUrl(`/finance/sell-invoice/${invoiceId}/update/`));
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const handleSelectValue = (e) => {
        setFormData({ ...formData, currency: e.target.value });
        setCurrencyRate(currencyData?.find((item) => item.currency === e.target.value)?.rate);
    };

    return (
        <div className="user-updater-container flex-col align-stretch">
            <div className={`updater-search-area ${isSearchedUser ? 'active' : ''}`}>
                <div>
                    <label htmlFor="search">Search User</label>

                    <input
                        id="search"
                        ref={ref}
                        onChange={handelSearchField}
                        placeholder="Type minimum 3 character"
                        type="text"
                        autoComplete="off"
                    />

                    <UserUpdaterSearchedUser
                        show={isSearchedUser}
                        data={users}
                        handelUserClick={handelUserClick}
                    />
                </div>
            </div>

            <div className="user-updater-body">
                <UserUpdaterSelectedUser data={selectedUser} />
            </div>

            <div className="user-custom-data flex-col gap-10 w-100p">
                {/* <div className="date-issue w-100p flex-row align-space-between position-middle">
                    <div className="title">Date of Issue</div>
                    <Flatpickr
                        options={{ dateFormat: 'Y-m-d' }}
                        value={dayjs().format('YYYY-MM-DD')}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                dateIssue: dayjs(e[0]).format('YYYY-MM-DD')
                            })
                        }
                        id="flatpickrInput"
                    />
                </div>

                <div className="date-due w-100p flex-row align-space-between position-middle">
                    <div className="title">Due Date</div>
                    <Flatpickr
                        value={dayjs().format('YYYY-MM-DD')}
                        onChange={(e) =>
                            setFormData({ ...formData, dateDue: dayjs(e[0]).format('YYYY-MM-DD') })
                        }
                    />
                </div> */}

                <div className="currency-type  w-100p flex-row align-space-between position-middle">
                    <div className="title">Currency</div>
                    <div className="select-currency">
                        <select className="custom-select" onChange={(e) => handleSelectValue(e)}>
                            <option value="">Select Currency</option>
                            {currencyData?.map((item) => (
                                <option key={item.currency} value={item.currency}>
                                    {item.currency}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="currency-rate  w-100p flex-row align-space-between position-middle">
                    <div className="title">Current Rate</div>
                    <input
                        className="custom-input"
                        type="text"
                        ref={rateRef}
                        value={currencyRate || ''}
                        placeholder="Current Rate"
                        disabled={formData?.currency !== 'BDT'}
                        onChange={(e) => setCurrencyRate(e.target.value)}
                    />
                </div>
            </div>
            <div className="flex-row flex-row align-right mt-10">
                <ButtonCreate evOnClick={handleCreateInvoice} />
            </div>
        </div>
    );
}

export default FinInvoiceCreate;
