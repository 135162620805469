import React, { useRef, useState } from 'react';
import { ButtonPurple } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import InstOption from '../../../../utils/InstOption';
import { GetDate, showCurrency } from '../../../../utils/Show';
import { UserInfoV3 } from '../../../finance/invoice_update/invoice_view/components/UserInfo';

function TableRow({ item, handleStarred, setUserAssignModal }) {
    const ref = useRef(null);

    const [showMenu, setShowMenu] = useState(false);
    const handleShowMoreMenu = () => setShowMenu((cuData) => !cuData);

    const handleBgActive = () => {
        if (!ref.current.classList.contains('active')) {
            ref.current.parentElement.childNodes.forEach((tr) => {
                if (tr.previousElementSibling?.classList.contains('previous')) {
                    tr.previousElementSibling?.classList.remove('previous');
                }
                tr.classList.remove('active');
            });

            ref.current.previousElementSibling?.classList.add('previous');
            ref.current.classList.add('active');
        }
    };
    const handleStatement = (id) => {
        console.log(id);
    };

    return (
        <tr className={item?.demo ? 'placeholder-mode' : ''} ref={ref} onClick={handleBgActive}>
            <td className="text-left p-5">
                <div className="flex-row position-middle align-space-between">
                    <span>
                        <button
                            type="button"
                            className="started-btn"
                            onClick={(e) => handleStarred(e, item?.id)}
                        >
                            <img
                                src={item?.isStar ? icons.startFill : icons.startLine}
                                alt=""
                                className="d-block"
                            />
                        </button>
                    </span>
                    <span>#{item?.id}</span>
                </div>
            </td>
            <td className="text-left">
                {item?.userInfo ? (
                    <UserInfoV3
                        name={item?.userInfo?.name}
                        email={item?.userInfo?.email}
                        img={item?.userInfo?.photoUrl}
                    />
                ) : (
                    <ButtonPurple
                        title="Assign User"
                        evOnClick={() =>
                            setUserAssignModal((cuData) => ({
                                ...cuData,
                                show: true,
                                id: item?.id
                            }))
                        }
                    />
                )}
            </td>
            <td>
                <div className="text-left">
                    <span className="text-dot">{item?.vendor}</span>
                </div>
            </td>
            <td>
                <span className="text-dot">{item?.domain}</span>
            </td>
            <td className="text-center">
                <span>{showCurrency(item?.price)}</span>
            </td>
            <td>
                <div className="flex-row align-center">
                    <span>{GetDate(item?.executed)}</span>
                </div>
            </td>
            <td className="text-center">
                <span>{GetDate(item?.renew)}</span>
            </td>
            <td className="text-center">
                <span>{GetDate(item?.expired)}</span>
            </td>
            <td className="text-center text-capitalize">
                <span className={`status ${item?.status}`}>{item?.status}</span>
            </td>

            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button type="button" className="" onClick={handleShowMoreMenu}>
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        <button type="button" onClick={() => handleStatement(item?.id)}>
                            Manage
                        </button>
                    </InstOption>
                </div>
            </td>
        </tr>
    );
}

function TableBody({ hostingListData, handleStarred, setUserAssignModal }) {
    return (
        <tbody>
            {hostingListData.map((item) => (
                <TableRow
                    key={item.id}
                    item={item}
                    handleStarred={handleStarred}
                    setUserAssignModal={setUserAssignModal}
                />
            ))}
        </tbody>
    );
}
function TableHeader() {
    return (
        <thead>
            <tr>
                <th width="12%" className="text-left">
                    SL
                </th>
                <th width="18%" className="text-left">
                    User
                </th>
                <th width="8%">Title</th>
                <th width="9%">Server</th>
                <th width="8%">Domain</th>
                <th width="10%">Email</th>
                <th width="10%">Price</th>
                <th width="10%">Expired</th>
                <th width="7%">Status</th>
                <th width="8%" className="text-right">
                    Action
                </th>
            </tr>
        </thead>
    );
}
function HostingTable({ hostingListData, handleStarred, setUserAssignModal }) {
    return (
        <div className="bik-table-parent">
            <table className="bik-table">
                <TableHeader />
                <TableBody
                    hostingListData={hostingListData}
                    handleStarred={handleStarred}
                    setUserAssignModal={setUserAssignModal}
                />
            </table>
        </div>
    );
}

export default HostingTable;
